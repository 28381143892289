import React, { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import i18next from "i18next";
import isTravelBenefitsActive, {
	hasSpecialSale,
	isBothActive,
	isFlexibilityActive
} from "../../utils/isTravelBenefitsActive";

import AsideProfile from "../../components/Profile/AsideProfile";
import NewHeaderProfile from "./components/NewHeaderProfile";
import TotalRewards from "../../components/Profile/TotalRewards";
import TravelBenefits from "../../components/Profile/TravelBenefits";
import VentaEspecial from "../../components/Profile/ventaespecial";
import BenefitsDiscounts from "../../components/Profile/BenefitsDiscounts";
import { ServicioContext } from "../../globalState";

import statement from "../../assets/images/profile/tab-icon01.svg";
import money from "../../assets/images/profile/tab-icon02.svg";
import money2 from "../../assets/images/profile/tab-icon03.svg";
import Flexi from "../Flexibility/assets/isotipo.png";
import { ReactComponent as FlexiSVG } from "../Flexibility/assets/isotipo.svg";
import { ReactComponent as Caballero } from "../../assets/identidad/caballero.svg";
import { AnimatePresence, motion } from "framer-motion";
import "./Styles/venta2020father.css";
import { isLocal, isFromDev, isFromQA } from "./../../core/Ambientes";
import "./profile.css";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getPrivateUser } from "../../redux/actions/actions-private";
import Flexibility from "../Flexibility/Flexibility";
import "./../../components/TriquesTrueques/css/triques.css";
import InfoEnvironment from "../../utils/info.environment";
import { isValidURL } from "../../utils/isValidEmail";
const sha1 = require("sha1");

const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`;
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`;

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd = `${process.env.REACT_APP_API_TOKEN}`;

// # Liga validada
const urlValid = isLocal === true ? urlLocal : isFromDev === true ? urlDev : isFromQA === true ? urlQA : urlProd;

// Se genera el token segun el ambiente
const tokenValid =
	isLocal === true ? tokenLocal : isFromDev === true ? tokenDev : isFromQA === true ? tokenQA : tokenProd;

const cabecera = new Headers({
	"Content-type": "application/json",
	Authorization: "Bearer " + tokenValid
});

function ProfileRewards({
	activeTab,
	privateUserData,
	publicUserData,
	privateData,
	privateLoadedAt,
	getPrivateUser,
	publicData
}) {
	const { serviceData } = useContext(ServicioContext);
	const history = useHistory();
	if(localStorage.getItem('pais') !== 'MX' || localStorage.getItem('tipo_empleado_AM') !== 'NS'){
		history.push("/mis-beneficios")
	}
	
	const [tabActive, setTabActive] = useState(
		activeTab === null || activeTab === undefined ? 'rewards' : activeTab
	);
	const [reduxLoading, setReduxLoading] = useState(true);
	const [flexActive, setFlexActive] = useState(false);
	const [publicDataSelected, setPublicDataSelected] = useState(null);
	const [privateDataSelected, setPrivateDataSelected] = useState(null);
	const [isLocalHost, setIsLocalHost] = useState(false);
	const [specialSale, setSpecialSale] = useState(false);
	const [boothActiveItems, setBoothActiveItems] = useState(false);

	const randomNumber = (min, max) => {
		return Math.floor(Math.random() * (max - min + 1) + min);
	};

	const infoEnvironment = new InfoEnvironment();
	useEffect(() => {
		async function validateFlex() {
			const isItActive = await isFlexibilityActive();
			setFlexActive(isItActive);
		}
		validateFlex();
		async function validateSpecialSale() {
			const urlItemsValidator = isValidURL(`${urlValid}menu-items-validator`);
			const specialActive = await fetch(urlItemsValidator, {
				method: "GET",
				headers: {
					Authorization: "Bearer " + tokenValid,
					"Content-Type": "application/json"
				}
			})
				.then((res) => res.json())
				.catch(console.error);

			const urlVEUsuarios = isValidURL(`${urlValid}ve-usuarios?filters[email][$contains]=${localStorage.getItem("email")}`);
			const userSpecial = await fetch(urlVEUsuarios, {
				method: "GET",
				headers: {
					Authorization: "Bearer " + tokenValid,
					"Content-Type": "application/json"
				}
			})
				.then((res) => res.json())
				.catch(console.error);
			// alert(
			// 	userSpecial.length > 0 ? specialActive.venta_especial : false
			// );
			//const hasSpecial = ""; //userSpecial.length > 0 ? specialActive.venta_especial : false;
			// const hasSpecial = userSpecial.length > 0 ? true : false;
			const hasSpecial = userSpecial.data.length > 0 ? specialActive.data.attributes.venta_especial : false;
			setSpecialSale(hasSpecial);
			setBoothActiveItems(hasSpecial && isTravelBenefitsActive() ? true : false);
		}
		validateSpecialSale();
		if (window.location.hostname === "localhost") {
			setIsLocalHost(true);
			const salarioItem = randomNumber(15000, 150000);
			const valesItem = randomNumber(3000, 5000);
			const autoBono = randomNumber(50000, 150000);
			const utilBono = randomNumber(0, 5000);
			const seguroVida = randomNumber(500000, 1500000);
			const sgmm = randomNumber(20000, 150000);
			const sgmmBenef = randomNumber(1, 4);
			const checkup = randomNumber(0, 150000);
			const travelBenefits = randomNumber(150000, 500000);
			setPrivateDataSelected({
				aguinaldo: salarioItem,
				bonoAutomovil: autoBono,
				bonoAutomovilMensual: autoBono > 0 ? autoBono / 12 : autoBono,
				bonoDesempeno: salarioItem * 2,
				bonoUtiles: utilBono,
				bonoUtilesMensual: utilBono > 0 ? utilBono / 12 : utilBono,
				compensacion: 0,
				compensacionMensual: 0,
				fondoAhorro: salarioItem - salarioItem * 0.4,
				lti: 0,
				primaVacacional: salarioItem / 4.286,
				ratio: ".90",
				salario: salarioItem,
				salarioAnual: salarioItem * 12,
				sumaAnual: 0,
				sumaAnualTotal:
					salarioItem * 12 +
					salarioItem +
					salarioItem / 4.286 +
					(salarioItem - salarioItem * 0.4) +
					valesItem * 12 +
					salarioItem * 2,
				sumaMensual: salarioItem + valesItem,
				valesDespensa: valesItem * 12,
				valesMensual: valesItem,
				seguroVida,
				sgmm,
				sgmmBenef,
				checkup,
				travelBenefits,
				totalBeneficios: seguroVida + sgmm + checkup + travelBenefits
			});
			setPublicDataSelected({
				antiguedadEmpleado: "2 Años 3 Meses ",
				antiguedadrol: "04/11/2019",
				apellidoMaterno: "BRISEÑO",
				apellidoPaterno: "GARCIA",
				codigo_empresa: "02",
				compania: "Aeroméxico",
				country: "MX",
				direccion: "RECURSOS HUMANOS",
				direccionArea: "RECURSOS HUMANOS",
				emailhrbp: "eparada@aeromexico.com",
				fechaContra: "04/11/2019",
				gradopydid: null,
				gradopydnombre: null,
				grupo: "02 NSD - NO SINDICALIZADOS",
				grupopydid: null,
				grupopydnombre: null,
				nivel: "6",
				noEmpleado: "357919",
				nombre: "KATIA ITZEL ALVARADO AMARO",
				nombrehrbp: "PARADA ELVIRA",
				posicion: "ESPECIALISTA SR HERRAMIENTAS DIGITALES RH",
				subordinados: null,
				subsidiaria: "Aerovías de México S.A. de C.V.",
				territorio_mexicano: "1",
				tipo_empleado: "NS",
				ubicacion: "CECAM",
				variable1: "14",
				variable2: "140"
			});
			setSpecialSale(true);
		} else {
			setPrivateDataSelected(privateData);
			setPublicDataSelected(publicData);
			const fiveMinutes = 60 * 5 * 1000;

			if (!privateUserData || new Date() - new Date(privateLoadedAt) > fiveMinutes) {
				// LOADS PRIVATE DATA FROM REDUX
				getPrivateUser();
				setReduxLoading(false);
			}
		}
	}, []);

	return (
        <>
            <div className="profile" style={{ marginTop: '28px' }}>
                {/* Inicia el termino global */}
                    {/* Inicia el menu lateral izquierdo*/}
                    <div className="contProfileMenu">
                        <div className="profileContainers" id="profileMenu">
                            {isLocalHost ? (
                                <AsideProfile publicData={publicDataSelected} privateData={privateDataSelected} />
                            ) : (
                                <>
                                    {privateDataSelected && publicDataSelected && (
                                        <AsideProfile
                                            publicData={publicDataSelected}
                                            privateData={privateDataSelected} />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    {/* Terminal el menu lateral izquierdo */}
                    {/* Inicia el menu lateral derecho */}
                    <div className="profileContainers" id="profileContent">
                        {/** Inicia parte de tabs y contendios **/}
                        <NewHeaderProfile />
                        <div className="ProfileWrapperCont">
                            {/** Inicia parte de tabs **/}
                            <div className={`profile-tabs`}>
                                <div className="container-tabs">
                                    <ul className={`${flexActive ? boothActiveItems ?  'grid grid-cols-5' : 'grid grid-cols-4' : boothActiveItems ? 'grid grid-cols-4' : 'grid grid-cols-3' }`}>
                                        {(localStorage.getItem('pais') === 'MX' && localStorage.getItem('tipo_empleado_AM') === 'NS') ? (
											<li className={`${ tabActive === 'rewards' ? 'profile-tab-active rewards' : 'profile-tab' }`} onClick={() => setTabActive('rewards')} name="rewards">
												<div className="textTabsCont">
													<img src={statement} alt="Icon Profile" className="icon_profile" />
													<p> {i18next.t( 'MiExperAM.compensacion.tab' )}</p>
												</div>
											</li>
										) : null}

                                        {isTravelBenefitsActive() && (
                                        <li className={`${tabActive === 'travel' ? 'profile-tab-active travel' : 'profile-tab' }`} onClick={() => setTabActive('travel')} name="travel">
                                            <div className="textTabsCont">
                                                <img src={money} alt="Icon Profile" className="icon_profile" />
                                                <p> {i18next.t( 'MiExperAM.travel.tab' )} </p>
                                            </div>
                                        </li>
                                        )}

                                        {specialSale && (
                                        <li className={`${tabActive === 'ventaespecial' ? 'profile-tab-active ventaespecial' : 'profile-tab' }`} onClick={() => setTabActive('ventaespecial') } name="ventaespecial">
                                            <div className="textTabsCont">
                                                <img src={money} alt="Icon Profile" className="icon_profile" />
                                                <p> {i18next.t( 'MiExperAM.venta.tab' )} </p>
                                            </div>
                                        </li>
                                        )}

										<li className={`${ tabActive === 'benefits' ? 'profile-tab-active benefits' : 'profile-tab' }`} onClick={() => setTabActive('benefits')} name="benefits">
											<div className="textTabsCont">
												<img src={money2} alt="Icon Profile" className="icon_profile" />
												<p> {i18next.t('MiExperAM.benef.tab')} </p>
											</div>
										</li>
										

										{(localStorage.getItem('pais') === 'MX' && localStorage.getItem('tipo_empleado_AM') === 'NS') ? (
											flexActive ? (
												<li className={`${tabActive === 'flexibility' ? 'profile-tab-active' : 'profile-tab' }`} onClick={() => setTabActive('flexibility') } name="flexibility" >
													<div className="textTabsCont" style={{display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
														<FlexiSVG className="order-1 mr-2 h-6 w-auto" />
														<p className="order-2"> My Flexibility </p>
													</div>
												</li>
											) : null
										) : null}

                                    </ul>
                                </div>
                            </div>
                            {/** Termina parte de tabs **/}
                            {/** Inicia parte de contenido **/}
                            <div className="profile-tabs-content">
                                <AnimatePresence>
									{(localStorage.getItem('pais') === 'MX' && localStorage.getItem('tipo_empleado_AM') === 'NS') ? (
											<>
												{tabActive === 'rewards' && (
													<>
														{!isLocalHost ? (
														<Fragment>
															{!privateUserData ? (
																<motion.div style={{ display: 'flex',flexDirection: 'column',alignItems: 'center',justifyContent: 'center' }} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ repeat: 'infinity',repeatType: 'loop', duration: 1 }} >
																	<p className="loader-rewards"> Estamos cargando tu información, en 30 segundos podrás consultarla. </p>
																	<Caballero style={{height: '100px',width: 'auto' }} />
																</motion.div>
															) : (
																<TotalRewards data={publicData} dataPriv={privateData} />
															)}
														</Fragment>
														) : (
															<Fragment> <TotalRewards data={publicDataSelected} dataPriv={privateDataSelected} /> </Fragment>
														)}
													</>
												)}
												{tabActive === 'flexibility' && <Flexibility />}
												{tabActive === 'travel' && <TravelBenefits />}
												{tabActive === 'ventaespecial' && <VentaEspecial />}
												{tabActive === 'benefits' && <BenefitsDiscounts />}
											</>
										) : null}
                                </AnimatePresence>
                            </div>
                            {/** Termina parte de contenido **/}
                        </div>
                    </div>
                    {/* Terminal el menu lateral derecho */}
                {/* Termina el contenido global */}
            </div>
        </>
    );
	// } else {
	// 	return <h1>Cargando por favor espere</h1>;
	// }
}

/*
-██████╗ ██████╗ ███╗   ██╗███╗   ██╗███████╗ ██████╗████████╗             
██╔════╝██╔═══██╗████╗  ██║████╗  ██║██╔════╝██╔════╝╚══██╔══╝             
██║     ██║   ██║██╔██╗ ██║██╔██╗ ██║█████╗  ██║        ██║                
██║     ██║   ██║██║╚██╗██║██║╚██╗██║██╔══╝  ██║        ██║                
╚██████╗╚██████╔╝██║ ╚████║██║ ╚████║███████╗╚██████╗   ██║                
-╚═════╝ ╚═════╝ ╚═╝  ╚═══╝╚═╝  ╚═══╝╚══════╝ ╚═════╝   ╚═╝                
██╗    ██╗██╗████████╗██╗  ██╗    ██████╗ ███████╗██████╗ ██╗   ██╗██╗  ██╗
██║    ██║██║╚══██╔══╝██║  ██║    ██╔══██╗██╔════╝██╔══██╗██║   ██║╚██╗██╔╝
██║ █╗ ██║██║   ██║   ███████║    ██████╔╝█████╗  ██║  ██║██║   ██║ ╚███╔╝ 
██║███╗██║██║   ██║   ██╔══██║    ██╔══██╗██╔══╝  ██║  ██║██║   ██║ ██╔██╗ 
╚███╔███╔╝██║   ██║   ██║  ██║    ██║  ██║███████╗██████╔╝╚██████╔╝██╔╝ ██╗
-╚══╝╚══╝ ╚═╝   ╚═╝   ╚═╝  ╚═╝    ╚═╝  ╚═╝╚══════╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝
*/
const mapStateToProps = (state) => ({
	privateUserData: state.privateUser.privateUserData,
	publicUserData: state.publicUser.publicUserData,
	privateData: state.privateUser.privateData,
	privateLoadedAt: state.privateUser.privateLoadedAt,
	publicData: state.publicUser.publicData
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ getPrivateUser }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRewards);
