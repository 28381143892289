import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Banner from "./assets/banner-procesos-administrativos.jpeg";
import logoAMX from "./assets/aeromexico.png";
import i18next from "i18next";
import fakeStockER from "./usr_aspa2024"; 
import fateVarER from "./fat_aspa2024";

const AccionesPersonales = () => {
    const [email, setEmail] = useState('');
    const [id, setId] = useState('');
    const [intentos, setIntentos] = useState( parseInt(localStorage.getItem("intentos")) || 0 );
    const [bloqueado, setBloqueado] = useState( localStorage.getItem("bloqueado") === "true" || false );
    const [tiempoRestante, setTiempoRestante] = useState( parseInt(localStorage.getItem("tiempoRestante")) || 180);
    const [notificacion, setNotificacion] = useState(null); // Estado para la notificación
    const [isValidUser, setIsValidUser] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [salarioBase, setSalarioBase] = useState(0);
    const [horasVuelo, setHorasVuelo] = useState(0);
    const [salarioPorHoras, setSalarioPorHoras] = useState(0);

    /*INICIA PARA TEMAS DE LOGIN */

    /* Function para el bloqueo de intento */
    useEffect(() => {
        let timer;
        if (bloqueado && tiempoRestante > 0) {
            timer = setInterval(() => {
                setTiempoRestante((prev) => {
                    const newTime = prev - 1;
                    localStorage.setItem("tiempoRestante", newTime); // Actualiza en localStorage
                    return newTime;
                });
            }, 1000);
        } else if (tiempoRestante === 0) {
            setBloqueado(false);
            setIntentos(0);
            localStorage.removeItem("tiempoRestante"); // Limpia el tiempo restante
            localStorage.removeItem("bloqueado");
            localStorage.removeItem("intentos"); // Reinicia intentos y bloqueo
        }
        return () => clearInterval(timer);
    }, [bloqueado, tiempoRestante]);

    /* Funcion para identificar usuario */
    const handleLogin = () => {
        if (bloqueado) return;
        const user = fakeStockER.find((user) => user.email === email && user.passConstra === id);

        if (user) {
            setIsValidUser(true);
            setCurrentUser(user);
            setIntentos(0);
            localStorage.removeItem("intentos");
            
            // Verificar el tipo de contrato del usuario
            const contratoEncontrado = fateVarER.find(
                (contrato) => contrato.contrato === user.contrato // user.contrato debe corresponder al tipo de contrato
            );
            
            // Si se encuentra el contrato, asignamos los detalles al currentUser
            const detallesContrato = contratoEncontrado ? contratoEncontrado.detalles : {};
            
            // Asignar las propiedades del contrato (o dejarlas vacías si no hay contrato)
            setCurrentUser({
                ...user, // Retenemos la información existente del usuario
                prima_vacacional_actual: detallesContrato.prima_vacacional_actual || "",
                garantia_actual: detallesContrato.garantia_actual || "",
                bono_reconocimiento_actual: detallesContrato.bono_reconocimiento_actual || "",
                clausula_sindical_actual: detallesContrato.clausula_sindical_actual || "",
                caja_ahorro_actual: detallesContrato.caja_ahorro_actual || "",
                tienda_sindical_actual: detallesContrato.tienda_sindical_actual || "",
                salario_ajustado_actual: detallesContrato.salario_ajustado_actual || "",
                despensa_actual: detallesContrato.despensa_actual || "",
                aguinaldo_actual: detallesContrato.aguinaldo_actual || "",
                fondo_ahorro_actual: detallesContrato.fondo_ahorro_actual || "",
                bono_prod_mensual_actual: detallesContrato.bono_prod_mensual_actual || "",
                bono_prod_anual_actual: detallesContrato.bono_prod_anual_actual || "",
                bono_disponibilidad_actual: detallesContrato.bono_disponibilidad_actual || "",
                TX_mensual_actual: detallesContrato.TX_mensual_actual || "",
                TX_excendente_actual: detallesContrato.TX_excendente_actual || "",
                TX_vuelo_diario_actual: detallesContrato.TX_vuelo_diario_actual || "",
                TX_servicio_diario_actual: detallesContrato.TX_servicio_diario_actual || "",
                TX_nocturno_actual: detallesContrato.TX_nocturno_actual || "",
                dias_festivos_actual: detallesContrato.dias_festivos_actual || "",
                viaticos_actual: detallesContrato.viaticos_actual || "",
                prima_vacacional_propuesta: detallesContrato.prima_vacacional_propuesta || "",
                garantia_propuesta: detallesContrato.garantia_propuesta || "",
                bono_reconocimiento_propuesta: detallesContrato.bono_reconocimiento_propuesta || "",
                clausula_sindical_propuesta: detallesContrato.clausula_sindical_propuesta || "",
                caja_ahorro_propuesta: detallesContrato.caja_ahorro_propuesta || "",
                tienda_sindical_propuesta: detallesContrato.tienda_sindical_propuesta || "",
                salario_ajustado_propuesta: detallesContrato.salario_ajustado_propuesta || "",
                despensa_propuesta: detallesContrato.despensa_propuesta || "",
                aguinaldo_propuesta: detallesContrato.aguinaldo_propuesta || "",
                fondo_ahorro_propuesta: detallesContrato.fondo_ahorro_propuesta || "",
                bono_prod_mensual_propuesta: detallesContrato.bono_prod_mensual_propuesta || "",
                bono_prod_anual_propuesta: detallesContrato.bono_prod_anual_propuesta || "",
                bono_disponibilidad_propuesta: detallesContrato.bono_disponibilidad_propuesta || "",
                TX_mensual_propuesta: detallesContrato.TX_mensual_propuesta || "",
                TX_excendente_propuesta: detallesContrato.TX_excendente_propuesta || "",
                TX_vuelo_diario_propuesta: detallesContrato.TX_vuelo_diario_propuesta || "",
                TX_servicio_diario_propuesta: detallesContrato.TX_servicio_diario_propuesta || "",
                TX_nocturno_propuesta: detallesContrato.TX_nocturno_propuesta || "",
                dias_festivos_propuesta: detallesContrato.dias_festivos_propuesta || "",
                viaticos_propuesta: detallesContrato.viaticos_propuesta || "",
            });
           
        } else {
            setIsValidUser(false);
            mostrarNotificacion("Usuario no validado"); // Mostrar notificación personalizada
            const newIntentos = intentos + 1;
            setIntentos(newIntentos);
            localStorage.setItem("intentos", newIntentos);
            
            if (newIntentos >= 3) {
                setBloqueado(true);
                localStorage.setItem("bloqueado", true);
            }
        }
    };

    /* Funcion para mostrar mensajes de error */
    const mostrarNotificacion = (mensaje) => {
        setNotificacion(mensaje);
        setTimeout(() => {
            setNotificacion(null);
        }, 3000); // Desaparece después de 3 segundos
    };

    /*TERMINA  PARA TEMAS DE LOGIN */
    
    /*INICIA PARA TRAER INFORMACIÓN */

    const formatValue = (value, type) => {
        if (type === "d") {
            return `${value} días`;
        } else if (type === "p") {
            return `${value}%`;
        } else {
            return value; // En caso de que no se especifique tipo, devolver el valor tal cual
        }
    };

    function obtenerDiasDelAño() {
        const añoActual = new Date().getFullYear();
        return (añoActual % 4 === 0 && (añoActual % 100 !== 0 || añoActual % 400 === 0)) ? 366 : 365;
    }
    const diasEnElAño = obtenerDiasDelAño();

    function obtenerDiasDelMesActual() {
        const fechaActual = new Date();
        const añoActual = fechaActual.getFullYear();
        const mesActual = fechaActual.getMonth();
        return new Date(añoActual, mesActual + 1, 0).getDate();
    }
    const diasEnElMesActual = obtenerDiasDelMesActual();
    
    

    const handleSalarioBaseChange = (e) => {
        const value = e.target.value.replace(/[^\d.]/g, ""); // Acepta números y puntos decimales
        setSalarioBase(value ? parseFloat(value) : 0); // Permite decimales
    };
    
    const handleHorasVueloChange = (e) => {
        const value = e.target.value.replace(/\D/g, ""); // Solo acepta números
        setHorasVuelo(value ? parseInt(value) : 0); // Asigna el valor solo si no está vacío
    };
    /*TERMINA PARA TRAER INFORAMCIÓN */

      const calcularBonificacionPorHorasVuelo = (horas) => {
    if (horas >= 1 && horas <= 20) {
      return 0.1; // 10% de bonificación
    } else if (horas >= 21 && horas <= 40) {
      return 0.2; // 20% de bonificación
    } else {
      return 0; // No hay bonificación para más de 40 horas
    }
  };

  const calcularBonificacionPorRango = (rango) => {
    if (rango == "CAPITAN") {
        return 0.1; // 10% de bonificación
    } else if (rango == "PRIMER OFICIAL") {
        return 0.2; // 20% de bonificación
    } else {
        return 0; // No hay bonificación para más de 40 horas
    }
  }
  const calcularBonificacionPorContrato = (contrato) => {
    if (contrato == "A") {
        return 0.15; // 10% de bonificación
    } else if (contrato == "B") {
        return 0.21; // 20% de bonificación
    } else {
        return 0; // No hay bonificación para más de 40 horas
    }
  }

    const calcularSalarioPorHorasVuelo = () => {

        const salarioMinimo = 248.93;
        const creditoNocturno_actual = (currentUser.contrato === "A") ? 15.89 : 6.93;
        const creditoNocturno_propuesta = 15.89;
        const creditoViatico = (currentUser.equipo === "787") ? 20950.74 : 14853.06;
        const costoGasolina = 24.5;
        const litrosGasolinaProm = (currentUser.contrato === "A") ? 50 : 50 ;
        const rest_gasolina_actual = costoGasolina * litrosGasolinaProm;
        const rest_gasolina_propuesta = costoGasolina * litrosGasolinaProm;
        const promedioDiasVacaciones = 42.8;
        
        /* Inicia Calculo de montos para Salario Disponible */
        const rest_salariobase_actual= salarioBase;
        const rest_salariobase_propuesta = salarioBase + (salarioBase * (currentUser.salario_ajustado_propuesta / 100));

        const rest_despensa_actual= salarioMinimo * currentUser.despensa_actual;
        const rest_despensa_propuesta= salarioMinimo * currentUser.despensa_propuesta;

        const rest_fondoAhorro_actual = (currentUser.fondo_ahorro_actual/10) * (salarioMinimo*30);
        const rest_fondoAhorro_propuesta = (currentUser.fondo_ahorro_propuesta/10) * (salarioMinimo*30);

       
        /* Termina Calculo de montos para Salario Base Ajustado */

        const rest_garantia_actual = rest_salariobase_actual * (currentUser.garantia_actual/100);
        const rest_garantia_propuesta =rest_salariobase_propuesta * (currentUser.garantia_propuesta/100);
        
        const rest_bonoReconocimiento_actual = (currentUser.bono_reconocimiento_actual/100) * rest_salariobase_actual;
        const rest_bonoReconocimiento_propuesta = (currentUser.bono_reconocimiento_propuesta/100) * rest_salariobase_propuesta;
        
        const rest_clausualSindical_actual = (currentUser.clausula_sindical_actual/100) * rest_salariobase_actual;
        const rest_clausualSindical_propuesta = (currentUser.clausula_sindical_propuesta/100) * rest_salariobase_propuesta;
        
        const rest_CajaAhorro_actual = (currentUser.caja_ahorro_actual/100) * rest_salariobase_actual;
        const rest_CajaAhorro_propuesta = (currentUser.caja_ahorro_propuesta/100) * rest_salariobase_propuesta;
        
        const rest_tiendaSindical_actual =(currentUser.tienda_sindical_actual/100) * rest_despensa_actual;
        const rest_tiendaSindical_propuesta =(currentUser.tienda_sindical_propuesta/100) * rest_despensa_propuesta;
        // const rest_tiendaSindical_propuesta ="":

      
        
        const salarioOrdinario_actual = rest_salariobase_actual + rest_garantia_actual + rest_bonoReconocimiento_actual + rest_clausualSindical_actual + rest_CajaAhorro_actual + rest_tiendaSindical_actual;

        const salarioOrdinario_propuesta = rest_salariobase_propuesta + rest_garantia_propuesta + rest_bonoReconocimiento_propuesta + rest_clausualSindical_propuesta + rest_CajaAhorro_propuesta + rest_tiendaSindical_propuesta;
        

        
        const rest_aguinaldo_actual = (salarioOrdinario_actual/30) * (currentUser.aguinaldo_actual/12);
        const rest_aguinaldo_propuesta = (salarioOrdinario_propuesta/30) * (currentUser.aguinaldo_propuesta/12);

       
        const rest_SalarioDisponible_actual = salarioOrdinario_actual + rest_despensa_actual + rest_aguinaldo_actual + rest_fondoAhorro_actual;
        const rest_SalarioDisponible_propuesta = salarioOrdinario_propuesta + rest_despensa_propuesta + rest_aguinaldo_propuesta + rest_fondoAhorro_propuesta;

       

        /* Termina Calculo de montos para Salario Disponible */

        /* Inicia Calculo de montos para BONOS en caso de contrato A */

            const rest_bonoProdMensual_actual = (currentUser.bono_prod_mensual_actual/100) * rest_salariobase_actual;
            const rest_bonoProdMensual_propuesta = (currentUser.bono_prod_mensual_propuesta/100) * rest_salariobase_propuesta;

            const rest_bonoProdAnual_actual = (currentUser.bono_prod_anual_actual/100) * rest_salariobase_actual;
            const rest_bonoProdAnual_propuesta = (currentUser.bono_prod_anual_propuesta/100) * rest_salariobase_propuesta;

            const rest_bonoDisponibilidad_actual = (currentUser.bono_disponibilidad_actual/100) * rest_SalarioDisponible_actual;
            const rest_bonoDisponibilidad_propuesta = (currentUser.bono_disponibilidad_propuesta/100) * rest_SalarioDisponible_propuesta;
    
        /* Termina Calculo de montos para BONOS en caso de contrato A */

       
        /* Inicia Calculo de Compensación variable */

        //currentUser.equipo = 737
        const fateVarER = [
            {
                equipo: "737",
                detalles: {
                    0:"0.16",
                    10:"0.16",
                    20:"0.31",
                    30:"0.49",
                    40:"0.93",
                    50:"0.96",
                    60:"1.45",
                    70:"1.63",
                    80:"1.30",
                    90:"0.99",
                    100:"0.00",
                }
            },
            {
                equipo: "787",
                detalles: {
                    0:"0.21",
                    10:"0.21",
                    20:"1.86",
                    30:"4.79",
                    40:"7.69",
                    50:"10.14",
                    60:"13.85",
                    70:"16.12",
                    80:"19.17",
                    90:"23.01",
                    100:"24.39",
                }
            }
        ]

        const fateSerER = [
            {
                equipo: "737",
                contratos: {
                    A: {
                        detalles: {
                            0:"1.09",
                            10:"1.09",
                            20:"2.76",
                            30:"3.91",
                            40:"6.9",
                            50:"8.27",
                            60:"12.32",
                            70:"15.14",
                            80:"14.68",
                            90:"14.33",
                            100:"9.51",
                        }
                    },
                    B: {
                        detalles: {
                            0:"0.32",
                            10:"0.32",
                            20:"0.69",
                            30:"1.10",
                            40:"2.05",
                            50:"2.29",
                            60:"3.41",
                            70:"3.95",
                            80:"3.46",
                            90:"2.98",
                            100:"0.45",
                        }
                    }
                }
            },
            {
                equipo: "787",
                contratos: {
                    A:{
                        detalles: {
                            0:"0.19",
                            10:"0.19",
                            20:"1.8",
                            30:"4.78",
                            40:"7.61",
                            50:"10.03",
                            60:"13.79",
                            70:"15.89",
                            80:"18.91",
                            90:"22.76",
                            100:"23.98",
                        }
                    },
                    B:{
                        detalles: {
                            0:"0.11",
                            10:"0.11",
                            20:"1.15",
                            30:"3.33",
                            40:"5.23",
                            50:"6.88",
                            60:"9.62",
                            70:"10.80",
                            80:"12.94",
                            90:"15.56",
                            100:"16.17",
                        }
                    }
                }
                
            }
        ]

        const encontrarValorCreditos = (avion, clave) => {
            const equipo = fateVarER.find((obj) => obj.equipo === avion);
            return equipo ? equipo.detalles[clave] : 0;
        }

        const encontrarValorServicio = (avion, contrato, clave) => {
            const equipo = fateSerER.find((obj) => obj.equipo === avion);
            if(equipo && equipo.contratos[currentUser.contrato]){
                return equipo.contratos[contrato].detalles[clave] || 0;
            }
        }
        
        const redondearDecenaInferior = (numero) => {
            return Math.floor(numero/ 10) * 10;
        }

        const TX_horas_credtos_vuelo = redondearDecenaInferior(horasVuelo);
        const TX_horas_servicio = redondearDecenaInferior(horasVuelo);
        
        const porcentaje_horas_vuelo_credito = encontrarValorCreditos(currentUser.equipo,TX_horas_credtos_vuelo);

        const porcentaje_horas_servicio = encontrarValorServicio(currentUser.equipo,currentUser.contrato,TX_horas_servicio);
        
        const arreglocreditos = [
            {
                A:{
                    mensualOrdinario_actual: "80",
                    mensualExcedente_actual: "90",
                    mensualOrdinario_propuesta: "80",
                    mensualExcedente_propuesta: "90"
                },
                B:{
                    mensualOrdinario_actual: "73",
                    mensualExcedente_actual: "75",
                    mensualOrdinario_propuesta: "73.5",
                    mensualExcedente_propuesta: "73.5"
                }
                
            }
        ]

        const findIntervalo = (contrato) => {
            const creditos = arreglocreditos[0][contrato];
            return creditos 
                ? { mensualOrdinario_actual : creditos.mensualOrdinario_actual,mensualExcedente_actual : creditos.mensualExcedente_actual, mensualOrdinario_propuesta: creditos.mensualOrdinario_propuesta, mensualExcedente_propuesta:creditos.mensualExcedente_propuesta }
                : null;
        }

        const creditosContrato = findIntervalo(currentUser.contrato);

        let mensualOrdinario_actual,mensualExcedente_actual, mensualOrdinario_propuesta,mensualExcedente_propuesta;
        if(creditosContrato){
            ({mensualOrdinario_actual,
                mensualExcedente_actual,
                mensualOrdinario_propuesta,
                mensualExcedente_propuesta} = creditosContrato);
        }

        const credito_tiempoExtraMensual_actual = Math.max( Math.min(horasVuelo-mensualOrdinario_actual,mensualExcedente_actual-mensualOrdinario_actual) , 0 );
        
        const credito_tiempoExtraMensual_propuesta = Math.max( Math.min(horasVuelo-mensualOrdinario_propuesta,mensualExcedente_propuesta-mensualOrdinario_propuesta) , 0 );

        const credito_tiempoExtraMensual_excedente_actual = Math.max(horasVuelo - mensualExcedente_actual, 0 );
        
        const credito_tiempoExtraMensual_excedente_propuesta = Math.max( horasVuelo - mensualExcedente_propuesta, 0 );


       
        /* VARIABLES A IMPRIMIR  */
        const rest_tiempoExtra_mensual_actual = ((currentUser.TX_mensual_actual/100)* rest_salariobase_actual) * credito_tiempoExtraMensual_actual;
        const rest_tiempoExtra_mensual_propuesta = ((currentUser.TX_mensual_propuesta/100)* rest_salariobase_propuesta) * credito_tiempoExtraMensual_propuesta;

        const rest_tiempoExtra_excedente_actual = ((currentUser.TX_excendente_actual/100) * credito_tiempoExtraMensual_excedente_actual) * rest_salariobase_actual;
        const rest_tiempoExtra_excedente_propuesta = ((currentUser.TX_excendente_propuesta/100) * credito_tiempoExtraMensual_excedente_propuesta) * rest_salariobase_propuesta;

        const rest_TX_vuelo_diario_actual = ( (currentUser.TX_vuelo_diario_actual/100) * porcentaje_horas_vuelo_credito ) * rest_salariobase_actual;
        const rest_TX_vuelo_diario_propuesta = ( (currentUser.TX_vuelo_diario_propuesta/100) * porcentaje_horas_vuelo_credito ) * rest_salariobase_propuesta;

        const rest_TX_servicio_diario_actual = ( (currentUser.TX_servicio_diario_actual/100) * porcentaje_horas_servicio ) * rest_salariobase_actual;
        const rest_TX_servicio_diario_propuesta = ( (currentUser.TX_servicio_diario_propuesta/100) * porcentaje_horas_servicio ) * rest_salariobase_propuesta;

        //aqui se utiliza esta variable creditoNocturno_actual y creditoNocturno_propuesta

        const rest_TX_nocturno_actual = ((currentUser.TX_nocturno_actual/100) * creditoNocturno_actual)* rest_salariobase_actual;
        const rest_TX_nocturno_propuesta =((currentUser.TX_nocturno_propuesta/100) * creditoNocturno_propuesta)* rest_salariobase_propuesta;

        const rest_diasFestivos_actual = ((salarioOrdinario_actual/30) * currentUser.dias_festivos_actual) * 1;
        const rest_diasFestivos_propuesta = ((salarioOrdinario_propuesta/30) * currentUser.dias_festivos_propuesta) * 1;

      

        // AQUI se utiliza creditoViatico
        const rest_viaticos_actual = creditoViatico * (currentUser.viaticos_actual/100)
        const rest_viaticos_propuesta = creditoViatico * (currentUser.viaticos_propuesta/100)

        const rest_primavacacional_actual = (((salarioOrdinario_actual/30) * promedioDiasVacaciones) * (currentUser.prima_vacacional_actual/100))/12;
        const rest_primavacacional_propuesta = (((salarioOrdinario_propuesta/30) * promedioDiasVacaciones) * (currentUser.prima_vacacional_propuesta/100))/12;
        
      
        /* VARIABLES A IMPRIMIR  */ 

        /* Termina Calculo de Compensación variable */

        /* INICIA CALCULO DE TOTALES */

        const sueldo_final_actual= rest_salariobase_actual;
        const sueldo_final_propuesta= rest_salariobase_propuesta;

        const sueldo_compensacionFija_actual= rest_garantia_actual + 
                                              rest_bonoReconocimiento_actual + 
                                              rest_despensa_actual + 
                                              rest_aguinaldo_actual + 
                                              rest_gasolina_actual + 
                                              rest_primavacacional_actual +
                                              rest_fondoAhorro_actual + 
                                              rest_clausualSindical_actual + 
                                              rest_CajaAhorro_actual + 
                                              rest_tiendaSindical_actual;
        const sueldo_compensacionFija_propuesta= rest_garantia_propuesta + 
                                                rest_bonoReconocimiento_propuesta + 
                                                rest_despensa_propuesta + 
                                                rest_aguinaldo_propuesta + 
                                                rest_gasolina_propuesta + 
                                                rest_primavacacional_propuesta +
                                                rest_fondoAhorro_propuesta + 
                                                rest_clausualSindical_propuesta + 
                                                rest_CajaAhorro_propuesta + 
                                                rest_tiendaSindical_propuesta;
        
      
        const sueldo_compensacion_variable_actual = rest_bonoProdMensual_actual +
                                                    rest_bonoProdAnual_actual +
                                                    rest_bonoDisponibilidad_actual +
                                                    rest_tiempoExtra_mensual_actual +
                                                    rest_tiempoExtra_excedente_actual +
                                                    rest_TX_vuelo_diario_actual +
                                                    rest_TX_servicio_diario_actual +
                                                    rest_TX_nocturno_actual +
                                                    rest_diasFestivos_actual +
                                                    rest_viaticos_actual;
        
        const sueldo_compensacion_variable_propuesta = rest_bonoProdMensual_propuesta +
                                                    rest_bonoProdAnual_propuesta +
                                                    rest_bonoDisponibilidad_propuesta +
                                                    rest_tiempoExtra_mensual_propuesta +
                                                    rest_tiempoExtra_excedente_propuesta +
                                                    rest_TX_vuelo_diario_propuesta +
                                                    rest_TX_servicio_diario_propuesta +
                                                    rest_TX_nocturno_propuesta +
                                                    rest_diasFestivos_propuesta +
                                                    rest_viaticos_propuesta;

        
        const compensacion_total_actual = sueldo_final_actual + sueldo_compensacionFija_actual + sueldo_compensacion_variable_actual;
        const compensacion_total_propuesta = sueldo_final_propuesta + sueldo_compensacionFija_propuesta + sueldo_compensacion_variable_propuesta;

       
        /* TERMINA  CALCULO DE TOTALES */
        const SalarioBaseAjustado = {
            ...currentUser,
            rest_salariobase_actual,
            rest_salariobase_propuesta,
            rest_despensa_actual,
            rest_despensa_propuesta,
            rest_fondoAhorro_actual,
            rest_fondoAhorro_propuesta,
            rest_garantia_actual,
            rest_garantia_propuesta,
            rest_bonoReconocimiento_actual,
            rest_bonoReconocimiento_propuesta,
            rest_clausualSindical_actual,
            rest_clausualSindical_propuesta,
            rest_CajaAhorro_actual,
            rest_CajaAhorro_propuesta,
            rest_tiendaSindical_actual,
            rest_tiendaSindical_propuesta,
            salarioOrdinario_actual,
            salarioOrdinario_propuesta,
            rest_aguinaldo_actual,
            rest_aguinaldo_propuesta,
            rest_bonoProdMensual_actual,
            rest_bonoProdMensual_propuesta,
            rest_bonoProdAnual_actual,
            rest_bonoProdAnual_propuesta,
            rest_bonoDisponibilidad_actual,
            rest_bonoDisponibilidad_propuesta,
            rest_tiempoExtra_mensual_actual,
            rest_tiempoExtra_mensual_propuesta,
            rest_tiempoExtra_excedente_actual,
            rest_tiempoExtra_excedente_propuesta,
            rest_TX_vuelo_diario_actual,
            rest_TX_vuelo_diario_propuesta,
            rest_TX_servicio_diario_actual,
            rest_TX_servicio_diario_propuesta,
            rest_TX_nocturno_actual,
            rest_TX_nocturno_propuesta,
            rest_diasFestivos_actual,
            rest_diasFestivos_propuesta,
            rest_viaticos_actual,
            rest_viaticos_propuesta,
            rest_primavacacional_actual,
            rest_primavacacional_propuesta,
            sueldo_final_actual,
            sueldo_final_propuesta,
            sueldo_compensacionFija_actual,
            sueldo_compensacionFija_propuesta,
            sueldo_compensacion_variable_actual,
            sueldo_compensacion_variable_propuesta,
            compensacion_total_actual,
            compensacion_total_propuesta,
        };

        setCurrentUser(SalarioBaseAjustado);

       
        
    };

  const formatCurrency = (value,decimals = 2) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals
    }).format(value);
  };

  return (
    <motion.div
      key="page-procesos-administrativos"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="full-container"
    >
    {!isValidUser ? (
        <>
         <div className="min-h-screen flex flex-col justify-center items-center bg-gray-100">
            <div className="w-full max-w-md bg-white p-8 rounded shadow-md">
                <div className="flex justify-center mb-6"><img src={logoAMX} alt="Logo" className="h-16 w-auto" /></div>
                <div className="w-full mb-6">
                    <label className="block text-sm font-bold mb-2" htmlFor="email">Usuario</label>
                    <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} className="w-full px-3 py-2 border rounded" placeholder="Usuario"/>
                </div>
                <div className="w-full mb-6">
                    <label className="block text-sm font-bold mb-2" htmlFor="id">Contraseña</label>
                    <input type="password" id="id" value={id} onChange={(e) => setId(e.target.value)} className="w-full px-3 py-2 border rounded" placeholder="Contraseña"/>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button onClick={handleLogin} disabled={bloqueado} style={{ ...styles.button,backgroundColor: bloqueado ? '#cccccc' : 'blue', cursor: bloqueado ? 'not-allowed' : 'pointer', }}> {bloqueado ? `Bloqueado: ${tiempoRestante}s` : 'Validar usuario'} </button>
                </div>
                {notificacion && ( <div style={styles.notificacion}> {notificacion} </div> )}
            </div>
            </div>
        </>
    ) : (
        <>
        <div className="content-holder-talent">
            <header className="talent-header relative py-40">
                <div className="absolute left-1/2 top-1/2 z-20 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
                    <h1 className="mb-1 text-center text-5xl font-bold leading-none text-white opacity-100"> {i18next.language === "es" ? "Propuesta negociaciones 2024 AMX" : "Proposal for negotiations 2024 AMX"} </h1>
                    <h2 className="text-center text-2xl font-bold leading-none text-white opacity-100"> {i18next.language === "es" ? "Información actualizada 03 octubre 2024" : "Information until October 2024"} </h2>
                </div>
                <div className="absolute left-0 top-0 z-10 h-full w-full bg-amDarkBlue opacity-75"></div>
                <img src={Banner} alt="Propuesta negociaciones 2024 AMX" className="absolute left-0 top-0 z-0 h-full w-full object-cover"/>
            </header>
            <section className="py-16" style={{ background: "#F6F8F9" }}>
                <div className="container mx-auto flex flex-col items-center px-10 lg:px-16">
                    {/* Datos del usuario validado */}
                    <p className="rewards_title text-left" style={{ fontSize: "1.5rem" }}>Datos del piloto</p>
                    <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                        <table className="min-w-full text-left text-sm whitespace-nowrap">
                            <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                <tr>
                                    <th scope="col" className="px-6 py-2 text-white" colSpan={6}><p>&nbsp;</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" className="px-6 py-4"><p>Nombre</p></th>
                                    <td className="px-6 py-4 border-b" colSpan={5}>
                                        <div className="space-y-2">
                                            <div className="py-2">
                                            <strong>{currentUser.nombre}</strong>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row" className="px-6 py-4"><p>Rango</p></th>
                                    <td className="px-6 py-4 border-b">
                                        <div className="space-y-2">
                                            <div className="py-2">
                                            <strong>{currentUser.rango}</strong>
                                            </div>
                                        </div>
                                    </td>
                                    <th scope="row" className="px-6 py-4"><p>Equipo</p></th>
                                    <td className="px-6 py-4 border-b">
                                        <div className="space-y-2">
                                            <div className="py-2">
                                            <strong>{currentUser.equipo}</strong>
                                            </div>
                                        </div>
                                    </td>
                                    <th scope="row" className="px-6 py-4"><p>Tipo de Contrato</p></th>
                                    <td className="px-6 py-4 border-b">
                                        <div className="space-y-2">
                                            <div className="py-2">
                                            <strong>{currentUser.contrato}</strong>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* Inputs para calcular salario */}
                    <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                        <table className="min-w-full text-left text-sm whitespace-nowrap">
                            <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                <tr>
                                    <th scope="row" className="px-6 py-2 text-white"><p><strong>Salario Base Mensual</strong></p></th>
                                    <th scope="row" className="px-6 py-2 text-white"><p><strong>Horas de Vuelo</strong></p></th>
                                    <th scope="row" className="px-6 py-2 text-white"><p></p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th scope="row" className="px-6 py-2">
                                        <div className="w-full">
                                            <input type="text" id="salarioBase" value={salarioBase} onChange={handleSalarioBaseChange} className="w-full px-3 py-2 border rounded" placeholder="0"/>
                                        </div>
                                    </th>
                                    <th>
                                        <div className="w-full">
                                            <input type="number" id="horasVuelo" value={horasVuelo} onChange={handleHorasVueloChange} className="w-full px-3 py-2 border rounded" placeholder="0" />
                                        </div>
                                    </th>
                                    <th>
                                        <div className="">
                                            <button onClick={calcularSalarioPorHorasVuelo} className="px-4 py-2 bg-blue-500 text-white rounded" > Calcular</button>
                                        </div>
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    {/* Inputs para calcular salario */}
                    {/* Inician tablas de información y calculo */}
                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Salario Disponible</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full text-left text-sm whitespace-nowrap">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Salario Base Ajustado</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(salarioBase)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(salarioBase)} + {formatValue(currentUser.salario_ajustado_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Despensa (días)</td>
                                    <td className="px-6 py-4 border-b">{formatCurrency(currentUser.despensa_actual)}</td>
                                    <td className="px-6 py-4 border-b">{formatValue(currentUser.despensa_propuesta,"d")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Aguinaldo (días)</td>
                                    <td className="px-6 py-4 border-b">{formatValue(currentUser.aguinaldo_actual,"d")} </td>
                                    <td className="px-6 py-4 border-b">{formatValue(currentUser.aguinaldo_propuesta,"d")} </td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">fondo de Ahorro (días)</td>
                                    <td className="px-6 py-4 border-b">{formatValue(currentUser.fondo_ahorro_actual,"p")} </td>
                                    <td className="px-6 py-4 border-b">{formatValue(currentUser.fondo_ahorro_propuesta,"p")} </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Salario Disponible en montos</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full bg-white">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Salario Base Ajustado</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_salariobase_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_salariobase_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Despensa (días)</td>
                                    <td className="px-6 py-4 border-b">{formatCurrency(currentUser.rest_despensa_actual)}</td>
                                    <td className="px-6 py-4 border-b">{formatCurrency(currentUser.rest_despensa_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Aguinaldo (días)</td>
                                    <td className="px-6 py-4 border-b">{formatCurrency(currentUser.rest_aguinaldo_actual)} </td>
                                    <td className="px-6 py-4 border-b">{formatCurrency(currentUser.rest_aguinaldo_propuesta)} </td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">fondo de Ahorro</td>
                                    <td className="px-6 py-4 border-b">{formatCurrency( currentUser.rest_fondoAhorro_actual)} </td>
                                    <td className="px-6 py-4 border-b">{formatCurrency( currentUser.rest_fondoAhorro_propuesta)} </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Salario Base Ajustado</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full bg-white">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Garantía</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.garantia_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.garantia_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Bono de Reconocimiento</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_reconocimiento_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_reconocimiento_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Cláusula Sindical</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.clausula_sindical_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.clausula_sindical_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Caja de Ahorro</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.caja_ahorro_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.caja_ahorro_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Tienda Sindical</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.tienda_sindical_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.tienda_sindical_propuesta,"p")}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Salario Base Ajustado en montos</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full bg-white">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Garantía</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_garantia_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_garantia_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Bono de Reconocimiento</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoReconocimiento_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoReconocimiento_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Cláusula Sindical</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_clausualSindical_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_clausualSindical_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Caja de Ahorro</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_CajaAhorro_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_CajaAhorro_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Tienda Sindical</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_tiendaSindical_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_tiendaSindical_propuesta)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    {/* INICIA BONOS PARA CONTRATOS TIPO A */}
                    {(currentUser.contrato === "A") ? (
                        <>
                        <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                            <p className="text-left text-xl font-bold mb-4">Bonos</p>
                            <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                                <table className="min-w-full bg-white">
                                    <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                        <tr>
                                            <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                            <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                            <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="px-6 py-4 border-b">Bonos Productividad Mensual</td>
                                        <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_prod_mensual_actual,"p")}</td>
                                        <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_prod_mensual_propuesta,"p")}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 border-b">Bonos Productividad Anual</td>
                                        <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_prod_anual_actual,"p")}</td>
                                        <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_prod_anual_propuesta,"p")}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 border-b">Bono de Disponiblidad</td>
                                        <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_disponibilidad_actual,"p")}</td>
                                        <td className="px-6 py-4 border-b"> {formatValue(currentUser.bono_disponibilidad_propuesta,"p")}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                            <p className="text-left text-xl font-bold mb-4">Bonos en cantidades</p>
                            <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                                <table className="min-w-full bg-white">
                                    <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                        <tr>
                                            <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                            <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                            <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className="px-6 py-4 border-b">Bonos Productividad Mensual</td>
                                        <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoProdMensual_actual)}</td>
                                        <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoProdMensual_propuesta)}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 border-b">Bonos Productividad Anual</td>
                                        <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoProdAnual_actual)}</td>
                                        <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoProdAnual_propuesta)}</td>
                                    </tr>
                                    <tr>
                                        <td className="px-6 py-4 border-b">Bono de Disponiblidad</td>
                                        <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoDisponibilidad_actual)}</td>
                                        <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_bonoDisponibilidad_propuesta)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        </>
                    ) : (
                        null
                    )}
                    {/* TERMINA BONOS PARA CONTRATOS TIPO A */}


                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Compensación variable</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full bg-white">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Tiempo Extra Mensual</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_mensual_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_mensual_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Tiempo Extra Mensual Excedente</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_excendente_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_excendente_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">TX Vuelo diario</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_vuelo_diario_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_vuelo_diario_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">TX Servicio diario</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_servicio_diario_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_servicio_diario_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">TX Nocturno<br/><small class="text-xs">La propuesta de jornada nocturna contempla la acreditación de toda la jornada</small></td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_nocturno_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.TX_nocturno_propuesta,"p")}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Días Festivos</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.dias_festivos_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.dias_festivos_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Viáticos</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.viaticos_actual,"p")}</td>
                                    <td className="px-6 py-4 border-b"> {formatValue(currentUser.viaticos_propuesta,"p")}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Compensación variable en montos</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full bg-white">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Tiempo Extra Mensual</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_tiempoExtra_mensual_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_tiempoExtra_mensual_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Tiempo Extra Mensual Excedente</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_tiempoExtra_excedente_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_tiempoExtra_excedente_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">TX Vuelo diario</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_TX_vuelo_diario_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_TX_vuelo_diario_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">TX Servicio diario</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_TX_servicio_diario_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_TX_servicio_diario_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">TX Nocturno<br/><small class="text-xs">La propuesta de jornada nocturna contempla la acreditación de toda la jornada</small></td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_TX_nocturno_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_TX_nocturno_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Días Festivos</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_diasFestivos_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_diasFestivos_propuesta)}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Viáticos</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_viaticos_actual)}</td>
                                    <td className="px-6 py-4 border-b"> {formatCurrency(currentUser.rest_viaticos_propuesta)}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div className="w-full bg-white shadow-md rounded mb-6 mt-4">
                        <p className="text-left text-xl font-bold mb-4">Totales</p>
                        <div className="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                            <table className="min-w-full bg-white">
                                <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                    <tr>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Concepto</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Actual</strong></p></th>
                                        <th scope="row" className="px-6 py-2 text-white"><p><strong>Propuesta</strong></p></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="px-6 py-4 border-b">Sueldo</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.sueldo_final_actual))}</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.sueldo_final_propuesta))}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Compensación fija</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.sueldo_compensacionFija_actual))}</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.sueldo_compensacionFija_propuesta))}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Compensación Variable</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.sueldo_compensacion_variable_actual))}</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.sueldo_compensacion_variable_propuesta))}</td>
                                </tr>
                                <tr>
                                    <td className="px-6 py-4 border-b">Compensación Total</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.compensacion_total_actual))}</td>
                                    <td className="px-6 py-4 border-b"> {(formatCurrency(currentUser.compensacion_total_propuesta))}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
                    <p>Este documento tiene fines informativos y está destinado únicamente para su visualización. No constituye un documento oficial.</p>
                    <p>Si tienes alguna duda, contacta a <a href="mailto:cvazquezpa@aeromexico.com">cvazquezpa@aeromexico.com</a>, <a href="mailto:lde@aeromexico.com">lde@aeromexico.com</a></p>
                    <p>&nbsp;</p>
                    <p>Nota: Si no puedes ver la tabla completa, utiliza el cursor para desplazarte hacia la derecha.</p> 
                </div>
            </section>
        </div>
        </>
    )}
      
    </motion.div>
  );
};

// Estilos
const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh', // Ocupa todo el alto de la ventana
    },
    button: {
      padding: '10px 20px',
      backgroundColor: 'blue',
      color: 'white',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
      marginTop: '10px', // Para separar el botón de los inputs
    },
    notificacion: {
      position: 'fixed',
      top: '20px',
      right: '20px',
      padding: '10px 20px',
      backgroundColor: 'red',
      color: 'white',
      borderRadius: '5px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      zIndex: 1000,
    },
  };

export default AccionesPersonales;
