import React, { Component } from 'react';
// import arrow from '../../../assets/images/arrow-blue.svg'
// import Modal from 'react-awesome-modal'
import close from '../../../assets/logo/close.svg';
// import periodos from '../../../assets/calendar.svg'
import internaicon from '../../../assets/ventaespecial/interna.svg';
import accesTravel01 from '../../../assets/images/arrow-blue.svg';
import * as contentful from '../../../lib/contentful';
import CurrencyFormat from 'react-currency-format';
// import ReactMarkdown from "react-markdown";
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';
// import { Trans } from "react-i18next";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

// import {
//     Accordion,
//     AccordionItem,
//     AccordionItemHeading,
//     AccordionItemPanel,
//     AccordionItemButton,
//   } from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';
import { color } from 'framer-motion';

var noScroll = require('no-scroll');

const options = {
	renderText: (text) => {
		return text.split('\n').reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment];
		}, []);
	},
	renderNode: {
		[INLINES.HYPERLINK]: (node, children) => {
			return (
				<a
					href={`${node.data.uri}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</a>
			);
		}
	}
};

export default class ModalEmbargoInterna extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			loading: true,
			error: null,
			entries: null,
			entries2: null,
			entries3: null,
			entries4: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener('keydown', this.escFunction, false);
		this.fetchEntries();
		this.fetchEntries2();
		this.fetchEntries3();
		this.fetchEntries4();
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialDestinos',
				'fields.typeOfDestination[all]': 'DestinoInternacional',
				'fields.departureFrom[all]': 'Saliendo desde Mexico',
				include: 2,
				locale: getLocale(),
				order: 'fields.destinationName'
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries2 = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialDestinos',
				'fields.typeOfDestination[all]': 'DestinoInternacional',
				'fields.departureFrom[nin]':
					'Saliendo desde Mexico,Saliendo desde República Mexicana',
				order: 'fields.destinationName',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries2: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries3 = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialLegales',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries3: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries4 = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialDestinos',
				'fields.typeOfDestination[all]': 'DestinoInternacional',
				'fields.departureFrom[all]':
					'Saliendo desde República Mexicana',
				include: 2,
				locale: getLocale(),
				order: 'fields.destinationName'
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries4: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	render() {
		const { loading, entries, entries2, entries3, entries4 } = this.state;
		return (
			<div>
				<div
					className="modal fade modal-xl"
					id="modal360interna"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modal360Title"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 modalVentaEspecial p-5">
							<a class="closeModal close" data-dismiss="modal">
								<img src={close} alt="Cerrar"></img>
							</a>
							<div className="wrapperDivName">
								<p className="titleVentaDestinosTodo">
									{i18next.t('InterModal.title')}
								</p>

								{!loading &&
									entries3 &&
									entries3.map((legales) => (
										<div className="rich-text header-text internacionales">
											{documentToReactComponents(
												legales.fields
													.encabezadoInternacionales,
												options
											)}
										</div>
									))}

								<div className="titleVentaDivInter internacionalBackground">
									<p className="fromMexicoCity">
										{i18next.t('InterModal.saliendo')}
									</p>
								</div>

								<div className="wrapperDestinosVenta internacionalBackground">
									{!loading &&
										entries &&
										entries.map((destinosVentaEspecial) => (
											<div
												className="destinoOption"
												key={
													destinosVentaEspecial.sys.id
												}
											>
												<div className="iconDestino">
													<img
														src={
															destinosVentaEspecial
																.fields
																.iconDestination
																.fields.file.url
														}
														alt="Destinos Venta Especial"
													></img>
												</div>
												<div className="textDestino">
													<div className="alignMiddleText">
														<p className="destinationNameInter">
															{
																destinosVentaEspecial
																	.fields
																	.destinationName
															}{' '}
															<span>
																{
																	destinosVentaEspecial
																		.fields
																		.iataDestination
																}
															</span>
														</p>
													</div>
												</div>
												<div className="priceDestino">
													<div className="alignMiddleText">
														<CurrencyFormat
															value={
																destinosVentaEspecial
																	.fields
																	.priceForTravel
															}
															displayType={'text'}
															thousandSeparator={
																true
															}
															decimalScale={'2'}
															prefix={''}
															renderText={(
																value
															) => (
																<p
																	className={
																		'priceDestinationInter' +
																		' ' +
																		destinosVentaEspecial
																			.fields
																			.typeOfDestination
																	}
																>
																	{value}{' '}
																</p>
															)}
														/>
													</div>
												</div>
											</div>
										))}
								</div>

								<div className="legalesTextoGreen internacionalBackground">
									<p>
										{i18next.t('NacionalModal.impuestos')}
									</p>
								</div>

								<div className="titleVentaDivInter internacionalBackground">
									<p className="fromMexicoCity">
										{i18next.t('InterModal.saliendoRep')}
									</p>
								</div>

								<div className="wrapperDestinosVenta internacionalBackground">
									{!loading &&
										entries4 &&
										entries4.map(
											(
												destinosInternacionalesRepublica
											) => (
												<div
													className="destinoOption"
													key={
														destinosInternacionalesRepublica
															.sys.id
													}
												>
													<div className="iconDestino">
														<img
															src={
																destinosInternacionalesRepublica
																	.fields
																	.iconDestination
																	.fields.file
																	.url
															}
															alt="Destinos Internationales"
														></img>
													</div>
													<div className="textDestino">
														<div className="alignMiddleText">
															<p className="destinationNameInter">
																{
																	destinosInternacionalesRepublica
																		.fields
																		.destinationName
																}{' '}
																<span>
																	{
																		destinosInternacionalesRepublica
																			.fields
																			.iataDestination
																	}
																</span>
															</p>
														</div>
													</div>
													<div className="priceDestino">
														<div className="alignMiddleText">
															<CurrencyFormat
																value={
																	destinosInternacionalesRepublica
																		.fields
																		.priceForTravel
																}
																displayType={
																	'text'
																}
																thousandSeparator={
																	true
																}
																decimalScale={
																	'2'
																}
																prefix={''}
																renderText={(
																	value
																) => (
																	<p
																		className={
																			'priceDestinationInter' +
																			' ' +
																			destinosInternacionalesRepublica
																				.fields
																				.typeOfDestination
																		}
																	>
																		{value}{' '}
																	</p>
																)}
															/>
														</div>
													</div>
												</div>
											)
										)}
								</div>

								<div className="legalesTextoGreen internacionalBackground">
									<p>
										{i18next.t('NacionalModal.impuestos')}
									</p>
								</div>

								<div className="titleVenta">
									<p className="fromMexicoCity">
										{i18next.t('InterModal.masrutas')}
									</p>
								</div>

								<div className="wrapperDestinosVenta internacionalBackground">
									{!loading &&
										entries2 &&
										entries2.map(
											(destinosVentaEspecialMas) => (
												<div
													className="destinoOption"
													key={
														destinosVentaEspecialMas
															.sys.id
													}
												>
													<div className="iconDestino">
														<img
															src={
																destinosVentaEspecialMas
																	.fields
																	.iconDestination
																	.fields.file
																	.url
															}
															alt="Destinos Venta Especial Más"
														></img>
													</div>
													<div className="textDestino">
														<div className="alignMiddleText">
															<p className="destinationNameInter">
																{
																	destinosVentaEspecialMas
																		.fields
																		.destinationName
																}{' '}
																<span>
																	{
																		destinosVentaEspecialMas
																			.fields
																			.iataDestination
																	}
																</span>
															</p>
															<p className="departurefromInter">
																{
																	destinosVentaEspecialMas
																		.fields
																		.departureFrom
																}
															</p>
														</div>
													</div>
													<div className="priceDestino">
														<div className="alignMiddleText">
															<CurrencyFormat
																value={
																	destinosVentaEspecialMas
																		.fields
																		.priceForTravel
																}
																displayType={
																	'text'
																}
																thousandSeparator={
																	true
																}
																decimalScale={
																	'2'
																}
																prefix={''}
																renderText={(
																	value
																) => (
																	<p
																		className={
																			'priceDestinationInter' +
																			' ' +
																			destinosVentaEspecialMas
																				.fields
																				.typeOfDestination
																		}
																	>
																		{value}{' '}
																	</p>
																)}
															/>
														</div>
													</div>
												</div>
											)
										)}
								</div>

								<div className="legalesTextoGreen internacionalBackground">
									<p>
										{i18next.t('NacionalModal.impuestos')}
									</p>
								</div>

								<div className="condicionesVentaEspecialUso">
									<p className="temsConditions">
										{i18next.t(
											'NacionalModal.terminos.title'
										)}
									</p>

									{!loading &&
										entries3 &&
										entries3.map((legales) => (
											<div className="ventaEspecialContent rich-text footer-text internacionales">
												{documentToReactComponents(
													legales.fields
														.terminosCondicionesInternacionales,
													options
												)}
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>

				<a
					className="ventaEspecialModalesCambios"
					// onClick={() => this.openModal()}
					data-toggle="modal"
					data-target="#modal360interna"
				>
					<div className="cardWrapperBoletos">
						<img
							className="icon marginTopIconNew"
							src={internaicon}
							alt="Tarifas"
						></img>
						<div className="ticketsText iconTextnoNecesario">
							<p>{i18next.t('InterModal.title')}</p>
							<p>{i18next.t('InterModal.desde')}</p>
							<a className="viewPricesTarifas">
								{i18next.t('NacionalModal.tarifas')}
							</a>
							{/* <p>Centroamérica y Caribe desde 162 USD</p>
                    <p>Asia desde 371 USD</p>
                    <p>Estados Unidos y Canadá desde 183 USD</p> */}
						</div>
						<img
							className="arrow"
							src={accesTravel01}
							alt="Travel01"
						/>
					</div>
				</a>
			</div>
		);
	}
}
