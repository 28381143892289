import React, { useState, useEffect } from 'react';

import l1_4 from '../../assets/images/profile/levels24/L1-L4.png';
import l5_6 from '../../assets/images/profile/levels24/L5-L6.png';
import l7_8 from '../../assets/images/profile/levels24/L7-L8.png';
import l9 from '../../assets/images/profile/levels24/L9.png';
import l10_12 from '../../assets/images/profile/levels24/L10-L12.png';
import l12C from '../../assets/images/profile/levels24/L12C.png';
import default_image from '../../assets/images/profile/levels24/L1-L4.png';

const TravelModal24 = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [nivel, setNivel] = useState(null);

  useEffect(() => {
    // Verificar si el modal ya fue mostrado
    const modalShown = localStorage.getItem('cookieNiveles2024');
    if (!modalShown) {
      setIsModalVisible(true);
    }

    // Obtener el nivel desde localStorage
    const storedNivel = localStorage.getItem('nivel');
    if (storedNivel) {
    const normalizedNivel = storedNivel === "12C" ? 13 : parseInt(storedNivel, 10);
      setNivel(normalizedNivel); // Convertir el nivel a número
    }
  }, []);

  const closeModal = () => {
    setIsModalVisible(false);
    // Guardar en localStorage que el modal ya fue mostrado
    //localStorage.setItem('cookieNiveles2024', 'true');
  };

  const handleOutsideClick = (event) => {
    if (event.target.id === 'modal-container') {
      closeModal();
    }
  };

  // Definir rangos y sus imágenes correspondientes
  const rangos = [
    { min: 1, max: 4, imagen: l1_4 },
    { min: 5, max: 6, imagen: l5_6 },
    { min: 7, max: 8, imagen: l7_8 },
    { min: 9, max: 9, imagen: l9 },
    { min: 10, max: 12, imagen: l10_12 },
    { min: 13, max: 13, imagen: l12C },
  ];

  // Obtener la imagen del rango según el nivel
  const getImageByLevel = (nivel) => {
    const rango = rangos.find(r => nivel >= r.min && nivel <= r.max);
    return rango ? rango.imagen : 'imagen-default.png';
  };

  const imageSrc = nivel !== null ? getImageByLevel(nivel) : null;

  if (!isModalVisible) return null; // No renderizar si el modal no debe mostrarse

  return (
    <div
      id="modal-container"
      onClick={handleOutsideClick}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '8px',
          padding: '20px',
          position: 'relative',
          width: '100%',
          maxWidth: '900px',
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <button
          onClick={closeModal}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
          }}
        >
          &times;
        </button>
        {nivel !== null ? (
          <>
            <img
              src={imageSrc}
              alt={`Imagen para el rango de nivel ${nivel}`}
              style={{ width: '100%', borderRadius: '8px', margin: '10px 0' }}
            />
          </>
        ) : (
          <p>Cargando información de nivel...</p>
        )}
      </div>
    </div>
  );
};

export default TravelModal24;
