import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";
import NavBar from "./NavBar";
import GridItem from "./GridItem";
import Bg from "./assets/header-bg5.png";
import Fg from "./assets/header-text2.png";
import Modal from "./Modal";
import "./style.css";
import "./tailwind.css";
import { useEffect } from "react"; 
import i18next from "i18next";
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
import { isValidURL } from "../../utils/isValidEmail";


const noscroll = require("no-scroll");

function BazarNavideno() {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState([]);
	const [category, setCategory] = useState("todos");
	const [allCats, setAllCats] = useState([]);
	const [open, setOpen] = useState(false);
	const [modal, setModal] = useState(false);
	// const allCats = [];

	useEffect(() => {
		/* //Consumo de servicio
		const url = `${process.env.REACT_APP_CADMIN}/christmas-bazaar-brands`;
		const cabeceras = {
			'Authorization':`Bearer ${process.env.REACT_APP_API_TOKEN}`,
			'Content-Type' :'application/json'
		};
		
		fetch( url, {
			method:'GET',
			headers:cabeceras
		} ).then( (res) => res.json )
		.then( (res)=>{
			const allCats = [];
			setProducts(res);
			for (let i = 0; i < res.length; i++) {
				if (allCats.indexOf(res[i].Category) === -1) {
					allCats.push(res[i].Category);
				}
			}
			setAllCats(allCats);
			setLoading(false);
		} ) */
		// const cabecera=  new Headers({
		// 	'Content-type': 'application/json',
		// 	'Authorization':'Bearer ' + process.env.REACT_APP_TKN
		// });

		// fetch(`${process.env.REACT_APP_CADMIN}/christmas-bazaar-brands`,{headers:cabecera})
		// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd
		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: `Bearer ${tokenValid}`
		});
		const locale = i18next.language === "es" ? "" : "?locale=en";
		const url = isValidURL(`${urlValid}christmas-bazaar-brands${locale}`);

		fetch(url, { headers: cabecera })
			.then((res) => res.json())
			.then((res) => {
				const uniqueCategories = new Set();
				setProducts(res.data);

				res.data.forEach((item) => {
					uniqueCategories.add(item.attributes.Category);
				});

				setAllCats(Array.from(uniqueCategories));
				setLoading(false);
			});
	}, []);

	const selectCategory = (theCat) => {
		setCategory(theCat);
	};

	// # Variables que switchean las ligas que se consumen segun sea elcaso
// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
/* Para productivo se ponen todas en falso */

// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + tokenValid
	});
	const handleClick = async (id) => {
		try {
			const urlChistmasBrands = isValidURL(`${urlValid}christmas-bazaar-brands/${id}`);
			const response = await fetch(encodeURI(urlChistmasBrands), {
				headers: cabecera
			});
			if (!response.ok) {
				throw new Error("Network response was not ok");
			}
			const data = await response.json();
			setModal(data);
			noscroll.on();
			setOpen(true);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const filterProducts = () => {
		const temp = [];
		for (let i = 0; i < products.length; i++) {
			if (category === products[i].attributes.Category) {
				temp.push(products[i]);
			}
		}
		return temp;
	};

	const handleClose = async () => {
		await setOpen(false);
		setModal(null);
		noscroll.off();
	};

	return (
		<div>
			<main className="main-container relative flex flex-col overflow-x-hidden bg-white md:flex-row">
				
				<section className="the-actual-content md:ml-space relative w-full pt-28 md:pt-0">
					
					<article className="the-content flex min-h-screen flex-col overflow-visible bg-amBlack pb-12">
						<header
							className="bazaar-header relative z-50 w-full rounded-lg"
							style={{
								backgroundImage: `url(${Bg})`,
								backgroundColor: "rgba(11, 35, 66, 0.75)",
								backdropFilter: "blur(5px)",
								backgroundPosition: "center top",
								height: "200px"
							}}>
							<img
								src={Fg}
								alt="Bazar Navideño"
								className="absolute top-1/2 left-1/2"
								style={{
									transform: "translate(-50%, -50%)",
									width: "390px"
								}}
							/>
						</header>
						<div className=" mx-auto flex flex-col items-center justify-center overflow-visible px-10">
							<div className="h-0.5 w-11/12 rounded-full bg-amRed" />
							<div className="grid-container mt-10 grid w-full grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
								{loading ? (
									<div className="flex flex-col items-center justify-center md:col-span-2 lg:col-span-3 xl:col-span-4">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											fill="none"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											className="animate-spin-slow h-10 w-10 stroke-current text-white"
											viewBox="0 0 24 24">
											<path stroke="none" d="M0 0h24v24H0z" />
											<path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" />
											<path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(60 12 12)" />
											<path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(120 12 12)" />
											<path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(180 12 12)" />
											<path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(240 12 12)" />
											<path d="M10 4l2 1l2 -1m-2 -2v6.5l3 1.72" transform="rotate(300 12 12)" />
										</svg>
										<h2 className="mt-3 w-full text-center text-sm font-bold uppercase text-white">
											cargando
										</h2>
									</div>
								) : (
									<AnimatePresence exitBeforeEnter={false} mode='wait'>
										{category === "todos" ? (
											<>
												{products.map((product, index) => (
													<GridItem
														delay={index}
														description={product.attributes.description}
														cat={product.attributes.Category}
														key={product.id + product.attributes.Category}
														id={product.id}
														title={product.attributes.Title}
														image={product.attributes?.Background[0].url}
														handleClick={() => handleClick(product.id)}
													/>
												))}
											</>
										) : (
											<>
												{filterProducts().map((product, index) => (
													<>
														{product.attributes.Category === category && (
															<GridItem
																delay={index}
																description={product.attributes.description}
																cat={product.attributes.Category}
																key={product.id}
																id={product.id}
																title={product.attributes.Title}
																image={product.attributes?.Background[0].url}
																handleClick={() => handleClick(product.id)}
															/>
														)}
													</>
												))}
											</>
										)}
									</AnimatePresence>
								)}
							</div>
						</div>
					</article>
				</section>
				<AnimatePresence mode="wait">
					{open && <Modal key="modal" intel={modal} handleClose={handleClose} />}
				</AnimatePresence>
			</main>
		</div>
	);
}

export default BazarNavideno;
