import isTravelBenefitsActive from '../isTravelBenefitsActive';

const DEV = false;
const level = parseInt(localStorage.getItem('nivel'));
const grupo = localStorage.getItem('grupo');
const email = localStorage.getItem('email');
const country = localStorage.getItem('pais');
const tipo_empleado = localStorage.getItem('tipo_empleado_AM');

export const validateMenu = () => {

	const country = localStorage.getItem('pais');
	const level = parseInt(localStorage.getItem('nivel'));

		if (country === 'MX') {
			if(localStorage.getItem("tipo_empleado_AM") === "NS"){
				if (
					level > 0 ||
					level !== null
				) {
					return true;
				} else {
					return false;
				}
			}else{
				return false;
			}
		} else {
			return false;
		}
};

export const validateTravelBenefits = () => {
	if (isTravelBenefitsActive()) {
		return true;
	} else {
		return false;
	}
};
