import React, { Component } from "react";
import arrow from "../../assets/images/arrow-blue.svg";
import { Link } from "react-router-dom";
import "./css/travelBenefits.css";

import avion from "../../assets/images/benefits/download-bg-travel.jpg";
import avionNew24 from "../../assets/images/benefits/download-bg-new24.jpg";

import logobenefit04 from "../../assets/images/profile/mybenefitslogotravel.svg";
import iconDownloadManual from "../../assets/images/download-icon.svg";
import estrellanew from '../../assets/travel/estrella_new.svg';

import asientoNew from '../../assets/images/profile/asiento_new.png';
import rewardsNew from '../../assets/images/profile/rewards_new.png';
import upgradeNew from '../../assets/images/profile/upgrade_new.png';

import AsientoGratisIMG from "../../assets/images/profile/travel-benefits-02.jpg";
import RewardsIMG from "../../assets/images/profile/rewards-2024.jpg"
import UpgradeIMG from "../../assets/images/profile/travel-benefits-01.jpg";



import profileIcon010 from "../../assets/images/profile/icon-mybenefits10.svg";
import profileIcon011 from "../../assets/images/profile/icon-mybenefits11.svg";
import profileIcon013 from "../../assets/images/profile/icon-mybenefits13.svg";
import accesTravel01 from "../../assets/images/arrow-blue.svg";
import linc from "../../assets/images/linc.jpg";
import core from "../../assets/images/core.jpg";
import id from "../../assets/images/id.jpg";
import periodos from "../../assets/calendar.svg";

import destinosTravel from "../../assets/destinos.svg";
import periodosTravel from "../../assets/politica.svg";
import politicaNewTravel from "../../assets/politicaNew.svg";
import Travel from "./TravelBenefits/travelbenefits";
import MaletaGratis from "./Modales/maleta";
import AsientoGratis from "./Modales/asiento";
import ModalPremier1 from "./Modales/modal1.js";
import ModalPremier7 from "./Modales/modalancillaries"; //--- Modal de Upgrade para niveles 7 - 9
import ModalPremier10 from "./Modales/premierL7"; //--- Modal de Upgrade para niveles 10 - 12

import PerdiodosEmbargo from "./Modales/modalembargo";

import Swiper from "react-id-swiper";
import "../Slider/css/swiper.css";
import i18next from "i18next";

import isTravelBenefitsActive, { covidTickets } from "../../utils/isTravelBenefitsActive";
import { AnimatePresence, color, motion } from "framer-motion";

import UpgradePremiere from "./Modales/UpgradePremiere";
import AncillarBtn from "./Modales/AncillarBtn";

import AsientoUpgrade from "../../assets/travel/asientoupgrade.svg";
import MaletaIconIMG from "../../assets/travel/maletasincosto.svg";

import MaletaGratisIMG from "../../assets/images/profile/travel-benefits-03.jpg";

import { isLocal, isFromDev, isFromQA } from "./../../core/Ambientes";

import TravelBenefitsModal from './TravelModal24';

const isSpanish = i18next.language === "es";
const showing = false;

const nivelUsuario = localStorage.getItem('nivel') || 'N/A';

export default class TravelBenefits extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: null,
			language: i18next.language,
			modalCovid: false,
			modalVisible: true,
			reembolsoAlert: sessionStorage.getItem("reembolsoAlert") || false
		};
	}

	async componentDidMount() {
		const urlLocal = `${process.env.REACT_APP_LOCAL}`;
		const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
		const urlQA = `${process.env.REACT_APP_CADMIN_QA}`;
		const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`;

		const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
		const tokenDev = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
		const tokenQA = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
		const tokenProd = `${process.env.REACT_APP_API_TOKEN}`;

		// # Liga validada
		const urlValid = isLocal === true ? urlLocal : isFromDev === true ? urlDev : isFromQA === true ? urlQA : urlProd;

		// Se genera el token segun el ambiente
		const tokenValid = isLocal === true ? tokenLocal : isFromDev === true ? tokenDev : isFromQA === true ? tokenQA : tokenProd;

		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});

		const baseUrl = `${urlValid}my-travel-benefits`;
		const locale = isSpanish ? "es-MX" : "en";
		const url = `${baseUrl}?locale=${locale}`;

		// Mostrar el modal si la cookie no está presente
		if (!localStorage.getItem("cookieNiveles2024")) {
			this.setState({ modalVisible: true });
		}
		
		await fetch(url, { headers: cabecera })
			.then((response) => {
				return response.json();
			}) 
			.then(async (json) => { 
					this.setState({
					...this.state,
					loading: false,
					content: i18next.language === "es" ? json.data.es : json.data.en
				});
			}); 
		// if (covidTickets()) {
		// 	setTimeout(() => {
		// 		this.setState({
		// 			...this.state,
		// 			modalCovid: true
		// 		});
		// 	}, 250);
		// }
	}

	handleCloseModal = () => {
		this.setState({ modalVisible: false });
		localStorage.setItem("cookieNiveles2024", "true");
	};

	hidingCookie = () => {
		this.setState({
			...this.state,
			reembolsoAlert: true
		});
		sessionStorage.setItem("reembolsoAlert", true);
	};

	upgradePremiereData = (level) => {
		let premData = {
			title: i18next.t("MiExperAM.travel.ancillares.upgrade.title"),
			firstAlert: i18next.t("MiExperAM.travel.ancillares.upgrade.new.l1_6_firstAlert"),
			content: i18next.t("MiExperAM.travel.ancillares.upgrade.new.l1_6"),
			endAlert: i18next.t("MiExperAM.travel.ancillares.upgrade.new.l1_6_endAlert")
		};
		switch (true) {
			case level < 7:
				return premData;
			case level > 6 && level < 10:
				premData.secondContent =
					i18next.t("MiExperAM.travel.ancillares.upgrade.new.l7_9_table_title") +
					i18next.t("MiExperAM.travel.ancillares.upgrade.new.price_table_first");
				premData.table = i18next.t("MiExperAM.travel.ancillares.upgrade.new.price_table");
				premData.endContent = i18next.t("MiExperAM.travel.ancillares.upgrade.new.price_table_end");
				return premData;
			case level > 9:
				premData.secondContent =
					i18next.t("MiExperAM.travel.ancillares.upgrade.new.l10_12") +
					i18next.t("MiExperAM.travel.ancillares.upgrade.new.l10_12_table_title") +
					i18next.t("MiExperAM.travel.ancillares.upgrade.new.price_table_first");
				premData.table = i18next.t("MiExperAM.travel.ancillares.upgrade.new.price_table");
				premData.endContent = i18next.t("MiExperAM.travel.ancillares.upgrade.new.price_table_end");
				return premData;
			default:
				return null;
		}
	};

	renderContentByLevel = (nivelRaw, email) => {
		switch (true) {
			case nivelRaw === "12C":
				return (
					<div>
						<h2>Tipo 12C</h2>
						<p>Nivel: {nivelRaw} <br /> Email: {email}</p>
					</div>
				);
			case parseInt(nivelRaw, 10) >= 1 && parseInt(nivelRaw, 10) <= 4:
				return (
					<div>
						<h2>Tipo A</h2>
						<p>Nivel: {nivelRaw} <br /> Email: {email}</p>
					</div>
				);
			case parseInt(nivelRaw, 10) >= 5 && parseInt(nivelRaw, 10) <= 8:
				return (
					<div>
						<h2>Tipo B</h2>
						<p>Nivel: {nivelRaw} <br /> Email: {email}</p>
					</div>
				);
			case parseInt(nivelRaw, 10) >= 9 && parseInt(nivelRaw, 10) <= 12:
				return (
					<div>
						<h2>Tipo C</h2>
						<p>Nivel: {nivelRaw} <br /> Email: {email}</p>
					</div>
				);
			case parseInt(nivelRaw, 10) > 12:
				return (
					<div>
						<h2>Tipo D</h2>
						<p>Nivel: {nivelRaw} <br /> Email: {email}</p>
					</div>
				);
			default:
				return (
					<div>
						<h2>Nivel no reconocido</h2>
						<p>Email: {email}</p>
					</div>
				);
		}
	};

	

	render() {
		
		const nivel = parseInt(localStorage.getItem("nivel"));
		const nivelRaw = localStorage.getItem("nivel");
		const email = localStorage.getItem("email");
		const params = {
			slidesPerView: "auto",
			spaceBetween: 30,
			grabCursor: true,
			autoplay: {
				delay: 5000,
				disableOnInteraction: true
			},
			mousewheel: true,
			freeMode: false,

			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev"
			}
		};

		const levelImageMap = {
			AsientoSinCosto: [
				{ range: [1, 4], image: asientoNew },
				{ range: [5, 6], image: asientoNew },
				{ range: [7, 8], image: asientoNew },
				{ range: [9, 9], image: asientoNew },
				{ range: [10, 12], image: asientoNew },
				{ range: [13, 13], image: asientoNew },
			],
			RewardsUsers: [
				{ range: [1, 4], image: rewardsNew },
				{ range: [5, 6], image: rewardsNew },
				{ range: [7, 8], image: rewardsNew },
				{ range: [9, 9], image: rewardsNew },
				{ range: [10, 12], image: rewardsNew },
				{ range: [13, 13], image: rewardsNew },
			],
			UpgradeUsers: [
				{ range: [1, 4], image: UpgradeIMG },
				{ range: [5, 6], image: UpgradeIMG },
				{ range: [7, 8], image: upgradeNew },
				{ range: [9, 9], image: upgradeNew },
				{ range: [10, 12], image: upgradeNew },
				{ range: [13, 13], image: upgradeNew },
			],
		  };

		  const getImageByLevelAndModal = (nivelUsuario, nameModal) => {
			const nivelFinal = localStorage.getItem("nivel");
			// Convertir "12C" a "13"
			const normalizedNivel = nivelFinal === "12C" ? 13 : parseInt(nivelFinal, 10);

			const modalMap = levelImageMap[nameModal];
			if (!modalMap) return AsientoGratisIMG; // Si el modal no está definido, usar imagen por defecto
			
			// Buscar el rango que contiene el nivel
			const match = modalMap.find(({ range }) => 
				normalizedNivel >= range[0] && normalizedNivel <= range[1]
			);
		
			console.log(match)
			return match?.image || AsientoGratisIMG; // Si no se encuentra un rango, usar imagen por defecto
		  };
		  

		if (isTravelBenefitsActive()) {
			return (
				// <AnimatePresence exitBeforeEnter>
				// 	{!hideCookie && (
						
				// 	)}
				// </AnimatePresence>
				<motion.div
					key="travel-benefits-contenedor-2021"
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 100 }}
					className="travel-container">
						<TravelBenefitsModal />
					<div className="totalBenefits__title__container">
						<div className="logoTabBenefit">
							<img className="Travel" src={logobenefit04} />
						</div>
					</div>

					{!showing ? (
						null
					) : null}
					
					{/* INICIA COMPONENTE CON LA INFORMACION DE LOS BOLETOS */}
					<Travel />
					{/* TERMINA COMPONENTE CON LA INFORMACION DE LOS BOLETOS */}
					{/* <div className='travel-benefit-cards' /> */}

					<div className="okBenefitsChange">
						<div className="totalBenefitsMidWrapper">
							<div className="totalBenefitsTravel__container flex w-full flex-wrap">
								<div className="totalBenefits__title__container" id="AncillariesTitle">
									<div className="iconTitleProfile">
										<img src={profileIcon010} />
									</div>
									<h1 className="font-base !text-[#020C41]">
										{i18next.t("MiExperAM.travel.ancillares.title")}
									</h1>
								</div>

								<div className="totalBenefitsTravel__cards__container">
									{/* {showing ? (
										<>
											{!!nivel && (
												<AncillarBtn
													icon={AsientoUpgrade}
													title={i18next.t("MiExperAM.travel.ancillares.upgrade.title")}
													description={i18next.t(
														"MiExperAM.travel.ancillares.upgrade.preview"
													)}
													image={UpgradeIMG}
													data={this.upgradePremiereData(nivel)}
												/>
											)}
										</>
									) : null} */}
									{/* INICIA BOTON DE ASIENTO SIN COSTO EN ANCILLARIES */}
									<AncillarBtn
										icon={null}
										title={i18next.t("MiExperAM.travel.ancillares.asiento.title")}
										description={i18next.t("MiExperAM.travel.ancillares.asiento.sub")}
										image={getImageByLevelAndModal(nivelUsuario, "AsientoSinCosto")}
										data={{
											title: i18next.t("MiExperAM.travel.ancillares.asiento.title"),
											content: i18next.t("MiExperAM.travel.ancillares.asiento.subModal")
										}}
										nameModal="AsientoSinCosto"
										levelUser={nivelUsuario}
									/>
									{/* TERMINA BOTON DE ASIENTO SIN COSTO EN ANCILLARIES */}
									
									{/* INICIA PUNTOS REWARDS EN ANCILLARIES */}
									<AncillarBtn
										icon={null}
										title="Uso de Puntos Aeroméxico Rewards"
										description="Si cuentas con Tarjetas Santander Aeroméxico o Tarjetas American Express® Aeroméxico, podrás usar tus puntos Aeroméxico Rewards para adquirir productos y servicios adicionales"
										image={getImageByLevelAndModal(nivelUsuario, "RewardsUsers")}
										data={{
											title: 'Uso de Puntos Aeroméxico Rewards',
										}}
										nameModal="RewardsUsers"
										levelUser={nivelUsuario}
									/>
									{/* TERMINA PUNTOS REWARDS EN ANCILLARIES */}


									{/* INICIA UPGRADE EN ANCILLARIES */}
									<AncillarBtn
										icon={null}
										title="Upgrade"
										description="Con la iniciativa Upgrade, al realizar check-in en web o en app de Aeroméxico, automáticamente estarás en lista de espera para ascensos a Clase Premier al cierre del vuelo."
										image={getImageByLevelAndModal(nivelUsuario, "UpgradeUsers")}
										data={this.upgradePremiereData(nivel)}
										nameModal="UpgradeUsers"
										levelUser={nivelUsuario}
									/>
									{/* TERMINA UPGRADE EN ANCILLARIES */}
									{/* INICIA MALETA SIN COSTO EN ANCILLARIES */}
									{/* <AncillarBtn
										icon={MaletaIconIMG}
										image={MaletaGratisIMG}
										title={i18next.t("MiExperAM.travel.ancillares.maleta.title")}
										description={i18next.t("MiExperAM.travel.ancillares.maleta.sub")}
										data={{
											title: i18next.t("MiExperAM.travel.ancillares.maleta.title"),
											subtitle: i18next.t("MiExperAM.travel.ancillares.maleta.sub"),
											content: i18next.t("MiExperAM.travel.ancillares.maleta.p1"),
											endAlert: i18next.t("MiExperAM.travel.ancillares.maleta.high")
										}}
									/> */}
									{/* TERMINA MALETA SIN COSTO EN ANCILLARIES */}
								</div>

								<div className="totalBenefits__title__container">
									<div className="iconTitleProfile">
										<img src={profileIcon013} />
									</div>
									<h1>{i18next.t("MiExperAM.travel.masInfoTitle")}</h1>
								</div>

								<div className="moreInformationWrap">
									<Swiper {...params} className="swiper-home-slider">
										<a
											className="boxMore information"
											href={i18next.t("MiExperAM.travel.masInfo.cambiosLink")}
											target="_blank"
											rel="noopener noreferrer">
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t("MiExperAM.travel.masInfo.cambios")}
													</p>
													<p className="subtitle">
														{i18next.t("MiExperAM.travel.masInfo.cambiosSub")}
													</p>
												</div>
												{/* <p className="link">Leer más</p> 
											</div>
										</a>

										<a
											className="boxMore information"
											href={i18next.t("MiExperAM.travel.masInfo.reembolsosLink")}
											target="_blank"
											rel="noopener noreferrer">
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t("MiExperAM.travel.masInfo.reembolsos")}
													</p>
													<p className="subtitle">
														{i18next.t("MiExperAM.travel.masInfo.reembolsosSub")}
													</p>
												</div>
												{/* <p className="link">Leer más</p>*/}
											</div>
										</a>

										<a
											className="boxMore information"
											href={i18next.t("MiExperAM.travel.masInfo.compaLink")}
											target="_blank"
											rel="noopener noreferrer">
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">
														{i18next.t("MiExperAM.travel.masInfo.compa")}
													</p>
													<p className="subtitle">
														{i18next.t("MiExperAM.travel.masInfo.compaSub")}
													</p>
												</div>
												{/* <p className="link">Leer más</p> */}
											</div>
										</a> 

										<a
											className="boxMore information"
											href={i18next.t("MiExperAM.travel.masInfo.zedLink")}
											target="_blank">
											<div className="pictureInformation"></div>
											<div className="textMoreInformation">
												<div className="textMoreInformationCell">
													<p className="title">{i18next.t("MiExperAM.travel.masInfo.zed")}</p>
													<p className="subtitle">
														{i18next.t("MiExperAM.travel.masInfo.zedSub")}
													</p>
												</div>
												{/* <p className="link">Leer más</p> */}
											</div>
										</a>
									</Swiper>
								</div>
							</div>
						</div>

						<div className="totalLeftBenefitsContacts">
							<div className="boletosEmbargo">
								<a href="https://aeromexico.com/es-mx/destinos" target="_blank">
									<div className="cardWrapperBoletos">
										<img className="icon" src={destinosTravel}></img>
										<div className="ticketsText">
											<p>{i18next.t("MiExperAM.travel.leftMenu.destinos")}</p>
										</div>
										<img className="arrow" src={accesTravel01}></img>
									</div>
								</a>

								<PerdiodosEmbargo />

								{parseInt(localStorage.getItem("nivel")) < 10 ? (
									<a
										// href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/travel-benefits/S_PO_GACB_08-1%20L1-9%20Espan%CC%83ol.pdf"
										href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/ptb-1-9.pdf"
										target="_blank">
										<div className="cardWrapperBoletos">
											<img className="icon" src={politicaNewTravel}></img>
											<div className="ticketsText">
												<p>{i18next.t("MiExperAM.travel.leftMenu.politica")}</p>
											</div>
											<img className="arrow" src={accesTravel01}></img>
										</div>
									</a>
								) : (
									<a
										// href="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/travel-benefits/S_PO_GACB_08-1%20L1-9%20Espan%CC%83ol.pdf"
										href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/ptb-10-12.pdf"
										target="_blank">
										<div className="cardWrapperBoletos">
											<img className="icon" src={politicaNewTravel}></img>
											<div className="ticketsText">
												<p>{i18next.t("MiExperAM.travel.leftMenu.politica")}</p>
											</div>
											<img className="arrow" src={accesTravel01}></img>
										</div>
									</a>
								)}

								{/* {localStorage.getItem('nivel') === '12C' ||
								localStorage.getItem('nivel') === '11' ||
								localStorage.getItem('nivel') === '12' ? (
									<a
										href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EbY3uuH2LfpKmIYL5ZeWBj0Be0XmdVWwOopYdwfwBbH_cw?e=yufxnj"
										target="_blank"
									>
										<div className="cardWrapperBoletos">
											<img
												className="icon"
												src={periodosTravel}
											></img>
											<div className="ticketsText">
												<p>
													{i18next.t(
														'MiExperAM.travel.leftMenu.politica'
													)}
												</p>
											</div>
											<img
												className="arrow"
												src={accesTravel01}
											></img>
										</div>
									</a>
								) : (
									''
								)}  */}
							</div>

							<div className="fixedBoletosEmbargo">
								<div className="acceslinks">
									<div className="title">
										<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.title")}</p>
									</div>

									<a href="https://autoservicio.aeromexico.com/" target="_blank">
										<div className="cardWrapperBoletos">
											<img className="icon" src={core}></img>
											<div className="ticketsText">
												<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.auto")}</p>
												<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.autoSub")}</p>
											</div>
											<img className="arrow" src={accesTravel01}></img>
										</div>
									</a>

									<a href="https://www.myidtravel.com/myidtravel/ui/" target="_blank">
										<div className="cardWrapperBoletos">
											<img className="icon" src={id} alt="Icono de boletos"></img>
											<div className="ticketsText">
												<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.travel")}</p>
												<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.travelSub")}</p>
											</div>
											<img className="arrow" src={accesTravel01} alt="Flecha de acceso"></img>
										</div>
									</a>

									<a href="tel:5591324488">
										<div className="cardWrapperBoletos">
											<img className="icon" src={linc}></img>
											<div className="ticketsText">
												<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.linea")}</p>
												<p>{i18next.t("MiExperAM.travel.leftMenu.boletos.lineaSub")}</p>
											</div>
											<img className="arrow" src={accesTravel01}></img>
										</div>
									</a>
								</div>
							</div>
						</div>
					</div>
					{/* <div className="CoreTravelContacts"></div> */}

					<DownloadManual />
				</motion.div>
			);
		} else {
			return null;
		}
	}
}
const BenefitCardBG = (props) => (
	<div
		className="benefits-big-card bg-container-card travel"
		style={{
			backgroundImage: `${props.gradient}, url(${props.image})`,
			backgroundSize: `${props.size}`,
			backgroundPositionY: `${props.positionY}`,
			backgroundPositionX: `${props.positionX}`
		}}>
		<p className="moreTravelBenefits__card__text" style={{ color: `${props.color}` }}>
			{props.content}
		</p>
		<h5
			className="moreTravelBenefits__card__title"
			style={{ color: `${props.color}` }}
			dangerouslySetInnerHTML={{ __html: props.title }}
		/>

		<Link className="link-travel-benefits" to="#">
			<div className="moreTravel">
				<h6 className="moreTravelBenefits__card__more" style={{ color: `${props.color}` }}>
					View more{" "}
				</h6>
				<img className="blue-arrow" src={arrow} alt="blue-arrow" />{" "}
			</div>
		</Link>
	</div>
);
const DownloadManual = () => {
	return (
		<>
		<div>
		{(() => {
			const levelPlane =localStorage.getItem('nivel');
			const luser = ((levelPlane == '12C')? '13' : levelPlane);
			const level = parseInt(luser);
			switch (true) {
				case parseInt(level, 10) >= 1 && parseInt(level, 10) <= 4:
					return (
						<>
							<div className="button-download travel">
								<a target="_blank" rel="noopener noreferrer" className="download-button" style={{ backgroundImage: `linear-gradient(rgba(11, 35, 67, 0), rgba(11, 35, 67,0.2)),url(${avionNew24})` }}
								href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/mtb_L1_L4_dic24.pdf">
									<div className="container-download">
										<div class="icondownload">
											<img src={estrellanew} alt="downloadmanual" />
										</div>
										{/* <i className="fa fa-download" /> */}
										<div className="down-benefits">
											<h3 className="download-benefits">{i18next.t("MiExperAM.travel.manualTitle")}</h3>
											<h5 className="download-bnf">{i18next.t("MiExperAM.travel.manualSub")}</h5>
										</div>
									</div>
									<h2 className="download" style={{color:'#000c44',fontSize:'1.2rem'}}>{i18next.t("MiExperAM.travel.descargar")}</h2>
								</a>
							</div>
						</>
					);
				case parseInt(level, 10) >= 5 && parseInt(level, 10) <= 6:
					return (
						<>
						<div className="button-download travel">
							<a target="_blank" rel="noopener noreferrer" className="download-button" style={{ backgroundImage: `linear-gradient(rgba(11, 35, 67, 0), rgba(11, 35, 67,0.2)),url(${avionNew24})` }}
							href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/mtb_L5_L6_dic24.pdf">
								<div className="container-download">
									<div class="icondownload">
										<img src={estrellanew} alt="downloadmanual" />
									</div>
									{/* <i className="fa fa-download" /> */}
									<div className="down-benefits">
										<h3 className="download-benefits">{i18next.t("MiExperAM.travel.manualTitle")}</h3>
										<h5 className="download-bnf">{i18next.t("MiExperAM.travel.manualSub")}</h5>
									</div>
								</div>
								<h2 className="download" style={{color:'#000c44',fontSize:'1.2rem'}}>{i18next.t("MiExperAM.travel.descargar")}</h2>
							</a>
						</div>
						</>
					);
				case parseInt(level, 10) >= 7 && parseInt(level, 10) <= 8:
					return (
						<>
						<div className="button-download travel">
							<a target="_blank" rel="noopener noreferrer" className="download-button" style={{ backgroundImage: `linear-gradient(rgba(11, 35, 67, 0), rgba(11, 35, 67,0.2)),url(${avionNew24})` }}
							href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/mtb_L7_L8_dic24.pdf">
								<div className="container-download">
									<div class="icondownload">
										<img src={estrellanew} alt="downloadmanual" />
									</div>
									{/* <i className="fa fa-download" /> */}
									<div className="down-benefits">
										<h3 className="download-benefits">{i18next.t("MiExperAM.travel.manualTitle")}</h3>
										<h5 className="download-bnf">{i18next.t("MiExperAM.travel.manualSub")}</h5>
									</div>
								</div>
								<h2 className="download" style={{color:'#000c44',fontSize:'1.2rem'}}>{i18next.t("MiExperAM.travel.descargar")}</h2>
							</a>
						</div>
						</>
					);
				case parseInt(level, 10) >= 9 && parseInt(level, 10) <= 9:
					return (
						<>
						<div className="button-download travel">
							<a target="_blank" rel="noopener noreferrer" className="download-button" style={{ backgroundImage: `linear-gradient(rgba(11, 35, 67, 0), rgba(11, 35, 67,0.2)),url(${avionNew24})` }}
							href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/mtb_L9_nov24.pdf">
								<div className="container-download">
									<div class="icondownload">
										<img src={estrellanew} alt="downloadmanual" />
									</div>
									{/* <i className="fa fa-download" /> */}
									<div className="down-benefits">
										<h3 className="download-benefits">{i18next.t("MiExperAM.travel.manualTitle")}</h3>
										<h5 className="download-bnf">{i18next.t("MiExperAM.travel.manualSub")}</h5>
									</div>
								</div>
								<h2 className="download" style={{color:'#000c44',fontSize:'1.2rem'}}>{i18next.t("MiExperAM.travel.descargar")}</h2>
							</a>
						</div>
						</>
					);
				case parseInt(level, 10) >= 10 && parseInt(level, 10) <= 12:
					return (
						<>
						<div className="button-download travel">
							<a target="_blank" rel="noopener noreferrer" className="download-button" style={{ backgroundImage: `linear-gradient(rgba(11, 35, 67, 0), rgba(11, 35, 67,0.2)),url(${avionNew24})` }}
							href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/mtb_L10_12_dic24.pdf">
								<div className="container-download">
									<div class="icondownload">
										<img src={estrellanew} alt="downloadmanual" />
									</div>
									{/* <i className="fa fa-download" /> */}
									<div className="down-benefits">
										<h3 className="download-benefits">{i18next.t("MiExperAM.travel.manualTitle")}</h3>
										<h5 className="download-bnf">{i18next.t("MiExperAM.travel.manualSub")}</h5>
									</div>
								</div>
								<h2 className="download" style={{color:'#000c44',fontSize:'1.2rem'}}>{i18next.t("MiExperAM.travel.descargar")}</h2>
							</a>
						</div>
						</>
					);
				case parseInt(level, 10) >= 13 && parseInt(level, 10) <= 13:
					
					return (
						<>
						<div className="button-download travel">
							<a target="_blank" rel="noopener noreferrer" className="download-button" style={{ backgroundImage: `linear-gradient(rgba(11, 35, 67, 0), rgba(11, 35, 67,0.2)),url(${avionNew24})` }}
							href="https://dyn-miam-cde-pd.miaeromexico.com/2024/travel-benefits/mtb_L12C_nov24.pdf">
								<div className="container-download">
									<div class="icondownload">
										<img src={estrellanew} alt="downloadmanual" />
									</div>
									{/* <i className="fa fa-download" /> */}
									<div className="down-benefits">
										<h3 className="download-benefits">{i18next.t("MiExperAM.travel.manualTitle")}</h3>
										<h5 className="download-bnf">{i18next.t("MiExperAM.travel.manualSub")}</h5>
									</div>
								</div>
								<h2 className="download" style={{color:'#000c44',fontSize:'1.2rem'}}>{i18next.t("MiExperAM.travel.descargar")}</h2>
							</a>
						</div>
						</>
					);
				default:
					return <p>Este es el contenido por defecto para otros modales.</p>;
				}
			})()}
	</div>
	</>
	);

};
