const fakeStockER = [
    {
      empleado: '301723',
      email: 'jalvaradoj@aeromexico.com',
      nombre: 'Jose Luis Alvarado Jimenez',
      rango: 'CAP',
      equipo: '787',
      contrato: 'A',
      passConstra : "Jakemate.12"
    },
    {	empleado:'100000',	email:'jamartinezf@aeromexico.com',	nombre:'MARTINEZ FERRER JAVIER ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'A',	passConstra:'cgc3#PHW48',	},
    {	empleado:'100100',	email:'ccruzg@aeromexico.com',	nombre:'CRUZ GONZALEZ CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'svALvzEZAb',	},
    {	empleado:'100300',	email:'dguerra@aeromexico.com',	nombre:'GUERRA ALANIS DALIA MARIBEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'11d87WB@ZJ',	},
    {	empleado:'100500',	email:'bfigueroa@aeromexico.com',	nombre:'FIGUEROA MARQUEZ BENJAMIN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZnL9ay93ry',	},
    {	empleado:'100600',	email:'gamonroy@aeromexico.com',	nombre:'MONROY BASTIDA GUILLERMO ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Ti6je4VJEN',	},
    {	empleado:'101009',	email:'joflores@aeromexico.com',	nombre:'FLORES TOM JORGE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PVZgY1Y@MT',	},
    {	empleado:'101010',	email:'ldiazh@aeromexico.com',	nombre:'DIAZ HIDALGO LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'zZ##ZEKV%z',	},
    {	empleado:'101012',	email:'cmiranda@aeromexico.com',	nombre:'MIRANDA CASTRO CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'vb9Y$4GJZk',	},
    {	empleado:'101018',	email:'rcervantessa@aeromexico.com',	nombre:'CERVANTES SANCHEZ RODOLFO NICOLAS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'LkHkSxVZ2E',	},
    {	empleado:'101020',	email:'aalvarezba@aeromexico.com',	nombre:'ALVAREZ BALBAS ALFREDO DE JESUS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'E8XYvuZMSX',	},
    {	empleado:'101021',	email:'mdrodriguez@aeromexico.com',	nombre:'RODRIGUEZ ALMADA MIGUEL DARIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Pf$Z5YxMkJ',	},
    {	empleado:'101022',	email:'gfranco@aeromexico.com',	nombre:'FRANCO BELTRAN GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'CR9UY#5yuT',	},
    {	empleado:'101025',	email:'ofischer@aeromexico.com',	nombre:'FISCHER RUBIO OSCAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'UuYyuKbcZL',	},
    {	empleado:'101321',	email:'jodominguez@aeromexico.com',	nombre:'DOMINGUEZ HERNANDEZ JOSE MIGUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'NUQ9UgAc@U',	},
    {	empleado:'101421',	email:'averut@aeromexico.com',	nombre:'VERUT SIERES ALFREDO TIMOTHEE DOMINIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'C7E2Qq4bv%',	},
    {	empleado:'101612',	email:'mcarvallo@aeromexico.com',	nombre:'CARVALLO ORNELAS MIGUEL ARISTEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Bt7f!z%UKM',	},
    {	empleado:'101712',	email:'mlopezm@aeromexico.com',	nombre:'LOPEZ MICHELLOD MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Yur9A5Jwjb',	},
    {	empleado:'101812',	email:'aaldama@aeromexico.com',	nombre:'ALDAMA MARQUEZ ALVARO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'akEYSd#69Z',	},
    {	empleado:'101912',	email:'aandrade@aeromexico.com',	nombre:'ANDRADE RODRIGUEZ ALEJANDRO TONATHIU',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'c$1AL2&ZxZ',	},
    {	empleado:'102012',	email:'jralvidrez@aeromexico.com',	nombre:'ALVIDREZ ROSENDO JOSE RAFAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JFrAy31ZiC',	},
    {	empleado:'102112',	email:'enavarrop@aeromexico.com',	nombre:'NAVARRO PERALTA ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'6Qpskge&m&',	},
    {	empleado:'102114',	email:'mprado@aeromexico.com',	nombre:'PRADO OLVERA MARIO ESTEBAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'escQqLdhNp',	},
    {	empleado:'102212',	email:'mtarazona@aeromexico.com',	nombre:'TARAZONA TELLEZ MARIA FERNANDA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'7HVkDrj1ZY',	},
    {	empleado:'102214',	email:'cnavarro@aeromexico.com',	nombre:'NAVARRO DIAZ CARLOS ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'wJgXNwfiU@',	},
    {	empleado:'102312',	email:'asevilla@aeromexico.com',	nombre:'SEVILLA CARMONA ALFONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'jMdu$NjCq5',	},
    {	empleado:'102317',	email:'jmartinezescaname@aeromexico.com',	nombre:'MARTINEZ ESCANAME MANZANILLA JUAN LUIS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%tZ2REzYzY',	},
    {	empleado:'102414',	email:'hmtorres@aeromexico.com',	nombre:'TORRES FERNANDEZ HECTOR MIGUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'T1UR#UnMc#',	},
    {	empleado:'102417',	email:'dfloresd@aeromexico.com',	nombre:'FLORES DIAZ DAVID',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Rv1iGmeWCn',	},
    {	empleado:'102514',	email:'yrosas@aeromexico.com',	nombre:'ROSAS PIZANO YAFETH RAFAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'tP5&t3JdJ8',	},
    {	empleado:'102614',	email:'bterres@aeromexico.com',	nombre:'TERRES CASAS BERNARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'GrhUzq&ZSb',	},
    {	empleado:'102617',	email:'dislas@aeromexico.com',	nombre:'ISLAS VAZQUEZ DANTE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'KTzaE6wi$s',	},
    {	empleado:'102717',	email:'hrflores@aeromexico.com',	nombre:'FLORES RODRIGUEZ HUGO RENE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PZTuXXywti',	},
    {	empleado:'102917',	email:'meuan@aeromexico.com',	nombre:'EUAN AGUILAR MIGUEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Tc%DN3fWXY',	},
    {	empleado:'103014',	email:'iramirezh@aeromexico.com',	nombre:'RAMIREZ HERNANDEZ ISAAC ABRAHAM',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'gKhyZDYGJ#',	},
    {	empleado:'103117',	email:'jmunozr@aeromexico.com',	nombre:'MUNOZ RAMOS JUAN LUIS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZnBPLE#wDp',	},
    {	empleado:'103414',	email:'lanunez@aeromexico.com',	nombre:'NUNEZ BANUELOS LUIS ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'g935zZZhi3',	},
    {	empleado:'103417',	email:'efigueroab@aeromexico.com',	nombre:'FIGUEROA BAZAN EMMANUELLE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'4qYkgjNNQZ',	},
    {	empleado:'103517',	email:'nbernal@aeromexico.com',	nombre:'BERNAL LARA NAIN RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Vj1hRdjr88',	},
    {	empleado:'103614',	email:'jaortiz@aeromexico.com',	nombre:'ORTIZ GALICIA JOSE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'UdMivpHEYY',	},
    {	empleado:'103617',	email:'pmustre@aeromexico.com',	nombre:'MUSTRE GARZA PABLO ADRIAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'qG&V9#kq@W',	},
    {	empleado:'103712',	email:'igarciadealba@aeromexico.com',	nombre:'GARCIA DE ALBA TRUEBA IVAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'EYhZ7Zw6gP',	},
    {	empleado:'103812',	email:'rgcastaneda@aeromexico.com',	nombre:'CASTANEDA GUZMAN RAMON GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZbnqruDJrK',	},
    {	empleado:'103817',	email:'fberuben@aeromexico.com',	nombre:'BERUBEN AGUILAR FLAVIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Qu6!PCtmhD',	},
    {	empleado:'103912',	email:'fmieryteran@aeromexico.com',	nombre:'MIER Y TERAN MARIN FELIX ESTEBAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'d11WKdNWv%',	},
    {	empleado:'104012',	email:'jpfernandez@aeromexico.com',	nombre:'FERNANDEZ ISLAS JUAN PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'5na2avhm&3',	},
    {	empleado:'104112',	email:'hcarrillo@aeromexico.com',	nombre:'CARRILLO GARCIA HERNAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'WjZSU3eUFi',	},
    {	empleado:'104212',	email:'rhuerta@aeromexico.com',	nombre:'HUERTA RIVERA RICARDO ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'cYWD7Qk##T',	},
    {	empleado:'104312',	email:'sanguiano@aeromexico.com',	nombre:'ANGUIANO ZUBIETA SERGIO ARMANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'zvSkKZqpVx',	},
    {	empleado:'10514',	email:'jmacias@aeromexico.com',	nombre:'MACIAS SALGADO JUAN CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'k&28NpJyKP',	},
    {	empleado:'105212',	email:'mmartinezt@aeromexico.com',	nombre:'MARTINEZ TORRES MIGUEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1r6%@CGupk',	},
    {	empleado:'105312',	email:'hdelcastillo@aeromexico.com',	nombre:'DEL CASTILLO VAZQUEZ HECTOR ADRIAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'B2n1fgNR@B',	},
    {	empleado:'105412',	email:'calva@aeromexico.com',	nombre:'ALVA CONTRERAS CARLOS DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'VZk6YTVgba',	},
    {	empleado:'105512',	email:'oggarcia@aeromexico.com',	nombre:'GARCIA VAZQUEZ OMAR GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PYiVFJsn6f',	},
    {	empleado:'105712',	email:'lacaballero@aeromexico.com',	nombre:'CABALLERO MARQUEZ LUIS ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1zEJ#kGs$B',	},
    {	empleado:'105812',	email:'jjoya@aeromexico.com',	nombre:'JOYA GONZALEZ JOSE DE JESUS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'QJ12ds1@m3',	},
    {	empleado:'105912',	email:'ladominguez@aeromexico.com',	nombre:'DOMINGUEZ BARIDO LUIS ARNULFO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'STHF7&Ycwq',	},
    {	empleado:'10599',	email:'lgarcialascurain@aeromexico.com',	nombre:'GARCIA LASCURAIN CHAVEZ LUIS FELIPE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'8@WnG%dX3c',	},
    {	empleado:'106012',	email:'aeescalante@aeromexico.com',	nombre:'ESCALANTE DUBOST ANTONIO ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'T4B6ZJT6b7',	},
    {	empleado:'106112',	email:'cburad@aeromexico.com',	nombre:'BURAD CUEVAS CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'iMxLCVJknw',	},
    {	empleado:'106312',	email:'hcastaneda@aeromexico.com',	nombre:'CASTANEDA HERNANDEZ HECTOR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'WF@n2#QNeM',	},
    {	empleado:'10699',	email:'mjgarcia@aeromexico.com',	nombre:'GARCIA VALENCIA MARIO JOSE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'AxwCgXG433',	},
    {	empleado:'10720',	email:'mapaez@aeromexico.com',	nombre:'APAEZ TOSCANO MARIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'vSvKHp&s6s',	},
    {	empleado:'10799',	email:'apenad@aeromexico.com',	nombre:'PENA DICKINSON ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'YsWin325bc',	},
    {	empleado:'10899',	email:'gyeesanchez@aeromexico.com',	nombre:'YEE SANCHEZ LOPEZ GABRIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'uU8w$xBJFY',	},
    {	empleado:'109712',	email:'fnavarrete@aeromexico.com',	nombre:'NAVARRETE MARTINEZ FRANCISCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'#iFC3E2yB1',	},
    {	empleado:'109812',	email:'jaoviedo@aeromexico.com',	nombre:'OVIEDO VALDEZ JORGE ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'53ty9Z&DiW',	},
    {	empleado:'10999',	email:'hrojo@aeromexico.com',	nombre:'ROJO CAVADA HECTOR FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'DT#e61ZZmG',	},
    {	empleado:'110812',	email:'jmontano@aeromexico.com',	nombre:'MONTANO JUAREZ JOSE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'G7kYAnGFGt',	},
    {	empleado:'110912',	email:'jcgarcia@aeromexico.com',	nombre:'GARCIA OJEDA JOSE CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'!HYPiPFfKE',	},
    {	empleado:'11099',	email:'ldelavenne@aeromexico.com',	nombre:'DE LAVENNE DE CHOULOT JARDON LOUIS ALEXANDRE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'En!hgkY&cU',	},
    {	empleado:'111012',	email:'fiturbide@aeromexico.com',	nombre:'ITURBIDE PEREZ FERNANDO ISAAC',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'inYGvxEvWZ',	},
    {	empleado:'111112',	email:'egutierrezl@aeromexico.com',	nombre:'GUTIERREZ LEAL ERNESTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'gs3Z#4kmGy',	},
    {	empleado:'111212',	email:'egaragarza@aeromexico.com',	nombre:'GARAGARZA LORENZO EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1!nakJTjMU',	},
    {	empleado:'111312',	email:'cobregonl@aeromexico.com',	nombre:'OBREGON LOPEZ SAINZ CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'TYJq2uVaSV',	},
    {	empleado:'1114',	email:'jpingarron@aeromexico.com',	nombre:'PINGARRON VELASCO JOSE LUIS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'6HUYQPvshn',	},
    {	empleado:'111412',	email:'mkuri@aeromexico.com',	nombre:'KURI AGUILAR MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ALn&!6!UWD',	},
    {	empleado:'111419',	email:'prazo@aeromexico.com',	nombre:'RAZO MORENO PEDRO FABRICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'C7zCvS3kWb',	},
    {	empleado:'11199',	email:'rsosa@aeromexico.com',	nombre:'SOSA OSIO REYNALDO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'3nmrrfQ85m',	},
    {	empleado:'11215',	email:'malvarezm@aeromexico.com',	nombre:'ALVAREZ MACIEL MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'m16d1dg5iZ',	},
    {	empleado:'112712',	email:'aduran@aeromexico.com',	nombre:'DURAN MARIN ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1cGkb4qnvB',	},
    {	empleado:'112812',	email:'svaldes@aeromexico.com',	nombre:'VALDES OROPEZA SANTIAGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'92JaiyzXfa',	},
    {	empleado:'112912',	email:'lprado@aeromexico.com',	nombre:'PRADO VALDEZ LUIS FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'npkxidmBb7',	},
    {	empleado:'112919',	email:'miperez@aeromexico.com',	nombre:'PEREZ CARRERO MIGUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'BQK$QTvkvU',	},
    {	empleado:'11299',	email:'paznavurian@aeromexico.com',	nombre:'AZNAVURIAN ROURE PABLO ARAM',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'wV#rHy8r@b',	},
    {	empleado:'113212',	email:'eespinosam@aeromexico.com',	nombre:'ESPINOSA MENDIOLEA ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'W2%NKn8#yY',	},
    {	empleado:'113312',	email:'dquinto@aeromexico.com',	nombre:'QUINTO ABONCE DAMIAN ISAAC',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'BXrbVJ41Va',	},
    {	empleado:'113412',	email:'jomartinez@aeromexico.com',	nombre:'MARTINEZ GONZALEZ JONATHAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'#girhGQpn1',	},
    {	empleado:'113419',	email:'jclark@aeromexico.com',	nombre:'CLARK ALDAY JOSE CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'SHdWW8LFJx',	},
    {	empleado:'113512',	email:'ageyne@aeromexico.com',	nombre:'GEYNE URIBE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nv9!amF9Je',	},
    {	empleado:'113619',	email:'hmayorga@aeromexico.com',	nombre:'MAYORGA ALCANTARA HECTOR EMANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nPg!uZNarL',	},
    {	empleado:'113712',	email:'everaa@aeromexico.com',	nombre:'VERA ARREOLA ERICK',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%E184e@CzD',	},
    {	empleado:'113819',	email:'lfcontreras@aeromexico.com',	nombre:'CONTRERAS OJEDA LUVIA FERNANDA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nwVAxpJKs$',	},
    {	empleado:'114022',	email:'dhernandezro@aeromexico.com',	nombre:'HERNANDEZ ROSAS DIEGO FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'KuFJ#AFHHh',	},
    {	empleado:'114119',	email:'jogarrido@aeromexico.com',	nombre:'GARRIDO PEDRON JOSE MANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'PJqyeZG$!$',	},
    {	empleado:'114122',	email:'pferia@aeromexico.com',	nombre:'FERIA PATTERSON POLLIE GABRIELA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#ALK6C28e3',	},
    {	empleado:'114322',	email:'lcastros@aeromexico.com',	nombre:'CASTRO SOTELO LUIS EDGAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'k#MynW4gGU',	},
    {	empleado:'114522',	email:'rmartinezpe@aeromexico.com',	nombre:'MARTINEZ PENA RUBEN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8&we@@Eivw',	},
    {	empleado:'114622',	email:'mhernandezme@aeromexico.com',	nombre:'HERNANDEZ MENDIOLA MIRIAM',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Z#tkhLJrbH',	},
    {	empleado:'115422',	email:'amorett@aeromexico.com',	nombre:'MORETT FLEISCHMANN ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'zGYsJJ8397',	},
    {	empleado:'115600',	email:'cacuevas@aeromexico.com',	nombre:'CUEVAS LEON CARLOS ANDRES',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'sm8tJ#992i',	},
    {	empleado:'116522',	email:'rlugop@aeromexico.com',	nombre:'LUGO PINEDA RODRIGO DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'d!nJv9WF&K',	},
    {	empleado:'116622',	email:'eadamec@aeromexico.com',	nombre:'ADAME CUEVAS ERWIN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'XSdRzZQq38',	},
    {	empleado:'116722',	email:'mmendozaga@aeromexico.com',	nombre:'MENDOZA GARCIA LEO MANUEL ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Y@p7#Kg8PC',	},
    {	empleado:'119421',	email:'faguilera@aeromexico.com',	nombre:'AGUILERA MIRANDA FERNANDO AMAURY',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8ZSKS4iZvk',	},
    {	empleado:'119621',	email:'rnovelo@aeromexico.com',	nombre:'NOVELO CICERO ROBERTO ANDRES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'q$$dJ$vPJu',	},
    {	empleado:'119821',	email:'dpavon@aeromexico.com',	nombre:'PAVON SANCHEZ DIEGO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'njHZJgZM9k',	},
    {	empleado:'120022',	email:'lgaytanr@aeromexico.com',	nombre:'GAYTAN REYES LUIS FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cjmeaSJ#Jz',	},
    {	empleado:'120322',	email:'olopezre@aeromexico.com',	nombre:'LOPEZ REYNA OSCAR JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@5&Yzu1kYg',	},
    {	empleado:'120422',	email:'jduranv@aeromexico.com',	nombre:'DURAN VALENCIA JOSE OLIVER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'hFdUbfFXyW',	},
    {	empleado:'121912',	email:'llara@aeromexico.com',	nombre:'LARA MEDINA LIZETTE ANGELICA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'3C&r369KVX',	},
    {	empleado:'122112',	email:'gpliego@aeromexico.com',	nombre:'PLIEGO Y MORENO GERARDO DARIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'hJ7eZFPnQ1',	},
    {	empleado:'122212',	email:'fjdiaz@aeromexico.com',	nombre:'DIAZ CAMACHO FRANCISCO JAVIER',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nZ2Jg8cCZt',	},
    {	empleado:'122312',	email:'mhernandezma@aeromexico.com',	nombre:'HERNANDEZ MARTINEZ MARIANA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'G3xzJq!Up$',	},
    {	empleado:'122412',	email:'svidal@aeromexico.com',	nombre:'VIDAL GARCIA SERGIO ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'9!qUT57Su$',	},
    {	empleado:'122512',	email:'jtaboada@aeromexico.com',	nombre:'TABOADA ALARCON JOSE ALFREDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'RfxcJnrZS!',	},
    {	empleado:'122612',	email:'mamerino@aeromexico.com',	nombre:'MERINO LOPEZ MARIO ALBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'#s25XTqZWJ',	},
    {	empleado:'122712',	email:'jabadie@aeromexico.com',	nombre:'ABADIE TORRES JORGE LUIS FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZfptESi8hf',	},
    {	empleado:'122812',	email:'dacontreras@aeromexico.com',	nombre:'CONTRERAS SOLORZANO DANIEL ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'XRzX3ZbsPk',	},
    {	empleado:'122912',	email:'fguerreror@aeromexico.com',	nombre:'GUERRERO RONQUILLO FAUSTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'TZdd5AR8EK',	},
    {	empleado:'123012',	email:'rcinta@aeromexico.com',	nombre:'CINTA MORALES RAUL ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'UHqtuxvJUM',	},
    {	empleado:'123112',	email:'gschmitter@aeromexico.com',	nombre:'SCHMITTER SOTO GUILLERMO FEDERICO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ky%QK$HQiu',	},
    {	empleado:'123212',	email:'ccruzbarrita@aeromexico.com',	nombre:'CRUZ BARRITA GOMEZ CARLOS ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Fi&ThV5xn4',	},
    {	empleado:'123312',	email:'hrenteria@aeromexico.com',	nombre:'RENTERIA MATAMOROS HECTOR ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'cN3Sw$RRY$',	},
    {	empleado:'123412',	email:'csiqueiros@aeromexico.com',	nombre:'SIQUEIROS SUAREZ CARLOS ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PSgu%u7!Rn',	},
    {	empleado:'12423',	email:'jaescobedo@aeromexico.com',	nombre:'ESCOBEDO BANDA JAIME ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'J$H71W#Z1g',	},
    {	empleado:'12523',	email:'rfernandez@aeromexico.com',	nombre:'FERNANDEZ BAUMEISTER RODRIGO JOSE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'bb6879ieVG',	},
    {	empleado:'125712',	email:'eharo@aeromexico.com',	nombre:'HARO BALLESTEROS ERICK',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'9N11%ubakz',	},
    {	empleado:'125812',	email:'lgomeza@aeromexico.com',	nombre:'GOMEZ ASCENCIO LUIS ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'7&1JE1ujQJ',	},
    {	empleado:'125912',	email:'jccalzada@aeromexico.com',	nombre:'CALZADA DE ANDA JUAN CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'KwJ%gY8JnR',	},
    {	empleado:'126012',	email:'ucuenca@aeromexico.com',	nombre:'CUENCA SABIDO URIEL DAVID',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1rWyzsV@6g',	},
    {	empleado:'126112',	email:'eoropeza@aeromexico.com',	nombre:'OROPEZA AGUILAR EDUARDO SAID',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'BtMsYTBHnr',	},
    {	empleado:'126212',	email:'mrochin@aeromexico.com',	nombre:'ROCHIN BERRIOS MARIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZDhSZ$jN#c',	},
    {	empleado:'126223',	email:'avarela@aeromexico.com',	nombre:'VARELA SOLIS ANDREA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'HA%KDb1exv',	},
    {	empleado:'12623',	email:'mfarias@aeromexico.com',	nombre:'FARIAS ZAMUDIO MIGUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#j&!EPJBNF',	},
    {	empleado:'126312',	email:'josaldana@aeromexico.com',	nombre:'SALDANA REYES JORGE OMAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xqRYGeVEbQ',	},
    {	empleado:'126412',	email:'eruiz@aeromexico.com',	nombre:'RUIZ ORTEGA EDGAR PATRICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kYwxcnFPvh',	},
    {	empleado:'126512',	email:'jaltamirano@aeromexico.com',	nombre:'ALTAMIRANO CALDERON JORGE FRANCISCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'3SeZ%Rg319',	},
    {	empleado:'126612',	email:'asordo@aeromexico.com',	nombre:'SORDO ZAVALA ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%tFX@L!4Rg',	},
    {	empleado:'126712',	email:'ahinterholzer@aeromexico.com',	nombre:'HINTERHOLZER ESTUDILLO ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'cjVYMZQ3YF',	},
    {	empleado:'126912',	email:'paltamirano@aeromexico.com',	nombre:'ALTAMIRANO CALDERON PHILIPPE SEBASTIAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'CY7uxzq11j',	},
    {	empleado:'127012',	email:'ghuerta@aeromexico.com',	nombre:'HUERTA OCAMPO GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#cyn@VaGnY',	},
    {	empleado:'127112',	email:'adaglio@aeromexico.com',	nombre:'DAGLIO RODRIGUEZ AUGUSTO MOISES',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'T&2RSWtvMK',	},
    {	empleado:'127212',	email:'dasanchez@aeromexico.com',	nombre:'SANCHEZ HERNANDEZ DIEGO ALBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZNi8W8q6nE',	},
    {	empleado:'12790',	email:'jisusih@aeromexico.com',	nombre:'ISUSI HUERTA JOSE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'uFurrc6DYw',	},
    {	empleado:'128900',	email:'nguillermoprieto@aeromexico.com',	nombre:'GUILLERMO PRIETO WOOD NOEL CHARLES',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'!f1MDE9Apw',	},
    {	empleado:'129000',	email:'lrarmenta@aeromexico.com',	nombre:'ARMENTA JASSO LUIS RAMON',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'VurYXTMaXn',	},
    {	empleado:'129100',	email:'jvilla@aeromexico.com',	nombre:'VILLA REZA JUAN JOSE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'87Z6EpJnmE',	},
    {	empleado:'129300',	email:'jhmartinez@aeromexico.com',	nombre:'MARTINEZ LOPEZ JAVIER HECTOR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Ad&uUhFR7b',	},
    {	empleado:'129400',	email:'darcos@aeromexico.com',	nombre:'ARCOS CRUZ DANIEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'aDnHk1t6Fx',	},
    {	empleado:'129922',	email:'cmperez@aeromexico.com',	nombre:'PEREZ CANTILLO MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'LJFVkAb551',	},
    {	empleado:'130122',	email:'destrada@aeromexico.com',	nombre:'ESTRADA PINEDA DIEGO ABRAHAM',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Jyq1J7FrCY',	},
    {	empleado:'130322',	email:'lacostaq@aeromexico.com',	nombre:'ACOSTA QUIROZ LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'6Kvjmgxk@J',	},
    {	empleado:'130422',	email:'joramirez@aeromexico.com',	nombre:'RAMIREZ CARDENAS JOSE MARTIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'@%xutZs#@U',	},
    {	empleado:'130522',	email:'rsaenz@aeromexico.com',	nombre:'SAENZ HERNANDEZ RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nC34y71vCq',	},
    {	empleado:'13123',	email:'vmichaud@aeromexico.com',	nombre:'MICHAUD BARBOSA VICTOR MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Vyu4zC69mY',	},
    {	empleado:'1314',	email:'jgonzalezc@aeromexico.com',	nombre:'GONZALEZ CANCINO JUAN FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'2P7gWeT1BK',	},
    {	empleado:'131515',	email:'lmgasca@aeromexico.com',	nombre:'GASCA ROSILLO LUIS MIGUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'FVSRwsGZfm',	},
    {	empleado:'131715',	email:'jgarciam@aeromexico.com',	nombre:'GARCIA MARTINEZ JESUS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'R4A4k!u4Zj',	},
    {	empleado:'131815',	email:'pmuriel@aeromexico.com',	nombre:'MURIEL BALLESTEROS PEDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'zk99xDbNvP',	},
    {	empleado:'131915',	email:'pcoverston@aeromexico.com',	nombre:'COVERSTON FERNANDEZ PAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'NBiBRvg2Jc',	},
    {	empleado:'132015',	email:'iriveram@aeromexico.com',	nombre:'RIVERA MONTES DE OCA ISRAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PRXhp6DzRY',	},
    {	empleado:'13323',	email:'ehuertaa@aeromexico.com',	nombre:'HUERTA AEDO EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'N57zLKYgv6',	},
    {	empleado:'133617',	email:'acalvo@aeromexico.com',	nombre:'CALVO BALTAZAR ARMANDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'8rA1TvZDKQ',	},
    {	empleado:'133718',	email:'oreyna@aeromexico.com',	nombre:'REYNA CARRILLO OMAR ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'yXrjL1rHbj',	},
    {	empleado:'133818',	email:'rgarciac@aeromexico.com',	nombre:'GARCIA CALDERON ROMAN AMHED',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'wgZ$mZVJN8',	},
    {	empleado:'133912',	email:'avargas@aeromexico.com',	nombre:'VARGAS DIAZ ADRIAN EMILIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'FHs#ydNVCm',	},
    {	empleado:'133918',	email:'lvelazquezc@aeromexico.com',	nombre:'VELAZQUEZ CARAPIA LUIS RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'6Fx91ex1ZA',	},
    {	empleado:'134012',	email:'irodriguezm@aeromexico.com',	nombre:'RODRIGUEZ MARTINEZ ISRAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'kCBJYrusZZ',	},
    {	empleado:'134112',	email:'jllopez@aeromexico.com',	nombre:'LOPEZ ARREOLA JEAN LOUI',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xPQDcmzZkC',	},
    {	empleado:'134212',	email:'daltamirano@aeromexico.com',	nombre:'ALTAMIRANO CALDERON DIEGO STEFAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Zse9h!qC4&',	},
    {	empleado:'134312',	email:'jcarreiro@aeromexico.com',	nombre:'CARREIRO RIVERA JOSE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1jeCXKysmK',	},
    {	empleado:'134412',	email:'szamorab@aeromexico.com',	nombre:'ZAMORA BANDERAS SALVADOR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'i#2UNe&TiV',	},
    {	empleado:'134612',	email:'iaponte@aeromexico.com',	nombre:'APONTE TORRES IGNACIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'4ZJ23GARrM',	},
    {	empleado:'134712',	email:'ebaca@aeromexico.com',	nombre:'BACA ARENAS EDUARDO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'q26k!ATZsJ',	},
    {	empleado:'134812',	email:'jecampos@aeromexico.com',	nombre:'CAMPOS DE LA GARZA JESUS ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Av3fftgitb',	},
    {	empleado:'13623',	email:'mfuentesar@aeromexico.com',	nombre:'FUENTES ARTEAGA MARIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'birBP2#mg1',	},
    {	empleado:'136417',	email:'jjareda@aeromexico.com',	nombre:'JAREDA BECERRIL JOSE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'$JqSnstp@i',	},
    {	empleado:'136517',	email:'ecruzm@aeromexico.com',	nombre:'CRUZ MENDOZA EDGAR EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Z2ZDYwh3ZU',	},
    {	empleado:'136617',	email:'despinola@aeromexico.com',	nombre:'ESPINOLA GONZALEZ DANIKA EUNICE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'9wB529kdnp',	},
    {	empleado:'136817',	email:'atiburciol@aeromexico.com',	nombre:'TIBURCIO LOPEZ ALONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'pZeB$W8nQ%',	},
    {	empleado:'137117',	email:'sroldan@aeromexico.com',	nombre:'ROLDAN HERRERA SAMUEL ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kSt&5E8e$3',	},
    {	empleado:'13723',	email:'cwinsnes@aeromexico.com',	nombre:'WINSNES ALVAREZ CHRISTIAN BIRGER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZUDEcDYBP@',	},
    {	empleado:'137317',	email:'rarios@aeromexico.com',	nombre:'RIOS CERVANTES RODRIGO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YLZpGV&vnH',	},
    {	empleado:'137517',	email:'jcaballerom@aeromexico.com',	nombre:'CABALLERO MARQUEZ JUAN CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'VZHudQkQd%',	},
    {	empleado:'137717',	email:'achavezm@aeromexico.com',	nombre:'CHAVEZ MARTINEZ ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YYqUjPBfXR',	},
    {	empleado:'137912',	email:'msosa@aeromexico.com',	nombre:'SOSA ESTRADA MIGUEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'dnBg@kAsGZ',	},
    {	empleado:'138012',	email:'gminor@aeromexico.com',	nombre:'MINOR REYNA GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'puwAsZpKku',	},
    {	empleado:'138112',	email:'aayalan@aeromexico.com',	nombre:'AYALA NOVALES ALAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'SDDjt#7Mp8',	},
    {	empleado:'13820',	email:'sachorro@aeromexico.com',	nombre:'CHORRO FERNANDEZ SHIRLEY ADMAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'QFE&ggtnM1',	},
    {	empleado:'138212',	email:'xdiezgutierrez@aeromexico.com',	nombre:'DIEZ GUTIERREZ COOK XAVIER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'HNTdsUj2Mc',	},
    {	empleado:'13823',	email:'jkneip@aeromexico.com',	nombre:'KNEIP RODRIGUEZ JOHANN ALEXANDER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fin$4aiMEs',	},
    {	empleado:'138312',	email:'dgallardo@aeromexico.com',	nombre:'GALLARDO BARAJAS DIEGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'DckTYiWV$K',	},
    {	empleado:'138400',	email:'avargasg@aeromexico.com',	nombre:'VARGAS GOMEZ ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'yhnP7Xn1Yc',	},
    {	empleado:'138412',	email:'kcruz@aeromexico.com',	nombre:'CRUZ ESPINOSA KARLO CESAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1ZkMZv@hSN',	},
    {	empleado:'138500',	email:'orincon@aeromexico.com',	nombre:'RINCON NAVARRETE OSCAR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'@uDF39H6ww',	},
    {	empleado:'138512',	email:'bcampos@aeromexico.com',	nombre:'CAMPOS LOPEZ BRUNO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'XP52YCB2Z9',	},
    {	empleado:'138600',	email:'rmora@aeromexico.com',	nombre:'MORA ZAZUETA RICARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZA%6XEkFgm',	},
    {	empleado:'138612',	email:'jmoraless@aeromexico.com',	nombre:'MORALES SUBIAUR JULIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'2nn7P$aZ15',	},
    {	empleado:'138623',	email:'eencinasl@aeromexico.com',	nombre:'ENCINAS LOPEZ EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'!CfaF2!hSA',	},
    {	empleado:'138700',	email:'ofernandez@aeromexico.com',	nombre:'FERNANDEZ ARRIOLA OMAR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'YS4whwGb2D',	},
    {	empleado:'138712',	email:'vrhernandez@aeromexico.com',	nombre:'HERNANDEZ MONROY VICTOR RAMSES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kPkZLeCYJ3',	},
    {	empleado:'138812',	email:'fchapa@aeromexico.com',	nombre:'CHAPA BACA FRANCISCO DE JESUS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'DmpJTZnh3y',	},
    {	empleado:'138823',	email:'pvillegas@aeromexico.com',	nombre:'VILLEGAS MIRAMONTES PABLO RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'t&RYZAjYiD',	},
    {	empleado:'138900',	email:'gmurilloh@aeromexico.com',	nombre:'MURILLO HARO GUSTAVO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'8kBznk17iX',	},
    {	empleado:'138912',	email:'cholm@aeromexico.com',	nombre:'HOLM SANCHEZ CRISTIAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'2FB6QqZnZx',	},
    {	empleado:'138923',	email:'cfarfan@aeromexico.com',	nombre:'FARFAN RIVERA CARLOS DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'BD&nK14ftk',	},
    {	empleado:'139123',	email:'igutierrezd@aeromexico.com',	nombre:'GUTIERREZ DEL ANGEL ILSE CAROLINA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'W7kfW$3f&L',	},
    {	empleado:'139223',	email:'gcandiani@aeromexico.com',	nombre:'CANDIANI VAZQUEZ GUISSEPE LUIGI',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'a@nYqy1taX',	},
    {	empleado:'13923',	email:'nkishi@aeromexico.com',	nombre:'KISHI MARTIN NAOKI ALONSO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&ZnWHXtax&',	},
    {	empleado:'139423',	email:'bescobar@aeromexico.com',	nombre:'ESCOBAR AVINA BENJAMIN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PtnwGt4D2M',	},
    {	empleado:'139523',	email:'dcarbantes@aeromexico.com',	nombre:'CARBANTES MORENO DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'bMS6xBxAJP',	},
    {	empleado:'139623',	email:'rbarraganc@aeromexico.com',	nombre:'BARRAGAN CARBAJAL RUBEN LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'qRMx#knK@J',	},
    {	empleado:'139823',	email:'mchapa@aeromexico.com',	nombre:'CHAPA BUTRON MAXIMILIANO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Vy4W9Vcjru',	},
    {	empleado:'14020',	email:'kmedina@aeromexico.com',	nombre:'MEDINA CASTILLO KARLA ARACY',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'LhxVfk6qk#',	},
    {	empleado:'140223',	email:'resandoval@aeromexico.com',	nombre:'SANDOVAL HUERTA RICARDO ERMILO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Y!mz1U#3wA',	},
    {	empleado:'140323',	email:'jalba@aeromexico.com',	nombre:'ALBA DE LA TORRE JUAN PABLO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'mesw%%ktWW',	},
    {	empleado:'140423',	email:'rcamacho@aeromexico.com',	nombre:'CAMACHO MEJIA RICARDO OMAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'rdkp$#ijVN',	},
    {	empleado:'140523',	email:'mmoranr@aeromexico.com',	nombre:'MORAN RODRIGUEZ MAXIMILIANO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Xgv%6fqRnx',	},
    {	empleado:'140712',	email:'rdominguez@aeromexico.com',	nombre:'DOMINGUEZ HERNANDEZ RAFAEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'4ZqAfkV5nn',	},
    {	empleado:'140812',	email:'gvazquezh@aeromexico.com',	nombre:'VAZQUEZ HERNANDEZ GUILLERMO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'G@JeHSj1Gg',	},
    {	empleado:'140912',	email:'ryanez@aeromexico.com',	nombre:'YANEZ GARCIA MORENO RODRIGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'kDL%9mpN$1',	},
    {	empleado:'141012',	email:'dmoguel@aeromexico.com',	nombre:'MOGUEL FLORES DAVID ALFREDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'xgGdKZ2AAb',	},
    {	empleado:'141022',	email:'jmarquezc@aeromexico.com',	nombre:'MARQUEZ CASTILLO JOSE AUGUSTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YWYTGR1Egk',	},
    {	empleado:'141112',	email:'smedinilla@aeromexico.com',	nombre:'MEDINILLA GARCIA SERGIO ADRIAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'@fpKq7ySUH',	},
    {	empleado:'14120',	email:'cestrada@aeromexico.com',	nombre:'ESTRADA CARRASCO CARLOS RAFAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nU$#UhJPFc',	},
    {	empleado:'141212',	email:'avillegasl@aeromexico.com',	nombre:'VILLEGAS LARRAURI ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'zY9dEZBnJZ',	},
    {	empleado:'141312',	email:'mpreciadod@aeromexico.com',	nombre:'PRECIADO DAVALOS MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1c6g7NGkGK',	},
    {	empleado:'1414',	email:'haguiniga@aeromexico.com',	nombre:'AGUINIGA FLORES HECTOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'hr$MxFHu1&',	},
    {	empleado:'141412',	email:'htamborrells@aeromexico.com',	nombre:'TAMBORRELL SEGURA HUGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nxppJFS!!E',	},
    {	empleado:'141512',	email:'rrmadrigal@aeromexico.com',	nombre:'MADRIGAL SAURI RICARDO RAINERO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Vgf@r#@e9a',	},
    {	empleado:'141612',	email:'pfislas@aeromexico.com',	nombre:'ISLAS MORENO PEDRO FERNANDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'n2cyzJQman',	},
    {	empleado:'141622',	email:'cdezulueta@aeromexico.com',	nombre:'DE ZULUETA LLERA CARLOS GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'p2yuQtGEuW',	},
    {	empleado:'141712',	email:'dolamendi@aeromexico.com',	nombre:'OLAMENDI CUEVAS DAVID',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JBJqQj32Yd',	},
    {	empleado:'141722',	email:'jmezag@aeromexico.com',	nombre:'MEZA GARCIA JUAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'NEABF@JzGk',	},
    {	empleado:'141812',	email:'srenteria@aeromexico.com',	nombre:'RENTERIA MATAMOROS SERGIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'df82HJ1gSR',	},
    {	empleado:'141822',	email:'lsolis@aeromexico.com',	nombre:'SOLIS SANCHEZ LEOPOLDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Ti1Ctd86gk',	},
    {	empleado:'14220',	email:'daovando@aeromexico.com',	nombre:'OVANDO ALVAREZ DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'isCuPRbhJT',	},
    {	empleado:'14223',	email:'evargasca@aeromexico.com',	nombre:'VARGAS CALDERON EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZVuV3k14YX',	},
    {	empleado:'143621',	email:'emlopez@aeromexico.com',	nombre:'LOPEZ ZAVALETA EMILIANO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'WgM&JNeCU6',	},
    {	empleado:'143721',	email:'emireles@aeromexico.com',	nombre:'MIRELES REVUELTA EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Uzm2Rp&q6C',	},
    {	empleado:'143821',	email:'sbrodriguez@aeromexico.com',	nombre:'RODRIGUEZ PEREZ SERGIO BELSAY',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'q1egM4JZvc',	},
    {	empleado:'143921',	email:'anesquivel@aeromexico.com',	nombre:'ESQUIVEL PORRAGAS ANDREA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kBUUAP$PEL',	},
    {	empleado:'144121',	email:'jcastilla@aeromexico.com',	nombre:'CASTILLA ESCUDERO JOSE GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'iAB$CwxzBs',	},
    {	empleado:'14420',	email:'dagonzalezv@aeromexico.com',	nombre:'GONZALEZ VELAZQUEZ DANIEL ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jv3QCedY4n',	},
    {	empleado:'144221',	email:'cacamacho@aeromexico.com',	nombre:'CAMACHO DIAZ CLAUDIO ALEXANDER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'7937DunkD7',	},
    {	empleado:'144421',	email:'rsalas@aeromexico.com',	nombre:'SALAS HERNANDEZ RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'CR#WDSLn#6',	},
    {	empleado:'144522',	email:'amgalaviz@aeromexico.com',	nombre:'GALAVIZ HIGUERA ALBERTO MARIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'awD4VCsYDP',	},
    {	empleado:'145012',	email:'jrsalcedo@aeromexico.com',	nombre:'SALCEDO CORREA JESUS RAFAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'f9VM7Mumt6',	},
    {	empleado:'145112',	email:'siperez@aeromexico.com',	nombre:'PEREZ GONZALEZ SERGIO ISRAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZbAQ81SYYP',	},
    {	empleado:'14520',	email:'maelizarraras@aeromexico.com',	nombre:'ELIZARRARAS FLORES MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Jb1gTuDsVB',	},
    {	empleado:'145212',	email:'cvrodriguez@aeromexico.com',	nombre:'RODRIGUEZ FERRO CARLOS VALENTIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'P18PgxJ&DU',	},
    {	empleado:'14523',	email:'oladron@aeromexico.com',	nombre:'LADRON DE GUEVARA ARENAS OSCAR ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'sB@e6ui45U',	},
    {	empleado:'145312',	email:'aabategiovanni@aeromexico.com',	nombre:'ABATEGIOVANNI ROZANES AARON EMILIANO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'b1HeknnaDL',	},
    {	empleado:'145318',	email:'ecamachod@aeromexico.com',	nombre:'CAMACHO DIAZ ERICK MIGUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'SZyKJktn1Z',	},
    {	empleado:'145518',	email:'gugonzalez@aeromexico.com',	nombre:'GONZALEZ CIRRITO GUILLERMO EMMANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'115xAEZU#A',	},
    {	empleado:'145618',	email:'asotog@aeromexico.com',	nombre:'SOTO GONZALEZ ALEXANDER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'R818p2UAiR',	},
    {	empleado:'145718',	email:'mlobatog@aeromexico.com',	nombre:'LOBATO GONZALEZ MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'n#tu@uXSmX',	},
    {	empleado:'145913',	email:'dduenas@aeromexico.com',	nombre:'DUENAS SALAZAR DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'s%@zDWeFHb',	},
    {	empleado:'145918',	email:'apayes@aeromexico.com',	nombre:'PAYES PRICE ALEJANDRO ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JXZ8A2ykT&',	},
    {	empleado:'146013',	email:'fgomezortigoza@aeromexico.com',	nombre:'GOMEZ ORTIGOZA GONZALEZ FRANCISCO EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'2hhVb7gAJk',	},
    {	empleado:'146018',	email:'arenteriam@aeromexico.com',	nombre:'RENTERIA MATAMOROS ALAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'469TETG7SH',	},
    {	empleado:'146113',	email:'jlardizabal@aeromexico.com',	nombre:'LARDIZABAL MOREL JAVIER HECTOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'kk1#eAE8H@',	},
    {	empleado:'14620',	email:'daruiz@aeromexico.com',	nombre:'RUIZ ARELLANO DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'vq5h1RQ56A',	},
    {	empleado:'146213',	email:'jalvidrez@aeromexico.com',	nombre:'ALVIDREZ ROSENDO JORGE ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ar32SnEWCZ',	},
    {	empleado:'14623',	email:'cmendozaf@aeromexico.com',	nombre:'MENDOZA FIGUEROA CARLOS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#EnE12w7LW',	},
    {	empleado:'146313',	email:'jrvazquez@aeromexico.com',	nombre:'VAZQUEZ NAVA JOSE RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZnB7dHDpiE',	},
    {	empleado:'146613',	email:'dvillegas@aeromexico.com',	nombre:'VILLEGAS DUENAS DIEGO ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'7TQj@rm4p1',	},
    {	empleado:'146713',	email:'ycanaan@aeromexico.com',	nombre:'CANAAN GOMEZ YEZMIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Gm1YH9eE&5',	},
    {	empleado:'147319',	email:'jcorta@aeromexico.com',	nombre:'CORTA CARRASCO JOSE MANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'wphJeJWRF1',	},
    {	empleado:'147419',	email:'dcastro@aeromexico.com',	nombre:'CASTRO SAITO DANIEL ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1rLJL8kU6R',	},
    {	empleado:'147519',	email:'gmonroy@aeromexico.com',	nombre:'MONROY FELIX GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YP$Z6nnWkU',	},
    {	empleado:'14923',	email:'mrojasp@aeromexico.com',	nombre:'ROJAS PEREGRINA MIGUEL ARISTEO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dZMSQHCZqh',	},
    {	empleado:'15018',	email:'lgonzalezj@aeromexico.com',	nombre:'GONZALEZ JIMENEZ LUIS ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5kp#jG$5HU',	},
    {	empleado:'15023',	email:'hhernandezc@aeromexico.com',	nombre:'HERNANDEZ CAPISTRAN HECTOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'BXrivUNXTk',	},
    {	empleado:'15118',	email:'asalomon@aeromexico.com',	nombre:'SALOMON DELGADO ALAIN ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YFF36fgR8Q',	},
    {	empleado:'1514',	email:'rramirezfd@aeromexico.com',	nombre:'RAMIREZ FUENTES DE MARIA RICARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'jD91e8NYZ9',	},
    {	empleado:'15218',	email:'smillan@aeromexico.com',	nombre:'MILLAN OCHOA SERGIO JESUS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'RGfFMhR@w3',	},
    {	empleado:'15223',	email:'jgrayeb@aeromexico.com',	nombre:'GRAYEB YANEZ JOSE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8t8k#MJdWq',	},
    {	empleado:'152400',	email:'mponcedeleon@aeromexico.com',	nombre:'PONCE DE LEON ZEPEDA MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Nn1S!6YgJt',	},
    {	empleado:'152600',	email:'jescobedo@aeromexico.com',	nombre:'ESCOBEDO AMEZCUA JAIME',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'UZMpg1!qki',	},
    {	empleado:'15323',	email:'roliveras@aeromexico.com',	nombre:'OLIVERAS OLMOS RAFAEL HUMBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zfcCsdsBg5',	},
    {	empleado:'153924',	email:'elozanot@aeromexico.com',	nombre:'LOZANO TORRES EUGENIO IVAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'w&nAWFxrpE',	},
    {	empleado:'154600',	email:'msaavedra@aeromexico.com',	nombre:'SAAVEDRA ACEVES MAURICIO HUGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'!GWBDQH32P',	},
    {	empleado:'154700',	email:'ahuesca@aeromexico.com',	nombre:'HUESCA BRAVO ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'8ZkRHmVZ1G',	},
    {	empleado:'154800',	email:'joy@aeromexico.com',	nombre:'DIAZ Y DIAZ JOSE EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'x$HcpP@dtQ',	},
    {	empleado:'154812',	email:'fsanchez@aeromexico.com',	nombre:'SANCHEZ ROMERO FELIPE ISRAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'&kAL8XwiEU',	},
    {	empleado:'154900',	email:'hvsanchez@aeromexico.com',	nombre:'SANCHEZ AMEZCUA HECTOR VINICIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'rYHZng9aGE',	},
    {	empleado:'154912',	email:'adelcastillo@aeromexico.com',	nombre:'DEL CASTILLO VILLARREAL ADALBERTO MIGUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$DsZZPmv41',	},
    {	empleado:'155100',	email:'pgutierrezc@aeromexico.com',	nombre:'GUTIERREZ CABALLERO PEDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'jyLk1$u&vR',	},
    {	empleado:'155413',	email:'gcarballido@aeromexico.com',	nombre:'CARBALLIDO MANZANILLA GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'QKefqpZE8i',	},
    {	empleado:'155513',	email:'eortegaa@aeromexico.com',	nombre:'ORTEGA ABRAHAM SERHANT EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'yx2R1fTNxZ',	},
    {	empleado:'155613',	email:'psuarez@aeromexico.com',	nombre:'SUAREZ FERNANDEZ PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'h8jBDhu6Z$',	},
    {	empleado:'155713',	email:'aescobedo@aeromexico.com',	nombre:'ESCOBEDO MARTINEZ ANTONIO IVAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'5vvyk@YXgP',	},
    {	empleado:'155813',	email:'aramon@aeromexico.com',	nombre:'RAMON QUINTANA ABRAHAM',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'uC1j8D864x',	},
    {	empleado:'155913',	email:'javalencia@aeromexico.com',	nombre:'VALENCIA GALLEGOS JESUS ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'fKJz4%G8kJ',	},
    {	empleado:'156013',	email:'raguillon@aeromexico.com',	nombre:'AGUILLON QUEZADA RICARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'4kqcPGkZef',	},
    {	empleado:'156113',	email:'gevelazquez@aeromexico.com',	nombre:'VELAZQUEZ VELAZQUEZ GUILLERMO ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'7nRE3g%mmc',	},
    {	empleado:'156213',	email:'hheredia@aeromexico.com',	nombre:'HEREDIA MALDONADO HECTOR ANDRES',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'DHH%H1mG8X',	},
    {	empleado:'15623',	email:'iarana@aeromexico.com',	nombre:'ARANA LOPEZ ILEANA DEL CARMEN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JpU73PCvyy',	},
    {	empleado:'156313',	email:'egrullan@aeromexico.com',	nombre:'RULLAN QUINTERO EDUARDO GREGORIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'htijCbEA2Z',	},
    {	empleado:'156413',	email:'jmorenom@aeromexico.com',	nombre:'MORENO MOCTEZUMA JORGE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'neCJGPL1JV',	},
    {	empleado:'156513',	email:'cmirandad@aeromexico.com',	nombre:'MIRANDA DE LEON CARLOS ALFREDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'LqEzKd&UxY',	},
    {	empleado:'156613',	email:'laestrada@aeromexico.com',	nombre:'ESTRADA GALOVIC LUIS ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'c6kDYZwJFY',	},
    {	empleado:'156713',	email:'hlopeza@aeromexico.com',	nombre:'LOPEZ AHUACTZIN HUMBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5zjJ9ZgaYG',	},
    {	empleado:'156813',	email:'gvonallworden@aeromexico.com',	nombre:'VON ALLWORDEN ROMERO GERD HUGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'xDu61niMZx',	},
    {	empleado:'156913',	email:'oelkhattabi@aeromexico.com',	nombre:'EL KHATTABI GONZALEZ OMAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'&CFCMi7eZ%',	},
    {	empleado:'157022',	email:'hayalaba@aeromexico.com',	nombre:'AYALA BARRAGAN HECTOR ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Xazjun1e4f',	},
    {	empleado:'157222',	email:'cgarciado@aeromexico.com',	nombre:'GARCIA DOMINGUEZ CARLOS ADIB',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'51UqyUV2uK',	},
    {	empleado:'157322',	email:'ggardoni@aeromexico.com',	nombre:'GARDONI PRIETO GUSTAVO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!hYMuN2k7Z',	},
    {	empleado:'157422',	email:'jlopezva@aeromexico.com',	nombre:'LOPEZ VAZQUEZ MAYOR JORGE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ERt#kY15J#',	},
    {	empleado:'157522',	email:'jmontanov@aeromexico.com',	nombre:'MONTANO VAZQUEZ JOSE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'a9Z1qfeLYw',	},
    {	empleado:'157622',	email:'jmunozmar@aeromexico.com',	nombre:'MUNOZ MARTINEZ JOSE ARMANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&19JLxyWV5',	},
    {	empleado:'157722',	email:'adiazm@aeromexico.com',	nombre:'DIAZ MORALES ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'rs#Nhp$yyY',	},
    {	empleado:'157822',	email:'operez@aeromexico.com',	nombre:'PEREZ BASURTO OMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'43YSjGYsag',	},
    {	empleado:'157922',	email:'mtorresb@aeromexico.com',	nombre:'TORRES BRINGAS MARCIAL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GVBf$aSG1Z',	},
    {	empleado:'158122',	email:'jvargasle@aeromexico.com',	nombre:'VARGAS LEON JORGE ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'$rAPn5Aj#p',	},
    {	empleado:'158222',	email:'ocruzr@aeromexico.com',	nombre:'CRUZ RODRIGUEZ ORLANDO DAVID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'q5DSEak3B6',	},
    {	empleado:'15823',	email:'msilverman@aeromexico.com',	nombre:'SILVERMAN RETANA MORRIS ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'aQH4yiXeVc',	},
    {	empleado:'158322',	email:'uvillanueva@aeromexico.com',	nombre:'VILLANUEVA SANTIAGO ULISES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZsYz4Pbn2$',	},
    {	empleado:'158622',	email:'cortizp@aeromexico.com',	nombre:'ORTIZ PARADA CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YQux8aZTJg',	},
    {	empleado:'15923',	email:'cllopez@aeromexico.com',	nombre:'LOPEZ MARIN CLAUDIA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8kJQNyvnM%',	},
    {	empleado:'159522',	email:'esanchezva@aeromexico.com',	nombre:'SANCHEZ VALADEZ ETIENNE ESTEBAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cFHQ1XAim2',	},
    {	empleado:'159622',	email:'hgarciasa@aeromexico.com',	nombre:'GARCIA SALAZAR HECTOR EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'QXv3JUnVKZ',	},
    {	empleado:'16023',	email:'dacevedo@aeromexico.com',	nombre:'ACEVEDO ZURITA DIEGO RAYMUNDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'MT8m%Ne6Qz',	},
    {	empleado:'16123',	email:'lbolanos@aeromexico.com',	nombre:'BOLANOS MORALES LEONOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5%2jUq4JvQ',	},
    {	empleado:'1614',	email:'caldama@aeromexico.com',	nombre:'ALDAMA MARQUEZ CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'j5dHY#!VGZ',	},
    {	empleado:'162601',	email:'mbernal@aeromexico.com',	nombre:'BERNAL CADENA MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZXFf%8J8Ta',	},
    {	empleado:'162901',	email:'ogomezj@aeromexico.com',	nombre:'GOMEZ JUAMBELZ OTHON',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Piiyn!ZYLz',	},
    {	empleado:'16299',	email:'rjimenez@aeromexico.com',	nombre:'JIMENEZ ESTRELLA RICARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'mNkuwAJ!u%',	},
    {	empleado:'163801',	email:'jgonzalezb@aeromexico.com',	nombre:'GONZALEZ BARRERA JULIO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'paJMXgV7m8',	},
    {	empleado:'16399',	email:'hgonzalezg@aeromexico.com',	nombre:'GONZALEZ GOMEZ HECTOR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'2Z&y6XX3Xm',	},
    {	empleado:'16423',	email:'lmperez@aeromexico.com',	nombre:'MENDEZ PEREZ TELLO LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z9Dz#RxwWW',	},
    {	empleado:'164311',	email:'jafernandez@aeromexico.com',	nombre:'FERNANDEZ ISLAS JAIME ANDRES',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%gnbGp%MN4',	},
    {	empleado:'164411',	email:'mdelrio@aeromexico.com',	nombre:'DEL RIO CARRAL MARTIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'q!3#TC@F47',	},
    {	empleado:'164511',	email:'pbasterra@aeromexico.com',	nombre:'BASTERRA MENDOZA PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'3&@Z@SjS1#',	},
    {	empleado:'164611',	email:'awalker@aeromexico.com',	nombre:'WALKER VILLARREAL ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZPt9CbE5Jw',	},
    {	empleado:'164711',	email:'msakal@aeromexico.com',	nombre:'SAKAL JASQUI MOSHE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'FcFY$ak9xD',	},
    {	empleado:'164811',	email:'jonate@aeromexico.com',	nombre:'ONATE VERA JOSE ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#GKfbAbTpq',	},
    {	empleado:'164911',	email:'jeversbusch@aeromexico.com',	nombre:'EVERSBUSCH WOLFF JORGE ANDRES',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Xw@t2BHBa1',	},
    {	empleado:'16499',	email:'barandia@aeromexico.com',	nombre:'ARANDIA CUBERO BERNARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3pwcHYF$@n',	},
    {	empleado:'165011',	email:'rgamboa@aeromexico.com',	nombre:'GAMBOA RAMIREZ RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z!Fyt!ADFP',	},
    {	empleado:'165111',	email:'ahernandezd@aeromexico.com',	nombre:'HERNANDEZ DE LEON ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'haS#KzUed1',	},
    {	empleado:'165211',	email:'ereyeso@aeromexico.com',	nombre:'REYES ORDUNA ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Q2wi3!PFq3',	},
    {	empleado:'165216',	email:'aperezt@aeromexico.com',	nombre:'PEREZ TREVINO ALONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ka&4J&ZwjL',	},
    {	empleado:'165311',	email:'cconde@aeromexico.com',	nombre:'CONDE DOMINGUEZ CARLOS EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'CMZZ!3mHPU',	},
    {	empleado:'165411',	email:'jlcardenas@aeromexico.com',	nombre:'CARDENAS CASTRO JORGE LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YgsJ3a$w3m',	},
    {	empleado:'165416',	email:'jfernandeza@aeromexico.com',	nombre:'FERNANDEZ AYALA JIMENA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Rw4%TTyn5c',	},
    {	empleado:'165511',	email:'fgomezn@aeromexico.com',	nombre:'GOMEZ NAVARRO FERNANDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'b1jJZ@GZmr',	},
    {	empleado:'165516',	email:'smartinezg@aeromexico.com',	nombre:'MARTINEZ GARCIA SERGIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'cEz8SdUXJ1',	},
    {	empleado:'165611',	email:'jsegundo@aeromexico.com',	nombre:'SEGUNDO GALICIA JORGE ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'y2yCz$2iZa',	},
    {	empleado:'165616',	email:'rahuerta@aeromexico.com',	nombre:'HUERTA ROMO RUBEN ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nFKRqxmk9V',	},
    {	empleado:'16599',	email:'gcoppola@aeromexico.com',	nombre:'COPPOLA GUILBOT GABRIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'JBYRGwtNXG',	},
    {	empleado:'16699',	email:'drocha@aeromexico.com',	nombre:'ROCHA EISENRING DANIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'nPsxZQGii7',	},
    {	empleado:'16719',	email:'vmmorales@aeromexico.com',	nombre:'MORALES BELTRAN VICTOR MANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZsLvCjNZLZ',	},
    {	empleado:'16723',	email:'aortizk@aeromexico.com',	nombre:'ORTIZ KHOURY ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'RFBh$is73S',	},
    {	empleado:'167317',	email:'frarellano@aeromexico.com',	nombre:'ARELLANO LOPEZ FERNANDO RAFAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'VnJRQTzxvb',	},
    {	empleado:'16799',	email:'fmonteverde@aeromexico.com',	nombre:'MONTEVERDE COBO FRANCISCO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'YTAs8ZCfY1',	},
    {	empleado:'16819',	email:'psmendoza@aeromexico.com',	nombre:'MENDOZA ORTUNO PAULO SERGIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5xNNay9ZWQ',	},
    {	empleado:'168211',	email:'oramosy@aeromexico.com',	nombre:'RAMOS Y RAMOS OSCAR JOAQUIN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'K#iE7anTM$',	},
    {	empleado:'16899',	email:'fsaldana@aeromexico.com',	nombre:'SALDANA PRESBITERO FRANCISCO JAVIER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Ta$e@6N2Wy',	},
    {	empleado:'169111',	email:'jsanjuan@aeromexico.com',	nombre:'SANJUAN DOMINGUEZ JULIAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'HH8c6qxfxx',	},
    {	empleado:'16919',	email:'damorales@aeromexico.com',	nombre:'MORALES BADIOLA DIEGO ALFONSO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'4vFZ$uj63n',	},
    {	empleado:'169211',	email:'rorrostietav@aeromexico.com',	nombre:'ORROSTIETA VERA RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'4vUeJ6@r9$',	},
    {	empleado:'169514',	email:'erubi@aeromexico.com',	nombre:'RUBI ALONSO EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'2g!ZWZhrJ!',	},
    {	empleado:'169614',	email:'hromero@aeromexico.com',	nombre:'ROMERO VIVAR HECTOR FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'XuZBDqNYwY',	},
    {	empleado:'169814',	email:'jsalas@aeromexico.com',	nombre:'SALAS RUIZ JOSE DAVID',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Jik$BKCQ@X',	},
    {	empleado:'169914',	email:'ayalonso@aeromexico.com',	nombre:'ALONSO BRAVO ALAN YAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'FxkPfBsDJR',	},
    {	empleado:'170011',	email:'fcastillos@aeromexico.com',	nombre:'CASTILLO SANCHEZ FERMIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'E3ZDU4GEZP',	},
    {	empleado:'170014',	email:'jsosa@aeromexico.com',	nombre:'SOSA ZARATE JESUS FERNANDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'@!dQKWYLEY',	},
    {	empleado:'170114',	email:'magomez@aeromexico.com',	nombre:'GOMEZ ALVARADO MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'A&@1FZD%P6',	},
    {	empleado:'17019',	email:'fvalenzuela@aeromexico.com',	nombre:'VALENZUELA BARBA FERNANDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'GAx5Bei2@u',	},
    {	empleado:'170211',	email:'scruzdelamora@aeromexico.com',	nombre:'CRUZ DE LA MORA MORENO SERGIO XAVIER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Zj%#JL5ZrJ',	},
    {	empleado:'170214',	email:'amorozco@aeromexico.com',	nombre:'OROZCO ANAHUATI ANDRES MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'AXibJtf4gs',	},
    {	empleado:'170311',	email:'cbasanez@aeromexico.com',	nombre:'BASANEZ ORTEGA CARLOS ALBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'bhaHTa!C%8',	},
    {	empleado:'170411',	email:'adominguezb@aeromexico.com',	nombre:'DOMINGUEZ BARIDO ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'8eFCvWnxz%',	},
    {	empleado:'170511',	email:'djuarezb@aeromexico.com',	nombre:'JUAREZ BENITEZ DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'HiZQcDEZ8D',	},
    {	empleado:'170611',	email:'jporozco@aeromexico.com',	nombre:'OROZCO ANAHUATI JUAN PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'SUNYLX4fai',	},
    {	empleado:'170711',	email:'jobregon@aeromexico.com',	nombre:'OBREGON ZELAYA JUAN PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'7&u!TpxyZp',	},
    {	empleado:'170811',	email:'hurrutia@aeromexico.com',	nombre:'URRUTIA QUINTANA HECTOR ALONSO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'gPjjrZ14%#',	},
    {	empleado:'170817',	email:'lpinedas@aeromexico.com',	nombre:'PINEDA SUAREZ LETICIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Zkyukq7XTb',	},
    {	empleado:'170917',	email:'fdelgadillo@aeromexico.com',	nombre:'DELGADILLO GONZALEZ FRANCISCO JAVIER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5tEhV2gWWE',	},
    {	empleado:'171011',	email:'hramirezv@aeromexico.com',	nombre:'RAMIREZ VAZQUEZ HUGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JY56dKJQzA',	},
    {	empleado:'171111',	email:'jglopez@aeromexico.com',	nombre:'LOPEZ CASTILLO JORGE GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5t1zCnsEnS',	},
    {	empleado:'171117',	email:'achavezma@aeromexico.com',	nombre:'CHAVEZ MARTINEZ ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'qMcS%$eyWP',	},
    {	empleado:'17119',	email:'ancastaneda@aeromexico.com',	nombre:'CASTANEDA HERNANDEZ ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Wn!#xxFpq1',	},
    {	empleado:'171211',	email:'jllanogarcia@aeromexico.com',	nombre:'LLANO GARCIA GRANADOS JUAN JACINTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nYZn5kMJLr',	},
    {	empleado:'171217',	email:'oodiaz@aeromexico.com',	nombre:'DIAZ MEDINA OSCAR OMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PkaXzEZFNL',	},
    {	empleado:'171219',	email:'jliceaga@aeromexico.com',	nombre:'LICEAGA MORA JAVIER',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'KCd$dt3#qJ',	},
    {	empleado:'171311',	email:'jmjartinezm@aeromexico.com',	nombre:'MARTINEZ MEJIA JOSE MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'akCWk5%zZy',	},
    {	empleado:'171317',	email:'jcuervoa@aeromexico.com',	nombre:'CUERVO ARANGO JOSE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'uQ3!RC&kq&',	},
    {	empleado:'171319',	email:'jcruzm@aeromexico.com',	nombre:'CRUZ MACIEL JUAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'y6&JyGGbcA',	},
    {	empleado:'1714',	email:'cacostar@aeromexico.com',	nombre:'ACOSTA ROSA CUAUHTEMOC',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JV7Z61nZfT',	},
    {	empleado:'171417',	email:'jrhuerta@aeromexico.com',	nombre:'HUERTA RIVERA JOSE DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZK@ZXWzknC',	},
    {	empleado:'171517',	email:'vchoel@aeromexico.com',	nombre:'CHOEL CASTILLO VICTOR MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nhAcZJpu9K',	},
    {	empleado:'171617',	email:'smendozaj@aeromexico.com',	nombre:'MENDOZA JIMENEZ SANTIAGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'w#9BZHfN&@',	},
    {	empleado:'17219',	email:'rbojalil@aeromexico.com',	nombre:'BOJALIL ROBLEDO RICARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'zn1YJBK@yx',	},
    {	empleado:'172701',	email:'crosas@aeromexico.com',	nombre:'ROSAS ROSAS CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'kZaB@i@91v',	},
    {	empleado:'173001',	email:'vfalcon@aeromexico.com',	nombre:'FALCON PERRONI VICTOR IGNACIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'kZJU6nD51q',	},
    {	empleado:'173111',	email:'rosanchez@aeromexico.com',	nombre:'SANCHEZ MERCADO ROGELIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1Kh12BRtuV',	},
    {	empleado:'17319',	email:'alrubio@aeromexico.com',	nombre:'RUBIO TIJERINA ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'NByA1eevWn',	},
    {	empleado:'173211',	email:'oobregon@aeromexico.com',	nombre:'OBREGON ZELAYA OSCAR EMILIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kki7My5xJr',	},
    {	empleado:'173311',	email:'mramireze@aeromexico.com',	nombre:'RAMIREZ ESPINOSA MIGUEL ALBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'w!Yq4tdUJu',	},
    {	empleado:'173511',	email:'jahernandezmo@aeromexico.com',	nombre:'HERNANDEZ MONTANO BENAVIDES JOSE ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Pqc@x3JTXF',	},
    {	empleado:'173611',	email:'tislas@aeromexico.com',	nombre:'ISLAS SOLLANO TANIA ITZEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'rYMPVMLU91',	},
    {	empleado:'173711',	email:'lzarate@aeromexico.com',	nombre:'ZARATE SEVILLA LUIS RODOLFO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nm!Rg@tgYS',	},
    {	empleado:'173811',	email:'ccastillog@aeromexico.com',	nombre:'CASTILLO GONZALEZ CARLOS ONCEAVO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'hxYyDnc3Xv',	},
    {	empleado:'173911',	email:'cocastillo@aeromexico.com',	nombre:'CASTILLO GONZALEZ CARLOS OCTAVO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nA4Ff%mnnH',	},
    {	empleado:'174011',	email:'aevelazquez@aeromexico.com',	nombre:'VELAZQUEZ AMADOR ALEJANDRO ELU',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'zFTLkk5b44',	},
    {	empleado:'17419',	email:'kimayett@aeromexico.com',	nombre:'MAYETT BARRERA KITZIA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'b%s4YHsZ8i',	},
    {	empleado:'17491',	email:'gcervantes@aeromexico.com',	nombre:'CERVANTES MAC SWINEY GUSTAVO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'QYz6DZktZS',	},
    {	empleado:'17519',	email:'dgomezc@aeromexico.com',	nombre:'GOMEZ CAMBEROS DANILO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'i$ZE&kkynV',	},
    {	empleado:'176119',	email:'eriverav@aeromexico.com',	nombre:'RIVERA VALTIERRA EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'u@ZtU@SnpW',	},
    {	empleado:'176218',	email:'driquelme@aeromexico.com',	nombre:'RIQUELME AVALOS DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xB!#Q2BxzY',	},
    {	empleado:'176318',	email:'rabelleyra@aeromexico.com',	nombre:'ABELLEYRA VALENCIA RICARDO MIGUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'a4PZwBfpLd',	},
    {	empleado:'176418',	email:'djimenezr@aeromexico.com',	nombre:'JIMENEZ RODRIGUEZ DAVID',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'VQQ8b!p4BU',	},
    {	empleado:'176518',	email:'bfrancom@aeromexico.com',	nombre:'FRANCO MARISCAL BENJAMIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'J1dGcP%hCB',	},
    {	empleado:'177213',	email:'galvarezg@aeromexico.com',	nombre:'ALVAREZ GERALA GERMAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'G1SEJnr7TC',	},
    {	empleado:'177313',	email:'rfarrera@aeromexico.com',	nombre:'FARRERA GUTIERREZ RIGEL ALONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'KmKRZ7@BgD',	},
    {	empleado:'177413',	email:'cescarcega@aeromexico.com',	nombre:'ESCARCEGA MAYNES CESAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'n7Phz6xnp5',	},
    {	empleado:'177613',	email:'iaguirre@aeromexico.com',	nombre:'AGUIRRE VILLICANA ISAIAS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JfEs7ME@Ei',	},
    {	empleado:'177713',	email:'atapiag@aeromexico.com',	nombre:'TAPIA GALLARDO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z!PEY5y2pP',	},
    {	empleado:'177813',	email:'vmontano@aeromexico.com',	nombre:'MONTANO ZETINA VICTORIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'dt5R@@1HZs',	},
    {	empleado:'177913',	email:'hvillaverdeb@aeromexico.com',	nombre:'VILLAVERDE BECERRIL HUGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'S4YHm9tatM',	},
    {	empleado:'178013',	email:'adiaz@aeromexico.com',	nombre:'DIAZ FALCON ANA LUISA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$DGf!fT6ZN',	},
    {	empleado:'178923',	email:'jriveraj@aeromexico.com',	nombre:'RIVERA JIMENEZ JUAN MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'MbtQweJ$#3',	},
    {	empleado:'179114',	email:'mmartello@aeromexico.com',	nombre:'MARTELLO GALINDO MIGUEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8Dk$v#F5cE',	},
    {	empleado:'179214',	email:'mavelazquez@aeromexico.com',	nombre:'VELAZQUEZ LOPEZ MARCO ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nz1wz6EYkm',	},
    {	empleado:'179314',	email:'mguzmans@aeromexico.com',	nombre:'GUZMAN SANCHEZ MARIA DEL CARMEN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'sY9YnGgBt1',	},
    {	empleado:'179723',	email:'ebasurto@aeromexico.com',	nombre:'BASURTO FLORES ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'w9ZmH#jZ8g',	},
    {	empleado:'180521',	email:'mgortiz@aeromexico.com',	nombre:'ORTIZ VERA MIJAIL GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%5tXULL4AV',	},
    {	empleado:'180621',	email:'jcrabtree@aeromexico.com',	nombre:'CRABTREE AVILA JOSE JAIME',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kb%HFuPMRG',	},
    {	empleado:'180721',	email:'jgutierrezg@aeromexico.com',	nombre:'GUTIERREZ GUTIERREZ JAVIER ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'FidpZ1u5FY',	},
    {	empleado:'180821',	email:'jninoderivera@aeromexico.com',	nombre:'NINO DE RIVERA GUZMAN JOSE CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nnJsGycMA%',	},
    {	empleado:'18091',	email:'mpereyda@aeromexico.com',	nombre:'PEREYDA MARTIN MARCO ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'1&E7t7K5f!',	},
    {	empleado:'180921',	email:'esaavedra@aeromexico.com',	nombre:'SAAVEDRA YANEZ ERICK MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Z6pf3kcV35',	},
    {	empleado:'181021',	email:'nchavez@aeromexico.com',	nombre:'CHAVEZ GARCIA NORA PAOLA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SQvZVD@99Z',	},
    {	empleado:'181121',	email:'iruiz@aeromexico.com',	nombre:'RUIZ GARCIGLIA IVAN ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'u6Az17bjZ4',	},
    {	empleado:'181315',	email:'lsgarcia@aeromexico.com',	nombre:'GARCIA MARTINEZ LESLIE STEPHANIE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'t9V86ik1&k',	},
    {	empleado:'1814',	email:'jsuarezv@aeromexico.com',	nombre:'SUAREZ VALDEZ JOSE DE JESUS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'9A65GEHr5R',	},
    {	empleado:'181821',	email:'disanchez@aeromexico.com',	nombre:'SANCHEZ ZAMACONA DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'LSQ%AjF%tc',	},
    {	empleado:'182521',	email:'rcerda@aeromexico.com',	nombre:'CERDA MENA RAMON ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JEKcuJ5gfU',	},
    {	empleado:'182721',	email:'aovando@aeromexico.com',	nombre:'OVANDO SUAZO ALAN ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yYegT&dZi@',	},
    {	empleado:'183021',	email:'jhernandezs@aeromexico.com',	nombre:'HERNANDEZ SANCHEZ JOSE IGNACIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8Q9%Auj4Jr',	},
    {	empleado:'183121',	email:'garreola@aeromexico.com',	nombre:'ARREOLA RUIZ GUSTAVO ADOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'axEsrZxgFw',	},
    {	empleado:'183415',	email:'asahuaya@aeromexico.com',	nombre:'SAHUAYA ROMAN ANUAR KARIM',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'JPEBBJ6C#r',	},
    {	empleado:'18418',	email:'mclemente@aeromexico.com',	nombre:'CLEMENTE RUIZ MANUEL DE JESUS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'n%8GZYLp78',	},
    {	empleado:'18423',	email:'czeledon@aeromexico.com',	nombre:'ZELEDON PALACIOS CHRISTIAN DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$1KtbKS2pW',	},
    {	empleado:'184314',	email:'medominguez@aeromexico.com',	nombre:'DOMINGUEZ CANO MIGUEL ESTEBAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z53v77RG##',	},
    {	empleado:'184514',	email:'evazquezg@aeromexico.com',	nombre:'VAZQUEZ GONZALEZ ERICK',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'njuiX7VrUX',	},
    {	empleado:'184614',	email:'gyannellit@aeromexico.com',	nombre:'YANNELLI TOCA GABRIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'znLAWStFUe',	},
    {	empleado:'184714',	email:'mesquivel@aeromexico.com',	nombre:'ESQUIVEL BARRIOS MARCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'sKrRVY6N6w',	},
    {	empleado:'184814',	email:'ajara@aeromexico.com',	nombre:'JARA CHAVEZ ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZFze!%3XJq',	},
    {	empleado:'184914',	email:'iolamendi@aeromexico.com',	nombre:'OLAMENDI CUEVAS ISRAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'3Dg5p7iTw9',	},
    {	empleado:'185014',	email:'arrojas@aeromexico.com',	nombre:'ROJAS AGUILAR ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'cm8i9X1qQY',	},
    {	empleado:'185114',	email:'jmurguia@aeromexico.com',	nombre:'MURGUIA ZAMORA JORGE LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'gfC%tsRVdP',	},
    {	empleado:'185214',	email:'eroman@aeromexico.com',	nombre:'ROMAN CASTELAN EDGAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'5yYzMCKSu3',	},
    {	empleado:'185314',	email:'polguin@aeromexico.com',	nombre:'OLGUIN DURAN PABLO ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nZaX&Y$2mY',	},
    {	empleado:'185315',	email:'gcarranza@aeromexico.com',	nombre:'CARRANZA VERDE GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1Z3mt1daJt',	},
    {	empleado:'185414',	email:'ggarcia@aeromexico.com',	nombre:'GARCIA MUNGUIA GUSTAVO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#ae%y$FTG1',	},
    {	empleado:'185514',	email:'sreza@aeromexico.com',	nombre:'REZA ARCELUS SAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kYTTqKhFfk',	},
    {	empleado:'185614',	email:'rgarciat@aeromexico.com',	nombre:'GARCIA TERAN ROSAMARIA IVANOVA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZEH&GdkZKf',	},
    {	empleado:'185714',	email:'srangel@aeromexico.com',	nombre:'RANGEL ORDIALES SANTIAGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'2BJ97smdqf',	},
    {	empleado:'185814',	email:'jcook@aeromexico.com',	nombre:'COOK PINA JOSE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'XFcUSZLd$E',	},
    {	empleado:'185914',	email:'cmendivil@aeromexico.com',	nombre:'MENDIVIL SANCHEZ CARLOS ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'n1rkMnVkJY',	},
    {	empleado:'186014',	email:'lvazquezo@aeromexico.com',	nombre:'VAZQUEZ OROZCO LUCIA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'aYsjRgDAjF',	},
    {	empleado:'189923',	email:'ebarajasca@aeromexico.com',	nombre:'BARAJAS CAMACHO EDGARDO ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'iX3TcNJF1J',	},
    {	empleado:'190913',	email:'cvargasg@aeromexico.com',	nombre:'VARGAS GOMEZ COSSET',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Smuk&N$75p',	},
    {	empleado:'191013',	email:'jlumbreras@aeromexico.com',	nombre:'LUMBRERAS MIJANGOS JORGE JAVIER',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Ws&#xf&Wjb',	},
    {	empleado:'191113',	email:'iarmas@aeromexico.com',	nombre:'ARMAS SALGADO ISRAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'cQqWTg6V9c',	},
    {	empleado:'19114',	email:'jfdiaz@aeromexico.com',	nombre:'DIAZ CASTRO JESUS FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'AyET1FTLcY',	},
    {	empleado:'191213',	email:'rcandiani@aeromexico.com',	nombre:'CANDIANI RIVAS RODOLFO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'hauwZkLxh1',	},
    {	empleado:'1914',	email:'sfmorales@aeromexico.com',	nombre:'MORALES MEDINA SAYURY FERNANDA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'rpHEW%WDnj',	},
    {	empleado:'191413',	email:'ceramirez@aeromexico.com',	nombre:'RAMIREZ SANCHEZ CHRISTIAN ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZXvxd#nQqX',	},
    {	empleado:'191513',	email:'jlopezh@aeromexico.com',	nombre:'LOPEZ HERRERA JOSE CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'LbJfzzLUe5',	},
    {	empleado:'191613',	email:'lmeyer@aeromexico.com',	nombre:'MEYER VALDES LUDWIG',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'fDPpmwiYUB',	},
    {	empleado:'192013',	email:'jgutierrezt@aeromexico.com',	nombre:'GUTIERREZ TORRES JESUS IVAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Sq8y%kgt33',	},
    {	empleado:'192113',	email:'fmunozm@aeromexico.com',	nombre:'MUNOZ MICHEL FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'y7Jd@#dT9W',	},
    {	empleado:'19214',	email:'crmedina@aeromexico.com',	nombre:'MEDINA SOTO CARLOS RICARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'4ZE9ZzjjY%',	},
    {	empleado:'192213',	email:'sdib@aeromexico.com',	nombre:'DIB GOMEZ SANTIAGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'wk48zKMPMa',	},
    {	empleado:'19314',	email:'maanaya@aeromexico.com',	nombre:'ANAYA ROMO MANUEL ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'9yqPiR8@kp',	},
    {	empleado:'19414',	email:'ldehesa@aeromexico.com',	nombre:'DEHESA SEGURA LUIS RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'3@dukbdJYr',	},
    {	empleado:'19499',	email:'mlozanod@aeromexico.com',	nombre:'LOZANO DAMM MAURICIO MOCTEZUMA RAFAEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'i7nsBxVg6s',	},
    {	empleado:'19514',	email:'lquintana@aeromexico.com',	nombre:'QUINTANA VALDOVINOS LEONARDO IGNACIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'PHNgZYKRjA',	},
    {	empleado:'195518',	email:'vmcalderon@aeromexico.com',	nombre:'CALDERON TAPIA VICTOR MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kZE3svvCvY',	},
    {	empleado:'195614',	email:'dornelas@aeromexico.com',	nombre:'ORNELAS JUAREZ DORIAM ISRAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'qR9JU$hSMK',	},
    {	empleado:'195718',	email:'cghernandez@aeromexico.com',	nombre:'HERNANDEZ PEREZ CHRISTIAN GUSTAVO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1nkrm@n$4w',	},
    {	empleado:'195818',	email:'gacarranza@aeromexico.com',	nombre:'CARRANZA REYES GABRIELA ALEJANDRA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1x1aZ!Lvvi',	},
    {	empleado:'19591',	email:'jadiaz@aeromexico.com',	nombre:'DIAZ TENOPALA JOSE ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'AnHj418S1p',	},
    {	empleado:'19599',	email:'eotero@aeromexico.com',	nombre:'OTERO ESCOBAR ERICH JESUS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'4WrZd&hLDM',	},
    {	empleado:'19614',	email:'fortiz@aeromexico.com',	nombre:'ORTIZ RIOS FABRICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'X9fv!tkw68',	},
    {	empleado:'196318',	email:'jpinon@aeromexico.com',	nombre:'PINON TOSKY JHOVANNY ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'p1k&zZ2drp',	},
    {	empleado:'196818',	email:'sromo@aeromexico.com',	nombre:'ROMO ACOSTA SALVADOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Ufe1kEF#jA',	},
    {	empleado:'19691',	email:'fdelachica@aeromexico.com',	nombre:'DE LACHICA CARBALLAR FAUSTO MARTIN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'bZJSGhN9Ek',	},
    {	empleado:'196918',	email:'samiranda@aeromexico.com',	nombre:'MIRANDA LEPEZ SALVADOR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'AKLhAZiXf1',	},
    {	empleado:'197018',	email:'fazpeitia@aeromexico.com',	nombre:'AZPEITIA ALVAREZ FRIDA KARINA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'26FP9453Gh',	},
    {	empleado:'19714',	email:'janavarrete@aeromexico.com',	nombre:'NAVARRETE GUEVARA JORGE ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%KAkYQNipw',	},
    {	empleado:'197218',	email:'edflores@aeromexico.com',	nombre:'FLORES RAMIREZ EDGAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'E@jtNUuPw9',	},
    {	empleado:'19799',	email:'jagonzalezr@aeromexico.com',	nombre:'GONZALEZ ROBLES JUAN ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'g&Wt3kHrVs',	},
    {	empleado:'19814',	email:'mlamelas@aeromexico.com',	nombre:'LAMELAS RUIZ MANUEL ERNESTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'AAZ5xkYGeF',	},
    {	empleado:'198722',	email:'jalgara@aeromexico.com',	nombre:'ALGARA GONZALEZ JORGE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'tmZC%2dR2!',	},
    {	empleado:'198922',	email:'jdelu@aeromexico.com',	nombre:'DE LUNA MALDONADO JONATHAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'hPpyJW%%xk',	},
    {	empleado:'19899',	email:'fnicolau@aeromexico.com',	nombre:'NICOLAU MARTINEZ FRANCISCO ARMANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'kNKp%8LGQs',	},
    {	empleado:'199122',	email:'dmonsivais@aeromexico.com',	nombre:'MONSIVAIS BRITO DIANA STEFANIE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ud5tEayYCL',	},
    {	empleado:'19914',	email:'rcarral@aeromexico.com',	nombre:'CARRAL PEREZ ROBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'fean&wf#nQ',	},
    {	empleado:'199422',	email:'asainz@aeromexico.com',	nombre:'SAINZ ORTEGA ALVARO ALEXIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'f%key#N6Fv',	},
    {	empleado:'199522',	email:'cguerreroa@aeromexico.com',	nombre:'GUERRERO ACOSTA CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#uH6#b7Kt&',	},
    {	empleado:'199622',	email:'adiazor@aeromexico.com',	nombre:'DIAZ ORNELAS ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'nG6zdAbhZa',	},
    {	empleado:'199914',	email:'dmata@aeromexico.com',	nombre:'MATA PEREZ RUL DIEGO ALONSO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ZqsdXRJEyJ',	},
    {	empleado:'200022',	email:'bhernandezc@aeromexico.com',	nombre:'HERNANDEZ CHAVEZ BOGART',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'tN#kiZ3grw',	},
    {	empleado:'20014',	email:'magonzalezm@aeromexico.com',	nombre:'GONZALEZ MARTINEZ MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'7x583GTfDi',	},
    {	empleado:'20099',	email:'jmherrera@aeromexico.com',	nombre:'HERRERA PORTILLO JUAN MARTIN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'8w6FsAMZvg',	},
    {	empleado:'20114',	email:'movando@aeromexico.com',	nombre:'OVANDO ALVAREZ MIGUEL ANGEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'kF53f1JAZs',	},
    {	empleado:'20120',	email:'jomedrano@aeromexico.com',	nombre:'MEDRANO LOPEZ JORGE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'w!TaBSX1uw',	},
    {	empleado:'2014',	email:'ealozano@aeromexico.com',	nombre:'LOZANO AMPOSTA EDUARDO AZAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JRFYi$@nJz',	},
    {	empleado:'201716',	email:'aosaldana@aeromexico.com',	nombre:'SALDANA DEL ANGEL ALI ODEK',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'&e5YhvLyU1',	},
    {	empleado:'201816',	email:'fcacho@aeromexico.com',	nombre:'CACHO SUAREZ PEREDO FEDERICO FRANCISCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1MpVFdEwvJ',	},
    {	empleado:'20199',	email:'rdiazg@aeromexico.com',	nombre:'DIAZ GUERRERO RICARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'aQBJfuk%hM',	},
    {	empleado:'20220',	email:'dlimon@aeromexico.com',	nombre:'LIMON DIAZ DIEGO ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'gsKWBJ6fem',	},
    {	empleado:'20315',	email:'rdelatorre@aeromexico.com',	nombre:'DE LA TORRE GUEVARA RAFAEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'MdzZ4E#uH6',	},
    {	empleado:'20320',	email:'jcaguirre@aeromexico.com',	nombre:'AGUIRRE AGUILAR JUAN CESAR MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'18nZ3j%Cnc',	},
    {	empleado:'20415',	email:'dtorresr@aeromexico.com',	nombre:'TORRES RIGADA DANIELLA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'B2VuLsJWFP',	},
    {	empleado:'20420',	email:'salvarezc@aeromexico.com',	nombre:'ALVAREZ CUE SANTIAGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'cfESsh9z1G',	},
    {	empleado:'20515',	email:'dmuro@aeromexico.com',	nombre:'MURO YANNELLI DIEGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8M$tdm$%ZZ',	},
    {	empleado:'20520',	email:'jofloresc@aeromexico.com',	nombre:'FLORES CONTRERAS JOSE CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'CrjnKBRbSa',	},
    {	empleado:'20615',	email:'gpingarron@aeromexico.com',	nombre:'PINGARRON VELASCO GONZALO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'uTRrGVw@QZ',	},
    {	empleado:'20620',	email:'jmolina@aeromexico.com',	nombre:'MOLINA FERNANDEZ JOSE FELIPE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'jsUJ9Yq3ci',	},
    {	empleado:'206214',	email:'ctadeo@aeromexico.com',	nombre:'TADEO SOLIS CLAUDIA DEL CARMEN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Wjijh&ZjLq',	},
    {	empleado:'206314',	email:'galonso@aeromexico.com',	nombre:'ALONSO ORLANZZINI GREG',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'hG$JKHdznq',	},
    {	empleado:'20715',	email:'rorrostieta@aeromexico.com',	nombre:'ORROSTIETA VERA RAFAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'9RFbTmE&9Y',	},
    {	empleado:'20720',	email:'ceiglesias@aeromexico.com',	nombre:'IGLESIAS PEREZ CESAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YBrJAtJ51P',	},
    {	empleado:'20820',	email:'jnajera@aeromexico.com',	nombre:'NAJERA SANTOS JORGE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'TWRruTKW8@',	},
    {	empleado:'208517',	email:'igutierrezb@aeromexico.com',	nombre:'GUTIERREZ BARQUIN INAKI XAVIER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JhP!NEskY7',	},
    {	empleado:'208617',	email:'maduarte@aeromexico.com',	nombre:'DUARTE TORRES MIGUEL ANGEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'@ZfD1X2k7E',	},
    {	empleado:'208817',	email:'amartin@aeromexico.com',	nombre:'MARTIN LOZANO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'s5gLTHardZ',	},
    {	empleado:'20920',	email:'pcook@aeromexico.com',	nombre:'COOK PINA PAULA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'TNnACe4Lhj',	},
    {	empleado:'215219',	email:'aecruz@aeromexico.com',	nombre:'CRUZ FLANDES ALFONSO EFRAIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'6ZapWfu7LZ',	},
    {	empleado:'215319',	email:'gruizf@aeromexico.com',	nombre:'RUIZ FIOL GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'v55kLpRfZG',	},
    {	empleado:'216018',	email:'afgelove@aeromexico.com',	nombre:'GELOVE HIMMELSTINE ARTURO FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'!1iJxDypQK',	},
    {	empleado:'216218',	email:'igdiaz@aeromexico.com',	nombre:'DIAZ HUERTA IRVYNG GREGORIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'uYjUa1bJ$P',	},
    {	empleado:'216418',	email:'edtorresv@aeromexico.com',	nombre:'TORRES VELASCO EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5ZUdtg%Zth',	},
    {	empleado:'216717',	email:'jrvillarreal@aeromexico.com',	nombre:'VILLARREAL ESCALANTE JOSE RAMIRO',	rango:'OFI',	equipo:'',	contrato:'B',	passConstra:'1J%Nx&Z!hL',	},
    {	empleado:'216818',	email:'jgomez@aeromexico.com',	nombre:'GOMEZ MEXIA JUAN PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'sB16w!eK12',	},
    {	empleado:'217017',	email:'hvalverde@aeromexico.com',	nombre:'VALVERDE GUERECA HUGO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'&iYD1p&JMZ',	},
    {	empleado:'217117',	email:'aanayagu@aeromexico.com',	nombre:'ANAYA GUILLOMEN ADRIAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'thj&25buey',	},
    {	empleado:'217317',	email:'lfloresj@aeromexico.com',	nombre:'FLORES JARDON LUIS ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'4K2nF2Xr#F',	},
    {	empleado:'218103',	email:'larmenta@aeromexico.com',	nombre:'ARMENTA OLVERA LUIGI EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Vti5br%8Y$',	},
    {	empleado:'218203',	email:'regutierrez@aeromexico.com',	nombre:'GUTIERREZ CAMANEZ RAFAEL EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'rCAwrD&#$!',	},
    {	empleado:'218403',	email:'edgarcia@aeromexico.com',	nombre:'GARCIA MARTINEZ EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZLAmcJJJU@',	},
    {	empleado:'218503',	email:'mgaray@aeromexico.com',	nombre:'GARAY CANALES MARCO ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'LpPEzndZ71',	},
    {	empleado:'2199',	email:'mmunozdecote@aeromexico.com',	nombre:'MUNOZ DE COTE ESQUINO MAURICIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'qjmZCnLxuS',	},
    {	empleado:'220819',	email:'cmancera@aeromexico.com',	nombre:'MANCERA ORTIZ CAYETANO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'BZdyqPgg1e',	},
    {	empleado:'223715',	email:'pgarciarojas@aeromexico.com',	nombre:'GARCIA ROJAS OROZCO PABLO ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YY5&VNeZk1',	},
    {	empleado:'229314',	email:'amacosta@aeromexico.com',	nombre:'ACOSTA ROMERO ALDO MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%tJ1jUgGDk',	},
    {	empleado:'229414',	email:'rvelazquezc@aeromexico.com',	nombre:'VELAZQUEZ CID RODRIGO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'!4r#icXYQ1',	},
    {	empleado:'229514',	email:'abecerra@aeromexico.com',	nombre:'BECERRA PASCUAL ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PZ6&PfERn8',	},
    {	empleado:'229614',	email:'azuniga@aeromexico.com',	nombre:'ZUNIGA FLORES ANA ELIZABETH',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'bwMrCRdcFW',	},
    {	empleado:'229714',	email:'jirevilla@aeromexico.com',	nombre:'REVILLA SAAVEDRA JONATHAN ISRAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'GLZwHp4ZJQ',	},
    {	empleado:'2299',	email:'ehuett@aeromexico.com',	nombre:'HUETT LOPEZ EDUARDO XAVIER',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ruaW6JiW7g',	},
    {	empleado:'230616',	email:'aaregnier@aeromexico.com',	nombre:'REGNIER CEBALLOS ALEX AZIM',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Mssc$@#hie',	},
    {	empleado:'230716',	email:'rriosc@aeromexico.com',	nombre:'RIOS CASTILLO RICARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'EDn&J!gWvr',	},
    {	empleado:'230816',	email:'jrodriguezr@aeromexico.com',	nombre:'RODRIGUEZ ROVELO JORGE ALFONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'sq$xtw15@2',	},
    {	empleado:'230916',	email:'averdugo@aeromexico.com',	nombre:'VERDUGO PEDEMONTE ALFONSO EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'7sv9d#7ixb',	},
    {	empleado:'2314',	email:'pcruz@aeromexico.com',	nombre:'CRUZ MARTINEZ PEDRO LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kJzLfU4Y#1',	},
    {	empleado:'234214',	email:'ljmoreno@aeromexico.com',	nombre:'MORENO GARCIA LUIS JOSE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'SmYNLSZ$dN',	},
    {	empleado:'234314',	email:'sguiot@aeromexico.com',	nombre:'GUIOT LINSS SERGIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'UpBJ&ACkY!',	},
    {	empleado:'234414',	email:'jsoots@aeromexico.com',	nombre:'SOOTS RAMOS JORGE DE JESUS JULIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'QikK4Ar%Ds',	},
    {	empleado:'2399',	email:'cfernandezdecastro@aeromexico.com',	nombre:'FERNANDEZ DE CASTRO CARDENAS CARLOS EDUARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'uWgHiCZw@W',	},
    {	empleado:'241421',	email:'aelizondo@aeromexico.com',	nombre:'ELIZONDO FLORES ANDREA PAMELA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1Y#N#CfJk7',	},
    {	empleado:'241521',	email:'damartinez@aeromexico.com',	nombre:'MARTINEZ SALAZAR DAFNE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nJ!wVtj@jp',	},
    {	empleado:'241621',	email:'mdherrera@aeromexico.com',	nombre:'HERRERA CONTRERAS MONICA DAMARA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'NVbFx8T%%a',	},
    {	empleado:'241818',	email:'lcanales@aeromexico.com',	nombre:'CANALES LOBERA LUIS PABLO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ph3xZfw@Un',	},
    {	empleado:'241918',	email:'tvgonzalez@aeromexico.com',	nombre:'GONZALEZ SALAZAR TONATIUH VICENTE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'49uznwww2j',	},
    {	empleado:'242018',	email:'jvelazquezc@aeromexico.com',	nombre:'VELAZQUEZ CANDELARIA JOEL ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'3puqZWLmX&',	},
    {	empleado:'242118',	email:'jdmonroy@aeromexico.com',	nombre:'MONROY FELIX JOSE DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'xcfnYL59tn',	},
    {	empleado:'242217',	email:'hrembis@aeromexico.com',	nombre:'REMBIS AVILA HECTOR ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'B1!G&TCPW9',	},
    {	empleado:'242218',	email:'omartinezsotomayor@aeromexico.com',	nombre:'MARTINEZ SOTOMAYOR VERDUZCO OSCAR ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'S11x1@2EK8',	},
    {	empleado:'242317',	email:'orivas@aeromexico.com',	nombre:'RIVAS FUENTES OMAR OTTONIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'KJv1paamir',	},
    {	empleado:'242417',	email:'lpedraza@aeromexico.com',	nombre:'PEDRAZA VIESCA LEONARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'&j1kn$r&mJ',	},
    {	empleado:'242717',	email:'damendicutti@aeromexico.com',	nombre:'MENDICUTTI GONZALEZ DANIEL ALBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZUWLZ2rJWa',	},
    {	empleado:'243117',	email:'gvenegas@aeromexico.com',	nombre:'VENEGAS VALLES GILBERTO ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'3pe!nN!zBf',	},
    {	empleado:'243604',	email:'lmgarcia@aeromexico.com',	nombre:'GARCIA PERRONI LUIS MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'&kPFevZN#g',	},
    {	empleado:'243719',	email:'mgonzalezs@aeromexico.com',	nombre:'GONZALEZ SANCHEZ MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kpcdW4HviC',	},
    {	empleado:'243904',	email:'vgarcia@aeromexico.com',	nombre:'GARCIA ESQUIVELZETA VICENTE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'q2@8ExVUZT',	},
    {	empleado:'244004',	email:'jfgonzalez@aeromexico.com',	nombre:'GONZALEZ ROBLES JORGE FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'yZxVa1t$Pm',	},
    {	empleado:'244119',	email:'jbarrenechea@aeromexico.com',	nombre:'BARRENECHEA CUADRA JOSE ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'cri6agcPEB',	},
    {	empleado:'244304',	email:'arubi@aeromexico.com',	nombre:'RUBI ARRIOLA ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'6mBm9%jeSJ',	},
    {	empleado:'244404',	email:'rgallardo@aeromexico.com',	nombre:'GALLARDO GONZALEZ RICARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'nyH2vfnwGt',	},
    {	empleado:'244517',	email:'japadillar@aeromexico.com',	nombre:'PADILLA RIVAS JAVIER ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'mn5ZaN2YUE',	},
    {	empleado:'244618',	email:'rbasto@aeromexico.com',	nombre:'BASTO CHAGOYAN ROBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'sU6Zge9SH5',	},
    {	empleado:'244619',	email:'rbautista@aeromexico.com',	nombre:'BAUTISTA RODRIGUEZ ROBERTO ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'67Hjh6B@rZ',	},
    {	empleado:'244717',	email:'vhernandezn@aeromexico.com',	nombre:'HERNANDEZ NEGRETE VICTOR HUGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PH4VeaJyZK',	},
    {	empleado:'244718',	email:'dadelgado@aeromexico.com',	nombre:'DELGADO RIVERO DANIEL ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZYxcPPF@@$',	},
    {	empleado:'244819',	email:'rsalamanca@aeromexico.com',	nombre:'SALAMANCA HERNANDEZ RAFAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$WMfPBYUW4',	},
    {	empleado:'245018',	email:'dahernandezs@aeromexico.com',	nombre:'HERNANDEZ SALAZAR DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'SqsnPS4Kk$',	},
    {	empleado:'245118',	email:'jcentellas@aeromexico.com',	nombre:'CENTELLAS ROSETE JORDI',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'PjbXZDyvJK',	},
    {	empleado:'245119',	email:'edanel@aeromexico.com',	nombre:'DANEL GARCIA EDUARDO JOSE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'11XihNiZ63',	},
    {	empleado:'246221',	email:'cerosas@aeromexico.com',	nombre:'ROSAS CIFUENTES CESAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8!7ZGZC894',	},
    {	empleado:'246421',	email:'luperez@aeromexico.com',	nombre:'PEREZ VILLALON LUIS MIGUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'qgARwVkz8!',	},
    {	empleado:'246521',	email:'eiruiz@aeromexico.com',	nombre:'RUIZ ZAVALA EDGAR ISAAC',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'AsBBtFB!Y2',	},
    {	empleado:'246621',	email:'pbarbosa@aeromexico.com',	nombre:'BARBOSA IBANEZ PATRICIA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'CVS1mUat1r',	},
    {	empleado:'246721',	email:'jalvarado@aeromexico.com',	nombre:'ALVARADO CERVANTES JOSE SEBASTIAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nkFfcKkaaZ',	},
    {	empleado:'246821',	email:'acrespo@aeromexico.com',	nombre:'CRESPO SAN EMETERIO ALVARO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1@1dJ!tHqq',	},
    {	empleado:'246921',	email:'cbourgues@aeromexico.com',	nombre:'BOURGES ESPINOSA CARLOS FRANCISCO EMILIANO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1evTbZ31Nr',	},
    {	empleado:'247019',	email:'ycordova@aeromexico.com',	nombre:'CORDOVA SALAZAR YAIR ORESTES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'irYqAKuSQw',	},
    {	empleado:'247021',	email:'bsarandia@aeromexico.com',	nombre:'ARANDIA RIVERA BERNARDO SAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YPRcxZZ6X4',	},
    {	empleado:'2499',	email:'latrujillo@aeromexico.com',	nombre:'TRUJILLO BUSTAMANTE LUIS ARMANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'jq74k7!m&V',	},
    {	empleado:'258218',	email:'cfranco@aeromexico.com',	nombre:'FRANCO MOCTEZUMA CARLOS DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'vbbqKiiYqb',	},
    {	empleado:'258304',	email:'balvarez@aeromexico.com',	nombre:'ALVAREZ MENDEZ BENJAMIN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'@cg47XwnPU',	},
    {	empleado:'258604',	email:'jmbasurto@aeromexico.com',	nombre:'BASURTO PEREZ JOSE MAURILIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'9JCf2LTHZ9',	},
    {	empleado:'258618',	email:'lislas@aeromexico.com',	nombre:'ISLAS CORONA LUIS FELIPE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'xt2gAA12$F',	},
    {	empleado:'258816',	email:'cpedroza@aeromexico.com',	nombre:'PEDROZA VELAZQUEZ CARLOS DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z2NsZqZT!S',	},
    {	empleado:'258904',	email:'corangel@aeromexico.com',	nombre:'RANGEL FRANCO CARLOS OMAR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'an98nPk@SP',	},
    {	empleado:'258918',	email:'jorodrigueze@aeromexico.com',	nombre:'RODRIGUEZ ESQUIVEL JOSE CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'T!teCRe!B3',	},
    {	empleado:'259016',	email:'cmedel@aeromexico.com',	nombre:'MEDEL GARCIA CARLOS EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'pJPKXJtN&g',	},
    {	empleado:'259116',	email:'ffbravo@aeromexico.com',	nombre:'BRAVO CALZADA FRANCISCO FELIPE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nkLZ&q2vwC',	},
    {	empleado:'259216',	email:'sffigueroa@aeromexico.com',	nombre:'FIGUEROA OLIVOS SERGIO FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'29eZ1r1YHZ',	},
    {	empleado:'259316',	email:'jvelazquezo@aeromexico.com',	nombre:'VELAZQUEZ OROZCO JOSE LUIS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'YT54jfKDJ@',	},
    {	empleado:'259416',	email:'dolvera@aeromexico.com',	nombre:'OLVERA PEREZ DIEGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'gJUqkaTLX1',	},
    {	empleado:'259516',	email:'fogomez@aeromexico.com',	nombre:'GOMEZ TERRONES FERNANDO OCTAVIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'b#ZbhmuJ1G',	},
    {	empleado:'259818',	email:'felopez@aeromexico.com',	nombre:'LOPEZ CASTILLO FERNANDO JAVIER SILVESTRE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z&&pJpsn!c',	},
    {	empleado:'2599',	email:'mdeysasi@aeromexico.com',	nombre:'DE YSASI GUTIERREZ MARIA DEL PILAR',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'xqaLKfk8e4',	},
    {	empleado:'264716',	email:'jlsilva@aeromexico.com',	nombre:'SILVA CORONEL JOSE LUIS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xEH7k1#Rt%',	},
    {	empleado:'264816',	email:'mquiroz@aeromexico.com',	nombre:'QUIROZ HERNANDEZ MANUEL ANGEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'dKF8GQnqLp',	},
    {	empleado:'264916',	email:'faranda@aeromexico.com',	nombre:'ARANDA ESTRADA FABIAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'s@QXwGkWJJ',	},
    {	empleado:'266704',	email:'jmadrid@aeromexico.com',	nombre:'MADRID DIAZ JESUS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZgCXzmnz$Q',	},
    {	empleado:'266804',	email:'wlopez@aeromexico.com',	nombre:'LOPEZ ORIVE WILLIAM DEL JESUS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'hs5DxUs31i',	},
    {	empleado:'267904',	email:'ralva@aeromexico.com',	nombre:'ALVA FLORES ROBERTO JUAN',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'SzykwWEFFM',	},
    {	empleado:'269004',	email:'avara@aeromexico.com',	nombre:'VARA PALACIO ALEJANDRO JAVIER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'7wqWbZJVxd',	},
    {	empleado:'269104',	email:'gvazquezc@aeromexico.com',	nombre:'VAZQUEZ CHAIDEZ GONZALO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'HTmkskU5$X',	},
    {	empleado:'2714',	email:'agmartinez@aeromexico.com',	nombre:'MARTINEZ CARMONA ANGEL GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'RUz#W@a&UJ',	},
    {	empleado:'272719',	email:'rmaceda@aeromexico.com',	nombre:'MACEDA SANCHEZ RODRIGO MARTIN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#RR3&n%R8q',	},
    {	empleado:'272819',	email:'mlomeli@aeromexico.com',	nombre:'LOMELI DIAZ MARCO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'46YHJNv!Wz',	},
    {	empleado:'273019',	email:'lromeropa@aeromexico.com',	nombre:'ROMERO PAULLADA LUIS FELIPE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ErjwTJxZ91',	},
    {	empleado:'273116',	email:'rbringas@aeromexico.com',	nombre:'BRINGAS MEDINA RODRIGO ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'CBZ6sMTYw3',	},
    {	empleado:'273119',	email:'smartinezro@aeromexico.com',	nombre:'MARTINEZ ROMERO SEBASTIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'WQKMZHKMD!',	},
    {	empleado:'273316',	email:'hperez@aeromexico.com',	nombre:'PEREZ ARREGUIN HECTOR MANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'HwsLF2Tbzr',	},
    {	empleado:'273419',	email:'cgarcian@aeromexico.com',	nombre:'GARCIA NAVARRO CHRISTIAN FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'i@jYrFZ1WF',	},
    {	empleado:'273516',	email:'mapulido@aeromexico.com',	nombre:'PULIDO GONZALEZ MARCO ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8cCAsiV!73',	},
    {	empleado:'273519',	email:'dportela@aeromexico.com',	nombre:'PORTELA ESPINOSA DE LOS MONTERO DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ed5K8n9Nn!',	},
    {	empleado:'273619',	email:'gdela@aeromexico.com',	nombre:'DE LA MORA ROVELO GUSTAVO SALVADOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kQ1vnC&cvS',	},
    {	empleado:'273717',	email:'jugarciam@aeromexico.com',	nombre:'GARCIA MARTINEZ JUAN GILBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kMZwTkuepU',	},
    {	empleado:'273817',	email:'gvillanueva@aeromexico.com',	nombre:'VILLANUEVA MORALES GUILLERMO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'CZ2y@PC$3J',	},
    {	empleado:'274723',	email:'gmmedina@aeromexico.com',	nombre:'MEDINA MARTINEZ GUADALUPE MARISOL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'a2N%V3TwDh',	},
    {	empleado:'276819',	email:'earriolaz@aeromexico.com',	nombre:'ARRIOLA ZUNIGA ENRIQUE ALFREDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'nGJT2YbQy2',	},
    {	empleado:'276919',	email:'marteagar@aeromexico.com',	nombre:'ARTEAGA RODRIGUEZ MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'KT&Mn74F#v',	},
    {	empleado:'278719',	email:'jsgonzalez@aeromexico.com',	nombre:'GONZALEZ PENA JOSE SALVADOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'9cG$rdd$Dy',	},
    {	empleado:'278819',	email:'jdebusk@aeromexico.com',	nombre:'DE BUSK MURGUIA JOSE RAMON',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'!JqtZ&5t7S',	},
    {	empleado:'279317',	email:'daaceves@aeromexico.com',	nombre:'ACEVES MONTERRUBIO DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'9Sfcv3PKQS',	},
    {	empleado:'279319',	email:'aperezpaulin@aeromexico.com',	nombre:'PEREZ PAULIN GERALDO ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'JZgEZKzY$@',	},
    {	empleado:'279417',	email:'lacarrillo@aeromexico.com',	nombre:'CARRILLO MARTINEZ LUIS ANGEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'v$%@B%BriU',	},
    {	empleado:'279419',	email:'hcervantes@aeromexico.com',	nombre:'CERVANTES MORALES HORACIO ROBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'8m$&k7ZUZ6',	},
    {	empleado:'279517',	email:'jcuevasc@aeromexico.com',	nombre:'CUEVAS CAMACHO JORGE ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'sypHdzGDeG',	},
    {	empleado:'279519',	email:'glopezn@aeromexico.com',	nombre:'LOPEZ NAVARRO GABRIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'mGDU1y5A&8',	},
    {	empleado:'279617',	email:'jfloreso@aeromexico.com',	nombre:'FLORES OLIVARES JACOB',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'yk4atxKbNM',	},
    {	empleado:'279717',	email:'cdgomezc@aeromexico.com',	nombre:'GOMEZ CUELLAR CARLOS DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'WRtGm1%ZJ6',	},
    {	empleado:'279817',	email:'camonreal@aeromexico.com',	nombre:'MONREAL SANCHEZ CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'wXZkZ@J3Ha',	},
    {	empleado:'279917',	email:'eorta@aeromexico.com',	nombre:'ORTA VARELA ESTEBAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YV4%RRP5k9',	},
    {	empleado:'28015',	email:'gdelafuente@aeromexico.com',	nombre:'DE LA FUENTE CELIS GABRIELA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%1kFBekZh3',	},
    {	empleado:'28115',	email:'ivaldez@aeromexico.com',	nombre:'VALDEZ CASILLAS ISMAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1AJQZkZAfN',	},
    {	empleado:'2814',	email:'marojo@aeromexico.com',	nombre:'ROJO AVENDANO MOISES ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'@v$Qa61s$x',	},
    {	empleado:'28215',	email:'mriveraj@aeromexico.com',	nombre:'RIVERA JIMENEZ MARIANA ARCELIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZYcnFYQJMs',	},
    {	empleado:'282224',	email:'oaflores@aeromexico.com',	nombre:'FLORES CARRILLO OSCAR AYAX',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kAY&wY$5X2',	},
    {	empleado:'28315',	email:'jcreyna@aeromexico.com',	nombre:'REYNA CARRILLO JUAN CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'7CAikN77$w',	},
    {	empleado:'283919',	email:'vaguilarv@aeromexico.com',	nombre:'AGUILAR VELAZQUEZ VINICIO ISAIAS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ky&1kFkL#7',	},
    {	empleado:'28415',	email:'oalvarez@aeromexico.com',	nombre:'ALVAREZ ANCIRA OCTAVIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'zTr#8zWnc#',	},
    {	empleado:'28515',	email:'vruesga@aeromexico.com',	nombre:'RUESGA SAENZ VICTOR MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'N%HYk@He8X',	},
    {	empleado:'285319',	email:'jtorresa@aeromexico.com',	nombre:'TORRES ALVA JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'py%BKTqyRM',	},
    {	empleado:'285519',	email:'jjara@aeromexico.com',	nombre:'JARA ALVARADO JOSE ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'sHLSYiZA3e',	},
    {	empleado:'285819',	email:'maparicio@aeromexico.com',	nombre:'APARICIO CORONA MARIO',	rango:'OFI',	equipo:'',	contrato:'B',	passConstra:'ZCZkQ2H3e1',	},
    {	empleado:'285924',	email:'cramirezca@aeromexico.com',	nombre:'RAMIREZ CANO CARLOS ARMANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cy#!6ypupJ',	},
    {	empleado:'286024',	email:'mcervantesji@aeromexico.com',	nombre:'CERVANTES JIMENEZ MANUEL ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8bUATSC4EG',	},
    {	empleado:'286124',	email:'rtrejope@aeromexico.com',	nombre:'TREJO PEREZ RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'qsY9Y$gQJ#',	},
    {	empleado:'28615',	email:'lmorales@aeromexico.com',	nombre:'MORALES BADIOLA LEONARDO FRANCISCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nWW3QBNZJU',	},
    {	empleado:'28622',	email:'dcarrillos@aeromexico.com',	nombre:'CARRILLO SILVA DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'HySvmB4D%&',	},
    {	empleado:'286319',	email:'jfuentesb@aeromexico.com',	nombre:'FUENTES BRICENO JUAN RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'C$HWZ3zQJt',	},
    {	empleado:'286619',	email:'bruizg@aeromexico.com',	nombre:'RUIZ GONZALEZ BRYAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6Ca7deXk11',	},
    {	empleado:'286819',	email:'aaldana@aeromexico.com',	nombre:'ALDANA FLORES ARTURO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'w9$Nkk66fV',	},
    {	empleado:'28715',	email:'ralmaraz@aeromexico.com',	nombre:'ALMARAZ ROMERO RUBEN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1RJRRD$GyY',	},
    {	empleado:'287519',	email:'mlozanop@aeromexico.com',	nombre:'LOZANO PADILLA MARCO ANTONIO DAVID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Bi52W1i4sA',	},
    {	empleado:'287719',	email:'mvazquezv@aeromexico.com',	nombre:'VAZQUEZ VAZQUEZ MARIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6g5B!$b9eb',	},
    {	empleado:'287924',	email:'rmartinezma@aeromexico.com',	nombre:'MARTINEZ MADARIAGA RAMSES IVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'4XANJwBZ5n',	},
    {	empleado:'288019',	email:'jsalinasa@aeromexico.com',	nombre:'SALINAS AGUIRRE JUAN CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'3k61ZWrULE',	},
    {	empleado:'28815',	email:'fmcastro@aeromexico.com',	nombre:'CASTRO VEGA FELIPE MARIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'sYJLaYpSd#',	},
    {	empleado:'288219',	email:'dmendez@aeromexico.com',	nombre:'MENDEZ LOPEZ DANIELA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6z1zaAb!fQ',	},
    {	empleado:'28822',	email:'jolveral@aeromexico.com',	nombre:'OLVERA LEYVA JOSE ABRAHAM',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'gH@LTADN8N',	},
    {	empleado:'288519',	email:'jfernandezm@aeromexico.com',	nombre:'FERNANDEZ MARTINEZ JOSE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'FBVBWK91jJ',	},
    {	empleado:'288819',	email:'erodriguezf@aeromexico.com',	nombre:'RODRIGUEZ FERNANDEZ ESTEBAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yN1bMbytk@',	},
    {	empleado:'288919',	email:'mramirezr@aeromexico.com',	nombre:'RAMIREZ RUIZ MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cSdDPA!wJ6',	},
    {	empleado:'28915',	email:'dlopez@aeromexico.com',	nombre:'LOPEZ AHUACTZIN DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'TnAZV4%xjQ',	},
    {	empleado:'28922',	email:'sde@aeromexico.com',	nombre:'DE HAAS MARTINEZ SOFIA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dfbmLVBTje',	},
    {	empleado:'289319',	email:'elaris@aeromexico.com',	nombre:'LARIS FUENTES EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'g8ghzk#kH7',	},
    {	empleado:'289619',	email:'rquevedo@aeromexico.com',	nombre:'QUEVEDO GONZALEZ RODRIGO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'AbCghmYn8k',	},
    {	empleado:'289719',	email:'achavezr@aeromexico.com',	nombre:'CHAVEZ RAMIREZ ARELHY',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&Veqe#ErKN',	},
    {	empleado:'289819',	email:'jmorenoc@aeromexico.com',	nombre:'MORENO CASILLAS JORGE ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!BtWkFbMjn',	},
    {	empleado:'2899',	email:'jangulo@aeromexico.com',	nombre:'ANGULO ALCANTAR JUAN MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'YPzfsf&ZQG',	},
    {	empleado:'289919',	email:'ldeg@aeromexico.com',	nombre:'DE GYVES BUENO LUIS ADOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'rCQFKVMrLk',	},
    {	empleado:'289924',	email:'pcastelazo@aeromexico.com',	nombre:'CASTELAZO RODRIGUEZ PAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Xn4LkdDnL7',	},
    {	empleado:'290019',	email:'egarciabu@aeromexico.com',	nombre:'GARCIA BUCIO ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Hk6@KWWSMv',	},
    {	empleado:'290024',	email:'amerlo@aeromexico.com',	nombre:'MERLO GURIDI ANDREA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'h91PWnGJaq',	},
    {	empleado:'290118',	email:'hxmendoza@aeromexico.com',	nombre:'MENDOZA MEDINA HECTOR XAVIER',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$hMf7kW#k7',	},
    {	empleado:'290124',	email:'gdelgadop@aeromexico.com',	nombre:'DELGADO PEREZ GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Zy@pAM%XwG',	},
    {	empleado:'290218',	email:'sescarcia@aeromexico.com',	nombre:'ESCARCIA ORTEGA SERGIO ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8HL2zRNkpk',	},
    {	empleado:'29022',	email:'rdelgado@aeromexico.com',	nombre:'DELGADO AGUINAGA RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'QW5ngudC!&',	},
    {	empleado:'290224',	email:'rortizga@aeromexico.com',	nombre:'ORTIZ GARCIA REYES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ba$v4SxTrM',	},
    {	empleado:'290318',	email:'lesnarriaga@aeromexico.com',	nombre:'ESNARRIAGA VILLASENOR LUIS EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'LYRc@ZP!KK',	},
    {	empleado:'290324',	email:'dcanteroc@aeromexico.com',	nombre:'CANTERO CASTILLO DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'XMDC7p2Mnp',	},
    {	empleado:'290424',	email:'dhernandezhe@aeromexico.com',	nombre:'HERNANDEZ HERRERA DANIEL ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#4mw2atc%h',	},
    {	empleado:'290524',	email:'mperezmar@aeromexico.com',	nombre:'PEREZ MARTINEZ SOTOMAYOR MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'eYnWaJ8Zvf',	},
    {	empleado:'290618',	email:'maalva@aeromexico.com',	nombre:'ALVA MOLINA MARIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YFZr4GZkqZ',	},
    {	empleado:'290619',	email:'mdelc@aeromexico.com',	nombre:'DEL CASTILLO ULLOA MIGUEL ARMANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kcS27uMLsc',	},
    {	empleado:'290624',	email:'egamboah@aeromexico.com',	nombre:'GAMBOA HERNANDEZ EDGAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'N5rrgE2cd%',	},
    {	empleado:'290718',	email:'jmuro@aeromexico.com',	nombre:'MURO QUINN JOSE CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'wMjkDHgyUG',	},
    {	empleado:'291119',	email:'eherrerara@aeromexico.com',	nombre:'HERRERA RAVELO EMMANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@TLV7AVn11',	},
    {	empleado:'2914',	email:'gvergara@aeromexico.com',	nombre:'VERGARA RODRIGUEZ GERARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'x4Y&gaCJde',	},
    {	empleado:'291924',	email:'mcarrilloe@aeromexico.com',	nombre:'CARRILLO ESLIMAN MIGUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'14tnaq7gJh',	},
    {	empleado:'29222',	email:'sgsosa@aeromexico.com',	nombre:'SOSA PEREZ SALVADOR GERARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'y%PkPVDuM6',	},
    {	empleado:'292616',	email:'jmbreton@aeromexico.com',	nombre:'BRETON DE LA O JORGE MISAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'hPrGq9DgG$',	},
    {	empleado:'293924',	email:'asalomonb@aeromexico.com',	nombre:'SALOMON BURGUENO ALAIN ESTEBAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Bsq&K2nPJZ',	},
    {	empleado:'299223',	email:'emhernandez@aeromexico.com',	nombre:'HERNANDEZ GONZALEZ EMILIANO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%1kRYUJs81',	},
    {	empleado:'300118',	email:'epazos@aeromexico.com',	nombre:'PAZOS FERNANDEZ EDUARDO RAFAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'A&mH5Uznnj',	},
    {	empleado:'300218',	email:'amassimi@aeromexico.com',	nombre:'MASSIMI CERVANTES ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'BHJytqYZFE',	},
    {	empleado:'301523',	email:'jgesquivel@aeromexico.com',	nombre:'ESQUIVEL ARELLANO JOSE GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'GSbG9J5!ev',	},
    {	empleado:'30303',	email:'eduhart@aeromexico.com',	nombre:'DUHART IRABIEN ERNESTO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Bt97SPrC9v',	},
    {	empleado:'30389',	email:'jmujica@aeromexico.com',	nombre:'MUJICA MAULHARDT JUAN CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'AGzYKnHA&j',	},
    {	empleado:'305505',	email:'jmartinezm@aeromexico.com',	nombre:'MARTINEZ MANCILLA JESUS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'qzgvEtSrvZ',	},
    {	empleado:'305705',	email:'jgutierrezh@aeromexico.com',	nombre:'GUTIERREZ HERRERA JORGE ISMAEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'eYgrd3rDTR',	},
    {	empleado:'305805',	email:'rvital@aeromexico.com',	nombre:'VITAL AYALA ROMARICO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'g#mkCP@jSC',	},
    {	empleado:'306005',	email:'gchavarria@aeromexico.com',	nombre:'CHAVARRIA REVOLLAR GERARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'!AH!4S3EkP',	},
    {	empleado:'306105',	email:'jdelafuente@aeromexico.com',	nombre:'DE LA FUENTE TRISTAN JOSE EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'5rR6Nuh9E7',	},
    {	empleado:'306205',	email:'vcan@aeromexico.com',	nombre:'CAN ESCANDON VICTOR HUGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Qi3hM1z1Li',	},
    {	empleado:'306305',	email:'nromero@aeromexico.com',	nombre:'ROMERO QUIROZ NICOLAS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Ekt8n$rQw%',	},
    {	empleado:'306405',	email:'abandin@aeromexico.com',	nombre:'BANDIN OSORIO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'8@eAGS47rn',	},
    {	empleado:'306505',	email:'jortiza@aeromexico.com',	nombre:'ORTIZ ALVAREZ JESUS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZK#QytJcG6',	},
    {	empleado:'308122',	email:'macostav@aeromexico.com',	nombre:'ACOSTA VAZQUEZ MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'11nAG7SZZm',	},
    {	empleado:'308322',	email:'jmanceraa@aeromexico.com',	nombre:'MANCERA ARES JESUS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&iEy2tgJnM',	},
    {	empleado:'308522',	email:'gnavarron@aeromexico.com',	nombre:'NAVARRO NAVARRO GERMAN SALVADOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'LZ1p9&Y%TZ',	},
    {	empleado:'309022',	email:'cvirgen@aeromexico.com',	nombre:'VIRGEN WALKER CARLOS ALFONSO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'TZBaHxG2Se',	},
    {	empleado:'309122',	email:'hcenteno@aeromexico.com',	nombre:'CENTENO GUTIERREZ HEGOEL IGNACIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yaPb1nTM1C',	},
    {	empleado:'30922',	email:'llunah@aeromexico.com',	nombre:'LUNA HUERTA LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'z1uZjqFXLm',	},
    {	empleado:'309222',	email:'jguadarrama@aeromexico.com',	nombre:'GUADARRAMA MENDOZA JESUS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZJfJvzztVP',	},
    {	empleado:'309319',	email:'rechenique@aeromexico.com',	nombre:'ECHENIQUE SANCHEZ RODRIGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xa$LxsmASZ',	},
    {	empleado:'309422',	email:'lsalmeron@aeromexico.com',	nombre:'SALMERON VALEZZI LIBERTAD',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Xag%4E&wpt',	},
    {	empleado:'309519',	email:'mfuentesr@aeromexico.com',	nombre:'FUENTES RIVAS MARCO ANTONIO JESUS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'rk$&i9JZ7t',	},
    {	empleado:'309522',	email:'gzamacona@aeromexico.com',	nombre:'ZAMACONA LICONA GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'aKgBuTumrX',	},
    {	empleado:'309619',	email:'rechegoyen@aeromexico.com',	nombre:'ECHEGOYEN MONROY RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YqNNmtVXdf',	},
    {	empleado:'309622',	email:'eandradea@aeromexico.com',	nombre:'ANDRADE ALVAREZ EMMANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%SMt11Uz2r',	},
    {	empleado:'309722',	email:'dvaldez@aeromexico.com',	nombre:'VALDEZ VALENZUELA DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZydDHZKrc7',	},
    {	empleado:'309819',	email:'ffmondragon@aeromexico.com',	nombre:'MONDRAGON RODRIGUEZ FEDERICO FERNANDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'HMAJ#RZ13W',	},
    {	empleado:'309822',	email:'lpulido@aeromexico.com',	nombre:'PULIDO SANCHEZ LUIS FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fsV6AnWacX',	},
    {	empleado:'309922',	email:'esantander@aeromexico.com',	nombre:'SANTANDER VERA EDGARD',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'E1JnfYF#WM',	},
    {	empleado:'310022',	email:'mrio@aeromexico.com',	nombre:'RIO DE LA LOZA IZQUIERDO MANUEL ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kg!B!x$A55',	},
    {	empleado:'3114',	email:'amaciasc@aeromexico.com',	nombre:'MACIAS CASTRO ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'12QD5KfbYj',	},
    {	empleado:'316316',	email:'jgonzalezt@aeromexico.com',	nombre:'GONZALEZ TELLO JUAN MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kNf9cnuJ2n',	},
    {	empleado:'316416',	email:'jphernandez@aeromexico.com',	nombre:'HERNANDEZ PINEDA JOHANN PHILIPP',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZK!G&#vMZ1',	},
    {	empleado:'316516',	email:'msaldanar@aeromexico.com',	nombre:'SALDANA REYES MAXIMILIANO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'bzfPMCYJbk',	},
    {	empleado:'320017',	email:'abailleresv@aeromexico.com',	nombre:'BAILLERES VERDUZCO ARELY',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'m7Vq%f#dzJ',	},
    {	empleado:'320117',	email:'raraujo@aeromexico.com',	nombre:'ARAUJO Y ARAUJO RAFAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'uPRhE5Z#tK',	},
    {	empleado:'320317',	email:'vramirez@aeromexico.com',	nombre:'RAMIREZ ESTRADA VICTOR HUGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#y!k5GV1ZT',	},
    {	empleado:'3214',	email:'gaguilarr@aeromexico.com',	nombre:'AGUILAR RAMIREZ GIORDY',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'&ijEfMw!mu',	},
    {	empleado:'32291',	email:'mherreraj@aeromexico.com',	nombre:'HERRERA JIMENEZ MARIO LUIS MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'7Q@V%zLVzt',	},
    {	empleado:'32391',	email:'agoni@aeromexico.com',	nombre:'GONI CABELLO ANGEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'GZaatKC$Z9',	},
    {	empleado:'324017',	email:'rgaliciah@aeromexico.com',	nombre:'GALICIA HERNANDEZ ROSA ALICIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'BMaf%sm2nG',	},
    {	empleado:'324217',	email:'jcruz@aeromexico.com',	nombre:'CRUZ FLORES JOSE EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'s9LB94mvLk',	},
    {	empleado:'324317',	email:'lfarias@aeromexico.com',	nombre:'FARIAS RIVA LUIS ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'s$iNfuVJgJ',	},
    {	empleado:'324417',	email:'dbalcazar@aeromexico.com',	nombre:'BALCAZAR ALVAREZ DAVID RICARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'94W@vDJMQ$',	},
    {	empleado:'324517',	email:'gnavarrom@aeromexico.com',	nombre:'NAVARRO MIRANDA GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9&bLvB2n8&',	},
    {	empleado:'326405',	email:'ccastrol@aeromexico.com',	nombre:'CASTRO LLAMAS CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ixxvPS@q6G',	},
    {	empleado:'326505',	email:'rzubieta@aeromexico.com',	nombre:'ZUBIETA FLORES ROBERTO MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ibskWUh5kZ',	},
    {	empleado:'326605',	email:'rbolio@aeromexico.com',	nombre:'BOLIO CUEVAS RAFAEL PAULO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'4FWZs4sf5G',	},
    {	empleado:'326705',	email:'glannoy@aeromexico.com',	nombre:'LANNOY BISOGNO GERMAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'f3C7#nfJGW',	},
    {	empleado:'326805',	email:'jmarin@aeromexico.com',	nombre:'MARIN MIRELES JOSE LUIS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'NGNiwhNyZ2',	},
    {	empleado:'326905',	email:'jlposada@aeromexico.com',	nombre:'POSADA MORA JOSE LUIS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'kRYiSjgZej',	},
    {	empleado:'32691',	email:'jcgonzalez@aeromexico.com',	nombre:'GONZALEZ CURZIO JUAN CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'WkAc1xx%C$',	},
    {	empleado:'327005',	email:'fmontesdeoca@aeromexico.com',	nombre:'MONTES DE OCA RAMIREZ FABRIZZIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'7%3Qg1gYhc',	},
    {	empleado:'327105',	email:'gduran@aeromexico.com',	nombre:'DURAN SANCHEZ GONZALO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'nGLyeUwp&Q',	},
    {	empleado:'327705',	email:'mpayala@aeromexico.com',	nombre:'AYALA OCHOA MARCO POLO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'7NZ&Y4h$WU',	},
    {	empleado:'327722',	email:'eortegaas@aeromexico.com',	nombre:'ORTEGA ASIAIN ERICK ULISES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YTeZ$JJYDZ',	},
    {	empleado:'327822',	email:'calmeidag@aeromexico.com',	nombre:'ALMEIDA GONZALEZ CARLOS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jCUZCYM$Wp',	},
    {	empleado:'327922',	email:'rrodriguezb@aeromexico.com',	nombre:'RODRIGUEZ BADILLO RODOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ykD39Kbfmz',	},
    {	empleado:'328022',	email:'sbarranco@aeromexico.com',	nombre:'BARRANCO MARQUEZ SUZZETTE NASHIRA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#u2AmX!Jdf',	},
    {	empleado:'328122',	email:'hquiroga@aeromexico.com',	nombre:'QUIROGA MATA HORACIO ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'DMy&wn7h2@',	},
    {	empleado:'32891',	email:'alavalle@aeromexico.com',	nombre:'LAVALLE VALDES ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'CGpdgmWyxN',	},
    {	empleado:'329405',	email:'lgarmenta@aeromexico.com',	nombre:'ARMENTA GUERRA LUIS GERARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'dfDZZ4v9vZ',	},
    {	empleado:'329605',	email:'oaguilar@aeromexico.com',	nombre:'AGUILAR AVELINO OCTAVIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'25#@kkVnEZ',	},
    {	empleado:'329705',	email:'vherrera@aeromexico.com',	nombre:'HERRERA DIAZ VICTOR HUGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Mf1!7Wpuv$',	},
    {	empleado:'32991',	email:'lmsanchez@aeromexico.com',	nombre:'SANCHEZ VILLALOBOS LUIS MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Y6!M&6Q4mn',	},
    {	empleado:'3314',	email:'jocampo@aeromexico.com',	nombre:'OCAMPO NORIEGA JOAQUIN OMAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'3s@Y1e!NtZ',	},
    {	empleado:'33191',	email:'rjimenezg@aeromexico.com',	nombre:'JIMENEZ GARCES ROLANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'!JyghBxQ3Z',	},
    {	empleado:'333333',	email:'vgarciai@aeromexico.com',	nombre:'Garcia illa Vicente',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'@5CA4914zn',	},
    {	empleado:'334222',	email:'rhidalgol@aeromexico.com',	nombre:'HIDALGO LOPEZ RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'W4mrYF8hwQ',	},
    {	empleado:'33489',	email:'btarazona@aeromexico.com',	nombre:'TARAZONA RODRIGUEZ BLAS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'26wBsZ3&Ph',	},
    {	empleado:'335122',	email:'rarandaz@aeromexico.com',	nombre:'ARANDA ZUNZUNEGUI RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'nZ9Kvq5u6Y',	},
    {	empleado:'336018',	email:'erherrera@aeromexico.com',	nombre:'HERRERA KUPTSOV EDUARDO RAUL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'G3LD!N5#DD',	},
    {	empleado:'336318',	email:'galgara@aeromexico.com',	nombre:'ALGARA ISLAS GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'@P2aFTrKV1',	},
    {	empleado:'336518',	email:'rortegar@aeromexico.com',	nombre:'ORTEGA ROBLES RAMON',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'5c6VsWx1cv',	},
    {	empleado:'336718',	email:'jmchavez@aeromexico.com',	nombre:'CHAVEZ TRUJILLO JUAN MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nzkcNHG#Yy',	},
    {	empleado:'336818',	email:'emedinav@aeromexico.com',	nombre:'MEDINA VILLARREAL ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'VJTid&AcDH',	},
    {	empleado:'34319',	email:'agoldstone@aeromexico.com',	nombre:'GOLDSTONE URQUIZA ANDRES',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'G7PkCPD6HJ',	},
    {	empleado:'34519',	email:'mgalvarez@aeromexico.com',	nombre:'ALVAREZ MORENO MARIO GERMAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'&&kWf3g1Yu',	},
    {	empleado:'34689',	email:'jcuervo@aeromexico.com',	nombre:'CUERVO CORDERO JOSE LUIS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'LsZ2wrT2Bn',	},
    {	empleado:'34719',	email:'edeluna@aeromexico.com',	nombre:'DE LUNA CHIO ERICK ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'jyZW6%R!R7',	},
    {	empleado:'35019',	email:'elujan@aeromexico.com',	nombre:'LUJAN ALDAZ EDUARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'nvHKvq6WaP',	},
    {	empleado:'3514',	email:'bestrella@aeromexico.com',	nombre:'ESTRELLA EVIA BERNARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'!w22dUzJ#7',	},
    {	empleado:'351823',	email:'lalvarezo@aeromexico.com',	nombre:'ALVAREZ ORTIZ LORENZO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'TrdFDDYkZe',	},
    {	empleado:'351923',	email:'eahedo@aeromexico.com',	nombre:'AHEDO FLORES ELIAS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'nYnu6%zQvZ',	},
    {	empleado:'352023',	email:'fsabag@aeromexico.com',	nombre:'SABAG GONZALEZ FAIRUZ',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'hcMSk#WVyV',	},
    {	empleado:'352123',	email:'fvasco@aeromexico.com',	nombre:'VASCO JULIAN FADUA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'d4dUY13YVP',	},
    {	empleado:'352323',	email:'vvargasa@aeromexico.com',	nombre:'VARGAS ALVAREZ VICTOR JOVANY',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JqRr$w#WsL',	},
    {	empleado:'352623',	email:'dquintanilla@aeromexico.com',	nombre:'QUINTANILLA CASTILLO DIANA CAROLINA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'gA1y&W&j3j',	},
    {	empleado:'353023',	email:'jleandro@aeromexico.com',	nombre:'LEANDRO KOLOVOS JUAN PABLO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5zuwKfRdS8',	},
    {	empleado:'353122',	email:'jariasra@aeromexico.com',	nombre:'ARIAS RAMIREZ JOSE JESUS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'2mewmkREsn',	},
    {	empleado:'353123',	email:'iroalandini@aeromexico.com',	nombre:'ROALANDINI VALERO IKER ANGELO GIOVANI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Sg#MkSDXsM',	},
    {	empleado:'353422',	email:'amorah@aeromexico.com',	nombre:'MORA HERNANDEZ ALBERTO GUADALUPE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'QZCcgbVZDm',	},
    {	empleado:'353423',	email:'jmorenof@aeromexico.com',	nombre:'MORENO FRANCO JOSE CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fcYYH8VkR3',	},
    {	empleado:'353523',	email:'vtorresp@aeromexico.com',	nombre:'TORRES PICAZO VICTOR MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'g4VZpZfc1M',	},
    {	empleado:'353623',	email:'rfuentesa@aeromexico.com',	nombre:'FUENTES ALVARADO RICARDO ALONSO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'xuyNXxZ!WP',	},
    {	empleado:'353722',	email:'hbustos@aeromexico.com',	nombre:'BUSTOS RIVAS HECTOR DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'h@9EXrixvL',	},
    {	empleado:'353723',	email:'onietom@aeromexico.com',	nombre:'NIETO MORA OSCAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@J9snW67Jq',	},
    {	empleado:'353823',	email:'gpatino@aeromexico.com',	nombre:'PATINO ARRATIA GUSTAVO ADOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'qg9Mdkn2d$',	},
    {	empleado:'353922',	email:'jcamachome@aeromexico.com',	nombre:'CAMACHO MEJIA JUAN CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5YEmZzJCL9',	},
    {	empleado:'354022',	email:'dcarabez@aeromexico.com',	nombre:'CARABEZ GALVAN DANIEL IVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Cm#KYNAa5T',	},
    {	empleado:'354123',	email:'lpechir@aeromexico.com',	nombre:'PECHIR ROBLES LUIS DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'4YZQrnJ@xt',	},
    {	empleado:'35419',	email:'wurquidi@aeromexico.com',	nombre:'URQUIDI CUELLAR WILFREDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'vY$SFf1rRD',	},
    {	empleado:'354323',	email:'avaldovinosc@aeromexico.com',	nombre:'VALDOVINOS CABRERA ANDREA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'aqqs$uQ3ZD',	},
    {	empleado:'35491',	email:'gdiazbarriga@aeromexico.com',	nombre:'DIAZ BARRIGA MARTINEZ GERMAN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'2HZCmX3Y8v',	},
    {	empleado:'355323',	email:'ssan@aeromexico.com',	nombre:'SAN JUAN OROZCO SYLVIA VANESA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1kVLdqr1rL',	},
    {	empleado:'355523',	email:'mpenuelas@aeromexico.com',	nombre:'PENUELAS GUERRERO MARCO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'iYtnx#&jRF',	},
    {	empleado:'355723',	email:'aescamillag@aeromexico.com',	nombre:'ESCAMILLA GONZALEZ ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SgCXrfX4vX',	},
    {	empleado:'355823',	email:'afuentesgo@aeromexico.com',	nombre:'FUENTES GONZALEZ ALFONSO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'VUkUvkFWvD',	},
    {	empleado:'35619',	email:'ncalvo@aeromexico.com',	nombre:'CALVO BALTAZAR NICOLAS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ajP9@t81Q3',	},
    {	empleado:'35691',	email:'ggallegos@aeromexico.com',	nombre:'GALLEGOS MORLOTE GUSTAVO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'HYVBiTMZKD',	},
    {	empleado:'35719',	email:'anacosta@aeromexico.com',	nombre:'ACOSTA QUIROZ ANDREA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1ES2!J@knp',	},
    {	empleado:'357822',	email:'lsantoyo@aeromexico.com',	nombre:'SANTOYO ARENAS LUIS ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'W$iFVLrhHx',	},
    {	empleado:'35791',	email:'hadelcastillo@aeromexico.com',	nombre:'DEL CASTILLO VILLA HECTOR ALFREDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'UNcskQjNG8',	},
    {	empleado:'35819',	email:'jleonm@aeromexico.com',	nombre:'LEON MUNGUIA JORGE MANUEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'dbZ&qFNs1u',	},
    {	empleado:'359122',	email:'aperesbarbosa@aeromexico.com',	nombre:'PERESBARBOSA GARZA ADRIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'veKG&Qb5!n',	},
    {	empleado:'359922',	email:'jmoragon@aeromexico.com',	nombre:'MORA GONZALEZ JAIR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GuZzj3CE%b',	},
    {	empleado:'3622',	email:'gcorral@aeromexico.com',	nombre:'CORRAL MENDOZA GABRIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'6bg7ZAdVBw',	},
    {	empleado:'362306',	email:'cberumens@aeromexico.com',	nombre:'BERUMEN SALGADO CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'&yN9Tc2&r@',	},
    {	empleado:'362406',	email:'gloya@aeromexico.com',	nombre:'LOYA Y AGUILAR GUILLERMO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'PaJSzUf9%p',	},
    {	empleado:'362506',	email:'alramirez@aeromexico.com',	nombre:'RAMIREZ RODRIGUEZ ALONSO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'JLGq!tFhQT',	},
    {	empleado:'362606',	email:'mgallardo@aeromexico.com',	nombre:'GALLARDO ELIZALDE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'7WzzBPEaTK',	},
    {	empleado:'362806',	email:'jlloam@aeromexico.com',	nombre:'LOAM SOTO JORGE LUIS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'aEf$JnGGvp',	},
    {	empleado:'362906',	email:'hrodriguez@aeromexico.com',	nombre:'RODRIGUEZ MORALES HECTOR EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'q$YZ4bZ87d',	},
    {	empleado:'36291',	email:'htamborrell@aeromexico.com',	nombre:'TAMBORRELL SUAREZ HUGO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'m5Y3kZkmP1',	},
    {	empleado:'363006',	email:'fpineda@aeromexico.com',	nombre:'PINEDA VALENCIA FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'A9%XQ39J1Z',	},
    {	empleado:'363506',	email:'rcruiz@aeromexico.com',	nombre:'RUIZ PERALTA RICARDO CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'JnSS5$Eqix',	},
    {	empleado:'363706',	email:'rklinckwort@aeromexico.com',	nombre:'KLINCKWORT PAAP RALF',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'&ufgz!VQBP',	},
    {	empleado:'36517',	email:'mmolinar@aeromexico.com',	nombre:'MOLINAR ZUBIETA MAURICIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'uhPQep6e!C',	},
    {	empleado:'36617',	email:'ellopez@aeromexico.com',	nombre:'LOPEZ QUINTERO ELLIOT LLOYD',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'$@SVVkniB&',	},
    {	empleado:'36717',	email:'akruger@aeromexico.com',	nombre:'KRUGER AYALA AXEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'n45i&jn%N5',	},
    {	empleado:'367523',	email:'famador@aeromexico.com',	nombre:'AMADOR VAZQUEZ FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'FWS1Zpb#8b',	},
    {	empleado:'367623',	email:'erodrigueze@aeromexico.com',	nombre:'RODRIGUEZ ESTOLANO EMMANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'vzZ4V5jzEv',	},
    {	empleado:'367723',	email:'frramirezm@aeromexico.com',	nombre:'RAMIREZ MERLOS FREDDY ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!EjBXTy&9F',	},
    {	empleado:'367823',	email:'dvera@aeromexico.com',	nombre:'VERA CARDENAS DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yzrGEinyfn',	},
    {	empleado:'367923',	email:'raflores@aeromexico.com',	nombre:'FLORES SALTIJERAL ROBERTO ALONSO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'aNVTnkt7Y8',	},
    {	empleado:'368023',	email:'rimendoza@aeromexico.com',	nombre:'MENDOZA HUESCA RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8HJtwvnx%9',	},
    {	empleado:'36817',	email:'desquivelb@aeromexico.com',	nombre:'ESQUIVEL BARRIOS DIEGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'t52pgcN1gx',	},
    {	empleado:'368219',	email:'atopeteg@aeromexico.com',	nombre:'TOPETE GONZALEZ ANDRES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jApg#p1KJd',	},
    {	empleado:'368223',	email:'rmedinas@aeromexico.com',	nombre:'MEDINA SOSA ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vieuqqXK1Z',	},
    {	empleado:'368506',	email:'hluce@aeromexico.com',	nombre:'LUCE DICKINSON HANS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'QRxqTTXZ58',	},
    {	empleado:'368606',	email:'gwallberg@aeromexico.com',	nombre:'WALLBERG YANNELLI GIANNI',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Lt1xn3VHMq',	},
    {	empleado:'368619',	email:'dvelazquezc@aeromexico.com',	nombre:'VELAZQUEZ CID DIEGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#ZachbQtPz',	},
    {	empleado:'368706',	email:'etorres@aeromexico.com',	nombre:'TORRES FERNANDEZ EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Z6%jsjwE4@',	},
    {	empleado:'368719',	email:'cbetancourt@aeromexico.com',	nombre:'BETANCOURT VARGAS CESAR SALVADOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'smRBRNTwnZ',	},
    {	empleado:'36917',	email:'hsalazar@aeromexico.com',	nombre:'SALAZAR AVILA HERIBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'aVtJzm7EZR',	},
    {	empleado:'369219',	email:'fjuareze@aeromexico.com',	nombre:'JUAREZ ESQUIVEL FAVIO AARON',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&5gyBWnLPb',	},
    {	empleado:'369319',	email:'csalgado@aeromexico.com',	nombre:'SALGADO LOPEZ CYNTHIA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1yk6HMM6k9',	},
    {	empleado:'369419',	email:'msepulveda@aeromexico.com',	nombre:'SEPULVEDA CUELLAR MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'t4mjU@y4Jn',	},
    {	empleado:'37017',	email:'ceavila@aeromexico.com',	nombre:'AVILA PEDRAZA CARLOS ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'CSkCAA6SeY',	},
    {	empleado:'371606',	email:'jebenitez@aeromexico.com',	nombre:'BENITEZ CAMACHO JUAN ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'pUWm@B6BFh',	},
    {	empleado:'371718',	email:'fesandoval@aeromexico.com',	nombre:'SANDOVAL DEL RIO FABIOLA ELIZABETH',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'T%k1Nj3jbS',	},
    {	empleado:'371806',	email:'jcrodriguez@aeromexico.com',	nombre:'RODRIGUEZ VAZQUEZ JUAN CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Z46WLZGkNE',	},
    {	empleado:'371906',	email:'asantaolalla@aeromexico.com',	nombre:'SANTAOLALLA LORENZO AXEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'RZH$3E3SL2',	},
    {	empleado:'371918',	email:'mmontess@aeromexico.com',	nombre:'MONTES SALAZAR MIGUEL ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'fZXn##RYN3',	},
    {	empleado:'372018',	email:'mogarciagaliano@aeromexico.com',	nombre:'GARCIA GALIANO RAMIREZ MONTSERRAT',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'7KS9%VTDw5',	},
    {	empleado:'3722',	email:'dcamarillo@aeromexico.com',	nombre:'CAMARILLO BARRERA DANNY YAIR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'snKqZc3!Fu',	},
    {	empleado:'372518',	email:'fpadillaf@aeromexico.com',	nombre:'PADILLA FERNANDEZ DE LA VEGA FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'YnkbKg%8K&',	},
    {	empleado:'372618',	email:'fregalado@aeromexico.com',	nombre:'REGALADO PENA FRANCISCO CAMERINO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'dZJK#3nshZ',	},
    {	empleado:'372706',	email:'imuro@aeromexico.com',	nombre:'MURO YANNELLI ITALO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'K1J1u419Nt',	},
    {	empleado:'372806',	email:'fcrespo@aeromexico.com',	nombre:'CRESPO DIAZ FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'bz4Ube@fG$',	},
    {	empleado:'372906',	email:'inegrete@aeromexico.com',	nombre:'NEGRETE CALVO IVAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'q$ZqPuZ19v',	},
    {	empleado:'372918',	email:'javillarreal@aeromexico.com',	nombre:'VILLARREAL LOZANO JUAN ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'BD6%#fQRkH',	},
    {	empleado:'373006',	email:'dplascencias@aeromexico.com',	nombre:'PLASCENCIA SANCHEZ DANIEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'VQJqZUX%1f',	},
    {	empleado:'373018',	email:'jriestra@aeromexico.com',	nombre:'RIESTRA CUESTA JUAN JOSE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#4qPt!BTZf',	},
    {	empleado:'373019',	email:'avallado@aeromexico.com',	nombre:'VALLADO MENENDEZ ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'J43EXkarZr',	},
    {	empleado:'373518',	email:'berubi@aeromexico.com',	nombre:'RUBI HIGAREDA BEATRIZ',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@FidZfsMZF',	},
    {	empleado:'373718',	email:'mdeanda@aeromexico.com',	nombre:'DE ANDA ESPARZA MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wi&pL2V#!G',	},
    {	empleado:'373918',	email:'jacosta@aeromexico.com',	nombre:'ACOSTA INURRETA JOSE GERARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Pd%bZD7%&J',	},
    {	empleado:'374518',	email:'maperezc@aeromexico.com',	nombre:'PEREZ CARRERO MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'h1h1R$cpvw',	},
    {	empleado:'375306',	email:'aamarin@aeromexico.com',	nombre:'MARIN WILTSHIRE ANDREI ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'sUigKVfx6Z',	},
    {	empleado:'375406',	email:'aolivar@aeromexico.com',	nombre:'OLIVAR OBREGON ALEJANDRO CESAR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'1JHnM!zJ9S',	},
    {	empleado:'375506',	email:'eaperez@aeromexico.com',	nombre:'PEREZ SACRISTAN ENRIQUE AXEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'dvZSc@MXx&',	},
    {	empleado:'375606',	email:'eschaefer@aeromexico.com',	nombre:'SCHAEFER RAMIREZ ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'XqNnwR8kYJ',	},
    {	empleado:'375706',	email:'hlangmaack@aeromexico.com',	nombre:'LANGMAACK RIVARA HANS ALEXIS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'qV@#8g6HVk',	},
    {	empleado:'375806',	email:'phernandezj@aeromexico.com',	nombre:'HERNANDEZ JIMENEZ PABLO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'q@dywkMnBM',	},
    {	empleado:'376423',	email:'mreyesgo@aeromexico.com',	nombre:'REYES GONZALEZ MIGUEL HUMBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GYPtjd4wQB',	},
    {	empleado:'376523',	email:'malatorrep@aeromexico.com',	nombre:'ALATORRE POOL MANUEL FAUSTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Fu@1GyYBX!',	},
    {	empleado:'376723',	email:'aalanisg@aeromexico.com',	nombre:'ALANIS GARCIA ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GZd5GgSnJr',	},
    {	empleado:'376823',	email:'alopezcam@aeromexico.com',	nombre:'LOPEZ CAMERAS ALEX IVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!K%ESt1W&Z',	},
    {	empleado:'376923',	email:'fnavarrov@aeromexico.com',	nombre:'NAVARRO VEGA FRANCISCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!JGkCGkRS1',	},
    {	empleado:'377023',	email:'fjara@aeromexico.com',	nombre:'JARA CORREA FRANCISCO JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'4tZE3p5ccw',	},
    {	empleado:'37717',	email:'dfuentevilla@aeromexico.com',	nombre:'FUENTEVILLA ZUNIGA DIEGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xie&fnBN9G',	},
    {	empleado:'377223',	email:'ecepedav@aeromexico.com',	nombre:'CEPEDA VALOR EMMANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'aK3tbLXs@J',	},
    {	empleado:'377423',	email:'rrodrigueze@aeromexico.com',	nombre:'RODRIGUEZ ESTRADA RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%CdDYeJ6SV',	},
    {	empleado:'377623',	email:'lterrazasb@aeromexico.com',	nombre:'TERRAZAS BRICENO LEONARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'3ukqQN6P#k',	},
    {	empleado:'377719',	email:'cpastranag@aeromexico.com',	nombre:'PASTRANA GARCIA CARLA LETICIA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6e7$4v859t',	},
    {	empleado:'377819',	email:'fguerrerol@aeromexico.com',	nombre:'GUERRERO LIZARRAGA FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@rhJJV6H2u',	},
    {	empleado:'377823',	email:'jgarciade@aeromexico.com',	nombre:'GARCIA DEL VALLE MELO JUAN PABLO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5by6NJq8VG',	},
    {	empleado:'377919',	email:'jerosa@aeromexico.com',	nombre:'EROSA MERCADO JORGE GREGORIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6KKnr%Y&p!',	},
    {	empleado:'378123',	email:'ngarciag@aeromexico.com',	nombre:'GARCIA GARZA NORMA MARIANA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'xnS7kTKS3Z',	},
    {	empleado:'378323',	email:'jmezarod@aeromexico.com',	nombre:'MEZA RODRIGUEZ JULIO ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'AQwqmYYu&f',	},
    {	empleado:'378423',	email:'stalavera@aeromexico.com',	nombre:'TALAVERA CAMACHO SERGIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'tw8Cekyj%U',	},
    {	empleado:'378523',	email:'marroyo@aeromexico.com',	nombre:'ARROYO MEDINA MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'M6v#gT!e6W',	},
    {	empleado:'379023',	email:'mlegazpi@aeromexico.com',	nombre:'LEGAZPI ZAMORA MARIO ARTURO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'RX3@Y&h778',	},
    {	empleado:'379123',	email:'alozanoc@aeromexico.com',	nombre:'LOZANO CALVILLO ALFREDO ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'i@WNi3dDdh',	},
    {	empleado:'379819',	email:'etureganos@aeromexico.com',	nombre:'TUREGANO SANCHEZ EDGAR MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'XYuHYEk&Z&',	},
    {	empleado:'380119',	email:'ylomelir@aeromexico.com',	nombre:'LOMELI REAL YONATHAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'j3J6nW8351',	},
    {	empleado:'38017',	email:'rravila@aeromexico.com',	nombre:'AVILA SCHIMMELFENNIG ROMAN RICARDO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'@2HX&4Pt6z',	},
    {	empleado:'38217',	email:'smunguiap@aeromexico.com',	nombre:'MUNGUIA PULIDO SIGFRIDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'akku&CJYyG',	},
    {	empleado:'3822',	email:'alestrella@aeromexico.com',	nombre:'ESTRELLA CASTRO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JkCfn@Q!34',	},
    {	empleado:'383822',	email:'dgaytan@aeromexico.com',	nombre:'GAYTAN GUTIERREZ DAVID',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'NZsgj@MnS7',	},
    {	empleado:'38417',	email:'lmartindelcampo@aeromexico.com',	nombre:'MARTIN DEL CAMPO VARGAS LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'C!n6HfWUB#',	},
    {	empleado:'38517',	email:'guperez@aeromexico.com',	nombre:'PEREZ POPOCA GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ygkHqs#RAN',	},
    {	empleado:'386516',	email:'ddelabarreda@aeromexico.com',	nombre:'DE LA BARREDA PABST DANIEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1MmZstgmJP',	},
    {	empleado:'387924',	email:'jalbarrang@aeromexico.com',	nombre:'ALBARRAN GALVAN JOSE RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8UvGZeJrRr',	},
    {	empleado:'388024',	email:'pbeltranga@aeromexico.com',	nombre:'BELTRAN GALICIA PRISCILA MARIANA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vze4D#pQFs',	},
    {	empleado:'388224',	email:'jescobara@aeromexico.com',	nombre:'ESCOBAR ALARCON JORGE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Z5eQfZdsRt',	},
    {	empleado:'388324',	email:'bfigueroaro@aeromexico.com',	nombre:'FIGUEROA RODRIGUEZ BRAULIO ALFREDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'nT6GUxBj1Z',	},
    {	empleado:'388624',	email:'pgamboam@aeromexico.com',	nombre:'GAMBOA MACIAS PATRICK',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'S6NJhP42C1',	},
    {	empleado:'388724',	email:'hgonzalezco@aeromexico.com',	nombre:'GONZALEZ CONTRERAS HUMBERTO RAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%Z5Ag4StK5',	},
    {	empleado:'388824',	email:'azamudioav@aeromexico.com',	nombre:'ZAMUDIO AVALOS ABIGAIL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9VZ$@JSg8K',	},
    {	empleado:'388924',	email:'fharo@aeromexico.com',	nombre:'HARO CUELLAR FRANCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'UEkZsm%Scq',	},
    {	empleado:'389024',	email:'fdalera@aeromexico.com',	nombre:'DALERA DIAZ FERNANDO ADRIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'v$%tMpn6B8',	},
    {	empleado:'389124',	email:'dheras@aeromexico.com',	nombre:'HERAS MUNGUIA DANIELA CAROLINA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%ZxwZYP7Vs',	},
    {	empleado:'389224',	email:'cibarral@aeromexico.com',	nombre:'IBARRA LOZANO CRISTOBAL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'arvj$epw5!',	},
    {	empleado:'389324',	email:'rlunaz@aeromexico.com',	nombre:'LUNA ZUNIGA RODRIGO URI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'UcTeZmVyj!',	},
    {	empleado:'389424',	email:'gmartinezg@aeromexico.com',	nombre:'MARTINEZ GARCIA GABRIEL JULIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fzpyb9n!kn',	},
    {	empleado:'389524',	email:'asotob@aeromexico.com',	nombre:'SOTO BORJA ALMEIDA ANDRES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'i&L5r5Z@Jn',	},
    {	empleado:'389624',	email:'gmorenom@aeromexico.com',	nombre:'MORENO MC GREGOR LIMON GABRIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZJjLpZ9Ag7',	},
    {	empleado:'389724',	email:'dsaucedoo@aeromexico.com',	nombre:'SAUCEDO OROZCO DANIELA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'LqXV$&VC87',	},
    {	empleado:'389824',	email:'msanchezpi@aeromexico.com',	nombre:'SANCHEZ PIMENTEL MOISES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'sDWZLqFfja',	},
    {	empleado:'390018',	email:'cduranm@aeromexico.com',	nombre:'DURAN MEDINA CARLOS JONATHAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'MADbnDT2Yb',	},
    {	empleado:'3922',	email:'slopezl@aeromexico.com',	nombre:'LOPEZ LOPEZ SANTIAGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'$gRbqZ9TKD',	},
    {	empleado:'39391',	email:'ghernandezq@aeromexico.com',	nombre:'HERNANDEZ QUINTERO GABRIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'JTg7EaLKeM',	},
    {	empleado:'396918',	email:'rpena@aeromexico.com',	nombre:'PENA ROMERO RAFAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'iwUZmZZ6k$',	},
    {	empleado:'397306',	email:'clarac@aeromexico.com',	nombre:'LARA CASTELLANOS CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'C9GwETn1pZ',	},
    {	empleado:'397606',	email:'magutierrez@aeromexico.com',	nombre:'GUTIERREZ MALDONADO MARIO ALFONSO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'uxiy1dH%w5',	},
    {	empleado:'397706',	email:'oegarcia@aeromexico.com',	nombre:'GARCIA ESCOBAR OSVALDO ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'dv#Kize@zJ',	},
    {	empleado:'397806',	email:'aquiroz@aeromexico.com',	nombre:'QUIROZ FERNANDEZ ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'VfE!4MgxAa',	},
    {	empleado:'397906',	email:'mcervantesb@aeromexico.com',	nombre:'CERVANTES BARRAGAN MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'t7eDnZVA3G',	},
    {	empleado:'398006',	email:'acastros@aeromexico.com',	nombre:'CASTRO SOTELO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'L296Z!caZ3',	},
    {	empleado:'398222',	email:'pdiaze@aeromexico.com',	nombre:'DIAZ ESPINO BARROS PABLO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'QZ7F9ntMGi',	},
    {	empleado:'398417',	email:'ugarcia@aeromexico.com',	nombre:'GARCIA MENDOZA ULISES YAEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'xA#xZg65kY',	},
    {	empleado:'398517',	email:'amallard@aeromexico.com',	nombre:'MALLARD JUAREZ ARTURO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'y!jq@gedAk',	},
    {	empleado:'398617',	email:'dafranco@aeromexico.com',	nombre:'FRANCO ROMERO DEMIAN ALEXIS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'WwY3kVh1eZ',	},
    {	empleado:'398717',	email:'araguilar@aeromexico.com',	nombre:'AGUILAR CORTES AURA RENATA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'8FLAAbaGcE',	},
    {	empleado:'39891',	email:'vmorita@aeromexico.com',	nombre:'MORITA OHORI VICTOR MAKOTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'!5dYDE26au',	},
    {	empleado:'399319',	email:'fparra@aeromexico.com',	nombre:'PARRA AYALA FRANCO ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5vSZdgVZBm',	},
    {	empleado:'399419',	email:'rmendez@aeromexico.com',	nombre:'MENDEZ GLATT RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wkb$n271xW',	},
    {	empleado:'399519',	email:'llopezcr@aeromexico.com',	nombre:'LOPEZ CRUZ LUIS ARTURO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'P2gbqkdd9G',	},
    {	empleado:'39991',	email:'rtorralba@aeromexico.com',	nombre:'TORRALBA AGUERIA RAMON',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'P8Zmd1YDjN',	},
    {	empleado:'40022',	email:'mguerraa@aeromexico.com',	nombre:'GUERRA ANCONA MORY SANTIAGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&xWDY&Mv2Q',	},
    {	empleado:'400806',	email:'emedina@aeromexico.com',	nombre:'MEDINA DE CAMPO ENRIQUE GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'v16Cb75C3%',	},
    {	empleado:'400816',	email:'ccalvillo@aeromexico.com',	nombre:'CALVILLO ORTIZ CARLO JESUS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'MZiHUZGJZb',	},
    {	empleado:'400906',	email:'jaldama@aeromexico.com',	nombre:'ALDAMA MARQUEZ JOSE JORGE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'TTLkz$r1$r',	},
    {	empleado:'400916',	email:'jjorozco@aeromexico.com',	nombre:'OROZCO SANCHO JOEL JAVIER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'XQ@ZAuZ&ME',	},
    {	empleado:'401006',	email:'anicolau@aeromexico.com',	nombre:'NICOLAU MARTINEZ ALEJANDRO JOSE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'JS82Y&WYd5',	},
    {	empleado:'401016',	email:'ogonzalezl@aeromexico.com',	nombre:'GONZALEZ LOPEZ OSCAR',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'bGZRnFrrqk',	},
    {	empleado:'401106',	email:'ealonsoo@aeromexico.com',	nombre:'ALONSO ORLANZZINI ERIK',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Vhv$MsS1BR',	},
    {	empleado:'401206',	email:'fduarte@aeromexico.com',	nombre:'DUARTE LOPEZ FRANCISCO MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'2s5y5Yk4K%',	},
    {	empleado:'401216',	email:'acmartinez@aeromexico.com',	nombre:'MARTINEZ ESCUDERO ANTONIO CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ke1A4Z69fM',	},
    {	empleado:'401316',	email:'tgonzalezc@aeromexico.com',	nombre:'GONZALEZ CARDENAS TANIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%@ms$ejQx5',	},
    {	empleado:'401406',	email:'mdesevilla@aeromexico.com',	nombre:'DE SEVILLA LOPEZ MARIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'NPAJXF!PE!',	},
    {	empleado:'401506',	email:'dmorenod@aeromexico.com',	nombre:'MORENO DAVILA DAMIAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'#JmJmZwcyF',	},
    {	empleado:'401606',	email:'jancona@aeromexico.com',	nombre:'ANCONA CRESPO JORGE ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'WxwXB3zFVZ',	},
    {	empleado:'401706',	email:'jgtellez@aeromexico.com',	nombre:'TELLEZ CASILLAS JOSE GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Y#1&@$j9V1',	},
    {	empleado:'401806',	email:'amartinezl@aeromexico.com',	nombre:'MARTINEZ LOPEZ ANA MARIA DE LOS ANGELES',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'F8&wkn#!2j',	},
    {	empleado:'401906',	email:'lchiapa@aeromexico.com',	nombre:'CHIAPA VIVAS LUIS ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'vkkkT12PGH',	},
    {	empleado:'402006',	email:'fegonzalez@aeromexico.com',	nombre:'GONZALEZ CASTILLO FEDERICO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'3GHAQ3iq93',	},
    {	empleado:'402106',	email:'jlbarroso@aeromexico.com',	nombre:'BARROSO RODRIGUEZ JOSE LUIS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'$guPJApyQb',	},
    {	empleado:'4022',	email:'lpantoja@aeromexico.com',	nombre:'PANTOJA GONZALEZ LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'$tA9XNaRXB',	},
    {	empleado:'402206',	email:'pberumen@aeromexico.com',	nombre:'BERUMEN SALGADO PATRICIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'$tpi%6YTnZ',	},
    {	empleado:'40222',	email:'jbustamantes@aeromexico.com',	nombre:'BUSTAMANTE SALAS JESUS ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'QsqnbhMcqt',	},
    {	empleado:'402306',	email:'csalicrup@aeromexico.com',	nombre:'SALICRUP DIAZ DE LEON CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'CeJLDVSBC@',	},
    {	empleado:'402406',	email:'amaya@aeromexico.com',	nombre:'MAYA CASTRO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'mP4Cx1t%t3',	},
    {	empleado:'403123',	email:'mhernandezf@aeromexico.com',	nombre:'HERNANDEZ FLORES MARIO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'fjH#AYK$TC',	},
    {	empleado:'40322',	email:'fdickinsong@aeromexico.com',	nombre:'DICKINSON GUERRERO FRANCISCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dZhE!S433i',	},
    {	empleado:'403223',	email:'aaguirre@aeromexico.com',	nombre:'AGUIRRE HERNANDEZ AARON GERARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%6!3$ZYZHG',	},
    {	empleado:'403323',	email:'dochoa@aeromexico.com',	nombre:'OCHOA LOPEZ VALLEJO DIEGO ADOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'xeVZCnyN$Z',	},
    {	empleado:'403423',	email:'agonzalezlo@aeromexico.com',	nombre:'GONZALEZ LOPEZ ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vXJXYga$uc',	},
    {	empleado:'40391',	email:'jgvegar@aeromexico.com',	nombre:'VEGA RAMOS JOSE GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kA3#n2c#ys',	},
    {	empleado:'404006',	email:'segonzalezs@aeromexico.com',	nombre:'GONZALEZ SANCHEZ SERGIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'8Qtt7B1kLj',	},
    {	empleado:'404106',	email:'gfarrera@aeromexico.com',	nombre:'FARRERA MENDEZ GERARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Jk6CQuG%in',	},
    {	empleado:'404206',	email:'gmgarcia@aeromexico.com',	nombre:'GARCIA HERNANDEZ GUSTAVO MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'9@x&jKZjzU',	},
    {	empleado:'404306',	email:'rcabello@aeromexico.com',	nombre:'CABELLO ARANA RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'&DfJcn11&G',	},
    {	empleado:'404406',	email:'gleipold@aeromexico.com',	nombre:'LEIPOLD DE LA LANZA GENOVEVA MARIA HANSITA',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'yJjjMBLZJV',	},
    {	empleado:'404506',	email:'isoto@aeromexico.com',	nombre:'SOTO WERSCHITZ IVAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'uf&8XJP1Ua',	},
    {	empleado:'404606',	email:'ramartinez@aeromexico.com',	nombre:'MARTINEZ MILLET RAUL ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'keHXDGRn6s',	},
    {	empleado:'404706',	email:'fpatinod@aeromexico.com',	nombre:'PATINO DOMINGUEZ FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'8PEQ8Z7BkM',	},
    {	empleado:'404806',	email:'jlascurain@aeromexico.com',	nombre:'LASCURAIN CABRERA JAIME',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'J@#VZa%h##',	},
    {	empleado:'404906',	email:'mlopezg@aeromexico.com',	nombre:'LOPEZ GAMBOA MIRYAM ESTIBALITZ',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'2Vby8QY@tH',	},
    {	empleado:'405106',	email:'avillalvazo@aeromexico.com',	nombre:'VILLALVAZO ROJAS ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'bvACdN&&P#',	},
    {	empleado:'405206',	email:'dborja@aeromexico.com',	nombre:'BORJA MIER DIEGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'r#7d3L1H1F',	},
    {	empleado:'40522',	email:'maguilara@aeromexico.com',	nombre:'AGUILAR ARRIAGA MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'km8s7kRAZ#',	},
    {	empleado:'405406',	email:'egarciam@aeromexico.com',	nombre:'GARCIA MARIN EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Ap&9b8MKGu',	},
    {	empleado:'405506',	email:'jtavera@aeromexico.com',	nombre:'TAVERA VAZQUEZ JUAN CARLOS',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'s5Pst2Z8nx',	},
    {	empleado:'405923',	email:'jorodriguezi@aeromexico.com',	nombre:'RODRIGUEZ ISLAS JORGE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'utk@1dJ%Kx',	},
    {	empleado:'40622',	email:'jgomezri@aeromexico.com',	nombre:'GOMEZ RICO JOSE ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'EJJQxpM%4@',	},
    {	empleado:'406223',	email:'amoralesro@aeromexico.com',	nombre:'MORALES ROMERO ALFREDO ANDRES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jpfhgkeYzu',	},
    {	empleado:'406423',	email:'rrodriguezi@aeromexico.com',	nombre:'RODRIGUEZ IBARRA RAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'m#yvTeHBrA',	},
    {	empleado:'406622',	email:'sesquivel@aeromexico.com',	nombre:'ESQUIVEL CAMACHO SILVERIO LEON',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'TZa!dwJJYV',	},
    {	empleado:'406722',	email:'dorrantia@aeromexico.com',	nombre:'ORRANTIA LARA DANIEL EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Bb8ELd1d4!',	},
    {	empleado:'406822',	email:'pbarrerav@aeromexico.com',	nombre:'BARRERA VARGAS PABLO DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'&ncE52pZTU',	},
    {	empleado:'407022',	email:'dlopezca@aeromexico.com',	nombre:'LOPEZ CASAS DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'71yX41am1B',	},
    {	empleado:'407122',	email:'mmartinezco@aeromexico.com',	nombre:'MARTINEZ CONTRERAS MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GHD@Rn%$$4',	},
    {	empleado:'407222',	email:'alopezald@aeromexico.com',	nombre:'LOPEZ ALDAVE ALDO PAULINO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Wjez#8wJ2R',	},
    {	empleado:'407322',	email:'ivictoria@aeromexico.com',	nombre:'VICTORIA GONZALEZ IRVING DAVID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'p7RWuYDd!Z',	},
    {	empleado:'407807',	email:'imejia@aeromexico.com',	nombre:'MEJIA ITURRIAGA IGNACIO ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'s%Z14rQ1$c',	},
    {	empleado:'407907',	email:'jmaceto@aeromexico.com',	nombre:'MACETO CORTES JUAN CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'8!wG5JkZz2',	},
    {	empleado:'40791',	email:'egarciagaliano@aeromexico.com',	nombre:'GARCIA GALIANO DE RIVAS ENRIQUE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'WjiDnkeK$J',	},
    {	empleado:'408007',	email:'jcolores@aeromexico.com',	nombre:'COLORES FERNANDEZ JAIME RODOLFO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'ZM$NnSkFJq',	},
    {	empleado:'408207',	email:'ggonzalezh@aeromexico.com',	nombre:'GONZALEZ HERNANDEZ GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Eq1CYJg6ke',	},
    {	empleado:'408622',	email:'jortizla@aeromexico.com',	nombre:'ORTIZ LARIOS JORGE IVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'pQrHmZLVft',	},
    {	empleado:'40891',	email:'gyannelli@aeromexico.com',	nombre:'YANNELLI MARTINEZ GABRIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Jtrvrek9Vn',	},
    {	empleado:'408922',	email:'dloera@aeromexico.com',	nombre:'LOERA ABUNDIS DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1ADvQTZtHi',	},
    {	empleado:'409022',	email:'vnava@aeromexico.com',	nombre:'NAVA LUJAN VICTOR HUGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jbZEsDM5Yg',	},
    {	empleado:'409122',	email:'mtorresma@aeromexico.com',	nombre:'TORRES MARTINEZ MARCO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Sk4L1gminZ',	},
    {	empleado:'409322',	email:'avaladezm@aeromexico.com',	nombre:'VALADEZ MARTINEZ ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%kgaCNYkcP',	},
    {	empleado:'409422',	email:'iruizs@aeromexico.com',	nombre:'RUIZ SAINOS ITZEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zZkAHCfenm',	},
    {	empleado:'409522',	email:'gruizatl@aeromexico.com',	nombre:'RUIZ ATLITEC GUSTAVO ADOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YS#BBPJYLZ',	},
    {	empleado:'409722',	email:'gpeniche@aeromexico.com',	nombre:'PENICHE ALCOCER GUSTAVO ADOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YHENTRXx8c',	},
    {	empleado:'409822',	email:'jmorcilloc@aeromexico.com',	nombre:'MORCILLO CETINA JESUS JALIL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'y6Nqyw2Z@E',	},
    {	empleado:'410022',	email:'rmunozr@aeromexico.com',	nombre:'MUNOZ REVELES RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'2JjDJ1PkF&',	},
    {	empleado:'41022',	email:'ozuniga@aeromexico.com',	nombre:'ZUNIGA ARRONIZ OSCAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1eZeXRHveu',	},
    {	empleado:'41023',	email:'agarciaace@aeromexico.com',	nombre:'GARCIA ACEVEDO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9UX%D7!LNd',	},
    {	empleado:'410613',	email:'jrsiller@aeromexico.com',	nombre:'SILLER MARIN JESUS RODRIGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'AFfZUgcH3M',	},
    {	empleado:'410713',	email:'jmaya@aeromexico.com',	nombre:'MAYA GALVAN JUAN CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'yZcBtZweBJ',	},
    {	empleado:'410813',	email:'cgasca@aeromexico.com',	nombre:'GASCA ROSILLO CHRISTIAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ke1AsVZJ4y',	},
    {	empleado:'411013',	email:'facalvo@aeromexico.com',	nombre:'CALVO COLIN FERNANDO ALAN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'JqkgQ31xk$',	},
    {	empleado:'411113',	email:'gquesada@aeromexico.com',	nombre:'QUESADA RIVERO GABRIEL EMILIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'traTHnCJ2q',	},
    {	empleado:'411213',	email:'slopezc@aeromexico.com',	nombre:'LOPEZ CADENA SANTIAGO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'RZEWueqGaf',	},
    {	empleado:'41123',	email:'agranadospa@aeromexico.com',	nombre:'GRANADOS PACHECO ANGEL JOEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6EDDHfJDcU',	},
    {	empleado:'411313',	email:'jgarciac@aeromexico.com',	nombre:'GARCIA CALDERON JOSE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Hwn4y2PhYd',	},
    {	empleado:'411407',	email:'mtoledo@aeromexico.com',	nombre:'TOLEDO MARZUCA MARCO VINICIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Q8Jw1LFRzf',	},
    {	empleado:'411507',	email:'jemunoz@aeromexico.com',	nombre:'MUNOZ CARCINI JULIO ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'kZdWfqbvHh',	},
    {	empleado:'411513',	email:'rcalvete@aeromexico.com',	nombre:'CALVETE LOPEZ RICARDO YEREB',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'t1f$%hjeTe',	},
    {	empleado:'411807',	email:'cgarcialascurain@aeromexico.com',	nombre:'GARCIA LASCURAIN CHAVEZ CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'im#KVY8yq!',	},
    {	empleado:'412007',	email:'jmdiaz@aeromexico.com',	nombre:'DIAZ PEREZ JOSE MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'pzUX1GpvLL',	},
    {	empleado:'412107',	email:'jparada@aeromexico.com',	nombre:'PARADA PEREZ JORGE EDUARDO EZEQUIEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'S34@YytLEm',	},
    {	empleado:'412207',	email:'jfonseca@aeromexico.com',	nombre:'FONSECA ARRIAGA JOSE DAVID',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'xpJHJExSje',	},
    {	empleado:'41222',	email:'taguilar@aeromexico.com',	nombre:'AGUILAR DOMINGUEZ TLALOC ERNESTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'LsdNFtpVG@',	},
    {	empleado:'41223',	email:'cfeleppa@aeromexico.com',	nombre:'FELEPPA GALVEZ CLAUDIO ALAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'r%Bbk5cPyM',	},
    {	empleado:'412307',	email:'mmunozc@aeromexico.com',	nombre:'MUNOZ CARCINI MILTON JULIO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'P8yN&CTmcj',	},
    {	empleado:'41322',	email:'jgarciana@aeromexico.com',	nombre:'GARCIA NAJERA JOSUE JACINTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'7!hYUUzBVj',	},
    {	empleado:'41323',	email:'agonzalezvi@aeromexico.com',	nombre:'GONZALEZ VILLALOBOS ALFREDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'CYXEkCDj!%',	},
    {	empleado:'414007',	email:'jjgutierrez@aeromexico.com',	nombre:'GUTIERREZ PENA JUVENCIO JAVIER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'JNkgZQaFJ2',	},
    {	empleado:'41422',	email:'asotelo@aeromexico.com',	nombre:'SOTELO CAUDURO ANDRES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'sJZDdYg2UL',	},
    {	empleado:'41423',	email:'dsilvas@aeromexico.com',	nombre:'SILVA STOUTE DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fbmkbmucTX',	},
    {	empleado:'41522',	email:'iibarra@aeromexico.com',	nombre:'IBARRA GARCIA IVAN EHECATL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zyb#qRiMf$',	},
    {	empleado:'415917',	email:'fcorona@aeromexico.com',	nombre:'CORONA CARRASCO FRANCISCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'iwwYvVnGsN',	},
    {	empleado:'416017',	email:'lgclaro@aeromexico.com',	nombre:'CLARO REYES LUIS GUILLERMO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'bgaDZ6Jpx%',	},
    {	empleado:'416117',	email:'aescalanted@aeromexico.com',	nombre:'ESCALANTE DUBOST ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'kHWn!Zxp5L',	},
    {	empleado:'41622',	email:'squinteroi@aeromexico.com',	nombre:'QUINTERO ISLAS SAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'2u#TBcqiY1',	},
    {	empleado:'416407',	email:'esoto@aeromexico.com',	nombre:'SOTO VARGAS EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'cjXNQQNLYC',	},
    {	empleado:'416507',	email:'emoguel@aeromexico.com',	nombre:'MOGUEL FLORES EDUARDO JESUS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'$91nMUqZfQ',	},
    {	empleado:'416517',	email:'aorozcoc@aeromexico.com',	nombre:'OROZCO CASTILLO ABRAHAM',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'PZixVujJWL',	},
    {	empleado:'416617',	email:'eaprieto@aeromexico.com',	nombre:'PRIETO GARZA EDUARDO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'m#kY2hcrJN',	},
    {	empleado:'416807',	email:'jcerecedo@aeromexico.com',	nombre:'CERECEDO VALDEZ JUAN RAUL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Yka$tut&Xs',	},
    {	empleado:'416817',	email:'anaval@aeromexico.com',	nombre:'NAVAL MEDINA ADOLFO SALVADOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'XrzTZmanYW',	},
    {	empleado:'416907',	email:'emayor@aeromexico.com',	nombre:'MAYOR MUTZ ERNESTO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'1VJ9UJw3Pw',	},
    {	empleado:'417007',	email:'cwoolrich@aeromexico.com',	nombre:'WOOLRICH LORENZO CHRISTIAN',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'8J8t1ai11H',	},
    {	empleado:'417107',	email:'ealamilla@aeromexico.com',	nombre:'ALAMILLA MARTINEZ EMMANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'#C1YEqQ7Mp',	},
    {	empleado:'41822',	email:'evargasw@aeromexico.com',	nombre:'VARGAS WITTIG ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'mH4tx6WjSD',	},
    {	empleado:'419188',	email:'jsanjuana@aeromexico.com',	nombre:'SANJUAN ARREOLA JULIAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'J38qjC7HHe',	},
    {	empleado:'41922',	email:'cloza@aeromexico.com',	nombre:'LOZA AGUILAR CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'mtt#TJQPZ9',	},
    {	empleado:'42122',	email:'agomezlu@aeromexico.com',	nombre:'GOMEZ LUNA BARBA ALVARO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yCkN%hcE%x',	},
    {	empleado:'421823',	email:'aesqueda@aeromexico.com',	nombre:'ESQUEDA DERGAL ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'CZqUWfXrTT',	},
    {	empleado:'421923',	email:'rpedroza@aeromexico.com',	nombre:'PEDROZA GUERRERO RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'u4UFmb86Ug',	},
    {	empleado:'422123',	email:'jgarciaper@aeromexico.com',	nombre:'GARCIA PEREZ JOSE ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'je!CKwwDXM',	},
    {	empleado:'4222',	email:'sgutierrezb@aeromexico.com',	nombre:'GUTIERREZ BRACAMONTES SEBASTIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9i6A1L1VeA',	},
    {	empleado:'42222',	email:'earrieta@aeromexico.com',	nombre:'ARRIETA AGUILAR EMILIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fJRkN91!bJ',	},
    {	empleado:'422223',	email:'epenna@aeromexico.com',	nombre:'PENNA ALVAREZ ESCARLET NYDIYARE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YHMeTxp1z&',	},
    {	empleado:'422507',	email:'anava@aeromexico.com',	nombre:'NAVA VILCHIS ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'KaFg$rLv9h',	},
    {	empleado:'42322',	email:'cdelgadoh@aeromexico.com',	nombre:'DELGADO HERNANDEZ CARLOS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'xNw8BAfu3y',	},
    {	empleado:'423707',	email:'hhernandezl@aeromexico.com',	nombre:'HERNANDEZ LUGO HUGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'xKnUVkFMt$',	},
    {	empleado:'42422',	email:'fprieto@aeromexico.com',	nombre:'PRIETO SEPULVEDA FEDERICO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YRf8gkH@8f',	},
    {	empleado:'424407',	email:'cibarra@aeromexico.com',	nombre:'IBARRA BARAJAS CARLOS ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'tytC&8dBfn',	},
    {	empleado:'424507',	email:'csanchezh@aeromexico.com',	nombre:'SANCHEZ HERNANDEZ CARLOS MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'xibwCjW$D6',	},
    {	empleado:'428619',	email:'aholz@aeromexico.com',	nombre:'HOLZ VERDUZCO ALFREDO GUNTHER',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Js$D5pHHRk',	},
    {	empleado:'429023',	email:'mlopezna@aeromexico.com',	nombre:'LOPEZ NAJERA MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#VPZ6J51!A',	},
    {	empleado:'429423',	email:'grosalesc@aeromexico.com',	nombre:'ROSALES CUETO GRACIELA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5ceiqMZZ!N',	},
    {	empleado:'429723',	email:'dacosta@aeromexico.com',	nombre:'ACOSTA RUIZ DAVID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'WyK9pNDPRA',	},
    {	empleado:'429823',	email:'jcastillool@aeromexico.com',	nombre:'CASTILLO OLEA JESUS HECTOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'37PRfAj%Dj',	},
    {	empleado:'430223',	email:'lduranbe@aeromexico.com',	nombre:'DURAN BEDOLLA LUIS ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'p2Ypvtt@2p',	},
    {	empleado:'430323',	email:'marredondo@aeromexico.com',	nombre:'ARREDONDO BASURTO MONTSERRAT',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'H6MbbT&HF#',	},
    {	empleado:'430423',	email:'rmoralesp@aeromexico.com',	nombre:'MORALES POZO RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'4yNfPc1@bi',	},
    {	empleado:'430523',	email:'etorresbaz@aeromexico.com',	nombre:'TORRES BAZAN EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'eAYaNLj3kC',	},
    {	empleado:'430623',	email:'galvaradog@aeromexico.com',	nombre:'ALVARADO GAMBOA GIOVANNI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'FvWqvXK8#7',	},
    {	empleado:'430723',	email:'jmarquezs@aeromexico.com',	nombre:'MARQUEZ SERRANO JESUS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jU%79YMZ%N',	},
    {	empleado:'430823',	email:'jyunes@aeromexico.com',	nombre:'YUNES ESTUDILLO JOSE FRANCISCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Ew4cwtkZyk',	},
    {	empleado:'430923',	email:'jcuervos@aeromexico.com',	nombre:'CUERVO SANCHEZ JUAN JOSE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'p9NYNkL7uR',	},
    {	empleado:'431023',	email:'egarciaroj@aeromexico.com',	nombre:'GARCIA ROJAS ERIK MOISES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#wJ#v1d$75',	},
    {	empleado:'43189',	email:'friquelme@aeromexico.com',	nombre:'RIQUELME VILLARREAL FERNANDO RUBEN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'83wpZjNu1K',	},
    {	empleado:'4322',	email:'gicervantes@aeromexico.com',	nombre:'CERVANTES HERNANDEZ GILBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'uZM1pt1BEZ',	},
    {	empleado:'43289',	email:'pislashe@aeromexico.com',	nombre:'ISLAS HERNANDEZ PEDRO FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'vMnCJ!kUZb',	},
    {	empleado:'43523',	email:'lahuerta@aeromexico.com',	nombre:'HUERTA NAVARRO LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!RuM1rZyJW',	},
    {	empleado:'43589',	email:'sahernandezg@aeromexico.com',	nombre:'HERNANDEZ GARCIA SERGIO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'b@YnkFdV8$',	},
    {	empleado:'43723',	email:'assebastian@aeromexico.com',	nombre:'SEBASTIAN TORRES ALNAIR SALVADOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'1LeWGMZJ1A',	},
    {	empleado:'43789',	email:'cfradet@aeromexico.com',	nombre:'FRADE THOMASSINY CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'2%@T#8PW3c',	},
    {	empleado:'43923',	email:'chramos@aeromexico.com',	nombre:'RAMOS MARQUEZ CESAR HUMBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'uBDve5YAra',	},
    {	empleado:'441423',	email:'cvillarrealr@aeromexico.com',	nombre:'VILLARREAL RIANDER CESAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'53p7W&J$E4',	},
    {	empleado:'441623',	email:'rcintam@aeromexico.com',	nombre:'CINTA MORALES ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'nVAUPZrYJH',	},
    {	empleado:'441723',	email:'arenteriar@aeromexico.com',	nombre:'RENTERIA RODRIGUEZ ALEXIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6#NM$rZj1S',	},
    {	empleado:'441823',	email:'ddosal@aeromexico.com',	nombre:'DOSAL TERROBA DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZmMuQJdSaV',	},
    {	empleado:'441923',	email:'jgarciaz@aeromexico.com',	nombre:'GARCIA ZARAGOZA JOSE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6eBL&V1Z&b',	},
    {	empleado:'442123',	email:'fmorgante@aeromexico.com',	nombre:'MORGANTE GARMENDIA FABIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5T1UsPQ9yr',	},
    {	empleado:'4422',	email:'cabarrios@aeromexico.com',	nombre:'BARRIOS CUERVO CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'eMmam3pD8Z',	},
    {	empleado:'44223',	email:'mriveraz@aeromexico.com',	nombre:'RIVERA ZEPEDA MANUEL EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vk1bnR1x%Z',	},
    {	empleado:'442423',	email:'cmedellin@aeromexico.com',	nombre:'MEDELLIN MUNOZ CARLOS HUMBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'n6HbvkCQbQ',	},
    {	empleado:'44522',	email:'dcazares@aeromexico.com',	nombre:'CAZARES NUNEZ DAMIAN ABISAI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PZ1vHv7unZ',	},
    {	empleado:'44523',	email:'mmadrids@aeromexico.com',	nombre:'MADRID SANCHEZ MONTSERRAT',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dsKkhsdiX5',	},
    {	empleado:'44622',	email:'afarrugia@aeromexico.com',	nombre:'FARRUGIA SILVA ALLAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'MsVQnyD$KQ',	},
    {	empleado:'446922',	email:'rreynaa@aeromexico.com',	nombre:'REYNA ACOSTA RAFAEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ST$DDniDXV',	},
    {	empleado:'447122',	email:'jnavarrog@aeromexico.com',	nombre:'NAVARRO GONZALEZ JONATHAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'b%wpfNYJ9P',	},
    {	empleado:'44722',	email:'daquino@aeromexico.com',	nombre:'AQUINO RAMIREZ DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'DnzQ3SB21V',	},
    {	empleado:'447222',	email:'haguilera@aeromexico.com',	nombre:'AGUILERA AVILES HECTOR CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ru3RcBSZ6V',	},
    {	empleado:'447322',	email:'negarcia@aeromexico.com',	nombre:'GARCIA PEREZ NESTOR ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'rcZjNbD4JS',	},
    {	empleado:'44822',	email:'egonzalezca@aeromexico.com',	nombre:'GONZALEZ CASTRO EMILIO BERNABE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'d9RvYCDujS',	},
    {	empleado:'448623',	email:'camiranda@aeromexico.com',	nombre:'MIRANDA CAMARILLO CHRISTIAN ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'FinmpEA3HY',	},
    {	empleado:'44922',	email:'ccane@aeromexico.com',	nombre:'CANE AREVALO CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dfA&YdV$k@',	},
    {	empleado:'449318',	email:'javargas@aeromexico.com',	nombre:'VARGAS ONTIVEROS JOSE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'bZyVpXd@T8',	},
    {	empleado:'449418',	email:'jvalenciaf@aeromexico.com',	nombre:'VALENCIA FERNANDEZ JORGE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'KncgQ@Mk@9',	},
    {	empleado:'449523',	email:'glirav@aeromexico.com',	nombre:'LIRA VARELA GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9fAVuA!YZ1',	},
    {	empleado:'449623',	email:'lnajeral@aeromexico.com',	nombre:'NAJERA LUNA LUIS ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dvEvNR#B9Y',	},
    {	empleado:'449723',	email:'spenag@aeromexico.com',	nombre:'PENA GUTIERREZ SERGIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'13v&j#HX#C',	},
    {	empleado:'449923',	email:'mmarino@aeromexico.com',	nombre:'MARINO ARMENTA MANUEL ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wYHpCJJ!vQ',	},
    {	empleado:'450023',	email:'rarmentao@aeromexico.com',	nombre:'ARMENTA OLVERA RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'C1c5pnJAw&',	},
    {	empleado:'450123',	email:'jmoralesmel@aeromexico.com',	nombre:'MORALES MELENDEZ JORGE ARMANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'X2ZaMSW%jY',	},
    {	empleado:'450223',	email:'gmalo@aeromexico.com',	nombre:'MALO NUNO GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'uzJn4JNgcL',	},
    {	empleado:'450423',	email:'clopezna@aeromexico.com',	nombre:'LOPEZ NAVARRO CESAR EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dxCqniP5JR',	},
    {	empleado:'450523',	email:'hrivero@aeromexico.com',	nombre:'RIVERO BORRELL DE HOYOS HUGO JOAQUIN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zMHa!revnZ',	},
    {	empleado:'450723',	email:'dcardenasf@aeromexico.com',	nombre:'CARDENAS FLORES DAVID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Za6ZU!yHyJ',	},
    {	empleado:'450823',	email:'jpeniche@aeromexico.com',	nombre:'PENICHE FRANCO JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'DPuxA$e6ak',	},
    {	empleado:'450923',	email:'jmartind@aeromexico.com',	nombre:'MARTIN DEL CAMPO BORBOLLA JOSE MIGUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'pnp3DZs6i4',	},
    {	empleado:'45122',	email:'dquintanaz@aeromexico.com',	nombre:'QUINTANA ZAMORA DIANA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'$kzmkh&5bw',	},
    {	empleado:'451223',	email:'fgarciame@aeromexico.com',	nombre:'GARCIA MENDICUTTI FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'TQKg8gUkHZ',	},
    {	empleado:'451323',	email:'lmondragon@aeromexico.com',	nombre:'MONDRAGON RAMIREZ LUIS ALFREDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'iPHH1YuHk1',	},
    {	empleado:'451423',	email:'fgomezo@aeromexico.com',	nombre:'GAMEZ OCHOA FRANCISCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Z9TGPQhvKq',	},
    {	empleado:'4522',	email:'ffballina@aeromexico.com',	nombre:'BALLINA VILLASENOR FERNANDO FRANCISCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'fEU9hsM1sc',	},
    {	empleado:'45222',	email:'jbobadillav@aeromexico.com',	nombre:'BOBADILLA VALENCIA JAIME ALEXANDER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZVt53MCEEH',	},
    {	empleado:'452522',	email:'ccontrerasc@aeromexico.com',	nombre:'CONTRERAS CAMERO CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JjvkByEfmk',	},
    {	empleado:'452622',	email:'isotona@aeromexico.com',	nombre:'SOTO NAVARRO IVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'QZqXZ15X&q',	},
    {	empleado:'452722',	email:'gjacinto@aeromexico.com',	nombre:'JACINTO VILCHIS GILBERTO XAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SrJXTykwQ1',	},
    {	empleado:'452822',	email:'bpalomareso@aeromexico.com',	nombre:'PALOMARES OROZCO BERNARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YgaaVL2mWk',	},
    {	empleado:'454208',	email:'hsantoscoy@aeromexico.com',	nombre:'SANTOSCOY OROZCO HUGO ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'6JJfk1kGmM',	},
    {	empleado:'454308',	email:'ecoloma@aeromexico.com',	nombre:'COLOMA MARGOLIES ERNESTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'TybiWeeAYY',	},
    {	empleado:'454408',	email:'mgaona@aeromexico.com',	nombre:'GAONA TORRES MARCO ANTONIO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'g$PDjDmqYF',	},
    {	empleado:'454508',	email:'dfflores@aeromexico.com',	nombre:'FLORES BENITEZ DAVID FABIAN',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'zSVp6PH5sP',	},
    {	empleado:'454608',	email:'nmora@aeromexico.com',	nombre:'MORA FRANCO NINO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'kjhc!@!kaH',	},
    {	empleado:'454708',	email:'mescamillag@aeromexico.com',	nombre:'ESCAMILLA GONZALEZ MARTIN GERARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'11CGXqS6N5',	},
    {	empleado:'454808',	email:'aortegaf@aeromexico.com',	nombre:'ORTEGA FISCHER AARON',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'xHHSh1cbJ1',	},
    {	empleado:'454908',	email:'mmunozg@aeromexico.com',	nombre:'MUNOZ GARCIA MARIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'sAXTabeW4Q',	},
    {	empleado:'455008',	email:'rconde@aeromexico.com',	nombre:'CONDE DOMINGUEZ ROBERTO ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'w8&49Jtqma',	},
    {	empleado:'455108',	email:'gsandoval@aeromexico.com',	nombre:'SANDOVAL BACHMAN GABRIEL BERK',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'Wpb7KRTE%A',	},
    {	empleado:'455308',	email:'sdepozo@aeromexico.com',	nombre:'DE POZO SANDOVAL SERGIO IVAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'VjXNB@&24k',	},
    {	empleado:'45722',	email:'gbautistar@aeromexico.com',	nombre:'BAUTISTA RAMIREZ GERARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Y&rKiNKwuD',	},
    {	empleado:'45822',	email:'acanteroc@aeromexico.com',	nombre:'CANTERO CASTILLO ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cjVzxen5p3',	},
    {	empleado:'4589',	email:'jmoralesta@aeromexico.com',	nombre:'MORALES TARDOS JULIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Kk#nmZXRGs',	},
    {	empleado:'45922',	email:'rsancheze@aeromexico.com',	nombre:'SANCHEZ ESCOBAR RAUL EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wpdZHf7bxW',	},
    {	empleado:'460024',	email:'luaranda@aeromexico.com',	nombre:'ARANDA ECHEVERRIA LUIS ENRIQUE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'kYGL!8t@k$',	},
    {	empleado:'460124',	email:'acapellino@aeromexico.com',	nombre:'CAPELLINO VILLEGAS ANGEL DANILO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6Utv1#Tr9K',	},
    {	empleado:'46020',	email:'mrubi@aeromexico.com',	nombre:'RUBI MANCILLA MARIANA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'c4Wb!Es&Yk',	},
    {	empleado:'46022',	email:'cvelazquezs@aeromexico.com',	nombre:'VELAZQUEZ SUAREZ CLAUDIO ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PJMLa3LAwp',	},
    {	empleado:'460224',	email:'mcastillod@aeromexico.com',	nombre:'CASTILLO DE LA HUERTA MAXIMILIANO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zBKGh$6#Y4',	},
    {	empleado:'460324',	email:'jescalantecas@aeromexico.com',	nombre:'ESCALANTE CASTRO JORGE ZAID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Y&R&p7X7hd',	},
    {	empleado:'460424',	email:'mgutierrezf@aeromexico.com',	nombre:'GUTIERREZ FARAH MARIA JOSE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zQjXNu7Ykk',	},
    {	empleado:'460524',	email:'xhernandez@aeromexico.com',	nombre:'HERNANDEZ CIREROL XIMENA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'gGzMaMLPXJ',	},
    {	empleado:'460624',	email:'ckneip@aeromexico.com',	nombre:'KNEIP GARCIA CARLOS ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'xFPBvDHHHU',	},
    {	empleado:'460724',	email:'fjmunoz@aeromexico.com',	nombre:'MUNOZ SUAREZ FRANCISCO JOSE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'d4Wv$qhXGs',	},
    {	empleado:'460824',	email:'aortizmart@aeromexico.com',	nombre:'ORTIZ MARTINEZ ALONZO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'HYLMKE5m&r',	},
    {	empleado:'460924',	email:'rpaz@aeromexico.com',	nombre:'PAZ SANCHEZ RIGOBERTO OSWALDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'w5hWqxaFLD',	},
    {	empleado:'461024',	email:'rgual@aeromexico.com',	nombre:'GUAL RUIZ ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'hVnkAD1qS8',	},
    {	empleado:'46120',	email:'ssepulveda@aeromexico.com',	nombre:'SEPULVEDA CHINCHILLAS SALVADOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZZi2ZpMZj%',	},
    {	empleado:'46122',	email:'evallef@aeromexico.com',	nombre:'VALLE FLORES ELIZ EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'T@GHPitap5',	},
    {	empleado:'46320',	email:'jposada@aeromexico.com',	nombre:'POSADA MEDRANO JOSE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZY4K3GDyQq',	},
    {	empleado:'464008',	email:'rromeros@aeromexico.com',	nombre:'ROMERO SALAZAR RENE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'9AuLSgTJhd',	},
    {	empleado:'464108',	email:'jgalonso@aeromexico.com',	nombre:'ALONSO TORRES JOSE GERARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'%sSPXcxecb',	},
    {	empleado:'46420',	email:'epleon@aeromexico.com',	nombre:'VILLA LEON ETEL PATRICIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'UN&BN6g6#b',	},
    {	empleado:'464208',	email:'frincon@aeromexico.com',	nombre:'RINCON YMAZ FEDERICO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'69DxJwTkfK',	},
    {	empleado:'464308',	email:'ycamunas@aeromexico.com',	nombre:'CAMUNAS ELIZONDO YESSICA YENDY',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'!&YyesJk5P',	},
    {	empleado:'464408',	email:'japaez@aeromexico.com',	nombre:'APAEZ TOSCANO JUAN ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'P6$TZcYdwk',	},
    {	empleado:'464508',	email:'jgarfias@aeromexico.com',	nombre:'GARFIAS TORAL JOSE ELISEO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZmxVGSQNPt',	},
    {	empleado:'464608',	email:'amelo@aeromexico.com',	nombre:'MELO QUEVEDO ADOLFO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'1nmLyndzav',	},
    {	empleado:'464708',	email:'mliceaga@aeromexico.com',	nombre:'LICEAGA CHAVIRA MIGUEL DE JESUS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'kr5q1RJMh2',	},
    {	empleado:'465008',	email:'rescobedo@aeromexico.com',	nombre:'ESCOBEDO AMEZCUA RICARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'J@ZT9LnD$1',	},
    {	empleado:'465108',	email:'irosasc@aeromexico.com',	nombre:'ROSAS CAMACHO IGNACIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'92BxA9gny8',	},
    {	empleado:'46520',	email:'lamonroy@aeromexico.com',	nombre:'MONROY MARTIN LIAM ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Jiq#cZmprL',	},
    {	empleado:'46620',	email:'bogarcia@aeromexico.com',	nombre:'GARCIA ARCEGA BENJAMIN OMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vp$stk9Cd#',	},
    {	empleado:'46720',	email:'jeceballos@aeromexico.com',	nombre:'CEBALLOS SERVIN JOSE EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6dPtqAYFG#',	},
    {	empleado:'46820',	email:'pcisneros@aeromexico.com',	nombre:'CISNEROS VILLA PEDRO ANDRES',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%whxLR!f6c',	},
    {	empleado:'469708',	email:'egonzalezz@aeromexico.com',	nombre:'GONZALEZ ZAGA EGAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ZAxphaSAzQ',	},
    {	empleado:'469808',	email:'jplascenciag@aeromexico.com',	nombre:'PLASCENCIA GUZMAN JAVAN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'RJ$s8U@Qcv',	},
    {	empleado:'470008',	email:'fcastrejon@aeromexico.com',	nombre:'CASTREJON ARMENDARIZ FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'9CYJ@#h$s3',	},
    {	empleado:'470108',	email:'rffuentes@aeromexico.com',	nombre:'FUENTES GONZALEZ RICARDO FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'b56BUQkc@p',	},
    {	empleado:'470208',	email:'avallejo@aeromexico.com',	nombre:'VALLEJO CHIAPA AGUSTIN',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'T@hA9s9nTM',	},
    {	empleado:'470308',	email:'jmora@aeromexico.com',	nombre:'MORA PEREZ JORGE EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Yh$hunQwkY',	},
    {	empleado:'470408',	email:'rmayor@aeromexico.com',	nombre:'MAYOR MUTZ RODOLFO',	rango:'OFI',	equipo:'787',	contrato:'A',	passConstra:'9s1%5s6S@e',	},
    {	empleado:'470508',	email:'rcarralp@aeromexico.com',	nombre:'CARRAL PEREZ RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'XktL!uksCH',	},
    {	empleado:'47090',	email:'ayannelli@aeromexico.com',	nombre:'YANNELLI SANCHEZ ALDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'cuLpb@!P6k',	},
    {	empleado:'4714',	email:'jelopezm@aeromexico.com',	nombre:'LOPEZ MARTINEZ JESUS FRANCISCO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'LCB9tS7uEG',	},
    {	empleado:'4722',	email:'mfvalencia@aeromexico.com',	nombre:'VALENCIA VARGAS MARIO FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'hzSuJ!B3$6',	},
    {	empleado:'47522',	email:'chall@aeromexico.com',	nombre:'HALL MONRAZ CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1pr6LTQxtf',	},
    {	empleado:'478917',	email:'cplascencia@aeromexico.com',	nombre:'PLASCENCIA NUNEZ CARLOS',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'12YrsELjHa',	},
    {	empleado:'483518',	email:'jamarquez@aeromexico.com',	nombre:'MARQUEZ ITURBIDE JAIME ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'pkn!X2GWK7',	},
    {	empleado:'484118',	email:'cgomezi@aeromexico.com',	nombre:'GOMEZ ITURBIDE CARLOS RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'!8PVj5pnZ$',	},
    {	empleado:'48621',	email:'jzapatav@aeromexico.com',	nombre:'ZAPATA VARGAS JANIN AMEYALLI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZeA$es1kJ%',	},
    {	empleado:'48699',	email:'jzetina@aeromexico.com',	nombre:'ZETINA LARA JORGE VICENTE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'YeuncfpRc!',	},
    {	empleado:'48821',	email:'avvargas@aeromexico.com',	nombre:'VARGAS BLASQUEZ ANGEL VICENTE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'wnCgbA#6ui',	},
    {	empleado:'48921',	email:'dsaldivar@aeromexico.com',	nombre:'SALDIVAR GARCIA DAVID ELIAS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1qn&iy#EVY',	},
    {	empleado:'489217',	email:'gvelazqueze@aeromexico.com',	nombre:'VELAZQUEZ ESPINO GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'E1nk&whi$N',	},
    {	empleado:'489417',	email:'hgual@aeromexico.com',	nombre:'GUAL RUIZ HUMBERTO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'ZT&X8LTcq$',	},
    {	empleado:'489617',	email:'cacorona@aeromexico.com',	nombre:'CORONA BOSCH CARLOS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'!8tjLC5WLY',	},
    {	empleado:'489717',	email:'rarivera@aeromexico.com',	nombre:'RIVERA RIVERA RAUL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'DP@J6j$Lin',	},
    {	empleado:'489917',	email:'mvmorales@aeromexico.com',	nombre:'MORALES HUERTA MARTHA VERONICA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Kbq&aq2G#@',	},
    {	empleado:'48999',	email:'rballina@aeromexico.com',	nombre:'BALLINA VELARDE RAMON',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'4%sfMd7yYL',	},
    {	empleado:'490017',	email:'maagonzalez@aeromexico.com',	nombre:'GONZALEZ GARCIA MARIO ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'dussx$WkRG',	},
    {	empleado:'49021',	email:'iaspe@aeromexico.com',	nombre:'ASPE JONES IAN ALESSANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'k$dFUJRUYN',	},
    {	empleado:'49099',	email:'ffigueroa@aeromexico.com',	nombre:'FIGUEROA ESCALANTE FRANCISCO BERNARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Z1k1ZNt&!J',	},
    {	empleado:'49121',	email:'rtmorales@aeromexico.com',	nombre:'MORALES CENTENO RICARDO TOMAS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'upqj4ZZiJZ',	},
    {	empleado:'4922',	email:'fgasperin@aeromexico.com',	nombre:'GASPERIN BRENIS FERNANDO',	rango:'OFI',	equipo:'',	contrato:'B',	passConstra:'FKc#KFeYCZ',	},
    {	empleado:'49221',	email:'canguiano@aeromexico.com',	nombre:'ANGUIANO CRISTOBAL CHRISTIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jQHFnMUS#U',	},
    {	empleado:'49299',	email:'elanda@aeromexico.com',	nombre:'LANDA BATULE EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'qLiHpsLyPJ',	},
    {	empleado:'49321',	email:'alrojasa@aeromexico.com',	nombre:'ROJAS AGUILAR ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'CuMjZxEZA9',	},
    {	empleado:'49399',	email:'ltrigo@aeromexico.com',	nombre:'TRIGO LUNA LUIS FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'WbnZM!n1w%',	},
    {	empleado:'49499',	email:'hmasetto@aeromexico.com',	nombre:'MASETTO CONTLA HUMBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'eJYfaT&ZZ1',	},
    {	empleado:'49599',	email:'cprieto@aeromexico.com',	nombre:'PRIETO GARZA CARLOS GERARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'J5YiptYxy3',	},
    {	empleado:'49721',	email:'gdiego@aeromexico.com',	nombre:'DIEGO LOPEZ GIOVANNI',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'zt62kXk3PG',	},
    {	empleado:'497723',	email:'dbetancourtv@aeromexico.com',	nombre:'BETANCOURT VARGAS DEBANHI LISSY',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'hNgM!4Mn$G',	},
    {	empleado:'49799',	email:'aflorese@aeromexico.com',	nombre:'FLORES ESCAMILLA ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'1JvF4ZknF%',	},
    {	empleado:'498818',	email:'gjpereda@aeromexico.com',	nombre:'PEREDA DELGADO GUILLERMO JEOVANNI',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'851&NZ#CCq',	},
    {	empleado:'499618',	email:'hrohde@aeromexico.com',	nombre:'ROHDE PENA HARTWIG RICARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%yZ8m2UFqu',	},
    {	empleado:'499718',	email:'ogonzalezc@aeromexico.com',	nombre:'GONZALEZ COTA OMAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'&uJpJ3kMtB',	},
    {	empleado:'5022',	email:'mmaza@aeromexico.com',	nombre:'MAZA REQUEJO MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'mmRCWJfFW@',	},
    {	empleado:'505223',	email:'ftellezb@aeromexico.com',	nombre:'TELLEZ BIRRUETE FRANCISCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dsFK7fn5ER',	},
    {	empleado:'505523',	email:'eleonh@aeromexico.com',	nombre:'LEON HERNANDEZ ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@yKJAcaDv#',	},
    {	empleado:'505823',	email:'jrojasp@aeromexico.com',	nombre:'ROJAS PUEBLA JORGE OMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GZ5ia!JngC',	},
    {	empleado:'506523',	email:'ldiazde@aeromexico.com',	nombre:'DIAZ DE LEON ALFONSIN LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'g2kU2ZmfJ$',	},
    {	empleado:'507523',	email:'jgomezme@aeromexico.com',	nombre:'GOMEZ MERAZ JORGE ARMANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'FZzkZP2WZ5',	},
    {	empleado:'50820',	email:'dmolinar@aeromexico.com',	nombre:'MOLINAR RODRIGUEZ DAVID GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'pns@aKa6ps',	},
    {	empleado:'508323',	email:'ecoronar@aeromexico.com',	nombre:'CORONA REYNA ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'$aZ@hR834N',	},
    {	empleado:'509123',	email:'jtrejog@aeromexico.com',	nombre:'TREJO GOMEZ JESUS ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6rCPPARhDk',	},
    {	empleado:'50920',	email:'fcelis@aeromexico.com',	nombre:'CELIS GALLARDO FRANCISCO JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6R%vXmfWQy',	},
    {	empleado:'51020',	email:'aolea@aeromexico.com',	nombre:'OLEA CASILLAS ANGELICA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'9L@5pb4k#t',	},
    {	empleado:'51120',	email:'scantero@aeromexico.com',	nombre:'CANTERO CASTILLO SERGIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'q!pQ1$cZ$T',	},
    {	empleado:'5122',	email:'jacevedod@aeromexico.com',	nombre:'ACEVEDO DOMINGUEZ JUAN ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'N$sf$5XhwB',	},
    {	empleado:'51220',	email:'hbello@aeromexico.com',	nombre:'BELLO ACOSTA HECTOR SEBASTIAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%g&sDB4e6b',	},
    {	empleado:'51320',	email:'mfcovarrubias@aeromexico.com',	nombre:'DIAZ COVARRUBIAS OLIVARES MARIA FERNANDA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SY%cvBTnKw',	},
    {	empleado:'51420',	email:'jrbarrera@aeromexico.com',	nombre:'BARRERA VARGAS JAVIER RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zPstLt5#eg',	},
    {	empleado:'51423',	email:'rcalvoc@aeromexico.com',	nombre:'CALVO CARRANZA RICARDO JOSHIMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SU5kTV#vZZ',	},
    {	empleado:'51523',	email:'fcortesg@aeromexico.com',	nombre:'CORTES GARCIA FERNANDO JOROAM',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'TAZQRebYZg',	},
    {	empleado:'51620',	email:'jsibarra@aeromexico.com',	nombre:'IBARRA MUNGUIA JUAN SAID',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'S73NwWRVdK',	},
    {	empleado:'51623',	email:'pvalencia@aeromexico.com',	nombre:'VALENCIA GONZALEZ PEDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'F71hVvTy1E',	},
    {	empleado:'51720',	email:'rvillalpando@aeromexico.com',	nombre:'VILLALPANDO PEREZ RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'eJYwDna2ic',	},
    {	empleado:'51723',	email:'rmercado@aeromexico.com',	nombre:'MERCADO AGUILAR RICARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'5AYnq&gmec',	},
    {	empleado:'51891',	email:'abojalil@aeromexico.com',	nombre:'BOJALIL RAMIREZ ANTONIO RICARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'&fNYp7ZFvZ',	},
    {	empleado:'51923',	email:'mmallen@aeromexico.com',	nombre:'MALLEN WIECHERS MANUEL EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'xrZiGn@nH&',	},
    {	empleado:'51991',	email:'acastellanos@aeromexico.com',	nombre:'CASTELLANOS REYES ALEJANDRO JOSE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'EPqZk7KH!j',	},
    {	empleado:'52091',	email:'ibarragan@aeromexico.com',	nombre:'BARRAGAN ORTEGA IGNACIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'6k7ZRYc@XF',	},
    {	empleado:'5222',	email:'hmendez@aeromexico.com',	nombre:'MENDEZ VILLAGRAN HUGO PEDRO GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'tHFdGP5Akk',	},
    {	empleado:'522322',	email:'gquintanaa@aeromexico.com',	nombre:'QUINTANA AMARO GERARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9ZF4&hx6fA',	},
    {	empleado:'522722',	email:'jvcarmona@aeromexico.com',	nombre:'CARMONA PATRON JOSE VICENTE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'bu@Jh$dN!Y',	},
    {	empleado:'522822',	email:'agarciame@aeromexico.com',	nombre:'GARCIA MENA ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZrUDEZSr98',	},
    {	empleado:'522922',	email:'ltico@aeromexico.com',	nombre:'TICO PIEDRAS LUIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'b@$1kYYR#m',	},
    {	empleado:'523022',	email:'jsotob@aeromexico.com',	nombre:'SOTO BORJA ALMEIDA JOSE ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'!d1&urbLVn',	},
    {	empleado:'523122',	email:'grodero@aeromexico.com',	nombre:'RODERO CABRERA GERARDO HUMBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Y1nV3&XBJm',	},
    {	empleado:'523222',	email:'ecamposs@aeromexico.com',	nombre:'CAMPOS SUAREZ DEL REAL ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Ph3zmpn1&K',	},
    {	empleado:'523322',	email:'jaguilarru@aeromexico.com',	nombre:'AGUILAR RUIZ JOSE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'w&ikstPx1P',	},
    {	empleado:'523522',	email:'acoca@aeromexico.com',	nombre:'COCA REYES ADRIAN ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zRLQYw4!uj',	},
    {	empleado:'523622',	email:'avargass@aeromexico.com',	nombre:'VARGAS SALMERON ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Z2V%PMZFBw',	},
    {	empleado:'523722',	email:'rcolado@aeromexico.com',	nombre:'COLADO PARTIDA RAMON DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'mQHvTSk#4L',	},
    {	empleado:'523822',	email:'cberuben@aeromexico.com',	nombre:'BERUBEN AGUILAR CRISTIAN JESUS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6ZpxsvVKMF',	},
    {	empleado:'524022',	email:'ddel@aeromexico.com',	nombre:'DEL VALLE LOPEZ VALLEJO DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PJ3AmDExbJ',	},
    {	empleado:'524122',	email:'vdelmoral@aeromexico.com',	nombre:'DEL MORAL ESTRADA VANESSA DIEPPE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'QVnP9t3g8e',	},
    {	empleado:'524222',	email:'pfabian@aeromexico.com',	nombre:'FABIAN QUI PABLO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'u!TCwh$%SZ',	},
    {	empleado:'524322',	email:'rcastanedaa@aeromexico.com',	nombre:'CASTANEDA ANAYA RAFAEL ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8rqxf@FDUE',	},
    {	empleado:'524422',	email:'vstangaciu@aeromexico.com',	nombre:'STANGACIU ABADIE VICENTE DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wWpxcVakny',	},
    {	empleado:'5322',	email:'cmadrigal@aeromexico.com',	nombre:'MADRIGAL BEJAR CARLOS MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Nt2kd#AaT5',	},
    {	empleado:'53590',	email:'jfaltamirano@aeromexico.com',	nombre:'ALTAMIRANO REYNOSO JORGE FRANCISCO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'wsDq1v446m',	},
    {	empleado:'539924',	email:'mcarmonao@aeromexico.com',	nombre:'CARMONA ORTEGA MANUEL ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#iz%anc43k',	},
    {	empleado:'540124',	email:'mvalenzuelab@aeromexico.com',	nombre:'VALENZUELA BARBA MARIANA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$niZZaYzZt',	},
    {	empleado:'540224',	email:'mvera@aeromexico.com',	nombre:'VERA DELGADILLO MARIA ESTHER',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$jZ2geJ8cu',	},
    {	empleado:'540424',	email:'dleon@aeromexico.com',	nombre:'LEON BRIONES DONOVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vjiHBR5yGR',	},
    {	empleado:'541723',	email:'gpadrona@aeromexico.com',	nombre:'PADRON ACUNA GONZALO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kCWTtw4j4p',	},
    {	empleado:'542023',	email:'fmedranog@aeromexico.com',	nombre:'MEDRANO GONZALEZ FRANCISCO JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'xQLtWXwuf&',	},
    {	empleado:'542192',	email:'pangulo@aeromexico.com',	nombre:'ANGULO DEL RIO PEDRO GERARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'BVi5nXjv4H',	},
    {	empleado:'542323',	email:'avillarn@aeromexico.com',	nombre:'VILLAR NEGRETE ADRIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vN&hs%HJwi',	},
    {	empleado:'542392',	email:'lreal@aeromexico.com',	nombre:'REAL GONZALEZ LUIS ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'BTJd#5WfRk',	},
    {	empleado:'542523',	email:'msanchezsa@aeromexico.com',	nombre:'SANCHEZ SANCHEZ MANUEL RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#UXGqdgbbb',	},
    {	empleado:'543023',	email:'jdavidsont@aeromexico.com',	nombre:'DAVIDSON TREJO JAMES DANIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'a##42tWJnn',	},
    {	empleado:'54315',	email:'jwalker@aeromexico.com',	nombre:'WALKER LECHON JONATHAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Mjp&nJ@dZY',	},
    {	empleado:'544023',	email:'fsanz@aeromexico.com',	nombre:'SANZ ALMIRON FEDERICO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'3!ZXRZbZwR',	},
    {	empleado:'54415',	email:'rjmoran@aeromexico.com',	nombre:'MORAN VERGARA RAMON JASSEN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'wPEyynHcAv',	},
    {	empleado:'544323',	email:'lmartineznu@aeromexico.com',	nombre:'MARTINEZ NUNO LUZ MARIA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'PEnhwauNHe',	},
    {	empleado:'544623',	email:'rcentenogu@aeromexico.com',	nombre:'CENTENO GUTIERREZ ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'AteqsD&TJt',	},
    {	empleado:'544823',	email:'pvieyra@aeromexico.com',	nombre:'VIEYRA PINCEL PAVEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'YyJDJ1Ue49',	},
    {	empleado:'544923',	email:'lgomezhe@aeromexico.com',	nombre:'GOMEZ HERNANDEZ LUIS MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'NJ3CXMkEbn',	},
    {	empleado:'545023',	email:'fquirozr@aeromexico.com',	nombre:'QUIROZ ROJAS FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'DJHrCkt$Jm',	},
    {	empleado:'545123',	email:'aramoss@aeromexico.com',	nombre:'RAMOS SANCHEZ ANDRES ARCADIO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'41LqUZ#B1h',	},
    {	empleado:'545223',	email:'cnavag@aeromexico.com',	nombre:'NAVA GARCIA CARLOS MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yZMCcYYTf6',	},
    {	empleado:'54615',	email:'rrenteria@aeromexico.com',	nombre:'RENTERIA MENDEZ RUBEN FERNANDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1Yf6MSnZsK',	},
    {	empleado:'54715',	email:'arnavarro@aeromexico.com',	nombre:'NAVARRO ROMERO ARY RENE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'jWLf#MziJW',	},
    {	empleado:'54815',	email:'jquijano@aeromexico.com',	nombre:'QUIJANO MARTINEZ JORGE',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'wZ4QPqJJVZ',	},
    {	empleado:'54915',	email:'gmorenog@aeromexico.com',	nombre:'MORENO GASS GERARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'T7D3Tx2ZJ!',	},
    {	empleado:'550292',	email:'jlopezroj@aeromexico.com',	nombre:'LOPEZ ROJAS JORGE EDUARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'#wD7VZU1U@',	},
    {	empleado:'550392',	email:'fdickinson@aeromexico.com',	nombre:'DICKINSON CALDERON FRANCISCO JESUS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'&x7WQAnHq1',	},
    {	empleado:'5522',	email:'lpineda@aeromexico.com',	nombre:'PINEDA SALAZAR LUIS MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'z#iCzZydJc',	},
    {	empleado:'55690',	email:'emayet@aeromexico.com',	nombre:'MAYETT MORENO ERIC',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'N!tK2a1MLj',	},
    {	empleado:'563322',	email:'dpasquel@aeromexico.com',	nombre:'PASQUEL GARCIA DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'s@xy%RUe11',	},
    {	empleado:'563422',	email:'rmendizabal@aeromexico.com',	nombre:'MENDIZABAL OSORIO RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZdCyVrhXBk',	},
    {	empleado:'563622',	email:'lgarciapr@aeromexico.com',	nombre:'GARCIA PRIETO LUIS EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'zn7wnMYsAF',	},
    {	empleado:'563922',	email:'smontano@aeromexico.com',	nombre:'MONTANO FERNANDEZ SERGIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'eHV@CyVJxR',	},
    {	empleado:'563992',	email:'fguadarramas@aeromexico.com',	nombre:'GUADARRAMA SISTOS FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'WsZ@FhdLQN',	},
    {	empleado:'564092',	email:'gavalencia@aeromexico.com',	nombre:'VALENCIA AVILA GUSTAVO ADOLFO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'rwiP21cWnH',	},
    {	empleado:'564122',	email:'gaoyama@aeromexico.com',	nombre:'AOYAMA VINCENT GUILLERMO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'788qJ6Znig',	},
    {	empleado:'564322',	email:'ajacobo@aeromexico.com',	nombre:'JACOBO FRAUSTO ANGEL TARIACURI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'hAvgJnrZ@n',	},
    {	empleado:'564522',	email:'ecruzg@aeromexico.com',	nombre:'CRUZ GUERRERO EDGAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'uZSTUX1e8v',	},
    {	empleado:'564722',	email:'alopezpi@aeromexico.com',	nombre:'LOPEZ PINZON ANGEL GABRIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9MZgHJ9t!K',	},
    {	empleado:'568623',	email:'mreyesgu@aeromexico.com',	nombre:'REYES GUERRERO MA TERESA VICTORIA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'mm8WZp6EJh',	},
    {	empleado:'568723',	email:'jreynag@aeromexico.com',	nombre:'REYNA GALINDO JOSE ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'nKsJYReZwh',	},
    {	empleado:'569692',	email:'mcanseco@aeromexico.com',	nombre:'CANSECO SERNA MARIA SUWANNY',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'$m&Sn&mCdY',	},
    {	empleado:'569792',	email:'jtapiaa@aeromexico.com',	nombre:'TAPIA AGUILAR JORGE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'BjiPSakc6R',	},
    {	empleado:'569992',	email:'lgraue@aeromexico.com',	nombre:'GRAUE OBSCURA LUIS ROBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ntSvrGKWZL',	},
    {	empleado:'570092',	email:'icarreon@aeromexico.com',	nombre:'CARREON RODRIGUEZ IGNACIO AGUSTIN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Bq6g$M5L5J',	},
    {	empleado:'570192',	email:'pmeixueiro@aeromexico.com',	nombre:'MEIXUEIRO VILLASENOR PABLO ALFONSO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'bZ7WKSuk1J',	},
    {	empleado:'570292',	email:'mgamoneda@aeromexico.com',	nombre:'GAMONEDA ACEVEDO MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'8tJwykPP3b',	},
    {	empleado:'570592',	email:'jhurtado@aeromexico.com',	nombre:'HURTADO Y DAGDA JUAN BERNARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Rrg7Ew7YY$',	},
    {	empleado:'57199',	email:'mbustamante@aeromexico.com',	nombre:'BUSTAMANTE QUIJANO MARTIN ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'bd@VNhA7HU',	},
    {	empleado:'5722',	email:'jrodriguezm@aeromexico.com',	nombre:'RODRIGUEZ MARTINEZ JOSE ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'UHL6%HHqs9',	},
    {	empleado:'57299',	email:'sccastaneda@aeromexico.com',	nombre:'CASTANEDA OJEDA SALVADOR CARLOS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'zSwJPjU&nA',	},
    {	empleado:'57399',	email:'aguizar@aeromexico.com',	nombre:'GUIZAR RAMIREZ ARTURO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'&VhXJ1!5cv',	},
    {	empleado:'575222',	email:'rperea@aeromexico.com',	nombre:'PEREA GENIS RAFAEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'bnRa8SWd33',	},
    {	empleado:'575422',	email:'agarciadea@aeromexico.com',	nombre:'GARCIA DE ALBA BERMEJO ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'pUZSAX%gh3',	},
    {	empleado:'575722',	email:'lcabildo@aeromexico.com',	nombre:'CABILDO ESQUIVEL LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JiJ3vqkPpB',	},
    {	empleado:'57599',	email:'afernandezdecastro@aeromexico.com',	nombre:'FERNANDEZ DE CASTRO CARDENAS ALEJANDRO XAVIER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Yt1E!w8GnZ',	},
    {	empleado:'576022',	email:'rgarciap@aeromexico.com',	nombre:'GARCIA PEREZ RUBEN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'EDZ@XptzH%',	},
    {	empleado:'576322',	email:'tregaladog@aeromexico.com',	nombre:'REGALADO GONZALEZ TOMAS DIEGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'mBM6$Bfb#z',	},
    {	empleado:'576522',	email:'bbeiles@aeromexico.com',	nombre:'BEILES GONZALEZ BELIVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'HbSFNZPxJQ',	},
    {	empleado:'57699',	email:'rlozanoa@aeromexico.com',	nombre:'LOZANO ANGLES ROBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'ygGRE9gcjK',	},
    {	empleado:'577623',	email:'aanayaca@aeromexico.com',	nombre:'ANAYA CAMARGO ARMANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1$2b4Ug1hZ',	},
    {	empleado:'577723',	email:'mrubiope@aeromexico.com',	nombre:'RUBIO PEREZ ULLOA MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'j2QTSP1ZgY',	},
    {	empleado:'578023',	email:'slopezmen@aeromexico.com',	nombre:'LOPEZ MENDEZ SERGIO MICHELLE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'dYkk&Afq!%',	},
    {	empleado:'578223',	email:'rosthofft@aeromexico.com',	nombre:'OSTHOFF TORRES RODOLFO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'PVm137Z#Z5',	},
    {	empleado:'578323',	email:'irimonm@aeromexico.com',	nombre:'RIMON MONSONEGO ISAAC',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'H1b!P!AM4c',	},
    {	empleado:'578423',	email:'jesquivelp@aeromexico.com',	nombre:'ESQUIVEL PEREZ JOSE ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'EDekP4S$Ag',	},
    {	empleado:'578523',	email:'dpatp@aeromexico.com',	nombre:'PAT PADILLA DANIEL IVAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JD92NkXJQH',	},
    {	empleado:'578623',	email:'lramosci@aeromexico.com',	nombre:'RAMOS CISNEROS LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JJYVXmE7BR',	},
    {	empleado:'578723',	email:'emolinac@aeromexico.com',	nombre:'MOLINA CRIVELLI EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#@V5S1aw99',	},
    {	empleado:'578823',	email:'agarciamun@aeromexico.com',	nombre:'GARCIA MUNGUIA AURELIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'3ws1$dk81i',	},
    {	empleado:'578923',	email:'estephang@aeromexico.com',	nombre:'STEPHAN GONZALEZ ERWIN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'6Jx%Ph3Lr7',	},
    {	empleado:'57899',	email:'jsanchezs@aeromexico.com',	nombre:'SANCHEZ SIFUENTES JAVIER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Yzc@B5Kjit',	},
    {	empleado:'579023',	email:'jaranari@aeromexico.com',	nombre:'ARANA RIVERA JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wYYQSy5X&7',	},
    {	empleado:'579123',	email:'mgonzalezjim@aeromexico.com',	nombre:'GONZALEZ JIMENEZ MIGUEL ANGEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'n1kbvvVJBL',	},
    {	empleado:'579223',	email:'fgarciacha@aeromexico.com',	nombre:'GARCIA CHAVEZ FERNANDO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'@@JkRzD!QQ',	},
    {	empleado:'57999',	email:'otorres@aeromexico.com',	nombre:'TORRES LEHMANN OTTO WERNER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'v@2ukRWQb1',	},
    {	empleado:'582592',	email:'rfranken@aeromexico.com',	nombre:'FRANKEN FLORES RICARDO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'FdwpKYXtZK',	},
    {	empleado:'59099',	email:'lnava@aeromexico.com',	nombre:'NAVA VILCHIS LUIS MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'K5U5ERphm3',	},
    {	empleado:'5922',	email:'rbayardo@aeromexico.com',	nombre:'BAYARDO GARCIA RIGOBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ia$sWnMd8Y',	},
    {	empleado:'601723',	email:'joropezam@aeromexico.com',	nombre:'OROPEZA MENDOZA JUAN MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZXqgrf$nKn',	},
    {	empleado:'601823',	email:'jvillicana@aeromexico.com',	nombre:'VILLICANA ROJAS JOEL ISAI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'uT8vZbyetZ',	},
    {	empleado:'601923',	email:'jlozanod@aeromexico.com',	nombre:'LOZANO DE ALVA JOSE PABLO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'n3jWBb@YYf',	},
    {	empleado:'601924',	email:'lsolano@aeromexico.com',	nombre:'SOLANO MEDINA LUIS ALFONSO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'7CJ#68cN7V',	},
    {	empleado:'602023',	email:'aguzmanb@aeromexico.com',	nombre:'GUZMAN BAEZ ADOLFO ENRIQUE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yvbd#CcLev',	},
    {	empleado:'602123',	email:'aalvarezvil@aeromexico.com',	nombre:'ALVAREZ VILLASENOR ALEXIS JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'euakYDXpZ3',	},
    {	empleado:'6022',	email:'fdiazb@aeromexico.com',	nombre:'DIAZ BARRIGA LOPEZ FERNANDA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'9afKv$kV%V',	},
    {	empleado:'602223',	email:'lmedinago@aeromexico.com',	nombre:'MEDINA GONZALEZ LUIS DAVID',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'My1TDR6wEb',	},
    {	empleado:'602323',	email:'gcamposv@aeromexico.com',	nombre:'CAMPOS VARGAS GUSTAVO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SqJ#v1hhEJ',	},
    {	empleado:'602423',	email:'jvegaf@aeromexico.com',	nombre:'VEGA FUENTES JOSE MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Q8dWQFH4Kv',	},
    {	empleado:'603323',	email:'jsanchezve@aeromexico.com',	nombre:'SANCHEZ VEYNA JUAN CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZUn61fBmzz',	},
    {	empleado:'603923',	email:'lcolli@aeromexico.com',	nombre:'COLLI GARZA LUIS ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vT6dPSNHur',	},
    {	empleado:'604323',	email:'jamezcua@aeromexico.com',	nombre:'AMEZCUA RAMIREZ JOSE GERARDO RAMON',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'kgguVgQprP',	},
    {	empleado:'604423',	email:'cgonzalezo@aeromexico.com',	nombre:'GONZALEZ OCHOA CESAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'UAYJPXtJFS',	},
    {	empleado:'604723',	email:'dregalado@aeromexico.com',	nombre:'REGALADO CALZADA DIEGO ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'FusD&eap&z',	},
    {	empleado:'605023',	email:'jfaz@aeromexico.com',	nombre:'FAZ IZAGUIRRE JOSE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'%pNBYEmw1#',	},
    {	empleado:'608823',	email:'fcamposmo@aeromexico.com',	nombre:'CAMPOS MORALES FRANCISCO JAVIER',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'vngpGcWQ$7',	},
    {	empleado:'608923',	email:'rsan@aeromexico.com',	nombre:'SAN GERMAN SALCEDO RODRIGO ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'DgZrCm$W21',	},
    {	empleado:'610492',	email:'arestrada@aeromexico.com',	nombre:'ESTRADA ALONSO ARTURO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3CPE@JHntX',	},
    {	empleado:'610592',	email:'jcortesgallardo@aeromexico.com',	nombre:'CORTES GALLARDO LARA JESUS ALFONSO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'$ZvD&ePnbD',	},
    {	empleado:'6122',	email:'dmonroyj@aeromexico.com',	nombre:'MONROY JIMENEZ DIEGO ANDRES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'epPvuZnQqX',	},
    {	empleado:'613492',	email:'jonegrete@aeromexico.com',	nombre:'NEGRETE MARTINEZ JORGE OCTAVIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'e1QCxzbL8w',	},
    {	empleado:'613592',	email:'mnegrete@aeromexico.com',	nombre:'NEGRETE MARTINEZ MAURICIO ARMANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'qik@wSDGgY',	},
    {	empleado:'613792',	email:'gdominguez@aeromexico.com',	nombre:'DOMINGUEZ FONSECA GERARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'GZTegkNHuG',	},
    {	empleado:'613823',	email:'aarreolab@aeromexico.com',	nombre:'ARREOLA BARRERA ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'RXsFnk5hZD',	},
    {	empleado:'614123',	email:'nlopeza@aeromexico.com',	nombre:'LOPEZ ANAYA NOE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'RZMDUZwLrz',	},
    {	empleado:'614723',	email:'ocorona@aeromexico.com',	nombre:'CORONA PENA OSVALDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JHLafZn8NW',	},
    {	empleado:'614823',	email:'abriceno@aeromexico.com',	nombre:'BRICENO DENIS ANDRES HUMBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Nsin9KudPM',	},
    {	empleado:'614923',	email:'cabadie@aeromexico.com',	nombre:'ABADIE SALAS CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ZN6tkJBYkm',	},
    {	empleado:'615023',	email:'jbuendial@aeromexico.com',	nombre:'BUENDIA LOYO JUAN CARLOS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'iwjMBbdYXx',	},
    {	empleado:'615123',	email:'omagana@aeromexico.com',	nombre:'MAGANA SOTO OSCAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'XR1w6TrjZJ',	},
    {	empleado:'615223',	email:'giannini@aeromexico.com',	nombre:'IANNINI LOPEZ GIAN FRANCO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'s8ZkZZZz19',	},
    {	empleado:'618992',	email:'mvaldes@aeromexico.com',	nombre:'VALDES RADERMACHER MARK THOMAS',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'2NZNPz4jC3',	},
    {	empleado:'619792',	email:'ftapiam@aeromexico.com',	nombre:'TAPIA MEDINA FRANCISCO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'SWBcdLrejK',	},
    {	empleado:'621924',	email:'avaldezm@aeromexico.com',	nombre:'VALDEZ MONROY AGUSTIN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Zs4DZBPN1W',	},
    {	empleado:'622024',	email:'jmendozab@aeromexico.com',	nombre:'MENDOZA BENITEZ JORGE ADRIAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'g1$Yc9Lsf1',	},
    {	empleado:'622124',	email:'mdominguezrod@aeromexico.com',	nombre:'DOMINGUEZ RODRIGUEZ MIGUEL ESTEBAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cLemSxXKQC',	},
    {	empleado:'622224',	email:'jmoragom@aeromexico.com',	nombre:'MORA GOMEZ JORGE EMILIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'GKb4n%GPQA',	},
    {	empleado:'622324',	email:'srubiope@aeromexico.com',	nombre:'RUBIO PEREZ ULLOA SANTIAGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'pqeYZtjj$1',	},
    {	empleado:'622424',	email:'ochavez@aeromexico.com',	nombre:'CHAVEZ PAREDES OMAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Ta5apJK8i9',	},
    {	empleado:'625092',	email:'rovalle@aeromexico.com',	nombre:'OVALLE RODRIGUEZ RAUL EDUARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'kCdRd9Pe8K',	},
    {	empleado:'625492',	email:'jcgutierrez@aeromexico.com',	nombre:'GUTIERREZ BORJA JORGE CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'g1bzX1BZEP',	},
    {	empleado:'625592',	email:'egamboa@aeromexico.com',	nombre:'GAMBOA AMORES EDGAR',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ukbBsT2Z%p',	},
    {	empleado:'62621',	email:'rdelaparra@aeromexico.com',	nombre:'DE LA PARRA CHAUL RAFAEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JELGmcf7aN',	},
    {	empleado:'62721',	email:'emvillegas@aeromexico.com',	nombre:'VILLEGAS PERLESTAIN EMMANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Jc@g4sZfDT',	},
    {	empleado:'635392',	email:'hcastro@aeromexico.com',	nombre:'CASTRO MELGAREJO HECTOR JOSE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Uh6#2ZzDAY',	},
    {	empleado:'635792',	email:'amalvarez@aeromexico.com',	nombre:'ALVAREZ DURAN ARTURO MARTIN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'LwQYZE6ZJg',	},
    {	empleado:'635992',	email:'jlmora@aeromexico.com',	nombre:'MORA VEGA JOSE LUIS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'EvN2hYX4!Q',	},
    {	empleado:'65891',	email:'rpasquel@aeromexico.com',	nombre:'PASQUEL GUERRERO RAMON',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'RJezz!yr3a',	},
    {	empleado:'66091',	email:'fdehaas@aeromexico.com',	nombre:'DE HAAS LE CLERCQ FRANCISCUS FERDINAND',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'dYrWNYa2Jb',	},
    {	empleado:'66591',	email:'edelatorre@aeromexico.com',	nombre:'DE LA TORRE CONTRERAS EDMUNDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3vZFM7RLUZ',	},
    {	empleado:'666024',	email:'mavila@aeromexico.com',	nombre:'AVILA JARAMILLO MARCELO ABELARDO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ieY%sAJJ4M',	},
    {	empleado:'66991',	email:'ielizarraras@aeromexico.com',	nombre:'ELIZARRARAS DAVILA IGNACIO ERNESTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'J#3@JHYBpf',	},
    {	empleado:'67391',	email:'juyanez@aeromexico.com',	nombre:'YANEZ MALDONADO JUAN JOSE ENRIQUE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'4CiM9Rs2NP',	},
    {	empleado:'68290',	email:'gnieto@aeromexico.com',	nombre:'NIETO ALTAMIRANO GUILLERMO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'L1kbwJ&Z$L',	},
    {	empleado:'69190',	email:'rreal@aeromexico.com',	nombre:'REAL GONZALEZ RAUL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'xmLnZ6Y9M1',	},
    {	empleado:'692824',	email:'fsilvas@aeromexico.com',	nombre:'SILVA SANCHEZ FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'K#B5gXMw8X',	},
    {	empleado:'692924',	email:'khashimotob@aeromexico.com',	nombre:'HASHIMOTO BAUTISTA KYOSHI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'k3KH&ZSJ3C',	},
    {	empleado:'693024',	email:'kfuad@aeromexico.com',	nombre:'FUAD MADRIGAL KIMBERLY',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'pH2PjJsKER',	},
    {	empleado:'693124',	email:'jortegae@aeromexico.com',	nombre:'ORTEGA ESTRADA JOSE RAMON',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'gL7EKLTa@j',	},
    {	empleado:'69317',	email:'oevazquez@aeromexico.com',	nombre:'VAZQUEZ GONZALEZ OSCAR EDUARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'hvY1YDj@1A',	},
    {	empleado:'693224',	email:'drojasji@aeromexico.com',	nombre:'ROJAS JIMENEZ DIEGO OMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'CckKvea$xA',	},
    {	empleado:'693324',	email:'jlazcano@aeromexico.com',	nombre:'LAZCANO BOLANOS JULIETA LOURDES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'8YNgJJiBDY',	},
    {	empleado:'693424',	email:'fbarrerag@aeromexico.com',	nombre:'BARRERA GONZALEZ FRANCISCO RAUL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Gc@Ghskvdn',	},
    {	empleado:'69417',	email:'lpioli@aeromexico.com',	nombre:'PIOLI QUERO LUCIANO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'$xJmcKUHcD',	},
    {	empleado:'695024',	email:'ehernandezma@aeromexico.com',	nombre:'HERNANDEZ MARTINEZ ERVING RENE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'cKSzNYFHM1',	},
    {	empleado:'69617',	email:'ssalazarn@aeromexico.com',	nombre:'SALAZAR NIETO SCARLETT',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'2W7mZn5CcT',	},
    {	empleado:'69917',	email:'afloresb@aeromexico.com',	nombre:'FLORES BENITEZ ADRIAN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'#BEx72AZY@',	},
    {	empleado:'70117',	email:'mmoralesma@aeromexico.com',	nombre:'MORALES MARTINEZ MARIO ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1JfrtByh@Z',	},
    {	empleado:'70217',	email:'sgcisneros@aeromexico.com',	nombre:'CISNEROS VILLA SERGIO GUILLERMO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'A5SKc2V!7Z',	},
    {	empleado:'70317',	email:'jsrosas@aeromexico.com',	nombre:'ROSAS OROZCO JUAN SALVADOR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'YA4HFa#t69',	},
    {	empleado:'70417',	email:'reherrera@aeromexico.com',	nombre:'HERRERA ZETINA RAMON ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'a7GJwYcvQ1',	},
    {	empleado:'70489',	email:'rosanchezr@aeromexico.com',	nombre:'SANCHEZ RAMIREZ ROGELIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'1RJD7Kb7q4',	},
    {	empleado:'70517',	email:'rrmoreno@aeromexico.com',	nombre:'MORENO EXZACARIAS ROBINSON RENE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'k1qdf6c3rD',	},
    {	empleado:'70617',	email:'lgallardo@aeromexico.com',	nombre:'GALLARDO GONZALEZ LORENA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'kZmYnbbTZm',	},
    {	empleado:'70717',	email:'ohurtado@aeromexico.com',	nombre:'HURTADO GERMANN OSCAR MAURICIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'%vYC17mFxQ',	},
    {	empleado:'72719',	email:'salvarado@aeromexico.com',	nombre:'ALVARADO GINER SELENE ABIGAIL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'GizNjFrz4v',	},
    {	empleado:'72819',	email:'cachavezb@aeromexico.com',	nombre:'CHAVEZ BAYARDO CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'S6hKSP8ARB',	},
    {	empleado:'73019',	email:'bboada@aeromexico.com',	nombre:'BOADA DE ATELA BEGONA ARANTZA',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'JGsWTGzxac',	},
    {	empleado:'73119',	email:'agomezi@aeromexico.com',	nombre:'GOMEZ ITURBIDE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'ukbEZ9w#PB',	},
    {	empleado:'73219',	email:'avilla@aeromexico.com',	nombre:'VILLA LEON ARTURO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'d2C6&#kvBi',	},
    {	empleado:'746124',	email:'epadron@aeromexico.com',	nombre:'PADRON SERAFIN EFRAIN ALEXIS',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'R1iyV8u5Zh',	},
    {	empleado:'750124',	email:'econtrerasgu@aeromexico.com',	nombre:'CONTRERAS GUZMAN EMILIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'62#JM4b5X7',	},
    {	empleado:'750224',	email:'scarreon@aeromexico.com',	nombre:'CARREON CARDENAS SEBASTIAN IGNACIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'U1nQmQ1%Ap',	},
    {	empleado:'750324',	email:'jruizco@aeromexico.com',	nombre:'RUIZ COBIAN JOSE LUIS',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'y1uJdnGNPa',	},
    {	empleado:'750424',	email:'asalast@aeromexico.com',	nombre:'SALAS TREJO ALEXIS URIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'Wnxgw2d3U5',	},
    {	empleado:'750524',	email:'jortegape@aeromexico.com',	nombre:'ORTEGA PEREZ JORGE ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'7kiU9#raXk',	},
    {	empleado:'750624',	email:'saranaga@aeromexico.com',	nombre:'ARANA GALLARDO SERGIO GABRIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'qn56kEz7Cb',	},
    {	empleado:'76614',	email:'mavera@aeromexico.com',	nombre:'VERA ALMARAZ MIGUEL ANGEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'UBE@aUU!PD',	},
    {	empleado:'79522',	email:'ebravod@aeromexico.com',	nombre:'BRAVO DURAN EMILIANO SALVADOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'JYiWiuC@Zy',	},
    {	empleado:'79622',	email:'jdiazj@aeromexico.com',	nombre:'DIAZ JIMENEZ JOSE ANTONIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#iJ9UxWwTz',	},
    {	empleado:'79700',	email:'rdromero@aeromexico.com',	nombre:'ROMERO DUARTE ROBIN DANIEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'AxxnT7%n8Y',	},
    {	empleado:'80100',	email:'earamos@aeromexico.com',	nombre:'RAMOS OBREGON ERICK ALEJANDRO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'THkvL&qgjr',	},
    {	empleado:'80222',	email:'eceronr@aeromexico.com',	nombre:'CERON RAMIREZ ELI',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'U1tmtCXSJZ',	},
    {	empleado:'80322',	email:'gescarcega@aeromexico.com',	nombre:'ESCARCEGA RAMIREZ GUSTAVO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'#JRnd9N6tE',	},
    {	empleado:'80422',	email:'ytavera@aeromexico.com',	nombre:'TAVERA PALMA YABEL EDIEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'j!7n9vGYH7',	},
    {	empleado:'811496',	email:'rcancino@aeromexico.com',	nombre:'CANCINO SOLORZANO RAFAEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'#qA1K#eXks',	},
    {	empleado:'811796',	email:'rhoyos@aeromexico.com',	nombre:'HOYOS CARRASCO ROBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'sTeZTAntxF',	},
    {	empleado:'811896',	email:'opulido@aeromexico.com',	nombre:'PULIDO ALABARDA OCTAVIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'YgkG23PnkA',	},
    {	empleado:'811996',	email:'fherreral@aeromexico.com',	nombre:'HERRERA LA RUE FRANCISCO MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'XZ1cn$YABZ',	},
    {	empleado:'812196',	email:'tegarcia@aeromexico.com',	nombre:'GARCIA GOMEZ TLALOC ENRIQUE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'yhf6haR%w$',	},
    {	empleado:'812296',	email:'jlopezg@aeromexico.com',	nombre:'LOPEZ GAMAS JORGE RICARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'cb324zxKs&',	},
    {	empleado:'826597',	email:'aperezm@aeromexico.com',	nombre:'PEREZ MARTINEZ AGUSTIN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'cbzecP%LYN',	},
    {	empleado:'826797',	email:'lvargas@aeromexico.com',	nombre:'VARGAS CABRERA LINO FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'jKk#Wn@#Y@',	},
    {	empleado:'826997',	email:'myvera@aeromexico.com',	nombre:'VERA ARAUJO MARTHA YOLANDA',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'amTgH2V6F!',	},
    {	empleado:'829397',	email:'rcenteno@aeromexico.com',	nombre:'CENTENO ULIN ROBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'nMv79BQDkA',	},
    {	empleado:'829797',	email:'japosada@aeromexico.com',	nombre:'POSADA WONG JOSE ALBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ZeJBJmJ2X9',	},
    {	empleado:'829997',	email:'magonzaleza@aeromexico.com',	nombre:'GONZALEZ AGUILERA MARIO ALBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'vJi96@uMz7',	},
    {	empleado:'835597',	email:'ocastaneda@aeromexico.com',	nombre:'CASTANEDA CASTILLO OSCAR ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'uGd53UkU4N',	},
    {	empleado:'835897',	email:'lpadilla@aeromexico.com',	nombre:'PADILLA MORENO LUIS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ze%JgLeGuS',	},
    {	empleado:'836097',	email:'hraguilera@aeromexico.com',	nombre:'AGUILERA GUTIERREZ HECTOR RAUL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'1RcVWdZ7C!',	},
    {	empleado:'84115',	email:'elongoria@aeromexico.com',	nombre:'LONGORIA GUTIERREZ ERICK',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Hwch1ufSUR',	},
    {	empleado:'84315',	email:'caruiz@aeromexico.com',	nombre:'RUIZ RODEA CARLOS ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'AUgdNjAeHd',	},
    {	empleado:'843697',	email:'jacevedo@aeromexico.com',	nombre:'ACEVEDO MARTINEZ JAVIER',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'hbUK8pkmkT',	},
    {	empleado:'843797',	email:'lovando@aeromexico.com',	nombre:'OVANDO FIGUEROA LUIS ENRIQUE',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Y6R8jSJkZ!',	},
    {	empleado:'843897',	email:'alobato@aeromexico.com',	nombre:'LOBATO PLASCENCIA ADOLFO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'gGD1&YZAPT',	},
    {	empleado:'843997',	email:'mialvarez@aeromexico.com',	nombre:'ALVAREZ TORRES MIGUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'m1&Zyez6vT',	},
    {	empleado:'844097',	email:'aortegac@aeromexico.com',	nombre:'ORTEGA CHAVEZ ADOLFO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'RY1@hKZ1XQ',	},
    {	empleado:'84415',	email:'grojas@aeromexico.com',	nombre:'ROJAS ORTIZ GERMAN GENARO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Z@H8GAZYzZ',	},
    {	empleado:'844197',	email:'soceguera@aeromexico.com',	nombre:'OCEGUERA MARTINEZ SALVADOR',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'xtJ&L2YcXd',	},
    {	empleado:'846797',	email:'gvelasco@aeromexico.com',	nombre:'VELASCO PEREZ GUILLERMO CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'%n1R@MYJp1',	},
    {	empleado:'846997',	email:'cortegab@aeromexico.com',	nombre:'ORTEGA BONAVIDES CESAR',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ZvFdw9md11',	},
    {	empleado:'847097',	email:'qamezcua@aeromexico.com',	nombre:'AMEZCUA MENDEZ QUIRINO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'@623Rqcnkj',	},
    {	empleado:'85300',	email:'mmooser@aeromexico.com',	nombre:'MOOSER TAUCHERT MICHAEL WALTER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'V4br%q#n2r',	},
    {	empleado:'85600',	email:'smarron@aeromexico.com',	nombre:'MARRON GARRIDO SERGIO MODESTO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'1JL164T5KZ',	},
    {	empleado:'85700',	email:'scortesb@aeromexico.com',	nombre:'CORTES BALLINA SERGIO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Z%t!dUyJ7Z',	},
    {	empleado:'859397',	email:'rarrieta@aeromexico.com',	nombre:'ARRIETA RODRIGUEZ RAMIRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'pRTmR3hmFp',	},
    {	empleado:'859897',	email:'avalverde@aeromexico.com',	nombre:'VALVERDE MOJICA ALEJANDRO FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'y7gdmSvJ&!',	},
    {	empleado:'872897',	email:'galatriste@aeromexico.com',	nombre:'ALATRISTE MOLINA GEUDIEL EDUARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'LY5JJRPiSn',	},
    {	empleado:'872997',	email:'rriveraf@aeromexico.com',	nombre:'RIVERA FERNANDEZ RAUL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ngv&xk&nqY',	},
    {	empleado:'873097',	email:'emogueld@aeromexico.com',	nombre:'MOGUEL DUARTE EDWIN NAZUL BICAEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'eDR1RkZ@x2',	},
    {	empleado:'873197',	email:'dmorenom@aeromexico.com',	nombre:'MORENO MURILLO DAVID',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'fZkkJ3DcLm',	},
    {	empleado:'884197',	email:'jyannelli@aeromexico.com',	nombre:'YANNELLI HERNANDO JOSE BLASS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'$7%UU#42iR',	},
    {	empleado:'884397',	email:'rpascalis@aeromexico.com',	nombre:'PASCALIS SOLORZANO RENE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'J#j@t$Cabk',	},
    {	empleado:'884697',	email:'jtobias@aeromexico.com',	nombre:'TOBIAS SONSINO JAIME',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'%!H7AS&6ZF',	},
    {	empleado:'892897',	email:'abuenfil@aeromexico.com',	nombre:'BUENFIL MARTINEZ ARTURO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'&DVPE2Za!Z',	},
    {	empleado:'892997',	email:'vrgallegos@aeromexico.com',	nombre:'GALLEGOS SALAZAR VICTOR RAFAEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'GZPs%knr67',	},
    {	empleado:'893097',	email:'mhernandezg@aeromexico.com',	nombre:'HERNANDEZ GARCIA MANUEL ABELARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'KnMBC1PN6i',	},
    {	empleado:'893297',	email:'avelascop@aeromexico.com',	nombre:'VELASCO PEREZ ARMANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'yKy9VBrY82',	},
    {	empleado:'896397',	email:'allopez@aeromexico.com',	nombre:'LOPEZ REYES ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'D!Q2G#WtaZ',	},
    {	empleado:'897097',	email:'jcamezcua@aeromexico.com',	nombre:'AMEZCUA MENDEZ JUAN CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'r2aUdjPnqs',	},
    {	empleado:'897197',	email:'aterroba@aeromexico.com',	nombre:'TERROBA TALAMANTES ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'hVZks7M3e8',	},
    {	empleado:'897397',	email:'mcruzb@aeromexico.com',	nombre:'CRUZ BECERRIL MAURICIO ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'bkqkb1V@tn',	},
    {	empleado:'901297',	email:'magaona@aeromexico.com',	nombre:'GAONA TORRES MARTHA ALICIA',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'6p3fk&WJU1',	},
    {	empleado:'909397',	email:'cvalverde@aeromexico.com',	nombre:'VALVERDE HOYOS CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'747YZF1Wxz',	},
    {	empleado:'909497',	email:'jmpadilla@aeromexico.com',	nombre:'PADILLA VILLARREAL JUAN MAURICIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'EqTM666QKb',	},
    {	empleado:'909597',	email:'aberumen@aeromexico.com',	nombre:'BERUMEN SALGADO ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'PXUhDzJYGs',	},
    {	empleado:'909897',	email:'lclaro@aeromexico.com',	nombre:'CLARO REYES LEONEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Q8ev8jbTB1',	},
    {	empleado:'909997',	email:'rortizc@aeromexico.com',	nombre:'ORTIZ CONTRERAS REYES',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'PWYz!T!KqZ',	},
    {	empleado:'910097',	email:'jimiranda@aeromexico.com',	nombre:'MIRANDA VICTORIA JESUS IGNACIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'vf2batc$Z9',	},
    {	empleado:'910197',	email:'rigonzalez@aeromexico.com',	nombre:'GONZALEZ MUIR RAUL IAN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'sXa%6@9ZZE',	},
    {	empleado:'912598',	email:'fborja@aeromexico.com',	nombre:'BORJA MIER FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ng%!ZuB9sr',	},
    {	empleado:'912698',	email:'rtovarv@aeromexico.com',	nombre:'TOVAR VARGAS RAUL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'ZE6Y1Zbu!5',	},
    {	empleado:'912798',	email:'epons@aeromexico.com',	nombre:'PONS CASTILLO EDUARDO GERMAN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'BxBdz1AT9N',	},
    {	empleado:'913398',	email:'epelaez@aeromexico.com',	nombre:'PELAEZ HURTADO EZEQUIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'bTzYhkw6@f',	},
    {	empleado:'913498',	email:'ediazv@aeromexico.com',	nombre:'DIAZ VIQUEZ EDUARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'bmcy57ZnK$',	},
    {	empleado:'913698',	email:'egomez@aeromexico.com',	nombre:'GOMEZ REYES EDGAR ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Z1YVw9Le8R',	},
    {	empleado:'913798',	email:'jmartinezsotomayor@aeromexico.com',	nombre:'MARTINEZ SOTOMAYOR VERDUZCO JORGE GERARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'MaA&&XH@Z&',	},
    {	empleado:'91814',	email:'fleong@aeromexico.com',	nombre:'LEON GONZALEZ FERMIN',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Z$n2Qeezkt',	},
    {	empleado:'91914',	email:'cbandin@aeromexico.com',	nombre:'BANDIN OSORIO CARLOS ALEJANDRO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'AJwcsRQSVp',	},
    {	empleado:'919898',	email:'damieva@aeromexico.com',	nombre:'AMIEVA REGUERA DAVID',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'QcnskdgZuy',	},
    {	empleado:'92014',	email:'gagarcia@aeromexico.com',	nombre:'GARCIA SERRANO GUSTAVO ADOLFO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'yw3ax1Aehs',	},
    {	empleado:'920998',	email:'ffrade@aeromexico.com',	nombre:'FRADE THOMASSINY FRANCISCO JAVIER',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'@Zf!fDBT2b',	},
    {	empleado:'921098',	email:'fdominguezm@aeromexico.com',	nombre:'DOMINGUEZ MORA FRANCISCO JAVIER',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'bQbDg#UDD2',	},
    {	empleado:'92114',	email:'fefernandez@aeromexico.com',	nombre:'FERNANDEZ DE LA VEGA SANCHEZ FRANCISCO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'k1$X5ULZyE',	},
    {	empleado:'921198',	email:'cviruete@aeromexico.com',	nombre:'VIRUETE HERNANDEZ CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'cfDPuDUM3c',	},
    {	empleado:'921298',	email:'fdavalos@aeromexico.com',	nombre:'DAVALOS MONROY FERNANDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'PHmJW$ZnDv',	},
    {	empleado:'92214',	email:'rvillicana@aeromexico.com',	nombre:'VILLICANA CASTREJON RODRIGO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'HmAsX!tjJ!',	},
    {	empleado:'922698',	email:'jrodriguezt@aeromexico.com',	nombre:'RODRIGUEZ TORRES JUAN ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'$2HjZxaMn5',	},
    {	empleado:'922798',	email:'jbarroso@aeromexico.com',	nombre:'BARROSO VALDIVIA JOSE ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'QSCFv$JNKz',	},
    {	empleado:'922898',	email:'jpdominguez@aeromexico.com',	nombre:'DOMINGUEZ MORA JUAN PABLO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'&JQ41ua4Yv',	},
    {	empleado:'922998',	email:'agarfias@aeromexico.com',	nombre:'GARFIAS ARRIOLA ALEJANDRO RAFAEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'DN2YnMneDK',	},
    {	empleado:'923098',	email:'agonzalezp@aeromexico.com',	nombre:'GONZALEZ PINTO ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'G1nG%&qA6K',	},
    {	empleado:'92314',	email:'jarjona@aeromexico.com',	nombre:'ARJONA RUIZ JOAQUIN',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'!8K6hmJJsL',	},
    {	empleado:'923198',	email:'ogfernandez@aeromexico.com',	nombre:'FERNANDEZ LOPEZ OSCAR GERARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'V&5MA7iZ16',	},
    {	empleado:'923298',	email:'lvazquez@aeromexico.com',	nombre:'VAZQUEZ GUTIERREZ LEONARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'&9vreSfPvZ',	},
    {	empleado:'923398',	email:'golvera@aeromexico.com',	nombre:'OLVERA MORALES GERONIMO ALBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'j79WH%iJHZ',	},
    {	empleado:'923798',	email:'wraya@aeromexico.com',	nombre:'RAYA CASILLAS WILHELM VON',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'x5BYiuYnCT',	},
    {	empleado:'924198',	email:'bfernandez@aeromexico.com',	nombre:'FERNANDEZ ROA BERNARDO MAURICIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'DVJvws1mWr',	},
    {	empleado:'924298',	email:'rmaier@aeromexico.com',	nombre:'MAIER LEON RICARDO ALFREDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'dn&g5JmDkP',	},
    {	empleado:'924398',	email:'cmrodriguez@aeromexico.com',	nombre:'RODRIGUEZ GARCIA CARLOS MANUEL',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'kP8$nZ1isK',	},
    {	empleado:'924498',	email:'malvarezt@aeromexico.com',	nombre:'ALVAREZ TORRES MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'$Lcuy6fZ9t',	},
    {	empleado:'924598',	email:'jprodriguez@aeromexico.com',	nombre:'RODRIGUEZ ESTOLANO JUAN PABLO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'RfjEE!ue%9',	},
    {	empleado:'924698',	email:'cmanzanilla@aeromexico.com',	nombre:'MANZANILLA VALDEZ CARLOS ARTURO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'whLZDiJkTN',	},
    {	empleado:'924798',	email:'bdelao@aeromexico.com',	nombre:'DE LA O MENDOZA BRAULIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'AMRkqUyGSZ',	},
    {	empleado:'924898',	email:'mlopezre@aeromexico.com',	nombre:'LOPEZ REBOLLAR MAURICIO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'YUwWJ91uJk',	},
    {	empleado:'924998',	email:'jruizg@aeromexico.com',	nombre:'RUIZ GRANADOS JOSE EDUARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'%YiZfPeQ9v',	},
    {	empleado:'925198',	email:'odgonzalez@aeromexico.com',	nombre:'GONZALEZ HERNANDEZ OCTAVIO DAVID',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'Nh2YEkFneP',	},
    {	empleado:'925298',	email:'rlopez@aeromexico.com',	nombre:'LOPEZ CONTRERAS RICARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'WdL6CB9mg5',	},
    {	empleado:'925498',	email:'mdiazcovarrubias@aeromexico.com',	nombre:'DIAZ COVARRUBIAS CASTILLON MARTIN RAFAEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'sqyW8pQpyM',	},
    {	empleado:'925598',	email:'carellano@aeromexico.com',	nombre:'ARELLANO MONDRAGON CARLOS ALBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'SjjSJrJNkJ',	},
    {	empleado:'925698',	email:'aortizm@aeromexico.com',	nombre:'ORTIZ MARTIN ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'2TyFKfLzBY',	},
    {	empleado:'92614',	email:'jggarciad@aeromexico.com',	nombre:'GARCIA DAVILA JOSE GUSTAVO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'&81J86N9&%',	},
    {	empleado:'92714',	email:'raperezm@aeromexico.com',	nombre:'PEREZ MARTINEZ DEL CERRO RICARDO ALFONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'VJwfJ18F23',	},
    {	empleado:'92914',	email:'mmorones@aeromexico.com',	nombre:'MORONES DAMIAN MICHAEL MARCELLE JAVIER',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'%@tqBa%h%9',	},
    {	empleado:'93014',	email:'jlmunoz@aeromexico.com',	nombre:'MUNOZ GARCIA JOSE LUIS DANIEL',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'RH18sfpz$r',	},
    {	empleado:'954698',	email:'jtorresn@aeromexico.com',	nombre:'TORRES NOVOA JAVIER',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'mHskR%BZUz',	},
    {	empleado:'954798',	email:'rbarroso@aeromexico.com',	nombre:'BARROSO RODRIGUEZ RICARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'j%Ly!FN1S5',	},
    {	empleado:'954898',	email:'gysunza@aeromexico.com',	nombre:'YSUNZA HERRERA GUSTAVO ADOLFO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3kjHcaCntd',	},
    {	empleado:'954998',	email:'gortegag@aeromexico.com',	nombre:'ORTEGA GOMEZPERALTA GERARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'k6MaxgPTfZ',	},
    {	empleado:'955098',	email:'nramirezc@aeromexico.com',	nombre:'RAMIREZ CESAR NORBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'%teum!Z4b4',	},
    {	empleado:'955298',	email:'mortizl@aeromexico.com',	nombre:'ORTIZ LOPEZ MIGUEL ANGEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'1vEBkD%DDf',	},
    {	empleado:'955398',	email:'mbustindui@aeromexico.com',	nombre:'BUSTINDUI SUAREZ MANUEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'F433Z9y$P8',	},
    {	empleado:'955498',	email:'aperis@aeromexico.com',	nombre:'PERIS MONTES ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'jGbbGMnYW%',	},
    {	empleado:'955598',	email:'csmith@aeromexico.com',	nombre:'SMITH VILLAFAN CARLOS GUSTAVO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'F2UwZqxTgs',	},
    {	empleado:'959698',	email:'acguevara@aeromexico.com',	nombre:'GUEVARA CALDERON ALBERTO CESAR',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'6!NkgV3et#',	},
    {	empleado:'959798',	email:'acardenasa@aeromexico.com',	nombre:'CARDENAS AZUARA ADOLFO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'CA!J%W95b#',	},
    {	empleado:'959898',	email:'rgomezg@aeromexico.com',	nombre:'GOMEZ GUZMAN RICARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'VcEdY3nLY1',	},
    {	empleado:'959998',	email:'jsempere@aeromexico.com',	nombre:'SEMPERE FARIAS JOSE ROBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'WZ7PEefZ82',	},
    {	empleado:'960098',	email:'hdelcastilloc@aeromexico.com',	nombre:'DEL CASTILLO VILLA HUMBERTO ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'x3AWDxJw6r',	},
    {	empleado:'960198',	email:'jcanchola@aeromexico.com',	nombre:'CANCHOLA SOTO JOSE ALFREDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'V$LQZfURAc',	},
    {	empleado:'960298',	email:'jayluardo@aeromexico.com',	nombre:'AYLUARDO COO JOSE ABEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'%M31Ew8hD5',	},
    {	empleado:'960398',	email:'hruiz@aeromexico.com',	nombre:'RUIZ MANZO HORACIO SIGFRIDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'MZE2YJHtX%',	},
    {	empleado:'964098',	email:'jgperez@aeromexico.com',	nombre:'PEREZ CORRAL JOSE GABRIEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'@ek$LM!kn1',	},
    {	empleado:'964198',	email:'cbuenfil@aeromexico.com',	nombre:'BUENFIL MARTINEZ CESAR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'N@xymJ&RpK',	},
    {	empleado:'964298',	email:'rguzmane@aeromexico.com',	nombre:'GUZMAN ESTRADA ROBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'REbd%Y2Uv4',	},
    {	empleado:'964398',	email:'eblazquez@aeromexico.com',	nombre:'BLAZQUEZ PONS EDUARDO',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'6vb5Z#Lw7T',	},
    {	empleado:'964498',	email:'jcastaneda@aeromexico.com',	nombre:'CASTANEDA MADRID JOSE ALBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'h5Xy1MaeJz',	},
    {	empleado:'964598',	email:'mmanzanilla@aeromexico.com',	nombre:'MANZANILLA VALDEZ MANUEL RODOLFO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'B%Jj1a4vVu',	},
    {	empleado:'964698',	email:'obgarcia@aeromexico.com',	nombre:'GARCIA INIGUEZ OSCAR BENJAMIN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'#fjjbxjgk4',	},
    {	empleado:'964798',	email:'arodriguezgo@aeromexico.com',	nombre:'RODRIGUEZ GOMEZ ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'aY83dHzf#e',	},
    {	empleado:'964898',	email:'lterrazas@aeromexico.com',	nombre:'TERRAZAS MARCOS LUIS GERARDO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'uv5jHVuBHX',	},
    {	empleado:'968698',	email:'dplascencia@aeromexico.com',	nombre:'PLASCENCIA GUZMAN DAHIR',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'Zh4K!K6AC6',	},
    {	empleado:'968898',	email:'opereda@aeromexico.com',	nombre:'PEREDA ZAVALA OMAR',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'W9NkCkjkFz',	},
    {	empleado:'968998',	email:'acarrasco@aeromexico.com',	nombre:'CARRASCO YANEZ ABRAHAM',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3k%6JzSed5',	},
    {	empleado:'969098',	email:'ryannelli@aeromexico.com',	nombre:'YANNELLI HERNANDO RODRIGO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'&nz!B5tz#k',	},
    {	empleado:'969198',	email:'cvillaverde@aeromexico.com',	nombre:'VILLAVERDE GUZMAN CARLOS',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'jvsv29V7ek',	},
    {	empleado:'969298',	email:'yyestrada@aeromexico.com',	nombre:'ESTRADA MAGANA YURI YOMEL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'JY1DSPc&1R',	},
    {	empleado:'969398',	email:'aaguirrer@aeromexico.com',	nombre:'AGUIRRE RAMIREZ AARON',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3MZneGMJzK',	},
    {	empleado:'969498',	email:'forduno@aeromexico.com',	nombre:'ORDUNO ESTUDILLO FAUSTINO ALBERTO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'3YErYCnJjt',	},
    {	empleado:'97422',	email:'fcevallos@aeromexico.com',	nombre:'CEVALLOS SHEHAB FRANCISCO CESAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'SXWc1YnYnx',	},
    {	empleado:'97522',	email:'rmelendez@aeromexico.com',	nombre:'MELENDEZ VALDEZ RUBEN OMAR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'MfDgYuqJXk',	},
    {	empleado:'97723',	email:'mramosl@aeromexico.com',	nombre:'RAMOS LOPEZ MIRSHA',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'y5iEx777az',	},
    {	empleado:'97823',	email:'ayanezs@aeromexico.com',	nombre:'YANEZ SANCHEZ ABRAHAM',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'wncwFWRGVP',	},
    {	empleado:'97922',	email:'rvegag@aeromexico.com',	nombre:'VEGA GOMEZ RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'5SSgA5Uuej',	},
    {	empleado:'98022',	email:'rramirezmi@aeromexico.com',	nombre:'RAMIREZ MIRANDA RICARDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'HtW1&&7HnZ',	},
    {	empleado:'98023',	email:'gcoronato@aeromexico.com',	nombre:'CORONATO SEGURA GERMAN FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'1e&1jKye74',	},
    {	empleado:'98122',	email:'odavilao@aeromexico.com',	nombre:'DAVILA OLGUIN OSCAR ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'t7JFzm5YUq',	},
    {	empleado:'98123',	email:'ajarac@aeromexico.com',	nombre:'JARA CHAVEZ ALBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'TcJ1aD78%#',	},
    {	empleado:'9817',	email:'agonzalezt@aeromexico.com',	nombre:'GONZALEZ TRABOLSI ALFONSO',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'Kie41R%yyL',	},
    {	empleado:'98222',	email:'rcoronab@aeromexico.com',	nombre:'CORONA BLANCO RICARDO SERGIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'RByYMVddtP',	},
    {	empleado:'98323',	email:'asierra@aeromexico.com',	nombre:'SIERRA DEBERNARDI AXEL MANUEL',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'sskGCTThZy',	},
    {	empleado:'98414',	email:'rolivar@aeromexico.com',	nombre:'OLIVAR OBREGON RODRIGO ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'WZdk56&SyJ',	},
    {	empleado:'98423',	email:'bcamargoa@aeromexico.com',	nombre:'CAMARGO AGUILAR BRYAN',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'3FM6sY8$uv',	},
    {	empleado:'98523',	email:'djetzer@aeromexico.com',	nombre:'JETZER VARGAS DANIEL ALEJANDRO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'AEzD6d8khc',	},
    {	empleado:'98723',	email:'cbastar@aeromexico.com',	nombre:'BASTAR JUAREZ CARLOS MAURICIO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'jf1cCX57Re',	},
    {	empleado:'98823',	email:'jarabi@aeromexico.com',	nombre:'ARABI PUENTE JORGE',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'4J7fPGe6Xx',	},
    {	empleado:'98923',	email:'kdiaza@aeromexico.com',	nombre:'DIAZ AYALA KEVIN ROBERTO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'ndrfZp2@Tr',	},
    {	empleado:'99021',	email:'lminor@aeromexico.com',	nombre:'MINOR MEDRANO LUIS RODRIGO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'HZcdqakusX',	},
    {	empleado:'99023',	email:'dherrerac@aeromexico.com',	nombre:'HERRERA CASTELLANOS DAVID SALVADOR',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'NuwZxzEn3j',	},
    {	empleado:'99121',	email:'ulopez@aeromexico.com',	nombre:'LOPEZ BARRIENTOS ULISES FERNANDO',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'yAzUG#3LfQ',	},
    {	empleado:'9917',	email:'jogomezm@aeromexico.com',	nombre:'GOMEZ MEXIA JOSE EDGAR',	rango:'OFI',	equipo:'787',	contrato:'B',	passConstra:'3A6jnCTthw',	},
    {	empleado:'99321',	email:'mocarrasco@aeromexico.com',	nombre:'CARRASCO GARCIA MOISES',	rango:'OFI',	equipo:'737',	contrato:'B',	passConstra:'3ur#k1jqQK',	},
    {	empleado:'99421',	email:'hvillarreal@aeromexico.com',	nombre:'VILLARREAL COMPAGNY HUMBERTO ROMEO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'3vwGi$3&wi',	},
    {	empleado:'99521',	email:'jofloresrom@aeromexico.com',	nombre:'FLORES ROMERO JOSE ALBERTO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'JFD#cSfinf',	},
    {	empleado:'995799',	email:'rpazzi@aeromexico.com',	nombre:'PAZZI GOMEZ RICARDO MARCELINO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'aa9Rz5ejZR',	},
    {	empleado:'995899',	email:'jpuebla@aeromexico.com',	nombre:'PUEBLA HERNANDEZ JOSE JUAN',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'BBg7!Q8ZeZ',	},
    {	empleado:'995999',	email:'fcruz@aeromexico.com',	nombre:'CRUZ BECERRIL FRANCISCO JAVIER',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'VvXN3ss11K',	},
    {	empleado:'996099',	email:'gvaldez@aeromexico.com',	nombre:'VALDEZ IRIGOYEN GERARDO FRANCISCO',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'eXQnhZZh$R',	},
    {	empleado:'996199',	email:'palvarezdelcastillo@aeromexico.com',	nombre:'ALVAREZ DEL CASTILLO SOSA PASCUAL',	rango:'CAP',	equipo:'787',	contrato:'A',	passConstra:'KUCknZ@9Ye',	},
    {	empleado:'99621',	email:'pcamposg@aeromexico.com',	nombre:'CAMPOS GARCIA PAMELA',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'mSpiMBtHQW',	},
    {	empleado:'99721',	email:'jatoral@aeromexico.com',	nombre:'TORAL LANDIN JOSE ANTONIO',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'EiWWz1&9ZQ',	},
    {	empleado:'99821',	email:'malfaroa@aeromexico.com',	nombre:'ALFARO ALVAREZ MANUEL',	rango:'CAP',	equipo:'737',	contrato:'B',	passConstra:'Hkq&Q@T3wv',	},
    {	empleado:'999910',	email:'eescalante@aeromexico.com',	nombre:'ESCALANTE OROPEZA ENRIQUE',	rango:'CAP',	equipo:'737',	contrato:'A',	passConstra:'s6xnTYHw2r',	},
    {	empleado:'999990',	email:'lesnarriagab@aeromexico.com',	nombre:'ESNARRIAGA BONAVIDES LUIS RENE',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'vyKYGZyJne',	},
    {	empleado:'999991',	email:'mblancasv@aeromexico.com',	nombre:'BLANCAS VARGAS MANUEL ANTONIO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'A86D4k61RQ',	},
    {	empleado:'999992',	email:'jcastellenos@aeromexico.com',	nombre:'CASTELLANOS VALLEJO JORGE ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'nnS7uJ9ZgZ',	},
    {	empleado:'999993',	email:'arodriguezu@aeromexico.com',	nombre:'RODRIGUEZ URENA ALFONSO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'Hf6PZYyS4z',	},
    {	empleado:'999994',	email:'ggeyne@aeromexico.com',	nombre:'GEYNE CRUZ GUSTAVO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'#cduMZt9nZ',	},
    {	empleado:'999996',	email:'ocampos@aeromexico.com',	nombre:'CAMPOS DE LA GARMA OCTAVIO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'N6dbynDk@k',	},
    {	empleado:'999997',	email:'jamorv@aeromexico.com',	nombre:'AMOR VIVEROS JOSE ALEJANDRO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'ehDH5A&M!J',	},
    {	empleado:'999998',	email:'carroyo@aeromexico.com',	nombre:'ARROYO LANDERO CARLOS AMADO',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'N5fD1wVQ@2',	},
    {	empleado:'999999',	email:'vchavezg@aeromexico.com',	nombre:'CHAVEZ GONZALEZ VICTOR MANUEL',	rango:'CAP',	equipo:'787',	contrato:'B',	passConstra:'GrwvejK4TW',	}
  ];

  export default fakeStockER;