import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Login from "../../pages/Login";
 
import { initializeAnalytics, setUserAnalytics, trackCustomEvents } from "./analyticsConfig";
import routes from "../../routes/routes";
 
import oktaAuth from "./oktaConfig";
 
import SolicitarNota from "../Aeronews/formsolicitar";
import InfoEnvironment from "../../utils/info.environment";
import ProfileRewards from "../../pages/Profile/ProfileRewards"; // NEW PROFILE REWARDS
import Notfound from "../../pages/404/notfound";
import ErrorMessage from "../../ErrorMessage";
import config from "../../Config";
import { UserAgentApplication } from "msal";
import userLocation from "../../utils/userLocation";
import isMockUser from "../../utils/isMockUser";
import setLevelMockUser from "../../utils/setLevelMockUser";
import setMockCompany from "../../utils/setMockCompany";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import rootReducer from "../../redux/rootReducer";
import { validateMenu } from "../../utils/NewBenefitsValidation/benefits.validation";
import BazarNavidad from "../BazarNavidad";
import FuturoPage from "../../pages/Futuro/FuturoPage";
import negociacionesASPA2024 from "../negociacionesASPA2024";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import Layout from "../Layout";
 
const middleware = [logger, thunk];
const store = createStore(rootReducer, load(), composeWithDevTools(applyMiddleware(...middleware, save())));
 
const sha1 = require("sha1");
 

const profileRewardsRoutes = [
    {path: "/recompensa-total", tab: "rewards" },
    {path: "/recompensa-total/perfil", tab: "rewards" },
    {path: "/recompensa-total/perfil/my-flexibility", tab: "flexibility" },
    {path: "/recompensa-total/perfil/my-travel-benefits", tab: "travel" },
    {path: "/recompensa-total/perfil/venta-especial", tab: "ventaespecial" },
    {path: "/recompensa-total/perfil/mis-beneficios", tab: "benefits" }
];

const PRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem("token") !== null ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={`/login`}
                />
            )
        }
    />
);
 
function onAuthRequired(oktaAuth) {
    const originalUri = oktaAuth.getOriginalUri();
    oktaAuth.setOriginalUri(originalUri || '/');
    window.location.assign('/login');
}
 
const RenderProfileRewards = (props) => {
    return validateMenu() ? (
        <ProfileRewards {...props} activeTab="rewards" />
    ) : (
        <Redirect to="/" />
    );
};
const RenderReciclando = () => {
    const getMobileOS = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent);
    };
 
    if (getMobileOS()) {
        window.location.href = "https://pixpik.org/aeromexico/";
        return null;
    }
 
    return (
        <iframe
            className="reciclando-iframe"
            title="Reciclando Ando"
            src="https://pixpik.org/aeromexico/"
            frameBorder="0"
            style={{
                height: "700px !important",
            }}
        ></iframe>
    );
};
 
 
export default class App extends Component {
    /**OFFICE */
    constructor(props) {
        super(props);
        this.infoEnvironment = new InfoEnvironment();
        this.userAgentApplication = new UserAgentApplication(config.appId, null, null);
        let user = this.userAgentApplication.getUser();
        this.state = {
            isAuthenticated: user !== null, // Se quita el signo ! para entrar por pruebas y se pone para activar el redireccionamiento
            user: {},
            error: null,
            charging: true,
            errorApi: false
        };
    }
 
    async componentDidMount() {
       
        document.addEventListener("mousemove", this.resetInactivityTimer);
        document.addEventListener("keydown", this.resetInactivityTimer);
       
        initializeAnalytics(); // Inicializa Google Analytics
        setUserAnalytics(); // Configura datos del usuario
        trackCustomEvents(); // Evento personalizado de inicio de sesión
 
        this.getInfo();
        this.inactivityTimeout = setTimeout(this.handleInactivity, 30 * 60 * 1000);
    }
 
    componentWillUnmount() {
        oktaAuth.authStateManager.unsubscribe(this.handleAuthStateChange);
        document.removeEventListener("mousemove", this.resetInactivityTimer);
        document.removeEventListener("keydown", this.resetInactivityTimer);
        clearTimeout(this.inactivityTimeout);
    }
 
    handleAuthStateChange = (authState) => {
        if(authState?.isAuthenticated){
            this.setState({isAuthenticated: true});
            this.loadUserData();
        }else{
            this.setState({ isAuthenticated: false });
            this.logout();
        }
    }
 
    resetInactivityTimer = () => {
        clearTimeout(this.inactivityTimeout);
        this.inactivityTimeout = setTimeout(this.handleInactivity, 30 * 60 * 1000);
    };
 
    handleInactivity = () => {
        this.logout();
        window.location.assign("/login");
    };
 
    async loadUserData() {
        try {
            const user = await oktaAuth.getUser();
            localStorage.setItem("email", user.email);
            localStorage.setItem("nombre", user.given_name);
            localStorage.setItem("accessDate", `${new Date()}`);
        } catch (err) {
            console.error("Error al cargar datos del usuario:", err);
        }
    }
 
    async logout() {
        var lang = localStorage.getItem("i18nextLng");
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("i18nextLng", lang);
        oktaAuth.tokenManager.clear();
        await oktaAuth.signOut();
    }
 
    setErrorMessage(message, debug) {
        this.setState({
            error: { message: message, debug: debug }
        });
    }
 
    setUserLocation() {
        let location = userLocation(
            localStorage.getItem("email"),
            localStorage.getItem("ubicacion"),
            localStorage.getItem("compania")
        );
 
        localStorage.setItem("location", location);
    }
 
    getInfo = async () => {
        // if (typeof Buffer !== "undefined") {
        if (localStorage.getItem("email") !== null && localStorage.getItem("nombre") !== null) {
            const HOST = "NOMBREHOST";
 
            const token = sha1(`${HOST}|${localStorage.getItem("email")}`).toUpperCase();
            // const email = new Buffer(localStorage.getItem("email")).toString("hex").toUpperCase();
            const email = this.stringToHex(localStorage.getItem("email")).toUpperCase();
            const URL = this.infoEnvironment.api_url({
                host: window.location.hostname
            });
 
            let formData = new FormData();
 
            formData.append("token", token);
            formData.append("email", email);
            formData.append("tipo", "1");
            formData.append("tipo_salida", "JSON");
 
            fetch(URL, {
                body: formData,
                method: "post"
            })
                .then((data) => data.json())
                .then((data) => {
                    if (!!data.subordinados) {
                        localStorage.setItem("subordinados", data.subordinados);
                    }
                    localStorage.setItem("nombreHRBP", data.nombrehrbp);
                    localStorage.setItem("emailHRBP", data.emailhrbp);
                    localStorage.setItem("nombre", data.nombre);
                    localStorage.setItem("grupo", data.grupo);
                    localStorage.setItem("pais", data.country);
                    localStorage.setItem("codigo_empresa_AM", data.codigo_empresa);
                    localStorage.setItem("tipo_empleado_AM", data.tipo_empleado);
                    localStorage.setItem("no_empleado_AM", data.noEmpleado);
 
                    this.setState({
                        error: false,
                        charging: false
                    });
 
                    if (data.noEmpleado !== "") {
                        localStorage.setItem("noEmpleado", data.noEmpleado);
                        localStorage.setItem("compania", data.compania);
                        localStorage.setItem("nombre", data.nombre);
                        localStorage.setItem("pais", data.country);
                        localStorage.setItem("apellidoMaterno", data.apellidoMaterno);
                        localStorage.setItem("apellidoPaterno", data.apellidoPaterno);
                        localStorage.setItem("grupo", data.grupo);
                        localStorage.setItem("nivel", data.nivel);
                        localStorage.setItem("ubicacion", data.ubicacion);
                        localStorage.setItem("posicion", data.posicion);
                        localStorage.setItem("direccion", data.direccion);
                        localStorage.setItem("direccionArea", data.direccionArea);
                        localStorage.setItem("esquemaColaborador", data.esquemacolaborador);
                        localStorage.setItem("colaboradores", JSON.stringify(data.COLABORADORES));
                        localStorage.setItem("fechaContra", data.fechaContra);
                        localStorage.setItem("antiguedadEmpleado", data.antiguedadEmpleado);
                        localStorage.setItem("flexIsActive", true);
                    } else {
                        if (isMockUser()) {
                            localStorage.setItem("noEmpleado", "-");
                            setMockCompany();
                            localStorage.setItem("nombre", "Usuario");
                            localStorage.setItem("pais", "MX");
                            localStorage.setItem("apellidoMaterno", "-");
                            localStorage.setItem("apellidoPaterno", "-");
                            localStorage.setItem("grupo", "XXXX");
                            setLevelMockUser();
                            localStorage.setItem("ubicacion", "-");
                            localStorage.setItem("posicion", "");
                            localStorage.setItem("direccion", "-");
                            localStorage.setItem("direccionArea", "-");
                            localStorage.setItem("fechaContra", "-");
                            localStorage.setItem("antiguedadEmpleado", "-");
                        } else {
                            localStorage.setItem("noEmpleado", "-");
                            localStorage.setItem("compania", "-");
                            localStorage.setItem("nombre", "-");
                            localStorage.setItem("pais", "MX");
                            localStorage.setItem("apellidoMaterno", "-");
                            localStorage.setItem("apellidoPaterno", "-");
                            localStorage.setItem("grupo", "un grupo");
                            localStorage.setItem("nivel", 0);
                            localStorage.setItem("ubicacion", "-");
                            localStorage.setItem("posicion", "");
                            localStorage.setItem("direccion", "-");
                            localStorage.setItem("direccionArea", "-");
                            localStorage.setItem("fechaContra", "-");
                            localStorage.setItem("antiguedadEmpleado", "-");
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        errorApi: error
                    });
                });
 
            this.setUserLocation();
        }
        // }
    };
 
    stringToHex(str) {
        let hex = "";
        for (let i = 0; i < str.length; i++) {
            hex += str.charCodeAt(i).toString(16).toUpperCase();
        }
        return hex;
    }
 
    render() {
        let error = null;
 
        if (this.state.error) {
            error = <ErrorMessage message={this.state.error.message} debug={this.state.error.debug} />;
        }
 
        return (
            <Provider store={store}>
                <Router>
                    <Security
                        oktaAuth={oktaAuth} // Importado y configurado previamente
                        restoreOriginalUri={async (_oktaAuth, originalUri) => {
                            // Lógica personalizada después de la autenticación
                            const authState = oktaAuth.authStateManager.getAuthState();
                   
                            if (authState?.isAuthenticated) {
                                const userInfo = await oktaAuth.tokenManager.get('idToken');
                                const user = await oktaAuth.getUser();
                   
                                if (userInfo) {
                                    const [apellidoPaterno, apellidoMaterno] = user.family_name.split(" ");
                                    localStorage.setItem("okta-user-id", userInfo.claims.sub);
                                    localStorage.setItem("email", userInfo.claims.email);
                                    localStorage.setItem("token", userInfo.claims.sub);
                                    localStorage.setItem("nombre", user.given_name);
                                    localStorage.setItem("oktaToken", userInfo.idToken);
                                    localStorage.setItem("accessDate", `${new Date()}`);
                                    localStorage.setItem("apellidoPaterno", apellidoPaterno || "");
                                    localStorage.setItem("apellidoMaterno", apellidoMaterno || "");
                                }
                            }else if(!authState?.isAuthenticated){
                                window.location.assign("/login");
                            }
                   
                            // Redirigir a la URI original o a una predeterminada
                            window.location.replace(originalUri || '/');
                        }}
                        onAuthRequired={onAuthRequired}>
 
                        <Switch>
                            {/* Rutas públicas */}
                            <Route path="/login/callback" component={LoginCallback} />
                            <Route exact path="/login" component={Login} />
                            {error}
                            <Route exact path="/bazar-navideno" component={BazarNavidad} />
                            <Route exact path="/creandoelfuturo" component={FuturoPage} />
                            <Route exact path="/solicitar-nota-aeronews" component={SolicitarNota} />
                            <Route exact path="/negociacionesASPA" component={negociacionesASPA2024} />

 
                            {/* Layout y rutas protegidas */}
                            <Layout>
                                <Switch>
                                    {/* Mapeo dinámico de rutas desde routes.js */}
                                    {routes.map(({ path, component: Component, exact, secure }, index) =>
                                        secure ? (
                                            <PRoute key={index} path={path} exact={exact} component={Component} />
                                        ) : (
                                            <Route key={index} path={path} exact={exact} component={Component} />
                                        )
                                    )}
 
                                    {/* Rutas complejas directamente en el Router */}
                                    

                                        {profileRewardsRoutes.map(({ path, tab }, index) => (
                                            <PRoute
                                                key={index}
                                                exact
                                                path={path}
                                                component={(props) => <ProfileRewards {...props} activeTab={tab} />}
                                            />
                                        ))}

                                        {/* Manejo especial de /mi-compensaciones con validación */}
                                        <PRoute
                                            exact
                                            path="/recompensa-total/perfil/mi-compensaciones"
                                            component={(props) =>
                                                validateMenu() ? <ProfileRewards {...props} activeTab="rewards" /> : <Redirect to="/" />
                                            }
                                        />

                                    <PRoute exact path="/reciclandoando" component={RenderReciclando} />
 
                                    {/* Ruta por defecto para páginas no encontradas */}
                                    <PRoute component={Notfound} />
                                </Switch>
                            </Layout>
                        </Switch>
                    </Security>
                </Router>
            </Provider>
        );
    }
}
const RedirectLogin = () => <Redirect to="/login" />;