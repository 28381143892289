import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Banner from "./assets/banner-procesos-administrativos.jpeg";
import i18next from "i18next";

const fakeStockER = [
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'JOSE LUIS ALVARADO JIMENEZ', 	 'email':'jalvaradoj@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'826', 	 'ltip_rsu_2_24':'827', 	 'ltip_rsu_3_25':'827', 	 'ltip_rsu_1_24':'790', 	 'ltip_rsu_2_25':'790', 	 'ltip_rsu_1_25':'416', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'30036', 	 'ltip_rsu_3_26':'790', 	 'ltip_rsu_2_26':'417', 	 'ltip_rsu_2_27':'417', 	 'oca_3_24':'0', 	 'total_anl_02':'1624', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'LUIS ANGEL LOPEZ ALVAREZ', 	 'email':'llopezalv@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'826', 	 'ltip_rsu_2_24':'827', 	 'ltip_rsu_3_25':'827', 	 'ltip_rsu_1_24':'790', 	 'ltip_rsu_2_25':'790', 	 'ltip_rsu_1_25':'416', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'30036', 	 'ltip_rsu_3_26':'790', 	 'ltip_rsu_2_26':'417', 	 'ltip_rsu_2_27':'417', 	 'oca_3_24':'0', 	 'total_anl_02':'1624', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ABU JAMRA SALGADO ROCHA FERNANDO GUILHERME', 	 'email':'fernando.rocha@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'826', 	 'ltip_rsu_2_24':'827', 	 'ltip_rsu_3_25':'827', 	 'ltip_rsu_1_24':'790', 	 'ltip_rsu_2_25':'790', 	 'ltip_rsu_1_25':'416', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'30036', 	 'ltip_rsu_3_26':'790', 	 'ltip_rsu_2_26':'417', 	 'ltip_rsu_2_27':'417', 	 'oca_3_24':'0', 	 'total_anl_02':'1624', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MULINELLI AVILES GIANCARLO', 	 'email':'gmulinelli@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'666', 	 'ltip_rsu_2_24':'667', 	 'ltip_rsu_3_25':'667', 	 'ltip_rsu_1_24':'694', 	 'ltip_rsu_2_25':'693', 	 'ltip_rsu_1_25':'400', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'46377', 	 'ltip_rsu_3_26':'693', 	 'ltip_rsu_2_26':'400', 	 'ltip_rsu_2_27':'400', 	 'oca_3_24':'0', 	 'total_anl_02':'1493', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PEREZ ZUBIRIA ALEJANDRO', 	 'email':'aperezz@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'876', 	 'ltip_rsu_2_24':'877', 	 'ltip_rsu_3_25':'877', 	 'ltip_rsu_1_24':'930', 	 'ltip_rsu_2_25':'930', 	 'ltip_rsu_1_25':'534', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'47614', 	 'ltip_rsu_3_26':'930', 	 'ltip_rsu_2_26':'533', 	 'ltip_rsu_2_27':'533', 	 'oca_3_24':'0', 	 'total_anl_02':'1996', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'JACOME ARMIDA JORGE LUIS', 	 'email':'jjacome@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'896', 	 'ltip_rsu_2_24':'897', 	 'ltip_rsu_3_25':'897', 	 'ltip_rsu_1_24':'930', 	 'ltip_rsu_2_25':'930', 	 'ltip_rsu_1_25':'534', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'47674', 	 'ltip_rsu_3_26':'930', 	 'ltip_rsu_2_26':'533', 	 'ltip_rsu_2_27':'533', 	 'oca_3_24':'0', 	 'total_anl_02':'1996', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MENDEZ GONZALEZ ALEJANDRO NICOLAS', 	 'email':'amendez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'906', 	 'ltip_rsu_2_24':'907', 	 'ltip_rsu_3_25':'907', 	 'ltip_rsu_1_24':'950', 	 'ltip_rsu_2_25':'950', 	 'ltip_rsu_1_25':'536', 	 'mip_rsu_1_23':'4970', 	 'mip_rsu_2_24':'4970', 	 'mip_rsu_3_25':'4970', 	 'mip_psu_1_24':'22360', 	'mip_psu_2_25':'22360',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'64786', 	 'ltip_rsu_3_26':'950', 	 'ltip_rsu_2_26':'537', 	 'ltip_rsu_2_27':'537', 	 'oca_3_24':'0', 	 'total_anl_02':'2024', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALVAREZ MATAR MAX EDUARDO', 	 'email':'malvarez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'766', 	 'ltip_rsu_2_24':'767', 	 'ltip_rsu_3_25':'767', 	 'ltip_rsu_1_24':'796', 	 'ltip_rsu_2_25':'797', 	 'ltip_rsu_1_25':'460', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'46943', 	 'ltip_rsu_3_26':'797', 	 'ltip_rsu_2_26':'460', 	 'ltip_rsu_2_27':'460', 	 'oca_3_24':'0', 	 'total_anl_02':'1717', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'LAGUNA CASTELLANOS CESAR AUGUSTO', 	 'email':'cclaguna@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'546', 	 'ltip_rsu_2_24':'547', 	 'ltip_rsu_3_25':'547', 	 'ltip_rsu_1_24':'566', 	 'ltip_rsu_2_25':'567', 	 'ltip_rsu_1_25':'330', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'45693', 	 'ltip_rsu_3_26':'567', 	 'ltip_rsu_2_26':'330', 	 'ltip_rsu_2_27':'330', 	 'oca_3_24':'0', 	 'total_anl_02':'1227', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'FERNANDEZ OJEDA ANTONIO', 	 'email':'afernandezo@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'406', 	 'ltip_rsu_2_24':'407', 	 'ltip_rsu_3_25':'407', 	 'ltip_rsu_1_24':'426', 	 'ltip_rsu_2_25':'427', 	 'ltip_rsu_1_25':'246', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'27879', 	 'ltip_rsu_3_26':'427', 	 'ltip_rsu_2_26':'247', 	 'ltip_rsu_2_27':'247', 	 'oca_3_24':'0', 	 'total_anl_02':'921', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MARTIN MATOS XIOMARA', 	 'email':'xmartin@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'466', 	 'ltip_rsu_2_24':'467', 	 'ltip_rsu_3_25':'467', 	 'ltip_rsu_1_24':'490', 	 'ltip_rsu_2_25':'490', 	 'ltip_rsu_1_25':'280', 	 'mip_rsu_1_23':'1210', 	 'mip_rsu_2_24':'1210', 	 'mip_rsu_3_25':'1210', 	 'mip_psu_1_24':'5430', 	'mip_psu_2_25':'5430',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17150', 	 'ltip_rsu_3_26':'490', 	 'ltip_rsu_2_26':'280', 	 'ltip_rsu_2_27':'280', 	 'oca_3_24':'0', 	 'total_anl_02':'1050', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ZAPATA CANTU JOSE ANGEL', 	 'email':'jazapatac@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'546', 	 'ltip_rsu_2_24':'547', 	 'ltip_rsu_3_25':'547', 	 'ltip_rsu_1_24':'570', 	 'ltip_rsu_2_25':'570', 	 'ltip_rsu_1_25':'364', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'28704', 	 'ltip_rsu_3_26':'570', 	 'ltip_rsu_2_26':'363', 	 'ltip_rsu_2_27':'363', 	 'oca_3_24':'0', 	 'total_anl_02':'1296', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CASTRO LOPEZ JAIME HECTOR', 	 'email':'jcastrolo@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'546', 	 'ltip_rsu_2_24':'547', 	 'ltip_rsu_3_25':'547', 	 'ltip_rsu_1_24':'566', 	 'ltip_rsu_2_25':'567', 	 'ltip_rsu_1_25':'320', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'28653', 	 'ltip_rsu_3_26':'567', 	 'ltip_rsu_2_26':'320', 	 'ltip_rsu_2_27':'320', 	 'oca_3_24':'0', 	 'total_anl_02':'1207', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CERVANTES MUNOZ CLAUDIA ANGELICA', 	 'email':'ccervantes@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'524', 	 'ltip_rsu_2_24':'523', 	 'ltip_rsu_3_25':'523', 	 'ltip_rsu_1_24':'664', 	 'ltip_rsu_2_25':'663', 	 'ltip_rsu_1_25':'380', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'45867', 	 'ltip_rsu_3_26':'663', 	 'ltip_rsu_2_26':'380', 	 'ltip_rsu_2_27':'380', 	 'oca_3_24':'0', 	 'total_anl_02':'1423', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GUTIERREZ SALAZAR RICARDO ISMAEL', 	 'email':'rigutierrez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'396', 	 'ltip_rsu_2_24':'397', 	 'ltip_rsu_3_25':'397', 	 'ltip_rsu_1_24':'470', 	 'ltip_rsu_2_25':'470', 	 'ltip_rsu_1_25':'306', 	 'mip_rsu_1_23':'2840', 	 'mip_rsu_2_24':'2840', 	 'mip_rsu_3_25':'2840', 	 'mip_psu_1_24':'12775', 	'mip_psu_2_25':'12775',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'36506', 	 'ltip_rsu_3_26':'470', 	 'ltip_rsu_2_26':'307', 	 'ltip_rsu_2_27':'307', 	 'oca_3_24':'0', 	 'total_anl_02':'1084', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MENCHACA GARZA CRISTINA', 	 'email':'cmenchaca@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'480', 	 'ltip_rsu_2_24':'480', 	 'ltip_rsu_3_25':'480', 	 'ltip_rsu_1_24':'514', 	 'ltip_rsu_2_25':'513', 	 'ltip_rsu_1_25':'290', 	 'mip_rsu_1_23':'2840', 	 'mip_rsu_2_24':'2840', 	 'mip_rsu_3_25':'2840', 	 'mip_psu_1_24':'12775', 	'mip_psu_2_25':'12775',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'36827', 	 'ltip_rsu_3_26':'513', 	 'ltip_rsu_2_26':'290', 	 'ltip_rsu_2_27':'290', 	 'oca_3_24':'0', 	 'total_anl_02':'1093', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CANO RAMIREZ JORGE', 	 'email':'jcanor@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'434', 	 'ltip_rsu_2_24':'433', 	 'ltip_rsu_3_25':'433', 	 'ltip_rsu_1_24':'450', 	 'ltip_rsu_2_25':'450', 	 'ltip_rsu_1_25':'254', 	 'mip_rsu_1_23':'1780', 	 'mip_rsu_2_24':'1780', 	 'mip_rsu_3_25':'1780', 	 'mip_psu_1_24':'7990', 	'mip_psu_2_25':'7990',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'23774', 	 'ltip_rsu_3_26':'450', 	 'ltip_rsu_2_26':'253', 	 'ltip_rsu_2_27':'253', 	 'oca_3_24':'0', 	 'total_anl_02':'956', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PINA BILBAO MIKEL', 	 'email':'mpina@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'520', 	 'ltip_rsu_2_24':'520', 	 'ltip_rsu_3_25':'520', 	 'ltip_rsu_1_24':'554', 	 'ltip_rsu_2_25':'553', 	 'ltip_rsu_1_25':'320', 	 'mip_rsu_1_23':'3550', 	 'mip_rsu_2_24':'3550', 	 'mip_rsu_3_25':'3550', 	 'mip_psu_1_24':'15970', 	'mip_psu_2_25':'15970',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'45577', 	 'ltip_rsu_3_26':'553', 	 'ltip_rsu_2_26':'320', 	 'ltip_rsu_2_27':'320', 	 'oca_3_24':'0', 	 'total_anl_02':'1193', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GOMEZ RODRIGUEZ LAURA LILIANA', 	 'email':'llgomez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'500', 	 'ltip_rsu_2_24':'500', 	 'ltip_rsu_3_25':'500', 	 'ltip_rsu_1_24':'520', 	 'ltip_rsu_2_25':'520', 	 'ltip_rsu_1_25':'316', 	 'mip_rsu_1_23':'2840', 	 'mip_rsu_2_24':'2840', 	 'mip_rsu_3_25':'2840', 	 'mip_psu_1_24':'12775', 	'mip_psu_2_25':'12775',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'36926', 	 'ltip_rsu_3_26':'520', 	 'ltip_rsu_2_26':'317', 	 'ltip_rsu_2_27':'317', 	 'oca_3_24':'0', 	 'total_anl_02':'1154', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SUAREZ CHAVEZ SERGIO ANGEL RAFAEL', 	 'email':'rsuarezc@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'474', 	 'ltip_rsu_2_24':'473', 	 'ltip_rsu_3_25':'473', 	 'ltip_rsu_1_24':'496', 	 'ltip_rsu_2_25':'497', 	 'ltip_rsu_1_25':'284', 	 'mip_rsu_1_23':'1780', 	 'mip_rsu_2_24':'1780', 	 'mip_rsu_3_25':'1780', 	 'mip_psu_1_24':'7990', 	'mip_psu_2_25':'7990',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'24017', 	 'ltip_rsu_3_26':'497', 	 'ltip_rsu_2_26':'283', 	 'ltip_rsu_2_27':'283', 	 'oca_3_24':'0', 	 'total_anl_02':'1063', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'OLMOS DEL AMO PABLO', 	 'email':'polmos@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'316', 	 'ltip_rsu_2_24':'317', 	 'ltip_rsu_3_25':'317', 	 'ltip_rsu_1_24':'330', 	 'ltip_rsu_2_25':'330', 	 'ltip_rsu_1_25':'190', 	 'mip_rsu_1_23':'1140', 	 'mip_rsu_2_24':'1140', 	 'mip_rsu_3_25':'1140', 	 'mip_psu_1_24':'5115', 	'mip_psu_2_25':'5115',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'15450', 	 'ltip_rsu_3_26':'330', 	 'ltip_rsu_2_26':'190', 	 'ltip_rsu_2_27':'190', 	 'oca_3_24':'0', 	 'total_anl_02':'710', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SANCHEZ BARRIENTOS DAVID', 	 'email':'dsanchez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'326', 	 'ltip_rsu_2_24':'327', 	 'ltip_rsu_3_25':'327', 	 'ltip_rsu_1_24':'344', 	 'ltip_rsu_2_25':'343', 	 'ltip_rsu_1_25':'200', 	 'mip_rsu_1_23':'1140', 	 'mip_rsu_2_24':'1140', 	 'mip_rsu_3_25':'1140', 	 'mip_psu_1_24':'5115', 	'mip_psu_2_25':'5115',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'15517', 	 'ltip_rsu_3_26':'343', 	 'ltip_rsu_2_26':'200', 	 'ltip_rsu_2_27':'200', 	 'oca_3_24':'0', 	 'total_anl_02':'743', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROCHO PORRAS ISAAC', 	 'email':'irocho@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'334', 	 'ltip_rsu_2_24':'333', 	 'ltip_rsu_3_25':'333', 	 'ltip_rsu_1_24':'354', 	 'ltip_rsu_2_25':'353', 	 'ltip_rsu_1_25':'224', 	 'mip_rsu_1_23':'1780', 	 'mip_rsu_2_24':'1780', 	 'mip_rsu_3_25':'1780', 	 'mip_psu_1_24':'7990', 	'mip_psu_2_25':'7990',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'23251', 	 'ltip_rsu_3_26':'353', 	 'ltip_rsu_2_26':'223', 	 'ltip_rsu_2_27':'223', 	 'oca_3_24':'0', 	 'total_anl_02':'799', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SANDOVAL PIEMONTE MANUEL RODRIGO', 	 'email':'rsandoval@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'300', 	 'ltip_rsu_2_24':'300', 	 'ltip_rsu_3_25':'300', 	 'ltip_rsu_1_24':'380', 	 'ltip_rsu_2_25':'380', 	 'ltip_rsu_1_25':'216', 	 'mip_rsu_1_23':'1780', 	 'mip_rsu_2_24':'1780', 	 'mip_rsu_3_25':'1780', 	 'mip_psu_1_24':'7990', 	'mip_psu_2_25':'7990',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'23196', 	 'ltip_rsu_3_26':'380', 	 'ltip_rsu_2_26':'217', 	 'ltip_rsu_2_27':'217', 	 'oca_3_24':'0', 	 'total_anl_02':'814', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CHAVEZ CRUZ VICTOR HUGO', 	 'email':'vhchavezc@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'294', 	 'ltip_rsu_2_24':'293', 	 'ltip_rsu_3_25':'293', 	 'ltip_rsu_1_24':'304', 	 'ltip_rsu_2_25':'303', 	 'ltip_rsu_1_25':'174', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17861', 	 'ltip_rsu_3_26':'303', 	 'ltip_rsu_2_26':'173', 	 'ltip_rsu_2_27':'173', 	 'oca_3_24':'0', 	 'total_anl_02':'649', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BAEZ MATA MARIO ALBERTO', 	 'email':'mbaez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'340', 	 'ltip_rsu_2_24':'340', 	 'ltip_rsu_3_25':'340', 	 'ltip_rsu_1_24':'374', 	 'ltip_rsu_2_25':'373', 	 'ltip_rsu_1_25':'216', 	 'mip_rsu_1_23':'2130', 	 'mip_rsu_2_24':'2130', 	 'mip_rsu_3_25':'2130', 	 'mip_psu_1_24':'9585', 	'mip_psu_2_25':'9585',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'27543', 	 'ltip_rsu_3_26':'373', 	 'ltip_rsu_2_26':'217', 	 'ltip_rsu_2_27':'217', 	 'oca_3_24':'0', 	 'total_anl_02':'807', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ARCOVERDE GOMES EDGAR', 	 'email':'egomes@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'326', 	 'ltip_rsu_2_24':'327', 	 'ltip_rsu_3_25':'327', 	 'ltip_rsu_1_24':'340', 	 'ltip_rsu_2_25':'340', 	 'ltip_rsu_1_25':'196', 	 'mip_rsu_1_23':'1780', 	 'mip_rsu_2_24':'1780', 	 'mip_rsu_3_25':'1780', 	 'mip_psu_1_24':'7990', 	'mip_psu_2_25':'7990',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'23176', 	 'ltip_rsu_3_26':'340', 	 'ltip_rsu_2_26':'197', 	 'ltip_rsu_2_27':'197', 	 'oca_3_24':'0', 	 'total_anl_02':'734', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PASTRANA MACIA CHRISTIAN', 	 'email':'chpastrana@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'376', 	 'ltip_rsu_2_24':'377', 	 'ltip_rsu_3_25':'377', 	 'ltip_rsu_1_24':'396', 	 'ltip_rsu_2_25':'397', 	 'ltip_rsu_1_25':'226', 	 'mip_rsu_1_23':'1570', 	 'mip_rsu_2_24':'1570', 	 'mip_rsu_3_25':'1570', 	 'mip_psu_1_24':'7030', 	'mip_psu_2_25':'7030',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'20919', 	 'ltip_rsu_3_26':'397', 	 'ltip_rsu_2_26':'227', 	 'ltip_rsu_2_27':'227', 	 'oca_3_24':'0', 	 'total_anl_02':'851', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MARTINEZ MARTINEZ DANIEL', 	 'email':'dmartinezm@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'300', 	 'ltip_rsu_2_24':'300', 	 'ltip_rsu_3_25':'300', 	 'ltip_rsu_1_24':'304', 	 'ltip_rsu_2_25':'303', 	 'ltip_rsu_1_25':'170', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17877', 	 'ltip_rsu_3_26':'303', 	 'ltip_rsu_2_26':'170', 	 'ltip_rsu_2_27':'170', 	 'oca_3_24':'0', 	 'total_anl_02':'643', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROMAN CHAVEZ ENRIQUE', 	 'email':'enroman@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'300', 	 'ltip_rsu_2_24':'300', 	 'ltip_rsu_3_25':'300', 	 'ltip_rsu_1_24':'316', 	 'ltip_rsu_2_25':'317', 	 'ltip_rsu_1_25':'180', 	 'mip_rsu_1_23':'1070', 	 'mip_rsu_2_24':'1070', 	 'mip_rsu_3_25':'1070', 	 'mip_psu_1_24':'4795', 	'mip_psu_2_25':'4795',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'14513', 	 'ltip_rsu_3_26':'317', 	 'ltip_rsu_2_26':'180', 	 'ltip_rsu_2_27':'180', 	 'oca_3_24':'0', 	 'total_anl_02':'677', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PARADA DOMMARCO ELVIRA', 	 'email':'eparada@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'284', 	 'ltip_rsu_2_24':'283', 	 'ltip_rsu_3_25':'283', 	 'ltip_rsu_1_24':'296', 	 'ltip_rsu_2_25':'297', 	 'ltip_rsu_1_25':'214', 	 'mip_rsu_1_23':'1420', 	 'mip_rsu_2_24':'1420', 	 'mip_rsu_3_25':'1420', 	 'mip_psu_1_24':'6390', 	'mip_psu_2_25':'6390',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'18697', 	 'ltip_rsu_3_26':'297', 	 'ltip_rsu_2_26':'213', 	 'ltip_rsu_2_27':'213', 	 'oca_3_24':'0', 	 'total_anl_02':'723', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SOLER MORENO FEDERICO', 	 'email':'fsoler@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'296', 	 'ltip_rsu_2_24':'297', 	 'ltip_rsu_3_25':'297', 	 'ltip_rsu_1_24':'314', 	 'ltip_rsu_2_25':'313', 	 'ltip_rsu_1_25':'180', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17897', 	 'ltip_rsu_3_26':'313', 	 'ltip_rsu_2_26':'180', 	 'ltip_rsu_2_27':'180', 	 'oca_3_24':'0', 	 'total_anl_02':'673', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GARCIA TAPIA ERNESTO', 	 'email':'ergarcia@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'326', 	 'ltip_rsu_2_24':'327', 	 'ltip_rsu_3_25':'327', 	 'ltip_rsu_1_24':'336', 	 'ltip_rsu_2_25':'337', 	 'ltip_rsu_1_25':'194', 	 'mip_rsu_1_23':'1070', 	 'mip_rsu_2_24':'1070', 	 'mip_rsu_3_25':'1070', 	 'mip_psu_1_24':'4795', 	'mip_psu_2_25':'4795',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'14647', 	 'ltip_rsu_3_26':'337', 	 'ltip_rsu_2_26':'193', 	 'ltip_rsu_2_27':'193', 	 'oca_3_24':'0', 	 'total_anl_02':'723', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CASARIN TANAHARA LUIS ANGEL', 	 'email':'lcasarin@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'290', 	 'ltip_rsu_2_24':'290', 	 'ltip_rsu_3_25':'290', 	 'ltip_rsu_1_24':'300', 	 'ltip_rsu_2_25':'300', 	 'ltip_rsu_1_25':'170', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17840', 	 'ltip_rsu_3_26':'300', 	 'ltip_rsu_2_26':'170', 	 'ltip_rsu_2_27':'170', 	 'oca_3_24':'0', 	 'total_anl_02':'640', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'NAKAMURA SAVOY DAVID MASAO', 	 'email':'dnakamura@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'426', 	 'ltip_rsu_2_24':'427', 	 'ltip_rsu_3_25':'427', 	 'ltip_rsu_1_24':'446', 	 'ltip_rsu_2_25':'447', 	 'ltip_rsu_1_25':'256', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'18629', 	 'ltip_rsu_3_26':'447', 	 'ltip_rsu_2_26':'257', 	 'ltip_rsu_2_27':'257', 	 'oca_3_24':'0', 	 'total_anl_02':'961', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALPIZAR MOREIRA CARLOS ENRIQUE', 	 'email':'calpizar@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'434', 	 'ltip_rsu_2_24':'433', 	 'ltip_rsu_3_25':'433', 	 'ltip_rsu_1_24':'546', 	 'ltip_rsu_2_25':'547', 	 'ltip_rsu_1_25':'306', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'18899', 	 'ltip_rsu_3_26':'547', 	 'ltip_rsu_2_26':'307', 	 'ltip_rsu_2_27':'307', 	 'oca_3_24':'0', 	 'total_anl_02':'1161', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GARCIA URIBE CESAR', 	 'email':'cgarcia@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'526', 	 'ltip_rsu_2_24':'527', 	 'ltip_rsu_3_25':'527', 	 'ltip_rsu_1_24':'474', 	 'ltip_rsu_2_25':'473', 	 'ltip_rsu_1_25':'266', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'18993', 	 'ltip_rsu_3_26':'473', 	 'ltip_rsu_2_26':'267', 	 'ltip_rsu_2_27':'267', 	 'oca_3_24':'0', 	 'total_anl_02':'1007', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MEDELLIN ENRIQUEZ DANIEL', 	 'email':'dmedellin@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'374', 	 'ltip_rsu_2_24':'373', 	 'ltip_rsu_3_25':'373', 	 'ltip_rsu_1_24':'396', 	 'ltip_rsu_2_25':'397', 	 'ltip_rsu_1_25':'226', 	 'mip_rsu_1_23':'1780', 	 'mip_rsu_2_24':'1780', 	 'mip_rsu_3_25':'1780', 	 'mip_psu_1_24':'7990', 	'mip_psu_2_25':'7990',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'23459', 	 'ltip_rsu_3_26':'397', 	 'ltip_rsu_2_26':'227', 	 'ltip_rsu_2_27':'227', 	 'oca_3_24':'0', 	 'total_anl_02':'851', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'FABIAN VILLALOBOS VICTOR MANUEL', 	 'email':'vfabian@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'310', 	 'ltip_rsu_2_24':'310', 	 'ltip_rsu_3_25':'310', 	 'ltip_rsu_1_24':'324', 	 'ltip_rsu_2_25':'323', 	 'ltip_rsu_1_25':'176', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17953', 	 'ltip_rsu_3_26':'323', 	 'ltip_rsu_2_26':'177', 	 'ltip_rsu_2_27':'177', 	 'oca_3_24':'0', 	 'total_anl_02':'677', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERNANDEZ MORENO JORGE EDUARDO', 	 'email':'jhernandez@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'346', 	 'ltip_rsu_2_24':'347', 	 'ltip_rsu_3_25':'347', 	 'ltip_rsu_1_24':'360', 	 'ltip_rsu_2_25':'360', 	 'ltip_rsu_1_25':'204', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'18164', 	 'ltip_rsu_3_26':'360', 	 'ltip_rsu_2_26':'203', 	 'ltip_rsu_2_27':'203', 	 'oca_3_24':'0', 	 'total_anl_02':'766', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERNANDEZ PINA SAUL', 	 'email':'spina@aeromexico.com', 	 'bloque':'1', 	 'ltip_rsu_1_23':'270', 	 'ltip_rsu_2_24':'270', 	 'ltip_rsu_3_25':'270', 	 'ltip_rsu_1_24':'280', 	 'ltip_rsu_2_25':'280', 	 'ltip_rsu_1_25':'160', 	 'mip_rsu_1_23':'1350', 	 'mip_rsu_2_24':'1350', 	 'mip_rsu_3_25':'1350', 	 'mip_psu_1_24':'6075', 	'mip_psu_2_25':'6075',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'17730', 	 'ltip_rsu_3_26':'280', 	 'ltip_rsu_2_26':'160', 	 'ltip_rsu_2_27':'160', 	 'oca_3_24':'0', 	 'total_anl_02':'600', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROSALES ERIVES GUILLERMO', 	 'email':'grosales@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'320', 	 'ltip_rsu_2_25':'320', 	 'ltip_rsu_1_25':'224', 	 'mip_rsu_1_23':'790', 	 'mip_rsu_2_24':'790', 	 'mip_rsu_3_25':'790', 	 'mip_psu_1_24':'3520', 	'mip_psu_2_25':'3520',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'10274', 	 'ltip_rsu_3_26':'320', 	 'ltip_rsu_2_26':'223', 	 'ltip_rsu_2_27':'223', 	 'oca_3_24':'0', 	 'total_anl_02':'766', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GUTIERREZ MARTINEZ JOSE ALBERTO CUITLAHUAC', 	 'email':'jgutierrezma@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'840', 	 'ltip_rsu_2_25':'840', 	 'ltip_rsu_1_25':'476', 	 'mip_rsu_1_23':'890', 	 'mip_rsu_2_24':'890', 	 'mip_rsu_3_25':'890', 	 'mip_psu_1_24':'4000', 	'mip_psu_2_25':'4000',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'12826', 	 'ltip_rsu_3_26':'840', 	 'ltip_rsu_2_26':'477', 	 'ltip_rsu_2_27':'477', 	 'oca_3_24':'0', 	 'total_anl_02':'1794', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'TREVINO ALVA BERENICE SOLEDAD', 	 'email':'btrevino@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6171', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'159', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROMERO CORONEL JOSE CHRISTIAN', 	 'email':'jcromero@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'84', 	 'ltip_rsu_2_25':'83', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6197', 	 'ltip_rsu_3_26':'83', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'183', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MONTEVERDE GOMEZ ROBERTO', 	 'email':'rmonteverde@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'84', 	 'ltip_rsu_2_25':'83', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6197', 	 'ltip_rsu_3_26':'83', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'183', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'TAPIA CORONA ELIAS JORGE ALBERTO', 	 'email':'ejtapia@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'300', 	 'ltip_rsu_2_25':'300', 	 'ltip_rsu_1_25':'170', 	 'mip_rsu_1_23':'790', 	 'mip_rsu_2_24':'790', 	 'mip_rsu_3_25':'790', 	 'mip_psu_1_24':'3520', 	'mip_psu_2_25':'3520',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'10180', 	 'ltip_rsu_3_26':'300', 	 'ltip_rsu_2_26':'170', 	 'ltip_rsu_2_27':'170', 	 'oca_3_24':'0', 	 'total_anl_02':'640', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GUZMAN BERAZALUCE CARLOS', 	 'email':'cguzmanb@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'84', 	 'ltip_rsu_2_25':'83', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6197', 	 'ltip_rsu_3_26':'83', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'183', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RESENDIZ GAMBOA MARIA FERNANDA', 	 'email':'mfresendiz@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6173', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'167', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BLAZQUEZ REYES ROCIO', 	 'email':'rblazquez@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'176', 	 'ltip_rsu_2_25':'177', 	 'ltip_rsu_1_25':'100', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6433', 	 'ltip_rsu_3_26':'177', 	 'ltip_rsu_2_26':'100', 	 'ltip_rsu_2_27':'100', 	 'oca_3_24':'0', 	 'total_anl_02':'377', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SPERANZA PASQUALE', 	 'email':'psperanza@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'114', 	 'ltip_rsu_2_25':'113', 	 'ltip_rsu_1_25':'204', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6411', 	 'ltip_rsu_3_26':'113', 	 'ltip_rsu_2_26':'203', 	 'ltip_rsu_2_27':'203', 	 'oca_3_24':'0', 	 'total_anl_02':'519', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MEJIA HUERTA FELIX BENJAMIN', 	 'email':'fmejiah@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6149', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'141', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'STANKIEWICZ CHAVEZ ANGELINA GRISELDA ', 	 'email':'astankiewicz@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6153', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'147', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RUIZ DAVIES FEDERICO JOHN', 	 'email':'fjruiz@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'60', 	 'ltip_rsu_2_25':'60', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3584', 	 'ltip_rsu_3_26':'60', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'126', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SEVY REZNIK RAFAEL', 	 'email':'rsevy@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'84', 	 'ltip_rsu_2_25':'83', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6193', 	 'ltip_rsu_3_26':'83', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'177', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ESCAMILLA EMBARCADERO JUAN CARLOS', 	 'email':'jescamilla@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6179', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'171', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VILLASENOR CORIA RICARDO', 	 'email':'rvillasenor@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6171', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'159', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MADRAZO UBACH MARIO', 	 'email':'mmadrazo@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'720', 	 'mip_rsu_2_24':'720', 	 'mip_rsu_3_25':'720', 	 'mip_psu_1_24':'3200', 	'mip_psu_2_25':'3200',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'8740', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RIOS LOPEZ ISABEL', 	 'email':'irios@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'640', 	 'mip_rsu_2_24':'640', 	 'mip_rsu_3_25':'640', 	 'mip_psu_1_24':'2875', 	'mip_psu_2_25':'2875',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'7839', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'141', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VELASCO OCEGUERA ALEJANDRO', 	 'email':'avelasco@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'640', 	 'mip_rsu_2_24':'640', 	 'mip_rsu_3_25':'640', 	 'mip_psu_1_24':'2875', 	'mip_psu_2_25':'2875',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'7850', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ARANALDE AGUILAR ALVAREZ ALFONSO ENRICO', 	 'email':'aaranalde@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'86', 	 'ltip_rsu_2_25':'87', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6203', 	 'ltip_rsu_3_26':'87', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'187', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SALAZAR FLORES MELISSA MICHELLE', 	 'email':'mmsalazar@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'570', 	 'mip_rsu_2_24':'570', 	 'mip_rsu_3_25':'570', 	 'mip_psu_1_24':'2560', 	'mip_psu_2_25':'2560',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'7010', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MIRANDA LUJAN JUAN PABLO', 	 'email':'jmirandal@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'570', 	 'mip_rsu_2_24':'570', 	 'mip_rsu_3_25':'570', 	 'mip_psu_1_24':'2560', 	'mip_psu_2_25':'2560',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'7023', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'157', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERRERA CONTRERAS AUREA PATRICIA', 	 'email':'pherrera@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'80', 	 'ltip_rsu_2_25':'80', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'570', 	 'mip_rsu_2_24':'570', 	 'mip_rsu_3_25':'570', 	 'mip_psu_1_24':'2560', 	'mip_psu_2_25':'2560',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'7036', 	 'ltip_rsu_3_26':'80', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'174', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VIDAL MARTINEZ GUSTAVO', 	 'email':'gvidal@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6160', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'LIZASO IVETTE', 	 'email':'ilizaso@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'106', 	 'ltip_rsu_2_25':'107', 	 'ltip_rsu_1_25':'64', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6257', 	 'ltip_rsu_3_26':'107', 	 'ltip_rsu_2_26':'63', 	 'ltip_rsu_2_27':'63', 	 'oca_3_24':'0', 	 'total_anl_02':'233', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'TAMAYO TORO ANDRES FELIPE', 	 'email':'atamayo@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'80', 	 'ltip_rsu_2_25':'80', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6186', 	 'ltip_rsu_3_26':'80', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'174', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MENDES DE OLIVEIRA ADENIL', 	 'email':'amendes@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'84', 	 'ltip_rsu_2_25':'83', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6193', 	 'ltip_rsu_3_26':'83', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'177', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'REYES HERRERA ELIAS ESKANI JURHIAT', 	 'email':'ereyes@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'164', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6297', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'163', 	 'ltip_rsu_2_27':'163', 	 'oca_3_24':'0', 	 'total_anl_02':'403', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BAUTISTA GUTIERREZ HECTOR AGUSTIN', 	 'email':'hbautista@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6177', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'163', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VALENCIA RODRIGUEZ GERARDO', 	 'email':'gvalencia@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6160', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MARQUEZ LIRA DIANA MELINA', 	 'email':'dmarquez@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6160', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'COELLO VARGAS RUBEN', 	 'email':'rcoello@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6171', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'159', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ISLAS PADILLA LUIS', 	 'email':'luislas@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'106', 	 'ltip_rsu_2_25':'107', 	 'ltip_rsu_1_25':'60', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6253', 	 'ltip_rsu_3_26':'107', 	 'ltip_rsu_2_26':'60', 	 'ltip_rsu_2_27':'60', 	 'oca_3_24':'0', 	 'total_anl_02':'227', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PAYAN GOMEZ ADOLFO MOISES', 	 'email':'apayan@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'84', 	 'ltip_rsu_2_25':'83', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6197', 	 'ltip_rsu_3_26':'83', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'183', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BRAVO SANSORES MARIO', 	 'email':'mbravos@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'80', 	 'ltip_rsu_2_25':'80', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6186', 	 'ltip_rsu_3_26':'80', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'174', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ZUMARRAGA CONTRERAS JUAN PABLO', 	 'email':'jzumarraga@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'86', 	 'ltip_rsu_2_25':'87', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6203', 	 'ltip_rsu_3_26':'87', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'187', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CRISTOBAL PULIDO HUGO', 	 'email':'hcristobal@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'90', 	 'ltip_rsu_2_25':'90', 	 'ltip_rsu_1_25':'174', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6334', 	 'ltip_rsu_3_26':'90', 	 'ltip_rsu_2_26':'173', 	 'ltip_rsu_2_27':'173', 	 'oca_3_24':'0', 	 'total_anl_02':'436', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CANALES RUIZ JOSE EDUARDO', 	 'email':'ecanales@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6153', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'147', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RAMIREZ PEREZ ISRAEL', 	 'email':'iramirez@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6153', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'147', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'TOLEDO TOVAR RAYMUNDO JAVIER', 	 'email':'rtoledo@aeromexico.com', 	 'bloque':'2', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6171', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'159', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ENRIQUEZ ORTIZ OSCAR', 	 'email':'oenriquezo@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VELAZQUEZ ALVAREZ MARIA JOSE', 	 'email':'mjvelazquez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VILLARREAL REYNAUD MARIANO', 	 'email':'mvillarrealr@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3464', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALCOCER DELANO JOSE', 	 'email':'jalcocer@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'', 	 'ltip_rsu_2_27':'', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'DIAZ INIESTRA SERGIO EDUARDO', 	 'email':'sdiaz@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3621', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'159', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ESPANA MENDIVIL FRANCISCO JAVIER', 	 'email':'fespana@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'DIAZ GUADARRAMA MARICELA', 	 'email':'mardiazg@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GUERRERO VARGAS FRANCO', 	 'email':'fguerrero@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RODRIGUEZ RUIZ BERTHA VERONICA', 	 'email':'brodriguez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'TALAVERA HERNANDEZ ALEJANDRO', 	 'email':'atalaverah@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERNANDEZ VILLA GADIEL', 	 'email':'ghernandezv@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MARTINSON MICHELE DENISE', 	 'email':'mmartinson@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ESQUEDA RUIZ FERNANDO', 	 'email':'fesqueda@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PINEDA CHAZARO ZOILA ELENA', 	 'email':'zpineda@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'64', 	 'ltip_rsu_2_25':'63', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3593', 	 'ltip_rsu_3_26':'63', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'137', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VAZQUEZ MELLADO GAVIDIA MARIA GABRIELA', 	 'email':'gvazquezmellado@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CASTRO FOULKES ANDRES', 	 'email':'acastrof@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'60', 	 'ltip_rsu_2_25':'60', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3586', 	 'ltip_rsu_3_26':'60', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'134', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PENICHE BETANCOURT LUIS FERNANDO', 	 'email':'lpeniche@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'74', 	 'ltip_rsu_2_25':'73', 	 'ltip_rsu_1_25':'156', 	 'mip_rsu_1_23':'860', 	 'mip_rsu_2_24':'860', 	 'mip_rsu_3_25':'860', 	 'mip_psu_1_24':'3835', 	'mip_psu_2_25':'3835',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'10553', 	 'ltip_rsu_3_26':'73', 	 'ltip_rsu_2_26':'157', 	 'ltip_rsu_2_27':'157', 	 'oca_3_24':'0', 	 'total_anl_02':'387', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MULEIRO ALVAREZ FELIPE', 	 'email':'fmuleiro@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'430', 	 'mip_rsu_2_24':'430', 	 'mip_rsu_3_25':'430', 	 'mip_psu_1_24':'1920', 	'mip_psu_2_25':'1920',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'5130', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GARMENDIA PULIDO ALBERTO', 	 'email':'agarmendia@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALCALA CORTES ALBERTO', 	 'email':'aalcala@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'360', 	 'mip_rsu_2_24':'360', 	 'mip_rsu_3_25':'360', 	 'mip_psu_1_24':'1605', 	'mip_psu_2_25':'1605',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'4290', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GROBEISEN DUQUE GALIA', 	 'email':'ggrobeisen@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'360', 	 'mip_rsu_2_24':'360', 	 'mip_rsu_3_25':'360', 	 'mip_psu_1_24':'1605', 	'mip_psu_2_25':'1605',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'4290', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALVAREZ CASTELLANOS ROBERT', 	 'email':'ralvarez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'360', 	 'mip_rsu_2_24':'360', 	 'mip_rsu_3_25':'360', 	 'mip_psu_1_24':'1605', 	'mip_psu_2_25':'1605',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'4290', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'YEPEZ DELGADO MAHETZI DESIREE', 	 'email':'myepez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERNANDEZ MOCTEZUMA JOSE GUSTAVO', 	 'email':'jghernandezm@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ESPINOSA VERA JORDANA RUTHVE', 	 'email':'jrespinosa@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'430', 	 'mip_rsu_2_24':'430', 	 'mip_rsu_3_25':'430', 	 'mip_psu_1_24':'1920', 	'mip_psu_2_25':'1920',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'5130', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROCCATTI HERNANDEZ JOSE FRANCISCO', 	 'email':'jroccatti@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SALIM SALOMON IVETTE', 	 'email':'isalim@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6160', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'REYES ELIZONDO ALEJANDRA', 	 'email':'areyese@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'360', 	 'mip_rsu_2_24':'360', 	 'mip_rsu_3_25':'360', 	 'mip_psu_1_24':'1605', 	'mip_psu_2_25':'1605',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'4290', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MATA VAQUERA HECTOR ORLANDO', 	 'email':'hmata@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3466', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'74', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BARROSO URIBE MARCO AURELIO', 	 'email':'mbarroso@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'DE LA MORA CRUZ LUIS EDUARDO', 	 'email':'lde@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3603', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'147', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CASTRO MENDOZA GONZALO', 	 'email':'gcastro@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'LOPEZ MIGUEZ ISRAEL', 	 'email':'ilopezm@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'60', 	 'ltip_rsu_2_25':'60', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'430', 	 'mip_rsu_2_24':'430', 	 'mip_rsu_3_25':'430', 	 'mip_psu_1_24':'1920', 	'mip_psu_2_25':'1920',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'5284', 	 'ltip_rsu_3_26':'60', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'126', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PARADIS BRAVO MARIA DOLORES', 	 'email':'mparadis@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALBA ESCAMILLA ANDRES MAURICIO', 	 'email':'aalba@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'70', 	 'ltip_rsu_2_25':'70', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'500', 	 'mip_rsu_2_24':'500', 	 'mip_rsu_3_25':'500', 	 'mip_psu_1_24':'2240', 	'mip_psu_2_25':'2240',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'6160', 	 'ltip_rsu_3_26':'70', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'150', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GARZA GUZMAN MARIANA', 	 'email':'mgarza@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'360', 	 'mip_rsu_2_24':'360', 	 'mip_rsu_3_25':'360', 	 'mip_psu_1_24':'1605', 	'mip_psu_2_25':'1605',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'4290', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GOMEZ RODRIGUEZ IRAN', 	 'email':'igomez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'360', 	 'mip_rsu_2_24':'360', 	 'mip_rsu_3_25':'360', 	 'mip_psu_1_24':'1605', 	'mip_psu_2_25':'1605',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'4290', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PINEDA BADILLO ALFREDO', 	 'email':'apineda@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'56', 	 'ltip_rsu_2_25':'57', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3577', 	 'ltip_rsu_3_26':'57', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'123', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MATADAMAS CANSECO GUILLERMO RENE', 	 'email':'gmatadamas@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERNANDEZ GUEVARA NANCY DOLORES', 	 'email':'ndhernandez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SEGURA CASTILLO HECTOR GUADALUPE', 	 'email':'hsegura@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GOMEZ RODRIGUEZ SANDRA', 	 'email':'sgomez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BYRD MARTINEZ ARTURO', 	 'email':'abyrd@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VELAZQUEZ JOSE LUIS', 	 'email':'jvelazquez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERRERA FLORES JOSE GPE FERNANDO', 	 'email':'fherrera@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ESPINOZA CASTRO VICTOR MANUEL', 	 'email':'vespinozac@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'FERNANDEZ ANTONIO NACIR', 	 'email':'nafernandez@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3470', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'80', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GONZALEZ GONZALEZ SALAS ANDREA', 	 'email':'angonzalezg@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SERRANO GAVINO MARIA ANGELINA', 	 'email':'maserrano@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MARTINEZ MORENO LUISA JOSEFINA', 	 'email':'lmartinezmo@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3464', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ANDRADE ZACARIAS SALVADOR', 	 'email':'sandrade@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'BANOS ENCISO ERIKA', 	 'email':'ebanos@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'64', 	 'ltip_rsu_2_25':'63', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3593', 	 'ltip_rsu_3_26':'63', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'137', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'AGUILAR AYALA EDGAR', 	 'email':'eaguilara@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3464', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RAYMUNDO RUIZ FRANCISCO', 	 'email':'fraymundo@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3464', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GUERRERO GARCIA LUCERO NOHEMI', 	 'email':'lnguerrero@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GRANADOS LOPEZ ARELY JAQUELINE', 	 'email':'agranados@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'LOPEZ DE ARKOS MARTINEZ ERNESTO JESUS', 	 'email':'elopezdearkos@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'TAVIZON CEBALLOS ADOLFO SALVADOR', 	 'email':'atavizon@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'430', 	 'mip_rsu_2_24':'430', 	 'mip_rsu_3_25':'430', 	 'mip_psu_1_24':'1920', 	'mip_psu_2_25':'1920',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'5130', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MORON CALVILLO VALENTIN', 	 'email':'vmoron@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'HERNANDEZ MENDIOLA MARIA DEL PILAR', 	 'email':'mhernandezm@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'GALVAN ALCALA DANIELA ISAURA', 	 'email':'dgalvan@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'PICHARDO ROMERO ROMAN', 	 'email':'rpichardor@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MERCADO RESENDIZ GISELA NOEMI', 	 'email':'gmercado@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SANCHEZ GARCIA ADRIANA', 	 'email':'asanchezg@aeromexico.com', 	 'bloque':'3', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'0', 	 'mip_rsu_1_23':'290', 	 'mip_rsu_2_24':'290', 	 'mip_rsu_3_25':'290', 	 'mip_psu_1_24':'1280', 	'mip_psu_2_25':'1280',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'3430', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'0', 	 'ltip_rsu_2_27':'0', 	 'oca_3_24':'0', 	 'total_anl_02':'0', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'JOVEL MUNGUIA CARLOS ROBERTO', 	 'email':'cjovel@aeromexico.com', 	 'bloque':'5', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'1436', 	 'ltip_rsu_2_25':'1437', 	 'ltip_rsu_1_25':'816', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'14197', 	 'oca_2_24':'14197', 	 'total_tal_01':'32083', 	 'ltip_rsu_3_26':'1437', 	 'ltip_rsu_2_26':'817', 	 'ltip_rsu_2_27':'817', 	 'oca_3_24':'14196', 	 'total_anl_02':'17267', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MORGAN JEFFREY WALKER', 	 'email':'jmorgan@aeromexico.com', 	 'bloque':'6', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'444', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'21295', 	 'oca_2_24':'21295', 	 'total_tal_01':'43034', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'443', 	 'ltip_rsu_2_27':'443', 	 'oca_3_24':'0', 	 'total_anl_02':'886', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RAMOS RUIZ EMILIO', 	 'email':'eramosru@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'434', 	 'ltip_rsu_2_25':'433', 	 'ltip_rsu_1_25':'246', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'1113', 	 'ltip_rsu_3_26':'433', 	 'ltip_rsu_2_26':'247', 	 'ltip_rsu_2_27':'247', 	 'oca_3_24':'0', 	 'total_anl_02':'927', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CONVALIA ZELADA DIEGO EDUARDO', 	 'email':'dconvalia@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'546', 	 'ltip_rsu_2_25':'547', 	 'ltip_rsu_1_25':'314', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'1407', 	 'ltip_rsu_3_26':'547', 	 'ltip_rsu_2_26':'313', 	 'ltip_rsu_2_27':'313', 	 'oca_3_24':'0', 	 'total_anl_02':'1173', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MARTINEZ SOTO GERARDO', 	 'email':'gmartinezs@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'374', 	 'ltip_rsu_2_25':'373', 	 'ltip_rsu_1_25':'214', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'961', 	 'ltip_rsu_3_26':'373', 	 'ltip_rsu_2_26':'213', 	 'ltip_rsu_2_27':'213', 	 'oca_3_24':'0', 	 'total_anl_02':'799', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'FARIAS CRUZADO KAREN ALEJANDRA', 	 'email':'kfarias@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'320', 	 'ltip_rsu_2_25':'320', 	 'ltip_rsu_1_25':'184', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'824', 	 'ltip_rsu_3_26':'320', 	 'ltip_rsu_2_26':'183', 	 'ltip_rsu_2_27':'183', 	 'oca_3_24':'0', 	 'total_anl_02':'686', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MORENO LAGOS MANUEL ALEJANDRO', 	 'email':'mmorenol@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'370', 	 'ltip_rsu_2_25':'370', 	 'ltip_rsu_1_25':'210', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'950', 	 'ltip_rsu_3_26':'370', 	 'ltip_rsu_2_26':'210', 	 'ltip_rsu_2_27':'210', 	 'oca_3_24':'0', 	 'total_anl_02':'790', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ALMANZA GALVEZ JUAN CARLOS', 	 'email':'jalmanzag@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'294', 	 'ltip_rsu_2_25':'293', 	 'ltip_rsu_1_25':'170', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'757', 	 'ltip_rsu_3_26':'293', 	 'ltip_rsu_2_26':'170', 	 'ltip_rsu_2_27':'170', 	 'oca_3_24':'0', 	 'total_anl_02':'633', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'EDWARDS MORENO MORGAN VALENTINA', 	 'email':'medwards@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'354', 	 'ltip_rsu_2_25':'353', 	 'ltip_rsu_1_25':'200', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'907', 	 'ltip_rsu_3_26':'353', 	 'ltip_rsu_2_26':'200', 	 'ltip_rsu_2_27':'200', 	 'oca_3_24':'0', 	 'total_anl_02':'753', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SOLARES Y SOLARES ADAN ANTONIO', 	 'email':'asolaresy@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'410', 	 'ltip_rsu_2_25':'410', 	 'ltip_rsu_1_25':'236', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'1056', 	 'ltip_rsu_3_26':'410', 	 'ltip_rsu_2_26':'237', 	 'ltip_rsu_2_27':'237', 	 'oca_3_24':'0', 	 'total_anl_02':'884', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RAMIREZ VILCHIS MARIO ALBERTO', 	 'email':'mramirezvi@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'56', 	 'ltip_rsu_2_25':'57', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'147', 	 'ltip_rsu_3_26':'57', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'123', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MEDINA GONZALEZ LUCERO ANGELICA', 	 'email':'lmedinag@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'60', 	 'ltip_rsu_2_25':'60', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'154', 	 'ltip_rsu_3_26':'60', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'126', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SANCHEZ OBREGON LETICIA', 	 'email':'lsanchezo@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'86', 	 'ltip_rsu_2_25':'87', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'223', 	 'ltip_rsu_3_26':'87', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'187', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'SALDANA SANCHEZ LUIS ANTONIO', 	 'email':'lsaldanas@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'197', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'163', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MORETT GOMEZ TAGLE ROSA MARIA', 	 'email':'rgomezt@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'64', 	 'ltip_rsu_2_25':'63', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'163', 	 'ltip_rsu_3_26':'63', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'137', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'AVILA REYES MARCO ROMAN', 	 'email':'mavilar@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'44', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'197', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'43', 	 'ltip_rsu_2_27':'43', 	 'oca_3_24':'0', 	 'total_anl_02':'163', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'VIVANCO DOMINGUEZ MARIO ENRIQUE', 	 'email':'mvivanco@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'66', 	 'ltip_rsu_2_25':'67', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'169', 	 'ltip_rsu_3_26':'67', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'141', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MAYA DESDIER CARLOS', 	 'email':'cmaya@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'76', 	 'ltip_rsu_2_25':'77', 	 'ltip_rsu_1_25':'46', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'199', 	 'ltip_rsu_3_26':'77', 	 'ltip_rsu_2_26':'47', 	 'ltip_rsu_2_27':'47', 	 'oca_3_24':'0', 	 'total_anl_02':'171', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MORALES MOLINA CARLOS DOMENICK', 	 'email':'dmoralesm@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'64', 	 'ltip_rsu_2_25':'63', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'163', 	 'ltip_rsu_3_26':'63', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'137', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'FIGUEROA PEREZ MONSERRAT', 	 'email':'mfigueroap@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'100', 	 'ltip_rsu_2_25':'100', 	 'ltip_rsu_1_25':'56', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'256', 	 'ltip_rsu_3_26':'100', 	 'ltip_rsu_2_26':'57', 	 'ltip_rsu_2_27':'57', 	 'oca_3_24':'0', 	 'total_anl_02':'214', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'CUCCARESE ALEJANDRO JAVIER', 	 'email':'acuccarese@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'56', 	 'ltip_rsu_2_25':'57', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'147', 	 'ltip_rsu_3_26':'57', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'123', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MURADAS RUIZ JUAN CARLOS', 	 'email':'jmuradas@aeromexico.com', 	 'bloque':'4', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'86', 	 'ltip_rsu_2_25':'87', 	 'ltip_rsu_1_25':'50', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'223', 	 'ltip_rsu_3_26':'87', 	 'ltip_rsu_2_26':'50', 	 'ltip_rsu_2_27':'50', 	 'oca_3_24':'0', 	 'total_anl_02':'187', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'LOPEZ MEYER GILBERTO', 	 'email':'glopezme@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'504', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'504', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'503', 	 'ltip_rsu_2_27':'503', 	 'oca_3_24':'0', 	 'total_anl_02':'1006', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROSAS RAMIREZ SALVADOR', 	 'email':'srosasr@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'34', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'COROMINAS MANZANEDO MARISOL', 	 'email':'mcorominas@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'34', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'URIBE HERNANDEZ AGUSTIN RODRIGO', 	 'email':'auribeh@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'34', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROLON ACOSTA ANDREA NAYELI', 	 'email':'arolon@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'36', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'36', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'37', 	 'ltip_rsu_2_27':'37', 	 'oca_3_24':'0', 	 'total_anl_02':'74', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'RAMIREZ HURTADO OSVALDO', 	 'email':'oramirezhu@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'34', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'34', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'33', 	 'ltip_rsu_2_27':'33', 	 'oca_3_24':'0', 	 'total_anl_02':'66', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'MEDELLIN SALINAS GERARDO STEVEN', 	 'email':'gmedellin@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'30', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'30', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'30', 	 'ltip_rsu_2_27':'30', 	 'oca_3_24':'0', 	 'total_anl_02':'60', 	 }, 
	{ "viewtable":"true", "contratoPdf":"false", 	 'empleado':'ROLON AVELINO', 	 'email':'avrolon@aeromexico.com', 	 'bloque':'NA', 	 'ltip_rsu_1_23':'0', 	 'ltip_rsu_2_24':'0', 	 'ltip_rsu_3_25':'0', 	 'ltip_rsu_1_24':'0', 	 'ltip_rsu_2_25':'0', 	 'ltip_rsu_1_25':'40', 	 'mip_rsu_1_23':'0', 	 'mip_rsu_2_24':'0', 	 'mip_rsu_3_25':'0', 	 'mip_psu_1_24':'0', 	'mip_psu_2_25':'0',	 'oca_1_24':'0', 	 'oca_2_24':'0', 	 'total_tal_01':'40', 	 'ltip_rsu_3_26':'0', 	 'ltip_rsu_2_26':'40', 	 'ltip_rsu_2_27':'40', 	 'oca_3_24':'0', 	 'total_anl_02':'80', 	 }
 ]

const apValid = () => {
	// Obtener el correo del localStorage
	const storedEmail = localStorage.getItem('email');

	// Verificar si el correo está en la lista de colaboradores
	return fakeStockER.some(colaborador => colaborador.email === storedEmail);
};

const prodUL = `${process.env.REACT_APP_SRC_URL}`;

const AccionesPersonales = () => {


	const [filteredColaboradores, setFilteredColaboradores] = useState([]);
	const [isValid, setIsValid] = useState(false);

	const getLeftPartOfEmail = (email) => {
		if (email && email.includes('@')) {
			return email.split('@')[0];
		}
		return '';
	};

	useEffect(() => {
		window.scrollTo(0, 0);

		const storedEmail = localStorage.getItem('email');
		const filtered = fakeStockER.filter(colaborador => colaborador.email === storedEmail);
		setIsValid(apValid());
		setFilteredColaboradores(filtered);
	}, []);


	const emailStore = localStorage.getItem('email');
	const inicioCorre = getLeftPartOfEmail(emailStore);
	const hrefURL = `${prodUL}/2024/pdf_incentivos/${inicioCorre}.pdf`;
	const name_pdf = `archivo_${inicioCorre}.pdf`;

	const formatCurrency = (value) => {
		return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
	};

	const formatNumberString = (numberString) => {
		if (typeof numberString !== 'string') {
			return '';
		}
		return numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};


	const colaboradorNombres = filteredColaboradores.map((colaborador) => colaborador.nombre).join(", ");
	const bloque = filteredColaboradores.map((colaborador) => colaborador.bloque);
	const ltip_rsu_1_24_v = filteredColaboradores.map((colaborador) => colaborador.ltip_rsu_1_24);
	const ltip_rsu_2_25_v = filteredColaboradores.map((colaborador) => colaborador.ltip_rsu_2_25);
	const ltip_rsu_1_25_v = filteredColaboradores.map((colaborador) => colaborador.ltip_rsu_1_25);
	const total_anl_02 = filteredColaboradores.map((colaborador) => colaborador.total_anl_02);
	
	
	const pdf = filteredColaboradores.map((colaborador) => colaborador.contratoPdf);
	const pdfValue = pdf[0];

	const viewt = filteredColaboradores.map((colaborador) => colaborador.viewtable);
	const viewtb = viewt[0];
	
	const ltipValues = filteredColaboradores.map((colaborador) => ({
		ltip_rsu_1_23: formatNumberString(colaborador.ltip_rsu_1_23),
		ltip_rsu_1_24: formatNumberString(colaborador.ltip_rsu_1_24),
		ltip_rsu_2_24: formatNumberString(colaborador.ltip_rsu_2_24),
		ltip_rsu_3_25: formatNumberString(colaborador.ltip_rsu_3_25),
		ltip_rsu_2_25: formatNumberString(colaborador.ltip_rsu_2_25),
		ltip_rsu_1_25: formatNumberString(colaborador.ltip_rsu_1_25),
	}));

	const mipValues = filteredColaboradores.map((colaborador) => ({
		mip_rsu_1_23: formatNumberString(colaborador.mip_rsu_1_23),
		mip_rsu_2_24: formatNumberString(colaborador.mip_rsu_2_24),
		mip_rsu_3_25 : formatNumberString(colaborador.mip_rsu_3_25),
		mip_psu_1_24: formatNumberString(colaborador.mip_psu_1_24),
		mip_psu_2_25: formatNumberString(colaborador.mip_psu_2_25),
	}));

	const ocaValues = filteredColaboradores.map((colaborador) => ({
		oca_1_24: formatNumberString(colaborador.oca_1_24),
		oca_2_24: formatNumberString(colaborador.oca_2_24),
		oca_3_24: formatNumberString(colaborador.oca_3_24),
	}));

	const totalTalValues = filteredColaboradores.map((colaborador) => formatNumberString(colaborador.total_tal_01));
	const totalAnlValues = filteredColaboradores.map((colaborador) => formatNumberString(colaborador.total_anl_02));

	if (!isValid) {
		return <p>Usuario no autorizado.</p>;
	}

	return (

		<motion.div
			key="page-procesos-administrativos"
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			className="full-container">
			{/* <Arriba /> */}
			<div className="content-holder-talent">
				<header className="talent-header relative py-40">
					<div className="absolute left-1/2 top-1/2 z-20 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
						<h1 className="mb-1 text-center text-5xl font-bold leading-none text-white opacity-100">
							{i18next.language === "es" ? "Incentivos en acciones" : "Stock incentives"}
						</h1>
						<h2 class="text-center text-2xl font-bold leading-none text-white opacity-100">{i18next.language === "es" ? "Información a enero 2025" : "Information until January 2025"}</h2>
					</div>
					<div className="absolute left-0 top-0 z-10 h-full w-full bg-amDarkBlue opacity-75"></div>
					<img
						src={Banner}
						alt="Talent Planning"
						className="absolute left-0 top-0 z-0 h-full w-full object-cover"
					/>
				</header>
				<section className="py-16" style={{ background: "#F6F8F9" }}>
					{/* { pdfValue == 'true' ?
						<>
							<div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
								<div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
									<table class="min-w-full text-left text-sm whitespace-nowrap">		
										<thead class="uppercase tracking-wider bg-[#035cf7] text-white">
											<tr>
												<th scope="col" class="px-6 py-2 text-center alert-warning"><h3 style={{ fontSize: "2rem"}}>INFORMACION IMPORTANTE</h3></th>
											</tr>
										</thead>
									</table>
								</div>
								<p class="rewards_title text-left" style={{ fontSize: "1.5rem" }}>Da clic en el siguiente link para descargar el contrato correspondiente al LTIP 2024</p>
								<a href={hrefURL} target='_blank' download={name_pdf} style={{ borderRadius: "50px",padding: "17px 30px 17px", fontSize: "14px",textTransform: "uppercase",fontWeight: "500",backgroundColor: "#e91b2f", border: "1px solid #e91b2f", color: "white", cursor: "pointer", webkitAppearance: "none", transition: "0.8s cubic-bezier(0.2, 0.8, 0.2, 1)", opacity: "1",left: "50%", bottom: "50%",display: "block",margin: "0" }}>Descargar PDF</a>
							</div>
						</>
					: null } */}
					{
						viewtb == 'false' ? null :
						
							<>

								<div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
									{/* INICIA TABLA PARA ACCIONES LIBERADAS  */}
									<div class="">
										<p class="rewards_title text-left" style={{ fontSize: "1.5rem" }}>ACCIONES LIBERADAS</p>
										<div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
											<table class="min-w-full text-left text-sm whitespace-nowrap">
												{bloque == "1" || bloque == "2" || bloque == "3" || bloque == "4" || bloque == "5" || bloque == "6" || bloque == "NA" ? (
													ltip_rsu_1_24_v != "0" || ltip_rsu_2_25_v != "0" || ltip_rsu_1_25_v != "0" ? (
														<>
															<thead className="uppercase tracking-wider bg-[#035cf7] text-white">
																<tr>
																	<th scope="col" className="px-6 py-2 text-white" colSpan={3}><p>Incentivo a largo plazo (LTIP)</p></th>
																</tr>
															</thead>
															<tbody>
																<tr className="">
																	<th scope="row" className="px-6 py-4">
																		<p>1/3 en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
																	</th>
																	{ltipValues.map((values, index) => (
																		<>
																			<td className="px-6 py-4 border-b"  >
																				<div className="space-y-2">
																					{bloque == "1" ? (<div className="py-2">2022 RSU Parte 1 - 2023 </div>) : null}
																					{bloque == "1" ? (<div className="py-2">2022 RSU Parte 2 - 2024</div>) : null}
																					{bloque == "1" ? (<div className="py-2">2022 RSU Parte 3 - 2025</div>) : null}
																					{values.ltip_rsu_1_24 != '0'? ((bloque != "NA" && bloque != "6") ? (<div className="py-2">2023 RSU Parte 1 - 2024</div>) : null) : null}
																					{values.ltip_rsu_2_25 != '0'? ((bloque != "NA" && bloque != "6") ? (<div className="py-2">2023 RSU Parte 2 - 2025</div>) : null) : null}
																					{values.ltip_rsu_1_25 != '0'? (<div className="py-2">2024 LTIP Parte 1 - 2025</div>) : null}
																				</div>
																			</td>
																			<td key={index} className="px-6 py-4 border-b" rowSpan="3">
																				<div className="space-y-2">
																					{bloque == "1" ? (<div className="py-2 text-right">{values.ltip_rsu_1_23}</div>) : null}
																					{bloque == "1" ? (<div className="py-2 text-right">{values.ltip_rsu_2_24}</div>) : null}
																					{bloque == "1" ? (<div className="py-2 text-right">{values.ltip_rsu_3_25}</div>) : null}
																					{values.ltip_rsu_1_24 != '0'? (<div className="py-2 text-right">{values.ltip_rsu_1_24}</div>) : null } 
																					{values.ltip_rsu_2_25 != '0'? (<div class="py-2 text-right">{formatNumberString(values.ltip_rsu_2_25)}</div>) : null } 
																					{values.ltip_rsu_1_25 != '0'? (<div class="py-2 text-right">{formatNumberString(values.ltip_rsu_1_25)}</div>) : null } 
																					
																				</div>
																			</td>
																		</>
																	))}
																</tr>
															</tbody>
														</>
													):null
												) : null}
												{bloque == "1" || bloque == "2" || bloque == "3" ? (
													<>
														<thead class="uppercase tracking-wider bg-[#035cf7] text-white">
															<tr><th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Management Incentive Plan (MIP)</p></th></tr>
														</thead>
														<tbody>
															<tr class="">
																<th scope="row" class="px-6 py-4">
																	<p>40% en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
																	<p>60% en acciones sujetas al desempeño del negocio (PSU), con un periodo de liberación del 50%<br /> en el segundo año y 50% en el tercer año posterior a la asignación.</p>
																</th>
																<td class="px-6 py-4 border-b" rowspan="3">
																	<div class="space-y-2">
																		<div class="py-2">RSU Parte 1 - 2023</div>
																		<div class="py-2">RSU Parte 2 - 2024</div>
																		<div class="py-2">RSU Parte 3 - 2025</div>
																		<div class="py-2">PSU Parte 1 - 2024</div>
																		<div class="py-2">PSU Parte 2 - 2025</div>
																	</div>
																</td>
																{mipValues.map((values, index) => (
																	<td key={index} className="px-6 py-4 border-b" rowSpan="3">
																		<div className="space-y-2">
																			<div className="py-2 text-right">{values.mip_rsu_1_23}</div>
																			<div className="py-2 text-right">{values.mip_rsu_2_24}</div>
																			<div className="py-2 text-right">{values.mip_rsu_3_25}</div>
																			<div className="py-2 text-right">{values.mip_psu_1_24}</div>
																			<div className="py-2 text-right">{values.mip_psu_2_25}</div>
																		</div>
																	</td>
																))}
															</tr>
														</tbody>
													</>
												) : null}
												{bloque == "5" || bloque == "6" ? (
													<>
														<thead class="uppercase tracking-wider bg-[#035cf7] text-white">
															<tr><th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Off Cycle Award</p></th></tr>
														</thead>
														<tbody>
															<tr class="">
																<th scope="row" class="px-6 py-4">
																	{bloque == "5" ? (
																		<p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
																	) : <p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un 50% cada año.</p>}
																</th>
																<td class="px-6 py-4 border-b">
																	<div class="space-y-2">
																		<div class="py-2">RSU Parte 1 - 2024</div>
																		{bloque == "5" || bloque == "6" ? (<div class="py-2">RSU Parte 2 - 2025</div>) : null}
																	</div>
																</td>
																{ocaValues.map((values, index) => (
																	<td key={index} className="px-6 py-4 border-b">
																		<div className="space-y-2">
																			<div className="py-2 text-right">{values.oca_1_24}</div>
																			<div className="py-2 text-right">{values.oca_2_24}</div>
																		</div>
																	</td>
																))}
															</tr>
														</tbody>
													</>
												) : null}

												<tfoot class="border-t-2 dark:border-neutral-600">
													<tr>
														<th class="px-6 py-4"></th>
														<td class="px-6 py-4"><strong>Total Acciones Liberadas</strong></td>
														{totalTalValues.map((value, index) => (
															<td key={index} className="px-6 py-4 text-right"><strong>{value}</strong></td>
														))}
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
									{/* TERMINA TABLA PARA ACCIONES LIBERADAS  */}
									{/* INICIA TABLA PARA ACCIONES NOOOOOOO LIBERADAS  */}
									{total_anl_02 != "0" ? (
										<>
										<div class="">
										<p class="rewards_title text-left" style={{ fontSize: "1.5rem" }}>ACCIONES RESTRINGIDAS</p>
										<div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
											<table class="min-w-full text-left text-sm whitespace-nowrap">
												{bloque == "1" || bloque == "2" || bloque == "3" || bloque == "4" || bloque == "5" || bloque == "6" || bloque == "NA"? (
													<>
														<thead class="uppercase tracking-wider bg-[#8d807a] text-white">
															<tr>
																<th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Incentivo a largo plazo (LTIP)</p></th>
															</tr>
														</thead>
														<tbody>
															<tr class="">
																<th scope="row" class="px-6 py-4">
																	<p>1/3 en acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
																</th>
																{filteredColaboradores.map((colaborador, index) => (
																	<>
																	<td class="px-6 py-4 border-b" rowspan="3">
																		<div class="space-y-2">
																			{colaborador.ltip_rsu_3_26 != '0'? (<div class="py-2">2023 RSU Parte 3 - 2026</div>) : null } 
																			{colaborador.ltip_rsu_2_26 != '0'? (<div class="py-2">2024 LTIP Parte 2 - 2026</div>) : null } 
																			{colaborador.ltip_rsu_2_27 != '0'? (<div class="py-2">2024 LTIP Parte 3 - 2027</div>) : null } 
																		</div>
																	</td>
																
																	<td class="px-6 py-4 border-b" rowspan="3">
																		<div class="space-y-2">
																			{colaborador.ltip_rsu_3_26 != '0'? (<div class="py-2 text-right">{formatNumberString(colaborador.ltip_rsu_3_26)}</div>) : null } 
																			{colaborador.ltip_rsu_2_26 != '0'? (<div class="py-2 text-right">{formatNumberString(colaborador.ltip_rsu_2_26)}</div>) : null } 
																			{colaborador.ltip_rsu_2_27 != '0'? (<div class="py-2 text-right">{formatNumberString(colaborador.ltip_rsu_2_27)}</div>) : null } 
																		</div>
																	</td>
																	</>
																))}
															</tr>
														</tbody>
													</>
												) : null }
												{bloque == "5" ? (
													<>
														<thead class="uppercase tracking-wider bg-[#8d807a] text-white">
															<tr><th scope="col" class="px-6 py-2 text-white" colSpan={3}><p>Off Cycle Award</p></th></tr>
														</thead>
														<tbody>
															<tr class="">
																<th scope="row" class="px-6 py-4">
																	{bloque == "5" ? (
																		<p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un tercio cada año.</p>
																	) : <p>Acciones restringidas (RSUs) sujetas a tiempo con un periodo de liberación de un 50% cada año.</p>}


																</th>
																<td class="px-6 py-4 border-b">
																	<div class="space-y-2">
																		{bloque == "5" ? (<div class="py-2">RSU Parte 3 - 2026</div>) : null}
																	</div>
																</td>
																{ocaValues.map((values, index) => (
																	<td key={index} className="px-6 py-4 border-b">
																		<div className="space-y-2">
																			{bloque == "5" ? (<div className="py-2 text-right">{values.oca_3_24}</div>) : null}
																		</div>
																	</td>
																))}
															</tr>
														</tbody>
													</>
												) : null}
												<tfoot class="border-t-2 dark:border-neutral-600">
													<tr>
														<th class="px-6 py-4"></th>
														<td class="px-6 py-4"><strong>Total Acciones No Liberadas</strong></td>
														{filteredColaboradores.map((colaborador, index) => (
															<td class="px-6 py-4 text-right"><strong>{formatNumberString(colaborador.total_anl_02)}</strong></td>
														))}
													</tr>
												</tfoot>
											</table>
										</div>
									</div>
									</>
									):null}
									{/* INICIA TABLA PARA ACCIONES NOOOOOOO LIBERADAS  */}
								</div>
								<div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
									<p>Este documento tiene fines informativos y está destinado únicamente para su visualización. No constituye un documento oficial.</p>
									<p>Si tienes alguna duda, contacta a <a href="mailto:rigutierrez@aeromexico.com">rigutierrez@aeromexico.com</a>, <a href="mailto:mmsalazar@aeromexico.com">mmsalazar@aeromexico.com</a> o <a href="mailto:lsaldanas@aeromexico.com">lsaldanas@aeromexico.com</a></p>
									<p>&nbsp;</p>
									<p>Nota: Si no puedes ver la tabla completa, utiliza el cursor para desplazarte hacia la derecha.</p>
								</div>
							</>
					}



				</section>
			</div>
		</motion.div>
	);
};

export default AccionesPersonales;