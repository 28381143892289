import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/golfo.css";
import * as contentful from "../../lib/contentful";
import Modal from "react-awesome-modal";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import ReactMarkdown from "react-markdown";
import close from "../../assets/logo/close.svg";
import ReactPlayer from "react-player";

var noScroll = require("no-scroll");

export default class golfoDelta extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			modalIsShow: false,
			modalIsShow2: false,
			error: null,
			entries: null,
			entries2: null,
			entries3: null,
			hasScrolledAlert: false,
			visible: false,
			visible2: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	openModal2() {
		this.setState({
			visible2: true
		});
		noScroll.on();
	}

	closeModal2() {
		this.setState({
			visible2: false
		});
		noScroll.off();
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
		noScroll.toggle();
	};

	showAlertModal2 = () => {
		this.setState({
			modalIsShow2: !this.state.modalIsShow2
		});
		noScroll.toggle();
	};

	componentDidMount() {
		this.fetchEntries();
		this.fetchEntries02();
		this.fetchEntries03();
		document.addEventListener("keydown", this.escFunction, false);
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false,
				visible2: false,
				modalIsShow: false,
				modalIsShow2: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false,
				visible2: false,
				modalIsShow: false,
				modalIsShow2: false
			});
			noScroll.off();
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
		// noScroll.toggle();
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "aerolineasdelGolfo",
				include: 2,
				locale: "en-US"
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	fetchEntries02 = () => {
		contentful.client
			.getEntries({
				content_type: "aerolineasDelGolfoPreguntasYRespesutas",
				include: 2,
				locale: "en-US",
				order: "fields.preguntaGolfo"
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries2: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	fetchEntries03 = () => {
		contentful.client
			.getEntries({
				content_type: "defendamosNuestroCieloNoticias",
				include: 2,
				locale: "es-US"
			})
			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entries3: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	render() {
		const { loading, entries, entries2, entries3 } = this.state;
		return (
			<div>
				{/* <div className="languageCambio">
            <Link className="list" to="/defendamos-nuestro-cielo">
              <div className="image es">ES</div>
              <p className="idiom">Español</p>
            </Link>
            <Link className="list" to="/lets-defend-our-sky">
              <div className="image en">EN</div>
              <p className="idiom">English</p>
            </Link>
          </div> */}

				<Modal
					visible={this.state.visible}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal()}
				>
					<div className="whiteWindowAsk modalheightInherithAsk">
						<div
							class="closeModal"
							href="javascript:void(0);"
							onClick={() => this.closeModal()}
						>
							<img src={close} alt="cerrar"></img>
						</div>
						<div>
							<h4 className="titlePreguntas">
								Frequently Asked Questions
							</h4>
							<Accordion
								allowZeroExpanded="true"
								id="wrapperAccordionThis"
								preExpanded={["foo"]}
							>
								{!loading &&
									entries2 &&
									entries2.map(preguntasRespuestas => (
										<AccordionItem
											key={preguntasRespuestas.sys.id}
											uuid={
												preguntasRespuestas.fields
													.expandedText
											}
										>
											<AccordionItemHeading>
												<AccordionItemButton>
													<div className="tableBoletosHeader">
														<p className="title">
															{
																preguntasRespuestas
																	.fields
																	.preguntaGolfo
															}
														</p>
													</div>
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<ReactMarkdown className="description">
													{
														preguntasRespuestas
															.fields
															.respuestaGolfo
													}
												</ReactMarkdown>
											</AccordionItemPanel>
										</AccordionItem>
									))}
							</Accordion>
						</div>
					</div>
				</Modal>

				<Modal
					visible={this.state.visible2}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal2()}
				>
					<div className="whiteWindowAsk">
						<div
							class="closeModal"
							href="javascript:void(0);"
							onClick={() => this.closeModal2()}
						>
							<img src={close}></img>
						</div>
						<div>
							<h4 className="titlePreguntas">Latest news</h4>

							{/* {!loading &&
                  entries2 &&
                  entries2.map(preguntasRespuestas => ( 
                    
                    key={preguntasRespuestas.sys.id}
                    */}

							<div className="noticiasligas">
								<div className="midWarpperContent midwarpperTop">
									<ul>
										{!loading &&
											entries3 &&
											entries3.map(noticiasEmirates => (
												<li>
													<a
														href={
															noticiasEmirates
																.fields
																.ligaDeLaNoticia
														}
													>
														{
															noticiasEmirates
																.fields
																.tituloDeLaNoticia
														}
													</a>
												</li>
											))}
									</ul>
								</div>
							</div>

							{/* ))} */}
						</div>
					</div>
				</Modal>

				{this.state.modalIsShow === true ? (
					<div className="flightVideoModal">
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModal}
							>
								<img src={close}></img>
							</div>

							<ReactPlayer
								url="https://aeromexico-2.wistia.com/medias/6u22cvle4w"
								playing="false"
								width="100%"
								height="100%"
								controls="true"
								volume=".5"
							/>
						</div>
					</div>
				) : (
					""
				)}

				{this.state.modalIsShow2 === true ? (
					<div className="flightVideoModal">
						<div
							className="videoModalWindow"
							ref={this.setWrapperRef}
						>
							<div
								className="closeModal"
								onClick={this.showAlertModal2}
							>
								<img src={close}></img>
							</div>

							<ReactPlayer
								url="https://alexmejia01.wistia.com/medias/n3hq3li68p"
								playing="false"
								width="100%"
								height="100%"
								controls="true"
								volume=".5"
							/>
						</div>
					</div>
				) : (
					""
				)}

				{!loading &&
					entries &&
					entries.map(aerolineaContent => (
						<div key={aerolineaContent.sys.id}>
							<div id="golfoAirlines">
								<div className="contentMnager">
									{/* blueBackEmirates */}
									<div className="paddingTopBlueHeader blueBackEmirates">
										<div className="midWarpperContent">
											<div className="logoGpoAM"></div>
											<p className="smallred">
												{
													aerolineaContent.fields
														.contentSmall
												}
											</p>
											<h2 className="title">
												{
													aerolineaContent.fields
														.contentTitulo
												}
											</h2>
											<p className="biggerText">
												<ReactMarkdown>
													{
														aerolineaContent.fields
															.contentIntro
													}
												</ReactMarkdown>
											</p>
										</div>
									</div>

									<div className="background-blue">
										<div className="meaning2 meaning2en"></div>
										<div
											className="map mapaingles"
											// style={{backgroundImage: `url(${aerolineaContent.fields.contentImageMapa.fields.file.url})`}}
										></div>
									</div>

									<div className="midWarpperContent midwarpperTop">
										<div className="wrapperQuintas">
											<div className="leftQuintaLibertad">
												<p className="subtitle">
													{
														aerolineaContent.fields
															.contentQueEsLibertad
													}
												</p>
												<div className="imgQuinta imgQuintaen"></div>
											</div>
											<div className="rightQuintaLibertad">
												<p className="biggerText">
													<ReactMarkdown>
														{
															aerolineaContent
																.fields
																.contentLibertadExplicacion
														}
													</ReactMarkdown>
												</p>
											</div>
										</div>
									</div>

									<div className="midWarpperContent midwarpperTop midWrapperLine">
										<div className="leftLogoEmirates">
											<div className="logoEmirates"></div>
											<h2 className="subtitle">
												<ReactMarkdown>
													{
														aerolineaContent.fields
															.contentEmiratesIntro
													}
												</ReactMarkdown>
											</h2>
										</div>

										<div className="rightLogoEmirates">
											<div className="wrapperGridPermite">
												<div className="optionGrid">
													<div className="img"></div>
													<p>
														{
															aerolineaContent
																.fields
																.contentIconoExplicacion01
														}
													</p>
												</div>

												<div className="optionGrid">
													<div className="img"></div>
													<p>
														{
															aerolineaContent
																.fields
																.contentIconoExplicacion02
														}
													</p>
												</div>

												<div className="optionGrid">
													<div className="img"></div>
													<p>
														{
															aerolineaContent
																.fields
																.contentIconoExplicacion03
														}
													</p>
												</div>
											</div>
										</div>
									</div>

									<div className="midWarpperContent midwarpperTop">
										<div className="youKnowWhat">
											<p>
												<ReactMarkdown>
													{
														aerolineaContent.fields
															.contentDatoCuriso
													}
												</ReactMarkdown>
											</p>
										</div>
										<div className="yellowAlert">
											<div className="wrapperAlert">
												<div className="imgAlert"></div>
												<p>
													<ReactMarkdown>
														{
															aerolineaContent
																.fields
																.contentAlertaEmirates
														}
													</ReactMarkdown>
												</p>
											</div>
										</div>
									</div>

									<div className="bluMapCompetencia">
										<div className="midWrapperTop">
											<div className="fixedAbsolute fixedingles"></div>
											<h2 className="subtitle">
												<ReactMarkdown>
													{
														aerolineaContent.fields
															.contentRiesgoConectividad
													}
												</ReactMarkdown>
											</h2>
											<p>
												{
													aerolineaContent.fields
														.contentMexico
												}
											</p>
										</div>
									</div>

									<div className="midWarpperContent midwarpperTop">
										<p
											className="subtitle"
											style={{ textAlign: "center" }}
										>
											{
												aerolineaContent.fields
													.contentOtrosPaisesTitulo
											}
										</p>

										<div className="gridFlags">
											<div className="gridOption">
												<div className="flag"></div>
												<p className="title">
													United States
												</p>
												<p className="subti">
													{
														aerolineaContent.fields
															.contentEstadosUnidos
													}
												</p>
											</div>

											<div className="gridOption">
												<div className="flag"></div>
												<p className="title">Canada</p>
												<p className="subti">
													{
														aerolineaContent.fields
															.contentCanada
													}
												</p>
											</div>

											<div className="gridOption">
												<div className="flag"></div>
												<p className="title">
													European Union
												</p>
												<p className="subti">
													{
														aerolineaContent.fields
															.contentEuropa
													}
												</p>
											</div>
										</div>
									</div>

									<div className="blueTextbg">
										<div className="imgLogo"></div>
										<div className="textWrapper">
											<h2 className="subtitle">
												{
													aerolineaContent.fields
														.contentQueHacemosEnMexico
												}
											</h2>

											<div className="leftWrapperTexto">
												<p className="biggerPText">
													<b>
														{
															aerolineaContent
																.fields
																.contentGrupoAeromexico
														}
													</b>
												</p>
											</div>

											<div className="leftWrapperTexto">
												<p className="">
													{
														aerolineaContent.fields
															.contentSindicatosAm
													}
												</p>
												<a
													href={
														aerolineaContent.fields
															.contentDesplegadoLiga
													}
													target="_blank"
													rel="noreferrer"
												>
													{
														aerolineaContent.fields
															.contentDesplegadoBoton
													}
												</a>
											</div>

											{/* <p className="clearText">Los sindicatos respaldan esta petición <a href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EVTmETF7pFVHsD-qWyxWLpYBtDJ1kwcJtKK5P0eQewTCDQ?e=CqsFaE" target="_blank">Conoce más</a></p> */}
										</div>

										<div className="impaktante">
											<p>
												{
													aerolineaContent.fields
														.contentFraseFinal
												}
											</p>
										</div>

										<div className="temsConditionsText">
											<ReactMarkdown>
												{
													aerolineaContent.fields
														.contentPieDePagina
												}
											</ReactMarkdown>
										</div>
									</div>
								</div>
								{/* Header Right */}
								<div
									className={
										aerolineaContent.fields
											.mostrarBotonClase +
										" " +
										"headerAirleGolfo"
									}
								>
									<div className="wrapMidTable">
										<div className="wrapMid">
											<div className="wrapMidElement">
												<p className="title">
													{
														aerolineaContent.fields
															.titulo
													}
												</p>
												<p className="text">
													<ReactMarkdown className="description">
														{
															aerolineaContent
																.fields.subtitle
														}
													</ReactMarkdown>
												</p>
												<p className="intro">
													{
														aerolineaContent.fields
															.textoChico
													}
												</p>

												<a
													className="firmarPeticion"
													href={
														aerolineaContent.fields
															.ligaDelBoton
													}
													target="_blank"
													rel="noreferrer"
												>
													{
														aerolineaContent.fields
															.textoDelBoton
													}
												</a>

												<p className="intro contamos">
													{
														aerolineaContent.fields
															.fraseDeCierre
													}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="fixedTop">
								<div className="rightBanner">
									<div className="bannerTopWrapper">
										<div className="wrapperButtons">
											<a
												className="sqDownload"
												onClick={() => this.openModal()}
											>
												<div className="iconText"></div>
												<p>
													{
														aerolineaContent.fields
															.contentPreguntas
													}
												</p>
											</a>

											<a
												className="sqDownload"
												href={
													aerolineaContent.fields
														.contentInfografiaLiga
												}
												target="_blank"
												rel="noopener noreferrer"
											>
												<div className="iconText"></div>
												<p>
													{
														aerolineaContent.fields
															.contentInfografiatext
													}
												</p>
											</a>

											<a
												className="sqDownload smallOption"
												onClick={() =>
													this.openModal2()
												}
											>
												<div className="iconText"></div>
												<p>
													{
														aerolineaContent.fields
															.contentNoticias
													}
												</p>
											</a>

											<a
												className="sqDownload videoDeltaThis"
												onClick={this.showAlertModal2}
											>
												<div className="iconText"></div>
												<div className="filterimage"></div>
												<p>
													Andrés Conesa In México
													INSIGHTS 2019
												</p>
											</a>

											<a
												className="sqDownload videoDeltaThis"
												onClick={this.showAlertModal}
											>
												<div className="iconText"></div>
												<div className="filterimage"></div>
												<p>
													{
														aerolineaContent.fields
															.contentDelta
													}
												</p>
											</a>

											<a
												className="sqDownload defaultOption"
												onClick={() =>
													this.openModal2()
												}
											>
												<div className="iconText"></div>
												<p>
													{
														aerolineaContent.fields
															.contentNoticias
													}
												</p>
											</a>
										</div>
									</div>
								</div>

								<div className="leftBanner"></div>
							</div>
						</div>
					))}
			</div>
		);
	}
}
