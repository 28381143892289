import Welcome from "../pages/Home/Welcome";
import ProfileRewards from "../pages/Profile/ProfileRewards";
import GolfoAirlines from "../components/DeltaEmirates/amgolfo";
import GolfoAirlinesEN from "../components/DeltaEmirates/amgolgoen";
import NewsCategory from "../components/News/NewsCategory";
import NewsHome from "../components/News/noticiaslanding";
import SingleNews from "../components/News/SingleNews";
import SingleNewsSlider from "../components/News/SingleNewsSlider";
import GamTalks from "../components/GamTalks/gamtalks";
import DesempenoDesarrollo from "../components/Talento/talento";
import FlotaPage from "../components/Nosotros/flota/flota";
import Triques from "../components/TriquesTrueques/triquestrueques";
import Search from "../pages/Search";
import TotalBenefits from "../components/Profile/TotalBenefits";
import TotalBenefitsTrans from "../components/Profile/TotalBenefitsTrans";
import SingleBenefitUSA from "../components/Profile/Benefits/SingleBenefitUSA";
import ViajesServicio from "../components/ViajesServicio/viajesservicio";
import DiscountViewSalud from "../components/Profile/Discounts/DescuentosSalud";
import DiscountViewEntre from "../components/Profile/Discounts/DescuentosEntre";
import DiscountViewGym from "../components/Profile/Discounts/DescuentosGym";
import DiscountViewCompras from "../components/Profile/Discounts/DescuentosCompras";
import DiscountViewEduca from "../components/Profile/Discounts/DescuentosEduca";
import DiscountViewResta from "../components/Profile/Discounts/DescuentosResta";
import DiscountViewCar from "../components/Profile/Discounts/DescuentosCar";
import DiscountViewSer from "../components/Profile/Discounts/DescuentosSer";
import DiscountViewGuar from "../components/Profile/Discounts/DescuentosGuar";
import Finanzas from "../components/Profile/Finanzas/finanzas";
import Experience from "../components/Profile/Experience/experience";
import LovedBenefits from "../components/Profile/LovedBenefits/lovedBenefits";
import ComiteEjecutivo from "../components/ComiteEjecutivo/comiteEjecutivo";
import CustomerExperience from "../components/CustomerExperience/customerexperience";
import Nosotros from "../components/Nosotros/transformacion";
import Responsabilidad from "../components/Nosotros/responsabilidad";
import Vacantes from "../components/Profile/vacantes";
import CXevolution from "../components/CXevolution/cxevolution";
import Seguridad from "../components/seguridad/seguridad";
import NOM035 from "../pages/NOM035/Nom035";
import Flightplan from "../components/flightplan/fligthplan";
import Flightplanpor from "../components/flightplan/fligthplanpor";
import Flightplanen from "../components/flightplan/fligthplanen";
import Flightplandut from "../components/flightplan/flightplandut";
import Flightplanjap from "../components/flightplan/flightplanjap";
import Flightplanfr from "../components/flightplan/flightplanfr";
import Flightplanchino from "../components/flightplan/flightplanchino";
import Flightplancor from "../components/flightplan/flightplancor";
import Logrocx from "../components/CustomerExperience/logroscx";
import Identidad from "../components/Nosotros/identidad";
import Alfabeto from "../components/Nosotros/alfabeto";
import Reportesostenibilidad from "../components/Nosotros/reportesostenibilidad";
import Reportetrimestral from "../components/Nosotros/reportetrimestral";
import Reporteoperacional from "../components/Nosotros/reporteoperacional";
import Reporteanual from "../components/Nosotros/reporteanual";
import Boletin from "../components/Nosotros/boletin";
import Jcaaeromexicodelta from "../components/Nosotros/jcaaeromexicodelta";
import ApplyForm from "../components/Profile/Apply/Form";
import Maps from "../pages/Geolocation/Mapa";
import Map2 from "../components/Geolocation/Map/Map2";
import ReporteSostenibilidad from "../components/Geolocation/Map/framer";
import CompliancePage from "../pages/Compliance/CompliancePage";
import EmailSearch from "../pages/Hidden/EmailSearch";
import { ComunicadosPage } from "../pages/Comunicados/ComunicadosPage";
import Clasificado from "../pages/Clasificado/Clasificado";
import Juntos from "../pages/Juntos/Juntos";
import DesdeCasa from "../pages/DesdeCasa/DesdePage";
import Max737 from "../pages/737Max";
import Teletrabajo from "../pages/Teletrabajo";
import TalentPlanningPage from "../pages/TalentPlanning";
import MyLearningPage from "../components/MyLearning";
import MiLineaAtencion from "../pages/LineaAtencion";
import AccionesPersonales from "../pages/AccionesPersonales";
import PageProcesosAdministrativos from "../pages/ProcesosAdministrativos";
import BenefitsOpenMX from "../components/Profile/Benefits/BenefitsOpenMX";
import Expenses from "../pages/Expenses";
 
//TODO: revisar el <PRoute history={history} exact path="/recompensa-total/my-hr"><Redirect to="/inicio" /></PRoute>
//TODO: revisar el <PRoute history={history} exact path="/nosotros/nuestras-rutas"><Redirect to="/inicio" /></PRoute>

const routes = [
  { path: "/defendamos-nuestro-cielo", component: GolfoAirlines, exact: true, secure: true },
  { path: "/lets-defend-our-sky", component: GolfoAirlinesEN, exact: true, secure: true },
  { path: "/", component: Welcome, exact: true, secure:true },
  { path: "/inicio", component: Welcome, exact: true, secure: true },
  
  { path: "/compliance", component: CompliancePage, exact: true, secure: true },
  { path: "/comunicados", component: ComunicadosPage, exact: true, secure: true },
 /*  { path: "/juntoscontinuaremosvolando", component: Juntos, exact: true, secure: true }, */
  { path: "/juntos-desde-casa", component: DesdeCasa, exact: true, secure: true },
  { path: "/hidden/emailSearch", component: EmailSearch, exact: true, secure: true },
  
  /* { path: "/recompensa-total", component: ProfileRewards, exact: true, secure: true },
  { path: "/recompensa-total/perfil", component: ProfileRewards, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mi-compensaciones", component: ProfileRewards, exact: true, secure: true },
  { path: "/recompensa-total/perfil/my-flexibility", component: ProfileRewards, exact: true, secure: true },
  { path: "/recompensa-total/perfil/my-travel-benefits", component: ProfileRewards, exact: true, secure: true },
  { path: "/recompensa-total/perfil/venta-especial", component: ProfileRewards, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mis-beneficios", component: ProfileRewards, exact: true, secure: true }, */
  { path: "/recompensa-total/talent-planning", component: TalentPlanningPage, exact: true, secure: true },
  { path: "/recompensa-total/mi-desempeno-desarrollo", component: DesempenoDesarrollo, exact: true, secure: true },
  { path: "/recompensa-total/my-learning", component: MyLearningPage, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo", component: TotalBenefits, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mis-beneficios/disfruta-tu-trabajo/transportacion", component: TotalBenefitsTrans, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mis-beneficios/administra-tus-finanzas", component: Finanzas, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mis-beneficios/vive-aeromexico", component: Experience, exact: true, secure: true },
  { path: "/recompensa-total/perfil/mis-beneficios/cuida-de-ti", component: LovedBenefits, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/salud-y-belleza", component: DiscountViewSalud, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/entretenimiento", component: DiscountViewEntre, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/gimnasios", component: DiscountViewGym, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/de-compras", component: DiscountViewCompras, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/educacion", component: DiscountViewEduca, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/restaurantes", component: DiscountViewResta, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/automoviles", component: DiscountViewCar, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/servicios", component: DiscountViewSer, exact: true, secure: true },
  { path: "/recompensa-total/mis-beneficios/descuentos/guarderias", component: DiscountViewGuar, exact: true, secure: true },
  { path: "/recompensa-total/viajes-aeromexico", component: ViajesServicio, exact: true, secure: true },
  
  { path: "/ayuda-teletrabajo", component: Teletrabajo, exact: true, secure: true },
  { path: "/mis-beneficios", component: BenefitsOpenMX, exact: true, secure: true },
  { path: "/benefits-usa/:articleId", component: SingleBenefitUSA, secure: true },
  { path: "/oportunidades-carrera", component: Vacantes, exact: true, secure: true },
  { path: "/oportunidades-carrera/aplicar-vacante/:jobId", component: ApplyForm, exact: true, secure: true},
  { path: "/maps", component: Maps, exact: true, secure: true },
  { path: "/nosotros/responsabilidad-social/informe-sostenibilidad", component: ReporteSostenibilidad, exact: true, secure: true },
  { path: "/map2", component: Map2, exact: true, secure: true },
  { path: "/buy-and-sell", component: Triques, exact: true, secure: true },
  
  { path: "/noticias", component: NewsHome, exact: true, secure: true },
  { path: "/noticias/ultimas-noticias", component: NewsHome, exact: true, secure: true },
  { path: "/noticias/:newsId", component: NewsCategory, secure: true },
  { path: "/articles/:articleId", component: SingleNews, secure: true },
  { path: "/buscar", component: Search, exact: true, secure: true },

  { path: "/seguridad", component: Seguridad, exact: true, secure: true },
  { path: "/seguridad/nom-035", component: NOM035, exact: true, secure: true },
  { path: "/mi-linea-atencion", component: MiLineaAtencion, exact: true, secure: true },
  { path: "/servicios-personal", component: PageProcesosAdministrativos, exact: true, secure: true },
  { path: "/acciones-personales", component: AccionesPersonales, exact: true, secure: true },
  { path: "/expenses", component: Expenses, exact: true, secure: true },
  { path: "/boeing-737-max", component: Max737, exact: true, secure: true },
  { path: "/articles/slider/:articleSliderId", component: SingleNewsSlider, secure: true },
  
  { path: "/nosotros/gam-talks", component: GamTalks, exact: true, secure: true },
  /* { path: "/nosotros/seguimos-y-seguiremos-volando", component: Clasificado, exact: true, secure: true }, */
  { path: "/nosotros/sobre-nosotros", component: Nosotros, exact: true, secure: true },
  { path: "/nosotros/nuestra-flota", component: FlotaPage, exact: true, secure: true },
  { path: "/nosotros", component: Nosotros, exact: true, secure: true },
  { path: "/nosotros/responsabilidad-social", component: Responsabilidad, exact: true, secure: true },
  { path: "/nosotros/nuestra-organizacion", component: ComiteEjecutivo, exact: true, secure: true },
  { path: "/nosotros/logos-templates", component: Identidad, exact: true, secure: true },
  { path: "/nosotros/alfabeto", component: Alfabeto, exact: true, secure: true },
  { path: "/nosotros/reporte-sustentabilidad", component: Reportesostenibilidad, exact: true, secure: true },
  { path: "/nosotros/reporte-operacional", component: Reporteoperacional, exact: true, secure: true },
  { path: "/nosotros/reporte-anual", component: Reporteanual, exact: true, secure: true },
  { path: "/nosotros/reporte-trimestral", component: Reportetrimestral, exact: true, secure: true },
  { path: "/nosotros/boletin-industria", component: Boletin, exact: true, secure: true },
  { path: "/nosotros/jca-aeromexico-delta", component: Jcaaeromexicodelta, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo", component: Flightplan, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/ingles", component: Flightplanen, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/portugues", component: Flightplanpor, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/holandes", component: Flightplandut, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/japones", component: Flightplanjap, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/frances", component: Flightplanfr, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/chino", component: Flightplanchino, exact: true, secure: true },
  { path: "/nosotros/plan-de-vuelo/coreano", component: Flightplancor, exact: true, secure: true },


  { path: "/experiencia-cliente", component: CustomerExperience, exact: true, secure: true },
  { path: "/experiencia-cliente/logros", component: Logrocx, exact: true, secure: true },
  { path: "/experiencia-cliente/net-promoter-score", component: CustomerExperience, exact: true, secure: true },
  { path: "/experiencia-cliente/cx-evolution", component: CXevolution, exact: true, secure: true },
];
 
export default routes;