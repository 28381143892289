import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import Banner from "./assets/banner-procesos-administrativos.jpeg";
import i18next from "i18next";

const Expenses = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const nombre_completo = `${localStorage.getItem("nombre")} ${localStorage.getItem("apellidoPaterno")} ${localStorage.getItem("apellidoMaterno")}`;

    return (

        <motion.div
            key="page-procesos-administrativos"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            className="full-container">
            {/* <Arriba /> */}
            <div className="content-holder-talent">
                <header className="talent-header relative py-40">
                    <div className="absolute left-1/2 top-1/2 z-20 flex w-full -translate-x-1/2 -translate-y-1/2 transform flex-col items-center">
                        <h1 className="mb-1 text-center text-5xl font-bold leading-none text-white opacity-100">
                            {i18next.language === "es" ? "Expenses" : "Expenses"}
                        </h1>
                        <h2 class="text-center text-2xl font-bold leading-none text-white opacity-100">{i18next.language === "es" ? "Información a marzo 2025" : "Information until March 2025"}</h2>
                    </div>
                    <div className="absolute left-0 top-0 z-10 h-full w-full bg-amDarkBlue opacity-75"></div>
                    <img
                        src={Banner}
                        alt="Talent Planning"
                        className="absolute left-0 top-0 z-0 h-full w-full object-cover"
                    />
                </header>
                <section className="py-16" style={{ background: "#F6F8F9" }}>
                    {
                        <>

                            <div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
                                {/* INICIA TABLA PARA ACCIONES LIBERADAS  */}
                                <div class="text-center">
                                    <p class="rewards_title" style={{ fontSize: "1.5rem" }}>Hola {nombre_completo}</p>
                                    <br />
                                    <p class="rewards_title" style={{ fontSize: "1.5rem" }}>Perteneces a {localStorage.getItem("compania")}</p>

                                </div>
                                {/* TERMINA TABLA PARA ACCIONES LIBERADAS  */}
                            </div>
                            <div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
                                <p>Razon Social: <span style={{fontWeight: "bold"}}>{localStorage.getItem("compania")}</span></p>
                                <p>Nombre del empleado: <span style={{fontWeight: "bold"}}>{nombre_completo}</span></p>
                                <p>Banco: <span style={{fontWeight: "bold"}}>Banamex</span></p>
                            </div>
                            <div class="container mx-auto flex flex-col items-center px-10 lg:px-16">
                                <div class="">
                                    <div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                                        <table class="min-w-full text-left text-sm whitespace-nowrap">
                                            <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                                <tr>
                                                    <th scope="col" className="px-6 py-2 text-white" colSpan={3}><p>Datos para Deposito en Ventanilla</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="">
                                                    <th scope="row" className="px-6 py-4">
                                                        <p>Cuenta</p>
                                                    </th>
                                                    <td className="px-6 py-4 border-b"  >
                                                        <p>1258221</p>
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th scope="row" className="px-6 py-4">
                                                        <p>Sucursal</p>
                                                    </th>
                                                    <td className="px-6 py-4 border-b"  >
                                                        <p>233</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="overflow-x-auto bg-white dark:bg-neutral-70  my-4 fake-table-element even-table m-0 grid h-auto w-full grid-cols-1 gap-0 rounded-md bg-white shadow-md">
                                        <table class="min-w-full text-left text-sm whitespace-nowrap">
                                            <thead className="uppercase tracking-wider bg-[#035cf7] text-white">
                                                <tr>
                                                    <th scope="col" className="px-6 py-2 text-white" colSpan={3}><p>Datos para Depositos por Transferencia</p></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="">
                                                    <th scope="row" className="px-6 py-4">
                                                        <p>Cuenta</p>
                                                    </th>
                                                    <td className="px-6 py-4 border-b"  >
                                                        <p>02331258221</p>
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th scope="row" className="px-6 py-4">
                                                        <p>Cuenta CLABE</p>
                                                    </th>
                                                    <td className="px-6 py-4 border-b"  >
                                                        <p>124180023312582219</p>
                                                    </td>
                                                </tr>
                                                <tr className="">
                                                    <th scope="row" className="px-6 py-4">
                                                        <p>Referencia</p>
                                                    </th>
                                                    <td className="px-6 py-4 border-b"  >
                                                        <p>3017230016</p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    }



                </section>
            </div>
        </motion.div>
    );
};

export default Expenses;