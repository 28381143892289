import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./css/Compliance.css";
import VideoPreview from "./assets/player preview.png";
import ComplianceLogo from "../../assets/compliance/compliance-logo.svg";
import ComplianceBg from "../../assets/compliance/compliance-bg@3x.jpg";
import ConductText from "../../assets/compliance/codigo-conducta-texto.svg";
import ConductTextEN from "../../assets/compliance/codigo-conducta-texto-en.svg";
import Background from "../../assets/compliance/linea-fondo@3x.png";
import EticaLogo from "../../assets/compliance/linea-etica-logo.svg";
import pdfIcon from "../../assets/compliance/pdf-icon2.svg";
import Descargar from "../../assets/compliance/download-icon.svg";
import AmbitionBtn from "./compliance-components/AmbitionBtn";
import Tabs from "./compliance-components/Tabs";
import Loader from "../../components/Loader/Loader";
import i18next from "i18next";
import ReactPlayer from "react-player";
import { motion, AnimatePresence } from "framer-motion";
import close from "../../assets/logo/close.svg";
import { Link } from "react-router-dom";
import { isLocal, isFromDev, isFromQA } from "./../../core/Ambientes";
import { animateScroll as scroll } from "react-scroll";
import { isValidURL } from "../../utils/isValidEmail";

const noScroll = require("no-scroll");

const ambits = [
	{
		title: {
			es: "Normativo",
			en: "Regulatory"
		},
		content: {
			es: "<ul><li>Código de Conducta&nbsp;</li><li>Política Anticorrupción</li><li>Política de Competencia Económica</li><li>Política de Prevención del Lavado de Dinero&nbsp;</li><li>Políticas y procedimientos de protección de&nbsp;datos</li><li>Política de conflicto de interés</li><li>Cláusula Anticorrupción</li></ul>",
			en: '<ul><li><span style="color: rgb(0, 0, 0);">Code of Conduct</span></li><li><span style="color: rgb(0, 0, 0);">Anti-Corruption policy</span></li><li><span style="color: rgb(0, 0, 0);">Economic Competition policy</span></li><li><span style="color: rgb(0, 0, 0);">Money-Laundering Prevention policy</span></li><li><span style="color: rgb(0, 0, 0);">Data-Protection policies and procedures</span></li><li><span style="color: rgb(0, 0, 0);">Conflict of Interest policy</span></li><li><span style="color: rgb(0, 0, 0);">Anti-Corruption clause</span></li></ul>'
		},
		_id: "5e05980f61d310e18e2938d0",
		image: "public/img/ambits/ambitos-normativo.svg",
		__v: 0,
		modalImage: "normativo"
	},
	{
		title: {
			es: "Tecnológico",
			en: "Technological"
		},
		content: {
			es: "<p>Contamos con los siguientes elementos tecnológicos que nos permiten reforzar nuestro Programa de Compliance:</p><p><br></p><ul><li>Sección de ética e integridad corporativa en aeromexico.com</li><li>Apartado de Compliance dentro de nuestro portal interno: Mi Aeroméxico</li><li>Línea Ética&nbsp;Aeroméxico</li></ul>",
			en: '<p>We count with the following elements to highlight our Compliance Program:</p><p><br></p><ul><li>The ethics and corporate integrity section on aeromexico.com</li><li>The Compliance section in our internal portal: Mi Aeroméxico</li><li><span style="color: rgb(0, 0, 0);">Aeromexico Ethics Line</span>&nbsp;</li></ul>'
		},
		_id: "5e0597af61d310e18e2938ce",
		image: "public/img/ambits/ambitos-tecnologico.svg",
		__v: 0,
		modalImage: "tecnologia"
	},
	{
		title: {
			es: "Capacitación y socialización",
			en: "Training and Dissemination"
		},
		content: {
			es: "<p>En Aeroméxico realizamos capacitaciones presenciales y en línea para promover el cumplimiento legal en materias de anticorrupción, acoso, conflicto de interés, competencia económica, protección de datos, prevención del lavado de dinero, debida diligencia, regalos y hospitalidades, entre otros.</p>",
			en: "<p>In Aeromexico we have face-to-face and on-line training courses in order to promote legal compliance in matters such as anti-corruption, harassment, conflict of interest, antitrust, data protection, prevention of money laundering, due diligence, gifts and hospitality, economic sanctions, among others.</p>"
		},
		_id: "5e05978461d310e18e2938cd",
		image: "public/img/ambits/ambitos-capacitacion.svg",
		__v: 0,
		modalImage: "capacitacion"
	},
	{
		title: {
			es: "Ejecutivo",
			en: "Executive"
		},
		content: {
			es: "<ul><li>Comité de Auditoría y Prácticas Societarias&nbsp;</li><li>Comité de Ética y Cumplimiento</li><li>Comité de Gestión y Control Penal</li><li>Consejo de datos personales</li></ul>",
			en: "<ul><li>Audit and Corporate Practices Committee</li><li>Ethics and Compliance Committee</li><li>Management and Criminal Control Committee</li><li>Personal Data Board</li></ul>"
		},
		_id: "5e0597db61d310e18e2938cf",
		image: "public/img/ambits/ambitos-ejecutivo.svg",
		__v: 0,
		modalImage: "ejecutivo"
	}
];

export default class CompliancePage extends Component {
	state = {
		tabs: ["e-Learning", "Políticas", "Manuales", "Guías"],
		currentTab: 0,
		level: parseInt(localStorage.getItem("nivel")),
		language: i18next.language,
		nivel: parseInt(localStorage.getItem("nivel")),
		loading: true,
		isPlaying: false,
		privacy_docs: [],
		openVideo: false,
		show: false,
		type: null
	};

	openModal(type = "normal") {
		this.setState({
			...this.state,
			show: true,
			type
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			...this.state,
			show: false,
			type: null
		});
		noScroll.off();
	}

	async UNSAFE_componentWillMount() {
		// await fetch(`${process.env.REACT_APP_CAPI}/api/compliance`)
		// 	.then((response) => {
		// 		return response.json();
		// 	})
		// 	.then(async (json) => {
		// 		const temp = json.data[0];
		// 		await this.setState({
		// 			...this.state,
		// 			loading: false,
		// 			data: temp,
		// 			benefits: json.benefits,
		// 			ambits: json.ambits,
		// 			learning: json.learning,
		// 			politics: json.politics,
		// 			manuals: json.manuals,
		// 			guides: json.guides,
		// 			eDescription:
		// 				this.state.language === "es"
		// 					? temp.our_program.ethics.description.es.split(", ")
		// 					: temp.our_program.ethics.description.en.split(", ")
		// 		});

		// 	});
		// const apiUrl = process.env.REACT_APP_CAPI_PROD  ? `${process.env.REACT_APP_CAPI_PROD }/api/compliance` : null;

		// if (apiUrl) {
		// 	try {
		// 		const response = await fetch(apiUrl);
		// 		if (!response.ok) {
		// 			throw new Error("Error al obtener datos de la API");
		// 		}
		// 		const json = await response.json();
		// 		const temp = json.data[0];
		// 		this.setState({
		// 			...this.state,
		// 			loading: false,
		// 			data: temp,
		// 			benefits: json.benefits,
		// 			ambits: json.ambits,
		// 			learning: json.learning,
		// 			politics: json.politics,
		// 			manuals: json.manuals,
		// 			guides: json.guides,
		// 			eDescription:
		// 				this.state.language === "es"
		// 					? temp.our_program.ethics.description.es.split(", ")
		// 					: temp.our_program.ethics.description.en.split(", ")
		// 		});
		// 	} catch (error) {
		// 		console.error("Error al realizar la solicitud:", error);
		// 	}
		// } else {
		// 	console.error("La URL del servidor no está configurada correctamente.");
		// }

		// # Variables que switchean las ligas que se consumen segun sea elcaso
		// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
		// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
		// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
		/* Para productivo se ponen todas en falso */

		// fetch('liga', {headers:cabecera})
		// 	.then(data => data.json())
		// 	.then(data => this.setState({
		// 		manuals:data.datos
		// 	}))manue

		// #URl de ambiente
		const urlLocal = `${process.env.REACT_APP_LOCAL}`;
		const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
		const urlQA = `${process.env.REACT_APP_CADMIN_QA}`;
		const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`;

		const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
		const tokenDev = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
		const tokenQA = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
		const tokenProd = `${process.env.REACT_APP_API_TOKEN}`;

		// # Liga validada
		const urlValid =
			isLocal === true ? urlLocal : isFromDev === true ? urlDev : isFromQA === true ? urlQA : urlProd;

		// Se genera el token segun el ambiente
		const tokenValid =
			isLocal === true ? tokenLocal : isFromDev === true ? tokenDev : isFromQA === true ? tokenQA : tokenProd;

		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});

		const urlLanguage = isValidURL(`${urlValid}traduccion-${i18next.language}`);
		await fetch(urlLanguage, { headers: cabecera })
			.then((res) => res.json())
			.then(async (res) => {
				if (res) {
					if (res.data.attributes.data.Compliance.privacy_docs.has_docs) {
						await this.setState({
							...this.state,
							privacy_docs: res.data.attributes.data.Compliance.privacy_docs.docs
						});
					}
				}
			});
	}

	changeCurrentTab() {}

	componentDidMount() {
		scroll.scrollToTop();
	}

	render() {
		return (
			<div className="bg-white">
				{/* {this.state.loading && <Loader />} */}
				{this.state.loading && (
					<React.Fragment>
						<header
							className="compliance-header"
							style={{
								background: `url(${ComplianceBg})`
							}}>
							<h1 className="header-title opacity-100">
								<img src={ComplianceLogo} alt="Compliance" />
							</h1>
							<p className="header-description">
								{this.state.language === "es"
									? `Procedimientos encaminados principalmente al cumplimiento de disposiciones jurídicas, contables, financieras, operativas, de seguridad y protección con el objeto de prevenir actos de corrupción, otros delitos y sanciones en las organizaciones.`
									: `A compliance program is a series of procedures chiefly intended to comply with legal, accounting, financial, operational, safety, and protection provisions in order to prevent acts of corruption, other crimes, and sanctions in organizations.`}
							</p>
							<button
								onClick={() => this.openModal()}
								className="mt-4 rounded-md bg-amRed px-4 py-2 font-bold text-white">
								{this.state.language === "es" ? "Conoce más" : "Learn more"}
							</button>
						</header>
						<section className="h-auto w-full bg-amBlack py-16">
							<div
								className="fake-container flex w-full flex-wrap"
								style={{ paddingLeft: "80px", paddingRight: "80px" }}>
								<div className="flex w-full items-start justify-center">
									<img
										className="h-12 w-auto"
										src={this.state.language === "es" ? ConductText : ConductTextEN}
										alt="Código de Conducta"
									/>
									{/* <img src={ConductIcon} alt="Icono de Código de Conducta" /> */}
								</div>
								<p className="px-!8 md:px-!16 lg:px-!40 mt-4 w-full text-center text-white">
									{this.state.language === "es"
										? `El presente Código de Conducta es la versión actualizada de 2024 alineada a la
									legislación local e internacional aplicable a los programas de ética e integridad
									corporativa para fomentar la visión, valores y comportamientos esperados por parte
									de Grupo Aeroméxico, de todos sus colaboradores y terceros con los que se relaciona.`
										: `This Code of Conduct is the 2024 updated version aligned with local and international legislation applicable to ethics and corporate integrity programs to foster the vision, values and behaviors expected by Grupo Aeroméxico of all its collaborators and third parties with whom it relates.`}
								</p>
								<div className="flex w-full items-center justify-center">
									{this.state.language === "es" ? (
										<a
											href="https://compliance.aeromexico.com/doc/CODIGO_DE_CONDUCTA_ESP.pdf"
											target="_blank"
											rel="noopener noreferrer"
											className="mt-4 rounded-md bg-amBlue px-4 py-2 font-bold text-white">
											Descargar
										</a>
									) : (
										<a
											href="https://compliance.aeromexico.com/doc/CODIGO_DE_CONDUCTA_ENG.pdf"
											target="_blank"
											rel="noopener noreferrer"
											className="mt-4 rounded-md bg-amBlue px-4 py-2 font-bold text-white">
											Download
										</a>
									)}
								</div>
							</div>
						</section>
						<section className="mt-2 flex h-auto w-full flex-wrap items-stretch justify-center md:flex-nowrap">
							<article className="flex h-auto w-full flex-wrap bg-amLightBlue py-16 md:!w-1/2">
								<div
									className="fake-container flex w-full flex-wrap justify-center"
									style={{ paddingLeft: "80px", paddingRight: "80px" }}>
									<img
										//src={`${process.env.REACT_APP_SRC_URL}miam-ui-assets/compliance/andres-conesa-circle.png`}
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam-ui-assets/compliance/andres-conesa-circle.png"
										alt="Andrés Conesa"
										className="h-52 w-auto"
									/>
									<h2 className="mt-4 w-full text-center text-2xl font-bold">
										{this.state.language === "es"
											? `Compromiso de la alta dirección (Tone at the top)`
											: `Senior Management Engagement (Tone at the top)`}
									</h2>
									<h3 className="mt-2 w-full text-center text-lg font-bold opacity-75">
										{this.state.language
											? `Cumplir con nuestros comportamientos y valores es fundamental para continuar siendo la opción #1`
											: `Complying with our behaviors and values is essential to continue being the #1 option.`}
									</h3>
									{this.state.language === "es" ? (
										<p className="w-full text-center lg:w-10/12">
											Ser parte de Grupo Aeroméxico implica vivir con integridad inquebrantable,
											actuando con disciplina y responsabilidad en todo momento, conforme a
											nuestros comportamientos y valores.
										</p>
									) : (
										<p className="w-full text-center lg:w-10/12">
											Being part of Grupo Aeroméxico implies living with unwavering integrity,
											acting with discipline and responsibility at all times, according to our
											behaviors and values.
										</p>
									)}
									<span className="block w-full" />
									<button
										onClick={() => this.openModal("other")}
										className="mt-4 rounded-md bg-amRegularBlue px-4 py-2 font-bold text-white">
										{this.state.language === "es" ? "Seguir leyendo" : "Learn more"}
									</button>
								</div>
								<div
									className="fake-container flex w-full flex-wrap justify-center"
									style={{ paddingLeft: "80px", paddingRight: "80px" }}>
									<h2 className="mt-2 w-full text-center text-2xl font-bold">
										{this.state.language === "es" ? `Nuestros comportamientos` : `Our behaviours`}
									</h2>
									<Link to="/inicio" className="mt-2 underline">
										{this.state.language === "es"
											? `Conoce cada uno de los comportamientos que forman parte de nuestro ADN`
											: `Learn more details about the behaviours that make up our DNA`}
									</Link>
								</div>
							</article>
							<article className="flex h-auto w-full flex-wrap bg-amBlack py-16 md:!w-1/2 lg:!w-1/2">
								<div
									className="fake-container flex w-full flex-wrap px-10"
									style={{ paddingLeft: "80px", paddingRight: "80px" }}>
									<div className="flex w-full flex-col items-center justify-center">
										<img
											//src={`${process.env.REACT_APP_SRC_URL}miam-ui-assets/compliance/icon-cicletransparent-anticorrupcio%CC%81n.svg`}
											src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam-ui-assets/compliance/icon-cicletransparent-anticorrupcio%CC%81n.svg"
											alt="Compliance anti corrupción"
											className="mr-2 h-24 w-auto"
										/>

										<h1 className="mt-2 text-center text-3xl font-bold text-white opacity-100">
											{this.state.language === "es"
												? `Compromiso anticorrupción de Grupo Aeroméxico`
												: `Grupo Aeromexico's Anti-Corruption Commitment`}
										</h1>
									</div>
									<div className="mt-4 space-y-6 text-center text-white">
										{this.state.language === "es" ? (
											<>
												<p>
													Contamos con un Programa de Anticorrupción, el cual es monitoreado
													por la Dirección Compliance en coordinación con otras áreas de la
													empresa, para contribuir a la integridad corporativa, ética,
													confiabilidad y competitividad de nuestras relaciones comerciales,
													labores e inversiones.
												</p>
											</>
										) : (
											<>
												<p>
													We have an Anti-Corruption Program that is monitored by the
													Compliance Department in coordination with other areas of the
													Company, in order to contribute to the corporate integrity, ethics,
													reliability and competitiveness of our comercial, labor and
													investemnt relations.
												</p>
											</>
										)}
										<p>
											{this.state.language === "es" ? (
												<a
													href="https://compliance.aeromexico.com/doc/Politica_Anticorrupcion_ESP.pdf"
													target="_blank"
													className="rounded-md bg-amBlue px-4 py-2 font-bold text-white">
													Política Anticorrupción
												</a>
											) : (
												<a
													href="https://compliance.aeromexico.com/doc/Politica_Anticorrupcion_ENG.pdf"
													target="_blank"
													className="rounded-md bg-amBlue px-4 py-2 font-bold text-white">
													Anti-Corruption Policy
												</a>
											)}
										</p>
									</div>
								</div>
							</article>
						</section>

						<section className="ambitos">
							<article className="ambitos-title pt-8">
								<h2 className="font-bold">
									{/* {this.state.language === "es"
										? this.state.data.our_program.title.es
										: this.state.data.our_program.title.en} */}
									{this.state.language === "es"
										? "Ámbitos de Nuestro Programa"
										: "Areas of our Program"}
								</h2>
							</article>
							<article className="ambitos-content container mx-auto flex items-center justify-center">
								<p className="intro-full">
									{/* {this.state.language === "es"
										? this.state.data.our_program.intro.es
										: this.state.data.our_program.intro.en} */}
									{this.state.language === "es"
										? "Hasta este momento en el Programa de Compliance se han generado diversas herramientas que se integran en los siguientes cuatro ámbitos:"
										: "So far, the Compliance Program has generated different tools applicable to the following four areas:"}
								</p>
								<div className="flex w-full flex-wrap items-center justify-center md:justify-around">
									{/* {ambits.map((item) => {
										return (
											<AmbitionBtn
												hasImage={true}
												background={item.title.es}
												content={
													this.state.language === "es" ? item.content.es : item.content.en
												}
												img={`${process.env.REACT_APP_CAPI}/` + item.image}
												title={this.state.language === "es" ? item.title.es : item.title.en}
											/>
										);
									})}
									 */}
									{/* {this.state.ambits.map((item) => {
										return (
											<AmbitionBtn
												hasImage={true}
												background={item.title.es}
												content={
													this.state.language === "es" ? item.content.es : item.content.en
												}
												img={`${process.env.REACT_APP_CAPI}/` + item.image}
												title={this.state.language === "es" ? item.title.es : item.title.en}
											/>
										);
									})} */}
								</div>
							</article>
							<article className="ambitos-content container mx-auto flex flex-wrap items-center justify-center rounded-md bg-amLighterBlue md:justify-start">
								<div
									className="flex w-full flex-col items-start justify-center px-0 lg:!w-1/2 lg:px-[80px]"
									style={{ paddingLeft: "80px", paddingRight: "80px" }}>
									<img
										//src={`${process.env.REACT_APP_SRC_URL}miam-ui-assets/compliance/icon-circle-transparent-capacitacion.svg`}
										src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam-ui-assets/compliance/icon-circle-transparent-capacitacion.svg"
										alt="Capacitación y Socialización"
										className="h-32 w-auto"
									/>
									<h3 className="mt-2 text-center text-2xl font-bold text-amBlack">
										{this.state.language === "es"
											? `Capacitación y Socialización`
											: `Training and Dissemination`}
									</h3>
									<p className="mt-2 text-left">
										{this.state.language === "es"
											? `En Aeroméxico se llevan a cabo cursos de capacitación presenciales y en línea
										para promover el cumplimiento legal en materias como anticorrupción, ética,
										integridad, acoso, conflicto de interés, competencia económica, protección de
										datos, prevención del lavado de dinero, debida diligencia, regalos y
										hospitalidades, sanciones económicas, entre otros.`
											: `In Aeromexico, face-to-face and on-line training courses are carried out to promote legal compliance in matters such as anti-corruption, ethics, integrity, harassment, conflict of interest, antitrust, data protection, prevention of money laundering, due diligence, gifts and hospitality, economic sanctions, among others.`}
									</p>
								</div>
								<div className="flex w-full lg:!w-1/2">
									<div className="my-4 mt-10 flex w-full flex-col flex-wrap items-start justify-center space-y-3">
										<div className="flag-item-moderated flex w-full flex-wrap items-center justify-between rounded-md bg-white px-4 py-3">
											<img
												src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam-ui-assets/compliance/icon-circle-direccion.svg"
												alt="Alta dirección"
											/>
											<div className="flex w-full flex-col items-center justify-start md:w-1/2">
												<h4 className="mt-2 w-full text-left text-xl font-bold text-amBlack">
													Alta Dirección
												</h4>
												<p className="mt-2 w-full text-left">
													{this.state.language === "es"
														? `Compromiso de Alta Dirección (Tone at the Top):`
														: `Senior Management Engagement (Tone at the Top):`}
												</p>
											</div>
											<div className="mt-4 flex w-full items-center justify-center space-x-4 md:mt-0 md:w-1/2">
												<p className="mt-2 flex flex-col items-start justify-center !text-left text-sm">
													Directivos
													<a
														href="https://compliance.aeromexico.com/doc/POCKET_L10_DIGITAL_ESP.pdf"
														target="_blank"
														rel="noopener noreferrer"
														className="mt-2 block rounded-md bg-amRegularBlue px-4 py-2 text-white hover:bg-amBlue">
														Consultar
													</a>
												</p>
												<p className="mt-2 flex flex-col items-start justify-center !text-left text-sm">
													Consejeros
													<a
														href="https://compliance.aeromexico.com/doc/POCKET_Consejeros_Digital_ESP.pdf"
														target="_blank"
														rel="noopener noreferrer"
														className="mt-2 block rounded-md bg-amRegularBlue px-4 py-2 text-white hover:bg-amBlue">
														Consultar
													</a>
												</p>
											</div>
										</div>
										<div className="flag-item-moderated flex w-full flex-wrap items-center justify-between rounded-md bg-white px-4 py-3">
											<img
												src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam-ui-assets/compliance/icon-circle-elearning.svg"
												alt="Alta dirección"
											/>
											<div className="flex w-full flex-col items-center justify-start md:w-1/2">
												<h4 className="mt-2 w-full text-left text-xl font-bold text-amBlack">
													E-Learning
												</h4>
												<p className="mt-2 w-full text-left">
													{this.state.language === "es"
														? `Programa de capacitación para reforzar el entendimiento de nuestro Código de Conducta y de las diferentes materias que lo conforman.`
														: `Training program to reinforce the understanding of our Code of Conduct and the different subjects that comprise it.`}
												</p>
											</div>
											<div className="mt-4 flex w-full items-center justify-center space-x-4 md:mt-0 md:w-1/2">
												<p className="mt-2 flex flex-col items-center justify-center text-center font-bold">
													<a
														href="https://compliance.aeromexico.com/doc/Programa_de_Capacitacion_Compliance_2023.pdf"
														target="_blank"
														rel="noopener noreferrer"
														className="mt-2 rounded-md bg-amRegularBlue px-4 py-2 text-white">
														Consultar
													</a>
												</p>
											</div>
										</div>
										<div className="flag-item-moderated flex w-full flex-wrap items-center justify-between rounded-md bg-white px-4 py-3">
											<img
												src="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam-ui-assets/compliance/icon-circle-interes.svg"
												alt="Alta dirección"
											/>
											<div className="flex w-full flex-col items-center justify-start md:w-1/2">
												<h4 className="mt-2 w-full text-left text-xl font-bold text-amBlack">
													Conflicto de Interés
												</h4>
												<p className="mt-2 w-full text-left">
													{this.state.language === "es"
														? `Suscripción de cartas de conocimiento y aceptación de la Política Anticorrupción, Conflicto de Interés, entre otras.`
														: `Execution of knowledge and acceptance letters of the Anti-Corruption Policy, Conflict of Interest Policy, among others.`}
												</p>
												<p
													className="mt-2 w-full text-left"
													dangerouslySetInnerHTML={{
														__html:
															this.state.language === "es"
																? `Envía tus declaraciones al correo <a href-"mailto:amcumplimiento@aeromexico.com">amcumplimiento@aeromexico.com</a>`
																: `Send your statements to <a href-"mailto:amcumplimiento@aeromexico.com">amcumplimiento@aeromexico.com</a>`
													}}
												/>
											</div>
											<div className="mt-4 flex w-full items-center justify-center space-x-4 md:mt-0 md:w-1/2">
												<p className="mt-2 flex flex-col items-start justify-center text-left text-sm">
													Carta Declaratoria <br /> Conflicto de <br /> Interés
													<a
														href="https://compliance.aeromexico.com/doc/Carta_Declaracion_Conflicto_de_Interes.pdf"
														target="_blank"
														rel="noopener noreferrer"
														className="mt-2 rounded-md bg-amRegularBlue px-4 py-2 text-white">
														Consultar
													</a>
												</p>
												<p className="mt-2 flex flex-col items-start justify-center text-left text-sm">
													Carta Declaratoria <br /> No Conflicto de <br /> Interés
													<a
														href="https://compliance.aeromexico.com/doc/Carta_Declaracion_No_Conflicto_Interes.pdf"
														target="_blank"
														rel="noopener noreferrer"
														className="mt-2 rounded-md bg-amRegularBlue px-4 py-2 text-white">
														Consultar
													</a>
												</p>
											</div>
										</div>
									</div>
								</div>
							</article>
						</section>
						<section className="line" style={{ background: `url(${Background})` }}>
							<div className="container mx-auto flex flex-wrap items-center justify-around">
								<div className="first-item w-full pr-6 lg:!w-2/4">
									<h2 className="text-left text-2xl font-bold">
										{/* {this.state.language === "es"
											? this.state.data.our_program.ethics.subtitle.es
											: this.state.data.our_program.ethics.subtitle.en} */}
										{this.state.language === "es"
											? "Línea Ética Aeroméxico"
											: "Aeromexico Ethics Line"}
									</h2>
									<ul className="mt-4 flex flex-wrap items-start justify-start font-medium">
										{/* {this.state.eDescription.map((item) => {
											return <li className="mb-1 w-1/2 text-lg">- {item}</li>;
										})} */}
									</ul>
								</div>
								<div className="second-item flex w-full flex-wrap items-center justify-around lg:!w-2/4">
									<div className="w-full rounded-md bg-amBlack py-2 text-white md:w-1/2">
										<h3 className="flex flex-col items-center justify-center">
											<i className="etica_title mt-2">
												{/* {this.state.language === "es"
													? this.state.data.our_program.ethics.title.es
													: this.state.data.our_program.ethics.title.en} 
													*/}
												{this.state.language === "es"}
											</i>
											<img className="guerreroAguila my-2" src={EticaLogo} alt="Línea Ética" />
										</h3>
										<p className="text-center">
											<strong>{"MEX"}</strong>
											<br />
											{/* {this.state.data.our_program.ethics.phone.usa} */}
											{this.state.language === "es" ? "800 112 0585" : "(00) 1 800 921 2240"}
										</p>
										{/* {this.state.data.our_program.ethics.phone.mex} */}
										<p className="text-center"></p>
										<p className="text-center">
											<strong>
												{this.state.language === "es"
													? "USA y resto del mundo"
													: "USA and rest of the world"}
											</strong>
											<br />
											{/* {this.state.data.our_program.ethics.phone.usa} */}
											{"(00) 1 800 921 2240"}
										</p>
										<p className="mb-2 w-full text-center">
											<a
												className="text-amWashedGreen hover:text-amBot"
												href={
													this.state.language === "es"
														? "lineaeticaaeromexico@resguarda.com"
														: "lineaeticaaeromexico@resguarda.com"
												}>
												{this.state.language === "es"
													? "lineaeticaaeromexico@resguarda.com"
													: "lineaeticaaeromexico@resguarda.com"}
											</a>
										</p>
									</div>
									<div className="mt-4 flex w-full flex-col items-center justify-center md:w-1/2 lg:mt-0">
										<a
											className="w-auto rounded-full bg-amRed px-3 py-3 text-center text-sm font-bold text-white lg:w-[270px] lg:text-lg"
											href="https://www.resguarda.com/aeromexico"
											target="_blank"
											rel="noopener noreferrer">
											{this.state.language === "es"
												? "DENUNCIA CONFIDENCIAL"
												: "CONFIDENTIAL COMPLAINT"}
										</a>

										<button
											onClick={() => {
												noScroll.on();
												this.setState({
													...this.state,
													openVideo: true
												});
											}}
											className="mt-4 flex w-auto items-center justify-center rounded-full border-b border-l border-r border-t border-amRegularBlue px-3 py-3 text-center text-amRegularBlue hover:bg-amRegularBlue hover:text-white lg:w-[270px]">
											<svg className="mr-2 h-4 w-4 fill-current" viewBox="0 0 12 12">
												<path d="M4.49614 1.99359C3.82948 1.61264 3 2.09401 3 2.86183V9.13865C3 9.90647 3.82948 10.3878 4.49614 10.0069L9.98836 6.86848C10.6602 6.48459 10.6602 5.51589 9.98836 5.132L4.49614 1.99359Z" />
											</svg>
											{i18next.language === "es" ? "Reproducir video" : "Play video"}{" "}
											{this.state.openVideo}
										</button>
									</div>
								</div>
							</div>
						</section>
						<section className="my-4 flex items-center justify-center">
							<div className="mb-10 flex w-full items-center justify-center px-10 md:w-10/12 lg:w-8/12">
								<AnimatePresence mode="wait">
									{this.state.openVideo ? (
										<motion.div
											key="modal-video-compliance-player"
											initial={{ opacity: 0 }}
											animate={{ opacity: 1 }}
											exit={{ opacity: 0 }}
											className="fixed left-0 top-0 z-999 flex h-screen w-screen items-center justify-center bg-transparent">
											<motion.div
												key="modal-video-compliance-player-background"
												initial={{ opacity: 0 }}
												animate={{ opacity: 0.25 }}
												exit={{
													opacity: 0,
													transition: { delay: 0.5 }
												}}
												onClick={() => {
													noScroll.off();
													this.setState({
														...this.state,
														openVideo: false
													});
												}}
												className="absolute left-0 top-0 h-full w-full bg-amBlack"></motion.div>
											<motion.div
												key="modal-video-compliance-player-player"
												initial={{
													opacity: 0,
													scale: 0.9
												}}
												animate={{
													opacity: 1,
													scale: 1,
													transition: {
														delay: 0.5
													}
												}}
												exit={{
													opacity: 0,
													scale: 0.9
												}}
												className="video-container-container">
												<div
													className="video-container"
													style={{
														position: "relative",
														paddingTop: "56.25%",
														width: "100%"
													}}>
													<ReactPlayer
														style={{
															position: "absolute",
															top: 0,
															left: "50%",
															transform: "translateX(-50%)",
															zIndex: 10
														}}
														// url="https://miaeromexico.sfo2.cdn.digitaloceanspaces.com/miam_docs/capitulo-11/MENSAJE_ANDRES_CONESA_CAPITULO11.mp4"
														// {i18next.t(
														// 	'MainMenu.nosotros.otros.direcciones'
														// )}
														url={i18next.t("Compliance.main_video")}
														playsinline
														controls={true}
														volume={100}
														playing={this.state.openVideo}
														width="100%"
														height="100%"
														light={true}
													/>
													<img
														className="pointer-events-none absolute left-0 top-0 z-0 h-full w-full object-cover"
														src={VideoPreview}
														alt="Video Preview"
													/>
													<div
														onClick={() => {
															noScroll.off();
															this.setState({
																...this.state,
																openVideo: false
															});
														}}
														className="closeVideo absolute -right-3 -top-3 z-30 cursor-pointer rounded-full bg-white p-2 text-amBlue">
														<svg
															className="h-5 w-5 fill-current"
															viewBox="0 0 30.718 30.84">
															<path
																id="Close"
																d="M894.788,1176.57l12.583-12.89a1.5,1.5,0,0,0-.022-2.11,1.478,1.478,0,0,0-2.1.02l-12.561,12.87-13.081-12.89a1.479,1.479,0,0,0-2.1.02,1.5,1.5,0,0,0,.02,2.11l13.062,12.87-13.062,12.87a1.5,1.5,0,0,0-.02,2.11,1.477,1.477,0,0,0,2.1.02l13.081-12.89,12.561,12.87a1.477,1.477,0,0,0,1.062.45,1.459,1.459,0,0,0,1.04-.43,1.5,1.5,0,0,0,.022-2.11Z"
																class="cls-1"
																transform="translate(-877.094 -1161.16)"
															/>
														</svg>
													</div>
												</div>
											</motion.div>
										</motion.div>
									) : null}
								</AnimatePresence>
							</div>
						</section>
						<Tabs isActive={true} currentTab={0}>
							<div
								label={this.state.language === "es" ? "Avisos de Privacidad" : "Privacy Notices"}
								isActive={true}
								exists={this.state.privacy_docs.length > 0 ? true : true}>
								<div className="tabber-content">
									{this.state.privacy_docs.length < 1 ? (
										<p>No hay avisos</p>
									) : (
										<React.Fragment>
											{this.state.privacy_docs.map((item) => (
												<a href={item.url} className="tab-item">
													<img src={pdfIcon} alt="PDF Documento" />
													<p>{item.title}</p>
													<div className="download-btn">
														<img src={Descargar} alt="Descargar documento" />
														<p>{this.state.language === "es" ? "Descargar" : "Download"}</p>
													</div>
												</a>
											))}
										</React.Fragment>
									)}
								</div>
							</div>
							<div
								label="e-Learning"
								isActive={false}
								// exists={this.state.learning.length > 0 ? true : false}>
								exists={false}>
								<div className="tabber-content">
									{true ? (
										<p>No hay documentos</p>
									) : (
										<React.Fragment>
											{this.state.learning.map((item) => {
												const pdfLevel = item.level.split(" - ");
												if (item.level === "0") {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												} else if (
													this.state.level >= parseInt(pdfLevel[0]) &&
													this.state.level <= parseInt(pdfLevel[1])
												) {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												}
											})}
										</React.Fragment>
									)}
								</div>
							</div>
							<div
								label={this.state.language === "es" ? "Políticas" : "Politics"}
								isActive={true}
								exists={true ? true : false}>
								<div className="tabber-content">
									{/* Documents */}
									{true ? (
										<p>No hay documentos</p>
									) : (
										<React.Fragment>
											{this.state.politics.map((item) => {
												const pdfLevel = item.level.split(" - ");
												if (item.level === "0") {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															target="_blank"
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												} else if (
													this.state.level >= parseInt(pdfLevel[0]) &&
													this.state.level <= parseInt(pdfLevel[1])
												) {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															target="_blank"
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												}
											})}
										</React.Fragment>
									)}
								</div>
							</div>
							<div
								label={this.state.language === "es" ? "Manuales" : "Manuals"}
								isActive={false}
								exists={false ? true : false}>
								<div className="tabber-content">
									{/* Documents */}
									{true ? (
										<p>No hay documentos</p>
									) : (
										<React.Fragment>
											{this.state.manuals.map((item) => {
												const pdfLevel = item.level.split(" - ");
												if (item.level === "0") {
													return (
														<>
															<a
																href={
																	this.state.language === "es"
																		? item.file.url.es
																		: item.file.url.en
																}
																target="_blank"
																className="tab-item">
																<img src={pdfIcon} alt="PDF Documento" />

																<p>
																	{this.state.language === "es"
																		? item.title.es
																		: item.title.en}
																</p>
																<div className="download-btn">
																	<img src={Descargar} alt="Descargar documento" />
																	<p>
																		{this.state.language === "es"
																			? "Descargar"
																			: "Download"}
																	</p>
																</div>
															</a>
															{/* ======================================== */}
															{/* <a
															href={
																this.state.language === "es"
																	? URL PARA ESPAÑOL 
																	: URL PARA INGLES
															}
															target="_blank"
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															
															<p>
																{this.state.language === "es"
																	? "TITULO ESPAÑOL"
																	: TITULO INGLES}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a> */}
															{/* ======================================== */}
														</>
													);
												} else if (
													this.state.level >= parseInt(pdfLevel[0]) &&
													this.state.level <= parseInt(pdfLevel[1])
												) {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															target="_blank"
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												}
											})}
										</React.Fragment>
									)}
								</div>
							</div>
							<div
								label={this.state.language === "es" ? "Guías" : "Guides"}
								isActive={false}
								exists={true}>
								<div className="tabber-content">
									{true ? (
										<p>No hay documentos</p>
									) : (
										<React.Fragment>
											{this.state.guides.map((item) => {
												const pdfLevel = item.level.split(" - ");
												if (item.level === "0") {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															target="_blank"
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												} else if (
													this.state.level >= parseInt(pdfLevel[0]) &&
													this.state.level <= parseInt(pdfLevel[1])
												) {
													return (
														<a
															href={
																this.state.language === "es"
																	? item.file.url.es
																	: item.file.url.en
															}
															target="_blank"
															className="tab-item">
															<img src={pdfIcon} alt="PDF Documento" />
															<p>
																{this.state.language === "es"
																	? item.title.es
																	: item.title.en}
															</p>
															<div className="download-btn">
																<img src={Descargar} alt="Descargar documento" />
																<p>
																	{this.state.language === "es"
																		? "Descargar"
																		: "Download"}
																</p>
															</div>
														</a>
													);
												}
											})}
										</React.Fragment>
									)}
								</div>
							</div>
						</Tabs>
						<section className="compliance-end-alert">
							<img src={ComplianceLogo} alt="Compliance" />
							<p
								dangerouslySetInnerHTML={{
									__html:
										this.state.language === "es"
											? 'Cualquier duda sobre Compliance contáctanos al correo: <a href=\\"mailto:AMCumplimiento@aeromexico.com\\">AMCumplimiento@aeromexico.com</a><br/>Para ver el organigrama del área de Compliance da <a href=\\"https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/amcomunicacioninterna_aeromexico_com/EVBTCRuKRthMmnNO_VUFJRMBF0jbCHohH-JAiy25qsD4Aw?e=b3y5Iz\\">click aquí</a>'
											: 'Any questions about the Compliance Program send an email to: <a href=\\"mailto:AMCumplimiento@aeromexico.com\\">AMCumplimiento@aeromexico.com</a><br/>To see the Compliance organization chart <a href=\\"https://grupoaeromexico-my.sharepoint.com/:p:/g/personal/amcomunicacioninterna_aeromexico_com/EVBTCRuKRthMmnNO_VUFJRMBF0jbCHohH-JAiy25qsD4Aw?e=b3y5Iz\\">click here</a>'
								}}></p>
						</section>
					</React.Fragment>
				)}
				{this.state.show && (
					<Modal
						visible={this.state.show}
						width="100%"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}>
						<div className="modal-notification">
							<div className="scrollModalAlert">
								<div className="modalPadding">
									<div
										class="closeModal"
										href="javascript:void(0);"
										onClick={() => this.closeModal()}>
										<img src={close}></img>
									</div>
									<h1>
										{this.state.type === "normal" ? (
											<>
												{this.state.language === "es"
													? `Programa de ética, integridad corporativa y cumplimiento`
													: `Corporate ethics and integrity`}
											</>
										) : (
											<>
												{this.state.language === "es"
													? `Compromiso de la alta dirección (Tone at the top)`
													: `Senior Management Engagement (Tone at the top)`}
											</>
										)}
									</h1>
									{this.state.type === "normal" ? (
										<>
											{this.state.language === "es" ? (
												<>
													<p>
														Quienes colaboramos en Grupo Aeroméxico, estamos convencidos de
														que las relaciones entre empleados, con clientes, socios,
														proveedores, partes interesadas y sociedad en general, deben
														llevarse a cabo en un estricto marco de principios éticos e
														integridad corporativa.
													</p>
													<p>
														En ese contexto, hemos desarrollado y puesto en funcionamiento
														normativa en materia de ética, integridad y combate a la
														corrupción, alineada a la práctica nacional e internacional, en
														cumplimiento al Sistema Nacional Anticorrupción y otros
														ordenamientos internacionales como la FCPA, UK Bribery Act,
														Sarbanes Oxley Act, logros alcanzados gracias a la evolución que
														hemos realizado en nuestro gobierno corporativo y cultura
														empresarial, que promueve la honestidad, seguridad,
														transparencia, y responsabilidad en todas sus actividades.
													</p>
													<p>
														En virtud de lo anterior, en este portal se integra el andamiaje
														normativo más relevante desarrollado dentro de nuestro Programa
														de Compliance, que incluye el Código de Conducta donde se
														promueve el respeto y el comportamiento íntegro esperado entre
														nosotros y hacia nuestros clientes y socios. También encontrarás
														normativa relacionada con anticorrupción, competencia económica,
														conflicto de interés y seguridad de la información, entre otros
														productos y herramientas del Programa.
													</p>
													<p>
														Otra información que podrás consultar en este sitio es la
														relacionada con las acciones en el ámbito del desarrollo social,
														cuidado del medio ambiente y otras que contribuyen al desarrollo
														sustentable.
													</p>
													<p>
														En Grupo Aeroméxico el factor humano es la pieza fundamental
														para ser la empresa que somos hacia nuestros clientes y socios,
														por lo que en esta página también encontrarás diversas campañas
														y herramientas relacionadas a la promoción de la igualdad de
														género, no discriminación y nuestro compromiso con los Derechos
														Humanos.
													</p>
													<p>
														En el combate a la corrupción, todos formamos parte de la gran
														tripulación Aeroméxico, por eso en este espacio compartimos
														nuestro Programa de Compliance que sin duda nos ayuda a tener el
														mejor vuelo para todos.
													</p>
												</>
											) : (
												<>
													<p>
														Those of us who collaborate with Grupo Aeroméxico, are convinced
														that relations among employees, with customers, partners,
														suppliers, stakeholders and society in general, must be carried
														out within a strict framework of ethical principles and
														corporate integrity.
													</p>
													<p>
														In this context, we have developed and implemented regulations
														on ethics, integrity and the fight against corruption, in line
														with national and international practice, in compliance with the
														National Anti-Corruption System and other international
														regulations such as the FCPA, the UK Bribery Act, the Sarbanes
														Oxley Act. These achievements have been possible thanks to the
														evolution we have made in our corporate governance and business
														culture, which fosters honesty, security, transparency, and
														responsibility in all its activities.
													</p>
													<p>
														As a result, this portal integrates the most relevant regulatory
														framework developed within our Compliance Program, which
														includes the Code of Conduct that sets forth the respect and
														integrity behavior that is expected amongst us and toward our
														customers and partners. You will also find regulations on
														matters such as anti-corruption, antitrust, conflict of interest
														and information security, among other products and tools of the
														Program.
													</p>
													<p>
														Other information that you can consult on this site is related
														to actions in the field of social development, care for the
														environment and others that contribute to sustainable
														development.
													</p>
													<p>
														In Grupo Aeroméxico, the human factor is the fundamental piece
														to be the company we are toward our customers and partners, so
														here you will also find various campaigns and tools related to
														the promotion of gender equality, non-discrimination and our
														commitment to human rights.
													</p>
													<p>
														In the fight against corruption, we are all part of the great
														Aeroméxico crew, so in this space we share our Compliance
														Program that undoubtedly helps us to have the best flight for
														everyone.
													</p>
												</>
											)}
										</>
									) : (
										<>
											{this.state.language === "es" ? (
												<>
													<p>
														Como embajadores de Aeroméxico debemos hacernos responsables
														ante el impacto de nuestras decisiones, respetar a nuestros
														Clientes y compañeros de trabajo, así como fomentar la
														diversidad e inclusión para construir un entorno en donde nos
														sintamos libres de ser quien realmente somos.
													</p>
													<p>
														Es así que, conocer y garantizar el cumplimiento de nuestro
														Código de Conducta, se vuelve fundamental. Ya que es ahí donde
														se establecen los compromisos que adoptamos al ser parte de
														Grupo Aeroméxico, mismos que, viviéndolos día con día, nos
														permiten cumplir con los más altos estándares de ética,
														integridad corporativa y excelencia.
													</p>
													<p>
														Como parte de nuestra continua evolución, hemos ajustado los
														comportamientos que conforman el ADN de nuestra compañía, a
														través de los cuales definimos nuestro actuar para continuar
														llevando lo mejor de México a todo el mundo.
													</p>
													<p>
														Te invito a leer detenidamente este Código, reflexionar sobre su
														alcance e importancia y aplicarlo sin excepciones, para
														convertirte en un embajador de nuestros valores y en
														consecuencia brindar a nuestros compañeros y Clientes la mejor
														experiencia.
													</p>
													<p>
														Si tienes preguntas, puedes acercarte con tu jefe directo, al
														área de Legal Cumplimiento o con tu HRBP. Si quieres reportar
														alguna falta ética o cualquier situación que vaya en contra de
														este Código, recuerda que tienes a tu disposición la Línea Ética
														Aeroméxico, donde puedes hacerlo de manera confidencial.
													</p>
													<p>
														Cumplir con nuestros comportamientos y valores es fundamental
														para continuar siendo la opción #1 de nuestros Clientes y
														Colaboradores.
													</p>
													<p>¡Cuento contigo!</p>
													<p>
														Andrés Conesa <br />
														Director General, Grupo Aeroméxico.
													</p>
												</>
											) : (
												<>
													<p>
														As Aeroméxico ambassadors, we must take responsibility for the
														impact of our decisions, respect our customers and co-workers,
														as well as promote diversity and inclusion to build an
														environment where we feel free to be who we really are.
													</p>
													<p>
														Thus, knowing and guaranteeing compliance with our Code of
														Conduct becomes essential. Since it is there where the
														commitments that we adopt as part of Grupo Aeroméxico are
														established, which, by living them every day, allow us to comply
														with the highest standards of ethics, corporate integrity and
														excellence.
													</p>
													<p>
														As part of our continuous evolution, we have adapted the
														behaviors that make up the DNA of our company, through which we
														define our actions to continue bringing the best of Mexico to
														the whole world.
													</p>
													<p>
														I invite you to carefully read this Code, reflect on its scope
														and importance, and apply it without exceptions, in order to
														become an ambassador of our values and, consequently, provide
														our colleagues and Customers with the best experience.
													</p>
													<p>
														If you have any question, you can approach your direct manager,
														the Legal and Compliance Corporate Division or your HRBP. Should
														you want to report any ethical fault or any situation that goes
														against this Code, remember that the Aeroméxico Ethics Line is
														available, where you can do it confidentially.
													</p>
													<p>
														Complying with our behaviors and values is essential to continue
														being the #1 option of our Customers and Collaborators.
													</p>
													<p>I count on you!</p>
													<p>
														Andrés Conesa Labastida <br />
														Chief Executive Officer of Grupo Aeroméxico.
													</p>
												</>
											)}
										</>
									)}
								</div>
							</div>
						</div>
					</Modal>
				)}
				{/* <Index /> */}
			</div>
		);
	}
}