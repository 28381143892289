import React, { Component } from "react";
import * as contentful from "../../../lib/contentful";
import { Link } from "react-router-dom";

import ItemCard from "./ItemCard";
import "./css/articleCards.css";
import "./css/tag.css";
import getLocale from "../../../utils/getLocale";
import i18next from "i18next";

export default class ArticleCards extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      error: null,
      entries: null
    };
  }
  componentDidMount = () => {
    this.fetchEntries();
  }
  fetchEntries = () => {
    contentful.client
      .getEntries({
        content_type: "articleClass", 'fields.articleTagES[all]': 'AM | DL JCA',
        include: 2,
        // order: '-sys.updatedAt',
        order: '-fields.fechaDeLaPublicacion',
        locale: getLocale(),
      })
      
      .then(response => response.items)
      .then(data => {
        this.setState({
          loading: false,
          entries: data
        });
      })

      // .then((space) => space.getLocale('es-MX'))

      .catch(error => {
        this.setState({
          loading: false,
          error: error
        });
      })
      .catch(err => console.error(err));
  };
  renderList = articles => {
    const list = articles.slice(0, 8).map(article => {
      return <ItemCard key={article.sys.id} article={article} />;
    });
    return list;
  };

  render() {
    const { loading, entries, error } = this.state;
     
    return (
      <div className="LatestWrap newsWrapperWidth">
        <div className="sub-menu-container">
        <ul className="column-item-list">
          <li className="Nav-list">
           
              <h2 className="nav-title">{i18next.t('News.ultimas')}</h2>
            
          </li>

         

          <li className="Nav-list all">
            <Link className="link-all floatingmenu" to="/noticias/delta">
              {i18next.t('News.todo')}
            </Link>
    </li>
        </ul>
      </div>
        <div className="ArticleCardWrap">
          {!loading && entries && this.renderList(entries)}
        </div>
      </div>
    );
  }
}
