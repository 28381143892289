import React, { Component } from 'react';
// import arrow from '../../../assets/images/arrow-blue.svg'
import Modal from 'react-awesome-modal';
import close from '../../../assets/logo/close.svg';
// import periodos from '../../../assets/calendar.svg'
import accesTravel01 from '../../../assets/images/arrow-blue.svg';
import * as contentful from '../../../lib/contentful';
import CurrencyFormat from 'react-currency-format';
// import ReactMarkdown from "react-markdown";
import nacionalicon from '../../../assets/ventaespecial/nacional.svg';
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';
import { Trans } from 'react-i18next';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton
} from 'react-accessible-accordion';

import 'react-accessible-accordion/dist/fancy-example.css';

var noScroll = require('no-scroll');

const options = {
	renderText: (text) => {
		return text.split('\n').reduce((children, textSegment, index) => {
			return [...children, index > 0 && <br key={index} />, textSegment];
		}, []);
	},
	renderNode: {
		[INLINES.HYPERLINK]: (node, children) => {
			return (
				<a
					href={`${node.data.uri}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{children}
				</a>
			);
		}
	}
};

export default class ModalEmbargo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false,
			loading: true,
			error: null,
			entries: null,
			entries2: null,
			entries3: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		setTimeout(() => this.setState({ loading: false }), 1500);
		document.addEventListener('keydown', this.escFunction, false);
		this.fetchEntries();
		this.fetchEntries2();
		this.fetchEntries3();
	}

	UNSAFE_componentWillMount() {
		document.addEventListener('mousedown', this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false,
				modalIsShowIdiomas: false,
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.escFunction, false);
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	validateFromNational(theOption) {
		
		const theObj = this.state.entries2.find((o, i) => {
			if (o.fields.departureFrom === theOption) {
				return true;
			}
			return false;
		});
		return !!theObj ? true : false;
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialDestinos',
				'fields.typeOfDestination[all]': 'DestinoNacional',
				'fields.departureFrom[all]': 'Saliendo desde Mexico',
				include: 2,
				locale: getLocale(),
				order: 'fields.destinationName'
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries2 = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialDestinos',
				'fields.typeOfDestination[all]': 'DestinoNacional',
				'fields.departureFrom[nin]': 'Saliendo desde Mexico',
				order: 'fields.destinationName',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries2: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries3 = () => {
		contentful.client
			.getEntries({
				// content_type: "ventaEspecialDestinos",
				content_type: 'ventaEspecialLegales',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries3: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	render() {
		const { loading, entries, entries2, entries3, legales } = this.state;
		
		return (
			<div>
				<Modal
					visible={this.state.visible}
					width="auto"
					height="auto"
					effect="fadeInUp"
					onClickAway={() => this.closeModal()}
				>
					<div className="modalWindowAncillaries upgradeModalHeightEmbargo">
						<div
							className="closeModal"
							onClick={() => this.closeModal()}
						>
							<img src={close} alt="Close"></img>
						</div>

						<div className="topBox">
							<div className="header">
								<div className="imageCircle calendarioembargo"></div>
								<div className="textLeftCircle">
									<p className="title">
										{i18next.t('NacionalModal.title')}
									</p>
									<p className="subtitle">
										{i18next.t('NacionalModal.subtitle')}
									</p>
								</div>
							</div>

							<div className="contentType">
								<Accordion
									allowZeroExpanded="true"
									id="wrapperAccordionThis"
								>
									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
													{i18next.t(
															'NacionalModal.ene19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.ene19.t1">
														ene{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ene19.t2">
														ene{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ene19.t3">
														ene{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ene19.t4">
														ene{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ene19.t5">
														ene{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.feb19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.feb19.t1">
														feb{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.feb19.t2">
														feb{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.feb19.t3">
														feb{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.feb19.t4">
														feb{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.mar19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.mar19.t1">
														mar{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.mar19.t2">
														mar{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.mar19.t3">
														mar{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.mar19.t4">
														mar{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.abr19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t1">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t2">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t3">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t4">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t5">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t6">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.abr19.t7">
														abr{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.may19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.may19.t1">
														may{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.may19.t2">
														may{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.may19.t3">
														may{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.jun19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.jun19.t1">
														jun{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.jun19.t2">
														jun{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.jul19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.jul19.t1">
														jul{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.jul19.t2">
														jul{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.jul19.t3">
														jul{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.jul19.t4">
														jul{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.ago19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.ago19.t1">
														ago{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ago19.t2">
														ago{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ago19.t3">
														ago{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.ago19.t4">
														ago{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.sep19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.sep19.t1">
														sep{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.sep19.t2">
														sep{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.sep19.t3">
														sep{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.sep19.t4">
														sep{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.oct19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.oct19.t1">
														oct{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.oct19.t2">
														oct{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.nov19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t1">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t2">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t3">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t4">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t5">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t6">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t7">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t8">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.nov19.t9">
														nov{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>

									<AccordionItem>
										<AccordionItemHeading>
											<AccordionItemButton>
												<div className="tableBoletosHeader">
													<p className="title">
														{i18next.t(
															'NacionalModal.dic19.title'
														)}
													</p>
												</div>
											</AccordionItemButton>
										</AccordionItemHeading>
										<AccordionItemPanel>
											<ul>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t1">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t2">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t3">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t4">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t5">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t6">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t7">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t8">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t9">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t10">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
												<li>
													<Trans i18nKey="NacionalModal.dic19.t11">
														dic{' '}
														<span>
															{i18next.t('1')}
														</span>
													</Trans>
												</li>
											</ul>
										</AccordionItemPanel>
									</AccordionItem>
								</Accordion>

								<p className="small">
									<Trans i18nKey="NacionalModal.ruta1">
										ruta1 <span>{i18next.t('1')}</span>
									</Trans>
								</p>
								<p className="small">
									<Trans i18nKey="NacionalModal.ruta2">
										ruta2 <span>{i18next.t('1')}</span>
									</Trans>
								</p>
								<p className="small">
									<Trans i18nKey="NacionalModal.ruta3">
										ruta3 <span>{i18next.t('1')}</span>
									</Trans>
								</p>
							</div>
						</div>
					</div>
				</Modal>

				<div
					className="modal fade modal-xl"
					id="modal360"
					tabindex="-1"
					role="dialog"
					aria-labelledby="modal360Title"
					aria-hidden="true"
				>
					<div
						className="modal-dialog modal-dialog-centered modal-xl"
						role="document"
					>
						<div className="modal-content w-100 modalVentaEspecial p-5">
							<a class="closeModal close" data-dismiss="modal">
								<img src={close} alt="Close"></img>
							</a>
							<div className="wrapperDivName">
								<p className="titleVentaDestinosTodo">
									{i18next.t(
										'NacionalModal.destinos.nacionales'
									)}
								</p>

								{!loading &&
									entries3 &&
									entries3.map((legales) => (
										<div className="rich-text header-text nacionales">
											{documentToReactComponents(
												legales.fields
													.encabezadoNacionales,
												options
											)}
										</div>
									))}

								<div className="titleVentaDiv">
									<p className="fromMexicoCity">
										{i18next.t(
											'NacionalModal.destinos.saliendo'
										)}
									</p>
								</div>

								<div className="wrapperDestinosVenta">
									{!loading &&
										entries &&
										entries.map((destinosVentaEspecial) => (
											<div
												className="destinoOption"
												key={
													destinosVentaEspecial.sys.id
												}
											>
												<div className="iconDestino">
													<img
														src={
															destinosVentaEspecial
																.fields
																.iconDestination
																.fields.file.url
														}
														alt="Destinos Venta Especial"
													/>
												</div>
												<div className="textDestino">
													<div className="alignMiddleText">
														<p className="destinationName">
															{
																destinosVentaEspecial
																	.fields
																	.destinationName
															}{' '}
															<span>
																{
																	destinosVentaEspecial
																		.fields
																		.iataDestination
																}
															</span>
														</p>
													</div>
												</div>
												<div className="priceDestino">
													<div className="alignMiddleText">
														<CurrencyFormat
															value={
																destinosVentaEspecial
																	.fields
																	.priceForTravel
															}
															displayType={'text'}
															thousandSeparator={
																true
															}
															decimalScale={'2'}
															prefix={''}
															renderText={(
																value
															) => (
																<p
																	className={
																		'priceDestination' +
																		' ' +
																		destinosVentaEspecial
																			.fields
																			.typeOfDestination
																	}
																>
																	{value}{' '}
																</p>
															)}
														/>
													</div>
												</div>
											</div>
										))}
								</div>
								{/* GUADALAJARA START */}
								{!loading &&
								entries2 &&
								this.validateFromNational(
									'Saliendo desde Guadalajara'
								) ? (
									<>
										<div className="titleVentaDiv m-0">
											<p className="fromMexicoCity">
												Saliendo desde Guadalajara
											</p>
										</div>
										<div className="wrapperDestinosVenta">
											{entries2.map((dNacional) =>
												dNacional.fields
													.departureFrom ===
												'Saliendo desde Guadalajara' ? (
													<div
														className="destinoOption"
														key={dNacional.sys.id}
													>
														<div className="iconDestino">
															<img
																src={
																	dNacional
																		.fields
																		.iconDestination
																		.fields
																		.file
																		.url
																}
																alt="Destinos Venta Especial"
															/>
														</div>
														<div className="textDestino">
															<div className="alignMiddleText">
																<p className="destinationName">
																	{
																		dNacional
																			.fields
																			.destinationName
																	}{' '}
																	<span>
																		{
																			dNacional
																				.fields
																				.iataDestination
																		}
																	</span>
																</p>
															</div>
														</div>
														<div className="priceDestino">
															<div className="alignMiddleText">
																<CurrencyFormat
																	value={
																		dNacional
																			.fields
																			.priceForTravel
																	}
																	displayType={
																		'text'
																	}
																	thousandSeparator={
																		true
																	}
																	decimalScale={
																		'2'
																	}
																	prefix={''}
																	renderText={(
																		value
																	) => (
																		<p
																			className={
																				'priceDestination' +
																				' ' +
																				dNacional
																					.fields
																					.typeOfDestination
																			}
																		>
																			{
																				value
																			}{' '}
																		</p>
																	)}
																/>
															</div>
														</div>
													</div>
												) : null
											)}
										</div>
									</>
								) : null}
								{/* GUADALAJARA END */}
								{/* República Mexicana START */}
								{!loading &&
								entries2 &&
								this.validateFromNational(
									'Saliendo desde República Mexicana'
								) ? (
									<>
										<div className="titleVentaDiv m-0">
											<p className="fromMexicoCity">
												Saliendo desde República
												Mexicana
											</p>
										</div>
										<div className="wrapperDestinosVenta">
											{entries2.map((dNacional) =>
												dNacional.fields
													.departureFrom ===
												'Saliendo desde República Mexicana' ? (
													<div
														className="destinoOption"
														key={dNacional.sys.id}
													>
														<div className="iconDestino">
															<img
																src={
																	dNacional
																		.fields
																		.iconDestination
																		.fields
																		.file
																		.url
																}
																alt="Destinos Venta Especial"
															/>
														</div>
														<div className="textDestino">
															<div className="alignMiddleText">
																<p className="destinationName">
																	{
																		dNacional
																			.fields
																			.destinationName
																	}{' '}
																	<span>
																		{
																			dNacional
																				.fields
																				.iataDestination
																		}
																	</span>
																</p>
															</div>
														</div>
														<div className="priceDestino">
															<div className="alignMiddleText">
																<CurrencyFormat
																	value={
																		dNacional
																			.fields
																			.priceForTravel
																	}
																	displayType={
																		'text'
																	}
																	thousandSeparator={
																		true
																	}
																	decimalScale={
																		'2'
																	}
																	prefix={''}
																	renderText={(
																		value
																	) => (
																		<p
																			className={
																				'priceDestination' +
																				' ' +
																				dNacional
																					.fields
																					.typeOfDestination
																			}
																		>
																			{
																				value
																			}{' '}
																		</p>
																	)}
																/>
															</div>
														</div>
													</div>
												) : null
											)}
										</div>
									</>
								) : null}
								{/* República Mexicana END */}
								{/* Santa Lucía START */}
								{!loading &&
								entries2 &&
								this.validateFromNational(
									'Saliendo desde Santa Lucía'
								) ? (
									<>
										<div className="titleVentaDiv m-0">
											<p className="fromMexicoCity">
												Saliendo desde Santa Lucía
											</p>
										</div>
										<div className="wrapperDestinosVenta">
											{entries2.map((dNacional) =>
												dNacional.fields
													.departureFrom ===
												'Saliendo desde Santa Lucía' ? (
													<div
														className="destinoOption"
														key={dNacional.sys.id}
													>
														<div className="iconDestino">
															<img
																src={
																	dNacional
																		.fields
																		.iconDestination
																		.fields
																		.file
																		.url
																}
																alt="Destinos Venta Especial"
															/>
														</div>
														<div className="textDestino">
															<div className="alignMiddleText">
																<p className="destinationName">
																	{
																		dNacional
																			.fields
																			.destinationName
																	}{' '}
																	<span>
																		{
																			dNacional
																				.fields
																				.iataDestination
																		}
																	</span>
																</p>
															</div>
														</div>
														<div className="priceDestino">
															<div className="alignMiddleText">
																<CurrencyFormat
																	value={
																		dNacional
																			.fields
																			.priceForTravel
																	}
																	displayType={
																		'text'
																	}
																	thousandSeparator={
																		true
																	}
																	decimalScale={
																		'2'
																	}
																	prefix={''}
																	renderText={(
																		value
																	) => (
																		<p
																			className={
																				'priceDestination' +
																				' ' +
																				dNacional
																					.fields
																					.typeOfDestination
																			}
																		>
																			{
																				value
																			}{' '}
																		</p>
																	)}
																/>
															</div>
														</div>
													</div>
												) : null
											)}
										</div>
									</>
								) : null}
								{/* Santa Lucía END */}

								<div className="legalesTextoGreen">
									<p>
										{i18next.t('NacionalModal.impuestos')}
									</p>
								</div>
								{/* SE REMUEVE TEMPORALMENTE INICIO */}
								{/* <div className="titleVenta">
									<p className="fromMexicoCity">
										{i18next.t(
											"NacionalModal.bidireccional"
										)}
									</p>
								</div> */}

								{/* <div className="wrapperDestinosVenta">
									{!loading &&
										entries2 &&
										entries2.map(
											destinosVentaEspecialMas => (
												<div
													className="destinoOption"
													key={
														destinosVentaEspecialMas
															.sys.id
													}
												>
													<div className="iconDestino">
														<img
															src={
																destinosVentaEspecialMas
																	.fields
																	.iconDestination
																	.fields.file
																	.url
															}
															alt="Destinos Venta Especial Más"
														/>
													</div>
													<div className="textDestino">
														<div className="alignMiddleText">
															<p className="destinationName">
																{
																	destinosVentaEspecialMas
																		.fields
																		.destinationName
																}{' '}
																<span>
																	{
																		destinosVentaEspecialMas
																			.fields
																			.iataDestination
																	}
																</span>
															</p>
															<p className="departurefrom">
																{
																	destinosVentaEspecialMas
																		.fields
																		.departureFrom
																}
															</p>
														</div>
													</div>
													<div className="priceDestino">
														<div className="alignMiddleText">
															<CurrencyFormat
																value={
																	destinosVentaEspecialMas
																		.fields
																		.priceForTravel
																}
																displayType={
																	'text'
																}
																thousandSeparator={
																	true
																}
																decimalScale={
																	'2'
																}
																prefix={''}
																renderText={value => (
																	<p
																		className={
																			'priceDestination' +
																			' ' +
																			destinosVentaEspecialMas
																				.fields
																				.typeOfDestination
																		}
																	>
																		{value}{' '}
																	</p>
																)}
															/>
														</div>
													</div>
												</div>
											)
										)}
								</div> */}

								{/* <div className="legalesTextoGreen">
									<p>
										{i18next.t('NacionalModal.impuestos')}
									</p>
								</div> */}
								{/* SE REMUEVE TEMPORALMENTE FINAL */}

								<div className="condicionesVentaEspecialUso">
									<p className="temsConditions">
										{i18next.t(
											'NacionalModal.terminos.title'
										)}
									</p>

									{!loading &&
										entries3 &&
										entries3.map((legales) => (
											<div className="ventaEspecialContent rich-text footer-text nacionales">
												{documentToReactComponents(
													legales.fields
														.terminosCondicionesNacionales,
													options
												)}
											</div>
										))}
								</div>
							</div>
						</div>
					</div>
				</div>

				<a
					className="ventaEspecialModalesCambios"
					// onClick={() => this.openModal()}
					data-toggle="modal"
					data-target="#modal360"
				>
					<div className="cardWrapperBoletos">
						<img
							className="icon marginTopIconNew"
							src={nacionalicon}
							alt="Precios Tarifas"
						></img>
						<div className="ticketsText iconTextnoNecesario">
							<p>
								{i18next.t('NacionalModal.destinos.nacionales')}
							</p>
							<p>{i18next.t('NacionalModal.desde')}</p>
							<a className="viewPricesTarifas">
								{i18next.t('NacionalModal.tarifas')}
							</a>
						</div>
						<img
							className="arrow"
							src={accesTravel01}
							alt="Arrow"
						></img>
					</div>
				</a>
			</div>
		);
	}
}
