import React, { useState, useEffect } from 'react';
import Modal from 'react-awesome-modal';
import './css/modal.css';
import close from '../../../assets/logo/close.svg';
import bg_img from '../../../assets/images/benefits/image-2.jpg'

const noScroll = require('no-scroll');

const ZEDBtn = ({ title }) => {
    const [visible, setVisible] = useState(false);

    const closeModal = () => {
        setVisible(false);
        noScroll.off();
    };

    const openModal = () => {
        setVisible(true);
        noScroll.on();
    };

    return (
        <>
            <a onClick={openModal} style={{ color: '#0000c6' }}><u style={{ fontWeight: 'bold' }}>Revisa el proceso para obtenerlo</u></a>
            <ModalImplement visible={visible} closeModal={closeModal} />
        </>
    );
};

export default ZEDBtn;

const ModalImplement = ({ visible, closeModal }) => {
    useEffect(() => {
        const paragraphs = document.querySelectorAll('.myp');
        paragraphs.forEach(paragraph => {
            paragraph.style.setProperty('color', 'blue', 'important'); // Añadir !important dinámicamente
        });
        const header = document.querySelector('.zed-header');
        if (header) {
            header.style.setProperty("background-image", `url('${bg_img}')`, "important");
            header.style.setProperty("background-size", "cover", "important");
            header.style.setProperty("background-position", "center", "important");
            header.style.setProperty("width", "100%", "important");
            header.style.setProperty("padding", "20px", "important");
            header.style.setProperty("border-top-left-radius", "10px", "important");
            header.style.setProperty("border-top-right-radius", "10px", "important");
        }
    }, []);

    return (
        <Modal
            visible={visible}
            width="auto"
            height="auto"
            effect="fadeInUp"
            onClickAway={closeModal}
        >
            <div className="modalWindowAncillaries" style={{ maxWidth: '700px' }}>
                <div className="closeModal" onClick={closeModal}>
                    <img src={close} alt="close modal" />
                </div>

                <div className="topBox">
                    <div className="header flex items-center justify-start zed-header">
                        <div className="textLeftCircle m-0">
                            <p className="m-0 text-2xl font-medium text-amBlack" style={{color: 'white'}}>
                                Extensión de beneficios de descuentos interlineales (ZED) a personal retirado en las empresas de Grupo Aeroméxico
                            </p>
                        </div>
                    </div>

                    <div className="contentType upgrade">
                        <div class="regular-text-content prose pt-4 font-normal text-amBlack" style={{ marginBottom: '20px' }}>
                            <p><strong>Aplicable a Personal No Sindicalizado de Grupo Aeroméxico que se retira de la empresa con 25 años de servicio o 55 años de edad y 10 años de servicio continuo.</strong></p>

                            <span><strong>Procedimiento</strong></span>
                            <ol>
                                <li>Confirma la elegibilidad con tu HRBP al momento de tu retiro.</li>
                                <li>En caso de ser elegible, compártele tu correo electrónico personal para el registro en sistema.</li>
                                <li>Cinco días hábiles después de tu baja en la empresa:
                                    <ul>
                                        <li>Activa tu cuenta en My IDTravel. Si tienes problemas, envía un reporte a <a href="mailto:ammyidtravel@aeromexico.com">ammyidtravel@aeromexico.com</a></li>
                                        <li>Recoge tu gafete de identificación como personal retirado:
                                            <ul>
                                                <li>CDMX: Cecam</li>
                                                <li>Estaciones: Oficina administrativa local correspondiente</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ol>
                            <p>En su momento solicita la renovación previo al vencimiento, a través de correo electrónico <a href="mailto:cssolicitudcredenciales@aeromexico.com">cssolicitudcredenciales@aeromexico.com</a> y recógelo personalmente en las instalaciones de la empresa</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
