import React, { Component, useState  } from 'react';
import NewsCategoryHeader from './NewsCategoryHeader';
// import NewsCategoryHeader from './NewsCategoryOnly';
//import SearchBar from "./SearchBar";
import TitleCategory from './TitleCategory';
import RecentCards from './RecentCards';
// import Month from "./Month";
// import MonthCards from "./MonthCards";
// import Loading from "./Loading";
import * as contentful from '../../lib/contentful';
import LoadingScreen from 'react-loading-screen';
// import avion from "../../assets/images/Spinner/avion.gif";
import getLocale from '../../utils/getLocale';
import i18next from 'i18next';
import './css/searchBar.css'; 
import { animateScroll as scroll} from "react-scroll";

const url = window.location;
const urlObj = new URL(url);
const recuperaId = urlObj.pathname.split("/")[2];




class NewsCategory extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			error: null,
			entries: null,
			entriesHeader: null,
			entriesMirror: null,
			loadingScreen: true,
			previousPathname: null
		}; 
		window.addEventListener('popstate', this.handleLocationChange);
	}
	handleLocationChange = () => {
		window.location.reload()
    }
	componentDidMount() {
		this.fetchEntries();
		this.fetchEntriesHeader();
		setTimeout(() => this.setState({ loadingScreen: false }), 1500); 
		scroll.scrollToTop();
	}
	componentWillUnmount() {
        // Eliminar el event listener cuando el componente se desmonte
        window.removeEventListener('popstate', this.handleLocationChange);
		window.scrollTo(0,0)
    }
	
	componentDidUpdate(prevProps) { 

		
		if (this.props.match.params.newsId !== prevProps.match.params.newsId) {
			this.fetchEntries();
			this.fetchEntriesHeader();
			window.addEventListener('popstate', this.handleLocationChange);
		}
	}
	fetchEntries = () => { 
		contentful.client
			.getEntries({
				content_type: 'articleClass',
				include: 2,
				locale: getLocale(),
				// order: '-sys.updatedAt',
				order: '-fields.fechaDeLaPublicacion'
			})
			.then(response => response.items)
			.then(data => {
				
				this.setState({
					loading: false,
					entries: data,
					entriesMirror: data
				});
			
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err))
			
	};
	fetchEntriesHeader = () => {
		contentful.client
			.getEntries({
				content_type: 'headerArticlePage',
				include: 2,
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entriesHeader: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	renderList = entries => {
		const categoryRecentCards = entries.filter(RecentCard => {
			if (
				this.news(this.props.match.params.newsId) ===
				RecentCard.fields.articleClass
			) {
				return RecentCard;
			}
		});

		const list = categoryRecentCards.map(recent => {
			return <RecentCards key={recent.sys.id} data={recent} />;
		});

		return list;
	};

	renderHeader = entries => {
		const categoryHeaderCards = entries.filter(HeaderCard => {
			//  alert("HEADER REDNDER"+JSON.stringify(HeaderCard.fields.articleClass) + ' : ' + JSON.stringify(this.props.newsId));
			if (this.news(this.props.match.params.newsId) === HeaderCard.fields.articleClass) {
				return HeaderCard;
			}
		});

		const list = categoryHeaderCards.map(header => {
			 
			return <NewsCategoryHeader key={header.sys.id} data={header} />;
			
		});

		return list;
	};

	news = links => {
		const recentNews = {
			experienciacliente: 'CustomerExTag',
			nuestragente: 'PeopleTag',
			delta: 'DeltaTag',
			comercial: 'CommercialTag',
			operaciones: 'OperationsTag',
			mensajesceo: 'CeoTag',
			skyteam: 'SkyTeamTag',
			seguridad: 'SecurityTag',
			sostenibilidad: 'SusteTag'
			//logros: "AchievementsExTag"
		};
		return recentNews[links];
	};

	headerTitle = links => {
		const recentNews = {
			experienciacliente: 'Customer Header test',
			nuestragente: 'People Header test',
			delta: 'Delta Header test',
			comercial: 'Commercial Header test',
			operaciones: 'Operations Header test',
			mensajesceo: 'CEO Header test',
			skyteam: 'SkyTeam Header test',
			seguridad: 'Seguridad Header test'
		};
		return recentNews[links];
	};

	searchByName = e => {
		let searchList = [...this.state.entries];
		var query = e.target.value;

		if (query !== '') {
			var coincidences = searchList.filter(
				searchItem =>
					searchItem.fields.articleTittle
						.toLowerCase()
						.indexOf(query.toLowerCase()) !== -1
			);
			this.setState({
				entries: coincidences
			});
		} else {
			this.setState({
				entries: this.state.entriesMirror
			});
		}
	};
	render() {
		const {
			loading,
			entries,
			error,
			entriesHeader,
			loadingScreen
		} = this.state;
		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text={i18next.t('News.cargando')}
			> 
				<>
					{!loading && entriesHeader && this.renderHeader(entriesHeader)}

					<div className="recentCards">
						<div className="recentCardsContainer">
							{!loading && entries && this.renderList(entries)}
						</div>
					</div>

					{/**<SearchBar />**/}
					<div id="buscar"></div>
					<div className="mainContainerSearchBar">
						<div className="containerSearchBar">
							<input
								onChange={this.searchByName}
								type="serach"
								name="searchBar"
								placeholder={i18next.t('News.buscarNoticia')}
							/>
							<i class="fa fa-search" aria-hidden="true" />
						</div>
					</div> 
					<TitleCategory data={this.props.match.params.newsId} />

					{/*<Month />*/}
					{/*<MonthCards />*/}
					{/*<Loading />*/}
				</>
				{/* <Index/> */}
			</LoadingScreen>
		);
	}
}

export default NewsCategory;
