import { OktaAuth } from '@okta/okta-auth-js';

//clientId: "00T2o75v1wO0yYGga0h7", // pruebas
//issuer: "https://lobbygateone.amx-digital.com/oauth2/default",

const oktaAuth = new OktaAuth({
    //clientId: "0oa2l5lu2ywEjXJpi4x7", // Ajusta según tu entorno
    //issuer: "https://gateone.amx-digital.com/",
    clientId: "0oaw9rnju9yDyiffv0h7", // pruebas
    issuer: "https://lobbygateone.amx-digital.com/",
    redirectUri: `${window.location.origin}/login/callback`,
    postLogoutRedirectUri: `${window.location.origin}/logout/callback`,
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
  });

export default oktaAuth;