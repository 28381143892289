import React, { Component } from 'react'; 
import './css/flightState.css';
import * as contentful from '../../../lib/contentful';
import getLocale from '../../../utils/getLocale';
import i18next from 'i18next';
import { isLocal, isFromDev, isFromQA } from './../../../core/Ambientes';
import { isValidURL } from '../../../utils/isValidEmail';

const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + tokenValid
	});


export default class flightPlaneState extends Component {
	constructor() {
		super();
		this.toggle = this.toggle.bind(this);
		this.state = {
			loading: true,
			error: null,
			entries: null,
			language: i18next.language,
			tooltipOpen: false,
			planVuelo: null,
			loadingPlan: true
		};
	}
	componentDidMount() {
	
		this.fetchEntries();

		const urlEN = isValidURL(`${urlProd}traduccion-en`);
		const urlES = isValidURL(`${urlProd}traduccion-es`);
		fetch(this.state.language === 'es'
				? urlES
				: urlEN, {headers: cabecera})
			.then((res) => res.json())
			.then((res) => { 
				this.setState({
					...this.state,
					planVuelo: res.data.attributes.data.Inicio.planVuelo.plan2021,
					loadingPlan: false
				});
			})
			.catch((err) => console.error(err));
	}

	toggle() {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen
		});
	}

	planVueloLink() {
		if (i18next.language === 'en') {
			return '/nosotros/plan-de-vuelo/ingles';
		} else {
			return '/nosotros/plan-de-vuelo';
		}
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'stateOfAirline',
				include: 2,
				locale: getLocale()
			})
			.then((response) => response.items)
			.then((data) => {
				
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	render() {
		return (
			<div className="stateFlightPlane overflow-hidden">
				<div class="TitleState flightplanstate bg-[#020C41]">
					<p className="w-full pl-0 text-center">{i18next.t('Inicio.planVuelo.title')}</p>
				</div>
				<div className="contDataFlight bg-[#030D75]">
					{!this.state.loadingPlan && (
						<>
							{this.state.planVuelo.map((item) => (
								<div
									key={item.id}
									className="DataWrapper bg-[#030D75]"
									id={item.id + '-Tooltip'}
								>
									<div className="textWrapper">
										<div className="CellWrapper">
											<p>{item.title}</p>
										</div>
									</div>
									<div className="graphWrapper">
										<div className="graphbgColor">
											<div className="goingGraphColor"></div>
										</div>
									</div>
								</div>
							))}
						</>
					)}
				</div>

				<div className="significados bg-[#030D75] text-white">
					<div className="cont text-white opacity-100">
						<p className="py-2 text-white opacity-100">{i18next.t('Inicio.planVuelo.actualizado')}</p>
					</div>
				</div>
				<a
					href={i18next.t('Inicio.planVuelo.planURL')}
					className='bg-[#020C41]'
					target="_blank"
					rel="noopener noreferrer">
					{i18next.t('Inicio.planVuelo.ver')}
				</a>
			</div>
		);
	}
}
