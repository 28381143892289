import React, { Component } from 'react';
import Aside from '../AsideProfile';
import i18next from 'i18next';
import swal from 'sweetalert';
import '../../Profile/css/alert-cs.css';
import './css/form.css';
import { isLocal, isFromDev, isFromQA } from './../../../core/Ambientes';
import { isValidURL } from '../../../utils/isValidEmail';

function validate(
	name,
	email,
	employee_number,
	actual_boss,
	actual_job,
	cv,
	pregunta_abierta
) {
	// true means invalid, so our conditions got reversed
	return {
		name: name.length === 0,
		email: email.length === 0,
		employee_number: employee_number.length === 0,
		actual_job: actual_job.length === 0,
		actual_boss: actual_boss.length === 0,
		cv: cv.length === 0,
		pregunta_abierta: pregunta_abierta.length === 0
	};
}


export default class Form extends Component {
	state = {
		name: '',
		error: false,
		charging: true,
		data: null,
		success: false,
		email: '',
		password: '',
		employee_number: '',
		actual_boss: '',
		actual_job: '',
		pregunta_abierta: '',
		cv: '',
		loadingEntry: true,
		touched: {
		  email: false,
		  password: false
		}
	};


	componentDidMount() {
		this.fetchEntry();
		this.setData();
	}
	fetchEntry = async () => {
		// # Variables que switchean las ligas que se consumen segun sea elcaso
		// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
		// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
		// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
		/* Para productivo se ponen todas en falso */

		// #URl de ambiente
		const urlLocal = `${process.env.REACT_APP_LOCAL}`;
		const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
		const urlQA = `${process.env.REACT_APP_CADMIN_QA}`;
		const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`;

		const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
		const tokenDev = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
		const tokenQA = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
		const tokenProd = `${process.env.REACT_APP_API_TOKEN}`;

		// # Liga validada
		const urlValid = isLocal === true ? urlLocal : isFromDev === true ? urlDev : isFromQA === true ? urlQA : urlProd;

		// Se genera el token segun el ambiente
		const tokenValid = isLocal === true ? tokenLocal : isFromDev === true ? tokenDev : isFromQA === true ? tokenQA : tokenProd;

		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});

		// const currentUrl = window.location.pathname.split("/").pop();
		// const apiUrl = urlValid ? `${urlValid}vacantes/${currentUrl}` : null;

		//const urlVacante = `${urlValid}vacantes/${this.props.match.params.jobId}`;
		const urlVacante = `${urlValid}vacantes/${this.props.match.params.jobId}`;


		if (urlVacante) {
			const newURLVacante = isValidURL(urlVacante);
			const vacanteData = await fetch(newURLVacante, { headers: cabecera })
				.then((res) => res.json())
				.catch((error) => {
					this.setState({
						loadingEntry: true,
						errorEntry: error
					});
				});
			this.setState({
				loadingEntry: false,
				entry: vacanteData
			});
		} else {
			console.error("La URL del servidor no está configurada correctamente.");
		}

		
	};
	handleChange = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
			succes: false
		});
	};
	handleCv = (e) => {
		if (e.target.files[0].size > 4500000) {
			alert("El archivo no puede pesar más de 4.5MB");
			return null;
		} else {
			this.setState({
				cv: e.target.files[0],
				fileName: e.target.files[0].name,
				succes: false
			});
		}
	};

	handleBlur = (field) => (evt) => {
		this.setState({
			touched: { ...this.state.touched, [field]: true }
		});
	};

	setData = () => {
		if (
			localStorage.getItem("nombre") !== null &&
			localStorage.getItem("apellidoMaterno") &&
			localStorage.getItem("apellidoMaterno")
		) {
			this.setState({
				name:
					localStorage.getItem("nombre").toLowerCase() +
					" " +
					localStorage.getItem("apellidoPaterno").toLowerCase() +
					" " +
					localStorage.getItem("apellidoMaterno").toLowerCase()
			});
		}
		if (localStorage.getItem("email") !== null) {
			this.setState({
				email: localStorage.getItem("email")
			});
		}
		if (localStorage.getItem("noEmpleado") !== null) {
			this.setState({
				employee_number: localStorage.getItem("noEmpleado")
			});
		}
		if (localStorage.getItem("noEmpleado") !== null) {
			this.setState({
				employee_number: localStorage.getItem("noEmpleado")
			});
		}
		if (localStorage.getItem("posicion") !== null) {
			this.setState({
				actual_job: localStorage.getItem("posicion").toLowerCase()
			});
		}
	};

	canBeSubmitted() {
		const {
			name,
			email,
			employee_number,
			actual_boss,
			actual_job,
			cv,
			pregunta_abierta
		} = this.state;
		const errors = validate(
			name,
			email,
			employee_number,
			actual_boss,
			actual_job,
			cv,
			pregunta_abierta
		);
		const isDisabled = Object.keys(errors).some((x) => errors[x]);
		return !isDisabled;
	}

	handleFileUpload(event) {
		const fileInput = document.getElementById('cv');
		const file = fileInput.files[0];
	
		if (file) {
			// El archivo ha sido seleccionado
	
			// Crear una instancia de FormData
			const formData = new FormData();
	
			// Agregar el archivo a FormData
			formData.append('cv', file);
	
		} 
	}

	handleSubmit = (e) => {
		e.preventDefault();
		if (!this.canBeSubmitted()) {
			return;
		}
		// const URL = 'http://localhost:5000/api/v1/send-email';
		// const URL2 =
		// 	'https://miaeromexico-jobs.herokuapp.com/api/v1/send-email';
		const URL_PROD = "https://capi.ngrok.pro/sendgrid/api/vacantes";
		//const URL_PROD = `${process.env.REACT_APP_CAPI_PROD}/sendgrid/api/vacantes`;

		let formData = new FormData();

		formData.append('name', this.state.name);
		formData.append('email', this.state.email);
		formData.append('employee_number', this.state.employee_number);
		formData.append('actual_boss', this.state.actual_boss);
		formData.append('pregunta_abierta', this.state.pregunta_abierta);
		formData.append('actual_job', this.state.actual_job);
		formData.append('recruiter_email', this.state.entry.data.attributes.recruiter_email);
		formData.append('vacante', this.state.entry.data.attributes.title);
		formData.append('fileName', this.state.fileName);
		formData.append('cv', this.state.cv);
		// formData.append("recruiter_email", this.state.)

		fetch(URL_PROD, {
			method: "POST",
			body: formData
		})
			.then((data) => data.json())
			.then((data) => {
				if (data.status === 200) {
					this.setState({
						error: false,
						data,
						charging: false,
						succes: true
					});
				} else {
					this.setState({
						error: data.message,
						charging: true
					});
				}
			})
			.then(() => document.getElementById("form-jobs").reset())
			.catch((error) => {
				this.setState({
					error,
					charging: true
				});
			});
	};

	render() {
		const {
		  charging,
		  error,
		  success,
		  name,
		  email,
		  employee_number,
		  actual_boss,
		  actual_job,
		  cv,
		  pregunta_abierta
		} = this.state;
		const errors = validate(
		  name,
		  email,
		  employee_number,
		  actual_boss,
		  actual_job,
		  cv,
		  pregunta_abierta
		);

		const isDisabled = Object.keys(errors).some((x) => errors[x]);
		const shouldMarkError = (field) => {
			const hasError = errors[field];
			const shouldShow = this.state.touched[field];
			return hasError ? shouldShow : false;
		};
		return (
			<div className="full-container" style={{ marginBottom: 'calc(24vh)', minHeight: '975px' }}>
				<div className="contProfileMenu">
					<div class="profileContainers" id="profileMenu">
						<Aside />
					</div>
				</div>
				<div class="vacantesWrapper form-container">
					<div className="wrapper-title-form">
						<h1 className="form-title">
							<strong>IMPORTANTE</strong> {i18next.t('Vacantes.aplicante.title').replace(/^Importante:\s*/, '')}
						</h1>
					</div>
					<div className="form-wrapper">
					<form
							id="form-jobs"
							onSubmit={this.handleSubmit}
							encType="multipart/form-data"
						>
							<fieldset>
								<div className="control-form control-name">
									<div className="label-form">
										<label for="name">
											{i18next.t(
												'Vacantes.aplicante.name'
											)}
										</label>
									</div>
									<div className="input-form input-name">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur('name')}
											className={
												shouldMarkError('name')
													? 'input-field error'
													: 'input-field'
											}
											name="name"
											id="name"
											value={name}
											required="true"
											type="text"
											placeholder="Nombre"
										/>
									</div>
								</div>
								<div className="control-form control-email">
									<div className="label-form">
										<label for="email">
											{i18next.t(
												'Vacantes.aplicante.email'
											)}
										</label>
									</div>
									<div className="input-form input-email">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur('email')}
											className={
												shouldMarkError('email')
													? 'input-field error'
													: 'input-field'
											}
											name="email"
											id="email"
											value={email}
											required="true"
											type="email"
											placeholder="Email"
										/>
									</div>
								</div>
								<div className="control-form control-employee">
									<div className="label-form">
										<label for="employee_number">
											{i18next.t(
												'Vacantes.aplicante.employee'
											)}
										</label>
									</div>
									<div className="input-form input-employee">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'employee_number'
											)}
											className={
												shouldMarkError(
													'employee_number'
												)
													? 'input-field error'
													: 'input-field'
											}
											name="employee_number"
											id="employee_number"
											value={employee_number}
											required="true"
											type="text"
											placeholder="Número de empleado"
										/>
									</div>
								</div>
								<div className="control-form control-boss">
									<div className="label-form">
										<label for="actual_boss">
											{i18next.t(
												'Vacantes.aplicante.boss'
											)}
										</label>
									</div>
									<div className="input-form input-boss">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'actual_boss'
											)}
											className={
												shouldMarkError('actual_boss')
													? 'input-field error'
													: 'input-field'
											}
											name="actual_boss"
											id="actual_boss"
											value={actual_boss}
											required="true"
											type="text"
											placeholder="Jefe inmediato"
										/>
									</div>
								</div>
								<div className="control-form control-name">
									<div className="label-form">
										<label for="actual_job">
											{i18next.t(
												'Vacantes.aplicante.position'
											)}
										</label>
									</div>
									<div className="input-form input-actual-job">
										<input
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'actual_job'
											)}
											className={
												shouldMarkError('actual_job')
													? 'input-field error'
													: 'input-field'
											}
											name="actual_job"
											id="actual_job"
											value={actual_job}
											required="true"
											type="text"
											placeholder="Posición actual"
										/>
									</div>
								</div>

								{/* PLACEHOLDER */}
								<div className="control-form control-pregunta">
									<div className="label-form">
										<label for="pregunta_abierta">
											{i18next.t(
												'Vacantes.aplicante.why'
											)}
										</label>
									</div>
									<div className="input-form input-pregunta">
										<textarea
											onChange={this.handleChange}
											onBlur={this.handleBlur(
												'pregunta_abierta'
											)}
											className={
												shouldMarkError(
													'pregunta_abierta'
												)
													? 'input-field error'
													: 'input-field'
											}
											name="pregunta_abierta"
											id="pregunta_abierta"
											required="true"
											type="text"
											placeholder="Escribe tu respuesta"
										/>
									</div>
								</div>

								<div className="control-form control-cv">
									<div className="label-form custom-file-upload">
										<label for="cv">
											{i18next.t('Vacantes.aplicante.cv')}
										</label>
									</div>
									<div className="input-form input-CV">
										{/* <p className="uploadyourfile">
											Selecciona un archivo
										</p> */}
										<input
											onChange={this.handleCv}
											onBlur={this.handleBlur('cv')}
											className={
												shouldMarkError('cv')
													? 'input-field error'
													: 'input-field'
											}
											name="cv"
											id="cv"
											required="true"
											type="file"
											placeholder="CV"
											size="3000"
										/>
									</div>
								</div>
							</fieldset>

							<button
								disabled={isDisabled}
								id="btn-job"
								className="btn-job"
								type="submit"
								onClick={() => {
									swal(
										'¡Todo listo!',
										'Tu aplicación ha sido enviada exitosamente',
										'success',
										{ button: false, timer: 5000 }
									);
								}}
							>
								{i18next.t('Vacantes.aplicante.send')}
							</button>
						</form>
					</div>
				</div>
			</div>
		);
	}
}
