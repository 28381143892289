import React, { Component } from 'react';
import './css/identidad.css';
import pdf from '../../assets/identidad/pdf.svg';
import styled from 'styled-components';
import i18next from 'i18next';
import { ri2024, ri2025 } from './jsons/reporteIndustrial';
import { animateScroll as scroll} from "react-scroll";

const ReporteSusten = styled.div`
	.wrapperIdenditad {
		grid-template-columns: repeat(5, 1fr);
	}
`;

export default class SliderSwiper extends Component {

	componentDidMount(){
		scroll.scrollToTop();
	}

	render() {
		return (
			<div style={{ background: '#EDF6FB' }}> 
				<div class="nosotrosWrapper">
					<div class="nosotrosHeader">
						<h4>{i18next.t('Reportes.Boletin.titulo')}</h4>
					</div>
				</div>

				<ReporteSusten>
					<div className="wrapperDownload">
						<div className="wrapperIdenditad">
							<h3 className="fulltitle">2025</h3>
							{ri2025.map((item, index) => {
								if (item.url !== '') {
									return (
										<div
											className="wrapperbuttonBig"
											key={index}
										>
											<div className="wrapperTop">
												<div className="wrapperTopCell">
													<img
														className="noTextImg"
														src={pdf}
														alt="PDF"
													></img>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.language ===
														'es'
															? item.month.es
															: item.month
																	.en}{' '}
														2025
													</p>
												</div>
											</div>
											<a
												className="wrapperBottom"
												href={item.url}
												target="_blank"
												rel="noopener noreferrer"
											>
												<div className="downloadIdenti">
													<span></span>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.t(
															'Reportes.Boletin.descargar'
														)}
													</p>
												</div>
											</a>
										</div>
									);
								}
							})}
							<h3 className="fulltitle">2024</h3>
							{ri2024.map((item, index) => {
								if (item.url !== '') {
									return (
										<div
											className="wrapperbuttonBig"
											key={index}
										>
											<div className="wrapperTop">
												<div className="wrapperTopCell">
													<img
														className="noTextImg"
														src={pdf}
														alt="PDF"
													></img>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.language ===
														'es'
															? item.month.es
															: item.month
																	.en}{' '}
														2024
													</p>
												</div>
											</div>
											<a
												className="wrapperBottom"
												href={item.url}
												target="_blank"
												rel="noopener noreferrer"
											>
												<div className="downloadIdenti">
													<span></span>
													<p style={{color:'#035cf7',fontWeight:'bold'}}>
														{i18next.t(
															'Reportes.Boletin.descargar'
														)}
													</p>
												</div>
											</a>
										</div>
									);
								}
							})}
						</div>
					</div>
				</ReporteSusten>
			</div>
		);
	}
}
