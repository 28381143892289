import React, { Component } from 'react';
import i18next from 'i18next';
import parse from 'html-react-parser';

import Modal from 'react-awesome-modal';
import close from '../../../assets/logo/close.svg';

var noScroll = require('no-scroll');

export default class BCardUSA extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: this.props.data.fields,
			id: this.props.data.sys.id,
			show: false,
			visible: false,
			language: i18next.language,
			loading: true,
			error: null,
			entries: null
		};
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}
	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}
	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}
	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	recursiveAddItems(item) {
		let text = '';
		item.content.map(inside => {
			text += this.addContentItem(inside);
		});
		return text;
	}
	addContentItem(item) {
		let text = '';
		if (item.marks && item.marks.length > 0) {
			if (item.marks[0].type === 'bold') {
				text += `<strong>${item.value}</strong>`;
			}
			if (item.marks[0].type === 'italic') {
				text += `<i>${item.value}</i>`;
			}
		}
		if (item.nodeType === 'hyperlink') {
			text += `<a href="${item.data.uri}" target="_blank">${item.content[0].value}</a>`;
		}
		if (!item.marks || item.marks.length === 0) {
			text += item.value;
		}
		// Reemplazar undefined
		text = text.replace(new RegExp('undefined', 'g'), '');
		return text;
	}
	mainFunction(item) {
		if (item.nodeType === 'paragraph') {
			let text = this.recursiveAddItems(item);
			return <p>{parse(text)}</p>;
		} else if (item.nodeType === 'unordered-list') {
			let text = '';
			item.content.map(list => {
				text += `<li>${this.recursiveAddItems(list.content[0])}</li>`;
			});
			return <ul>{parse(text)}</ul>;
		} else if (item.nodeType === 'heading-3') {
			let text = '';
			text += this.recursiveAddItems(item);
			return <h3>{parse(text)}</h3>;
		} else if (item.nodeType === 'heading-4') {
			let text = '';
			text += this.recursiveAddItems(item);
			return <h4>{parse(text)}</h4>;
		} else if (item.nodeType === 'embedded-asset-block') {
			return (
				<img
					src={item.data.target.fields.file.url}
					alt={item.data.target.fields.title}
				/>
			);
		}
	}
	render() {
		return (
			<React.Fragment>
				<div className="totalBenefis__card">
					<div
						className="fake-clicker"
						onClick={() => this.openModal()}
					>
						<div
							className="totalBenefits__card__image ownImage"
							style={{
								background: `url(${this.state.data.background.fields.file.url})`
							}}
						></div>
						<h5 className="totalBenefits__card__title">
							{this.state.language === 'es'
								? this.state.data.title
								: this.state.data.titleEn}
						</h5>
					</div>
					<Modal
						visible={this.state.visible}
						width="auto"
						height="auto"
						effect="fadeInDown"
						onClickAway={() => this.closeModal()}
					>
						<React.Fragment>
							<div
								ref={this.setWrapperRef}
								className="container-footer-card-modal"
							>
								<div
									class="closeModal"
									onClick={() => this.closeModal()}
								>
									<img src={close} alt="close" />
								</div>

								<div
									className="modal-footer-card-top benefitsCanada"
									style={{
										background: `url(${this.state.data.backgroundSmall.fields.file.url})`
									}}
								></div>

								<section className="modal-footer-card-content benefitsCanada">
									<header className="modal-card-content__header">
										<div
											className="header--icon-img"
											style={{
												background: `url(${this.state.data.icon.fields.file.url})`
											}}
										></div>
										<div className="header--text-content">
											<h2 className="text-content--title">
												{this.state.language === 'es'
													? this.state.data.title
													: this.state.data.titleEn}
											</h2>
											{this.state.data.description && (
												<p className="text-content--description">
													{this.state.language ===
														'es'
														? this.state.data
															.description
														: this.state.data
															.descriptionEn}
												</p>
											)}
										</div>
									</header>
									<article className="modal-card-content__body">
										{this.state.language === 'es' &&
											this.state.data.content.content.map(
												item => {
													const myData = this.mainFunction(
														item
													);
													return myData;
												}
											)}
										{this.state.language !== 'es' &&
											this.state.data.contentEn.content.map(
												item => {
													const myData = this.mainFunction(
														item
													);
													return myData;
												}
											)}

										{/* Learn more */}
										{this.state.data.requiresLearnMore ? (
											<a className="benefits-learn-more" href={'/benefits-usa/' + this.state.id} >Learn more</a>
										) : ''}
									</article>
								</section>
							</div>
						</React.Fragment>
					</Modal>
				</div>
			</React.Fragment>
		);
	}
}
