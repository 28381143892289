import React, { Component } from 'react';
import face1 from '../../assets/images/profile/user-profile01.svg';
// import wall1 from "../../assets/images/wallpaper4.jpg";
import back1 from '../../assets/images/background1.jpg';
import back2 from '../../assets/images/background2.jpg';
import back3 from '../../assets/images/background3.jpg';
import back4 from '../../assets/images/background4.jpg';
import back5 from '../../assets/images/background5.jpg';
import back6 from '../../assets/images/background6.jpg';
import request from 'superagent';

// const endPoint = 'https://mi-aeromexico.herokuapp.com';

export default class ModalBackground extends Component {
	constructor() {
		super();
		this.state = {
			backselected: localStorage.getItem('background'),
			colors: ['blue', 'teal', 'green', 'yellow', 'orange', 'red'],
			backs: [
				{ name: 'back1', img: back1 },
				{ name: 'back2', img: back2 },
				{ name: 'back3', img: back3 },
				{ name: 'back4', img: back4 },
				{ name: 'back5', img: back5 },
				{ name: 'back6', img: back6 }
			]
		};
	}

	selectBack = evt => {
		this.setState({
			backselected: evt.currentTarget.attributes.name.textContent
		});
	};

	updateSettings = () => {
		
		// request
		//   .put(`${ endPoint }/api/settings/${localStorage.getItem('_id')}`)
		//   .set({
		//     'Content-Type': 'application/json'
		//   })
		//   .send({
		//     background:this.state.backselected,
		//   })
		//   .then(response => {
		//     if (response.ok) {
		//       alert('La configuracion ha cambiado')
		//     }
		//   })
		//   .catch(error => console.error(error));
	};

	render() {
		return (
			<>
				{/* <span className="modal-subtitle">Select a background color</span> */}
				{/* <div className="modal-circles">
          {this.state.colors.map(circle => {
            return <div className={`${circle} circle-form`} />;
          })}
        </div> */}
				<span className="modal-title_profile">
					Select your background image
				</span>
				<div className="modal-backs">
					{this.state.backs.map(back => {
						return (
							<img
								onClick={this.selectBack}
								name={back.name}
								className={
									this.state.backselected === back.name
										? 'modal-circle-back active'
										: 'modal-circle-back'
								}
								src={back.img}
							/>
						);
					})}
					<div>
						<button
							className="button-save-settings"
							onClick={this.updateSettings}
						>
							SAVE SETTINGS
						</button>
					</div>
				</div>
			</>
		);
	}
}
