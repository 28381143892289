import React, { useEffect, useState } from 'react';
import { isValidURL } from '../../../utils/isValidEmail';
import DOMPurify from 'dompurify';

function OktaTools() {
	const userID = localStorage.getItem('okta-user-id') || null;
	const [loading, setLoading] = useState(true);
	const [tools, setTools] = useState([]);

	const compareArray = (a, b) => {
		const labelA = a.label.toUpperCase();
		const labelB = b.label.toUpperCase();
		let comparison = 0;
		if (labelA > labelB) {
			comparison = 1;
		} else if (labelA < labelB) {
			comparison = -1;
		}
		return comparison;
	};

	const sanitizeURL = (url) => {
		try {
			const safeUrl = new URL(url, window.location.origin);
			return ["http:", "https:"].includes(safeUrl.protocol) ? safeUrl.href : "#";
		} catch {
			return "#";
		}
	};

	useEffect(() => {
		fetch(`https://capi.miaeromexico.com/gate-one/user-apps/${userID}`, {
			headers: {
				'Access-Control-Allow-Origin': '*',
				Origin: 'https://rc.miaeromexico.com'
			}
		})
			.then(res => res.json())
			.then(res => {
				if (res.status === 200) {
					const tempArray = [];
					res.data.forEach(app => {
						if (app.visibility.hide.web === false) {
							const theApp = {
								label: app.label,
								image: app._links.logo[0].href
							};
							if (app.name === 'office365') {
								theApp.app = app._links.appLinks.find(function(
									theLink
								) {
									return theLink.name === 'login';
								});
							} else {
								theApp.app = app._links.appLinks[0];
							}
							tempArray.push(theApp);
						}
					});
					tempArray.sort(compareArray);
					setTools(tempArray);
					setLoading(false);
				}
			})
			.catch(console.error);
	}, []);

	if (userID) {
		return (
			<div className="LatestWrap">
				<div className="LatestGrid">
					<div className="LatestTitle">Gate One</div>
				</div>
				<div className="Worktools-container">
					<div className="workToolsSliderWrapper">
						{!loading &&
							tools.map((tool, index) => (
								!!tool.app && (
									<div key={tool.label + index} className="WorkToolWrap">
										<a
											href={sanitizeURL(tool.app.href)}
											target="_blank"
											rel="noopener noreferrer"
										>
											<img
												className="img-woork-tool"
												src={isValidURL(tool.image)}
												alt={DOMPurify.sanitize(tool.label)}
											/>
											<div className="descriptionWorkTool okta-worktool">
												<div className="padding">
													<p>{DOMPurify.sanitize(tool.label)}</p>
												</div>
											</div>
										</a>
									</div>
								)
							))}
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<div
				className="okta-not-logged"
				style={{
					background: '#F9F9FA',
					border: '1px solid #f1f1f1',
					borderRadius: '10px',
					width: '100%',
					height: '150px',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					marginTop: '70px',
					padding: '30px 50px'
				}}
			>
				<h3
					style={{
						fontSize: '20px',
						textAlign: 'center',
						color: '#c8c8c8',
						margin: 0
					}}
				>
					Inicia Sesión con Gate One para poder usar sus herramientas
				</h3>
			</div>
		);
	}
}

export default OktaTools;
