// #URl de ambiente
const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`;
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`;
const otherURL = `${process.env.REACT_APP_CADMIN}`;
const publicUrl = `${process.env.PUBLIC_URL}`;

const ALLOWED_DOMAINS = [urlLocal, urlDev, urlQA, urlProd, otherURL, publicUrl, "https://ok11static.oktacdn.com/"];

export function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidURL(url) {
  try {
    const parsedUrl = new URL(url);
    
    // Verificar que el dominio esté en la lista blanca
    if (!ALLOWED_DOMAINS.includes(`${parsedUrl.origin}/`)) {
      throw new Error("URL no permitida");
    }

    // Solo permitir HTTP y HTTPS
    if (!["https:"].includes(parsedUrl.protocol)) {
      throw new Error("Protocolo no permitido");
    }

    return url;
  } catch (error) {
    console.error("URL inválida o no permitida:", url);
    return null;
  }
}