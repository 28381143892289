export const ri2025 = [
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: "https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EQ0bSdHVWhRPoPqw3r61XqgBBcebvXEtoVy2TPIYQYNqSg?e=8NmCQh"
	}
];
export const ri2024 = [
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: "https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/ERpPi6WYUNZOhTvuN-ROrKoB8FJUH-d5LYNAdpKERvcjAg"
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: "https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EV2OlSXXBOxHvA6xuSPC1iUB_CWQD6EwFiZ5bYO8HpBr2w?e=RMvldM"
	},
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: "https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/amcomunicacioninterna_aeromexico_com/EWp3tFQ3ebhNjMfbThwWuNoB2mWLI3FTAOeyVJpYAFe-QA?e=MjcbOG"
	},
];
export const ri2022 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: ''
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: ''
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: ''
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: ''
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: ''
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/06._Bolet%C3%ADn_de_Industria_JUNIO_2022.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/05._Bolet%C3%ADn_de_Industria_MAYO_2022.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/04._Bolet%C3%ADn_de_Industria_abril_2022.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/03.%20Bolet%C3%ADn%20de%20Industria%20MARZO%202022%20%281%29.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/02._Bolet%C3%ADn_de_Industria_FEBRERO_2022%20%281%29.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2022/01._Bolet%C3%ADn_de_Industria_ENERO_2022_%281%29.pdf`
	}
];
export const ri2021 = [
	{
		month: {
			en: 'December',
			es: 'Diciembre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/12.%20Bolet%C3%ADn%20de%20Industria%20diciembre%202020.pdf`
	},
	{
		month: {
			en: 'November',
			es: 'Noviembre'
		},
		url: ''
	},
	{
		month: {
			en: 'October',
			es: 'Octubre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/10._Bolet%C3%ADn_de_Industria_OCTUBRE_2021.pdf`
	},
	{
		month: {
			en: 'September',
			es: 'Septiembre'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/09._Bolet%C3%ADn_de_Industria_SEPT_2021.pdf`
	},
	{
		month: {
			en: 'August',
			es: 'Agosto'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/08._Bolet%C3%ADn_de_Industria_AGOSTO_2021.pdf`
	},
	{
		month: {
			en: 'July',
			es: 'Julio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/07._Bolet%C3%ADn_de_Industria_JULIO_2021.pdf`
	},
	{
		month: {
			en: 'June',
			es: 'Junio'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/06._Bolet%C3%ADn_industria_JUNIO_2021.pdf`
	},
	{
		month: {
			en: 'May',
			es: 'Mayo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/05._Bolet%C3%ADn%20de%20Industria%20mayo%202021.pdf`
	},
	{
		month: {
			en: 'April',
			es: 'Abril'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/04._Bolet%C3%ADn%20de%20Industria%20abril%202021.pdf`
	},
	{
		month: {
			en: 'March',
			es: 'Marzo'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/03._Bolet%C3%ADn%20de%20Industria%20marzo%202021.pdf`
	},
	{
		month: {
			en: 'February',
			es: 'Febrero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/02._Bolet%C3%ADn%20de%20Industria%20febrero%202021.pdf`
	},
	{
		month: {
			en: 'January',
			es: 'Enero'
		},
		url: `${process.env.REACT_APP_SRC_URL}reportes_industriales/2021/01._Bolet%C3%ADn_Industria_enero_2021.pdf`
	}
];

