import React, { Component, useEffect, useState } from 'react';
import i18next from 'i18next';
import { motion } from 'framer-motion';

import './css/travelBenefits.css';
import './css/ventaespecial.css';

import * as contentful from '../../lib/contentful';
import getLocale from '../../utils/getLocale';
import VentaEspecialRegular from './venta_tabs/regular';
import VentaEspecialExtraordinaria from './venta_tabs/extraordinaria';
import { hasSpecialSale } from '../../utils/isTravelBenefitsActive';
import { useHistory } from 'react-router-dom';
import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
import { isValidURL } from '../../utils/isValidEmail';

const VentaEspecial = () => {
	// #URl de ambiente
	const urlLocal = `${process.env.REACT_APP_LOCAL}`;
	const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
	const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
	const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

	const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
	const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
	const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
	const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

	// # Liga validada
	const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

	// Se genera el token segun el ambiente
	const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + tokenValid
	});
	const history = useHistory();
	const [pageData, setPageData] = useState({});
	const [entries, setEntries] = useState(null);
	const [state, setState] = useState({
		visible: false,
		loadingData: true,
		loadingEntries: true,
		error: null,
		entries: null,
		entries2: null,
		entries3: null,
		tab: i18next.t('VentaEspecial.defaultTab'),
		hideCookie: false
	});

	const hideContainer = () => {
		setState({
			...state,
			hideCookie: true
		});
		localStorage.setItem('ventaEspecialCookie', true);
	};

	useEffect(() => {
		const fetchContent = async () => {

			const urlVentaEnabled = isValidURL(`${urlValid}menu-items-validator`); 
			const urlHasVenta = isValidURL(`${urlValid}ve-usuarios?filters[email][$contains]=${localStorage.getItem("email")}`);
			const urlVEData = isValidURL(`${urlValid}venta-especial-data`);

			const ventaEspecialEnabled = await fetch(urlVentaEnabled, {headers:cabecera}).then((res) => res.json()).catch(console.error);
			const hasVenta = await fetch(encodeURI(urlHasVenta), {headers:cabecera}).then((res) => res.json()).catch(console.error);
			await fetch(urlVEData, {headers:cabecera}).then((res) => res.json()).then((response) => {
				if (!ventaEspecialEnabled.data.attributes.venta_especial || hasVenta.data.length === 0) history.push("/inicio");
				setPageData(response);
				setState({ ...state, loadingData: false });
		}).catch(console.error);
		};
		fetchContent();
		contentful.client.getEntries({content_type: "ventaEspecialLegales",include: 2,locale: getLocale()}).then((response) => response.items).then((data) => {setEntries(data);}).catch(console.error);
	},[]);

	return !state.loadingData ? (
		<>
			<motion.div
				key="venta-especial-contenedor-vista-2021"
				initial={{ opacity: 0, y: 100 }}
				animate={{ opacity: 1, y: 0 }}
				exit={{ opacity: 0, y: 100 }}
				className="mb-3 flex w-full space-x-4"
			>
				<button
					disabled={!i18next.t('VentaEspecial.regularActive')}
					type="button"
					onClick={() => setState({ ...state, tab: 'especial' })}
					className={`${
						state.tab !== 'extraordinario'
							? 'bg-amDarkBlue bg-opacity-25 text-amBlack hover:text-amBlack'
							: 'text-gray-400 hover:text-gray-500'
					} outline-none focus:outline-none rounded-md px-3 py-2 text-sm font-medium focus:shadow-none ${
						i18next.t('VentaEspecial.regularActive') === true
							? 'text-gray-400'
							: 'pointer-events-none cursor-not-allowed text-gray-300 hover:text-gray-300'
					}`}
				>
					Venta Especial
				</button>
				<button
					disabled={!i18next.t('VentaEspecial.extraActive')}
					type="button"
					onClick={() =>
						setState({
							...state,
							tab: 'extraordinario'
						})
					}
					className={`${
						state.tab === 'extraordinario'
							? 'bg-amDarkBlue bg-opacity-25 text-amBlack hover:text-amBlack'
							: 'text-gray-400 hover:text-gray-500'
					} outline-none focus:outline-none rounded-md px-3 py-2 text-sm font-medium focus:shadow-none ${
						i18next.t('VentaEspecial.extraActive') === true
							? 'text-gray-400'
							: 'pointer-events-none cursor-not-allowed text-gray-300 hover:text-gray-300'
					}`}
				>
					Venta Extraordinaria
				</button>
			</motion.div>
			{state.tab === 'extraordinario' ? (
				<motion.div
					className="travel-container"
					key="venta-especial-extraordinario-tab"
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 100 }}
				>
					{/* hasSpecialSale */}
					{hasSpecialSale() ? (
						<VentaEspecialExtraordinaria />
					) : (
						<h1>
							No tienes permisos para acceder a la venta
							extraordinaria
						</h1>
					)}
				</motion.div>
			) : (
				<motion.div
					className="travel-container"
					key="venta-especial-regular-tab"
					initial={{
						opacity: 0
					}}
					animate={{
						opacity: 1
					}}
					end={{
						opacity: 0
					}}
				>
					{hasSpecialSale() && entries ? (
						<VentaEspecialRegular
							pageData={pageData}
							loading={state.loading}
							entries={entries}
							entries2={state.entries2}
							entries3={state.entries3}
							hideContainer={hideContainer}
						/>
					) : (
						<h1>
							No tienes permisos para acceder a la venta especial
						</h1>
					)}
				</motion.div>
			)}
		</>
	) : (
		<div className="flex h-96 w-full flex-col items-center justify-center rounded-lg bg-gray-100 text-amBlack">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="50"
				height="50"
				viewBox="0 0 50 50"
				className="h-10 w-10 animate-spin fill-current"
			>
				<g>
					<path d="M41.9 23.9c-.3-6.1-4-11.8-9.5-14.4-6-2.7-13.3-1.6-18.3 2.6-4.8 4-7 10.5-5.6 16.6 1.3 6 6 10.9 11.9 12.5 7.1 2 13.6-1.4 17.6-7.2-3.6 4.8-9.1 8-15.2 6.9-6.1-1.1-11.1-5.7-12.5-11.7-1.5-6.4 1.5-13.1 7.2-16.4 5.9-3.4 14.2-2.1 18.1 3.7 1 1.4 1.7 3.1 2 4.8.3 1.4.2 2.9.4 4.3.2 1.3 1.3 3 2.8 2.1 1.3-.8 1.2-2.5 1.1-3.8 0-.4.1.7 0 0z"></path>
				</g>
			</svg>
			<p className="text-gray-500">cargando</p>
		</div>
	);
};

export default VentaEspecial;
