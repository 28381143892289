import React, { Component } from 'react';
import NewsCategoryHeader from './NewsCategoryOnly';
import { Link } from 'react-router-dom';
import RecentCards from './RecentCards';
import * as contentful from '../../lib/contentful';
import LoadingScreen from 'react-loading-screen';
import AllNews from '../Home/Cards/allnews';
import getLocale from '../../utils/getLocale';
import i18next from 'i18next'; 
import { animateScroll as scroll} from "react-scroll";

class NewsCategory extends Component {
	constructor() {
		super();
		this.state = {
			loading: false,
			error: null,
			entries: null,
			entriesHeader: null,
			entriesMirror: null,
			loadingScreen: true
		};
	}
	
	componentDidMount() {
		// this.fetchEntries();
		// setTimeout(() => this.setState({ loadingScreen: false }), 1500);
		scroll.scrollToTop();
	}
	// componentDidUpdate = (prevProps) => {
	// 	if (this.props.match.params.newsId !== prevProps.match.params.newsId) {
	// 		this.fetchEntries();
	// 	}
	// }
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: 'articleClass',
				include: 2,
				locale: getLocale(),
				// order: '-sys.updatedAt',
				order: '-fields.fechaDeLaPublicacion'
			})
			.then(response => response.items)
			.then(data => {
				this.setState({
					loading: false,
					entries: data,
					entriesMirror: data
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	renderList = entries => {
		// const categoryRecentCards = entries.filter(RecentCard => {
		//   if (
		//     this.news(this.props.match.params.newsId) ===
		//     RecentCard.fields.articleClass
		//   ) {
		//     return RecentCard;
		//   }
		// });

		// const list = categoryRecentCards.map(recent => {
		//   return <RecentCards key={recent.sys.id} data={recent} />;
		// });

		const list = entries.map(recent => {
			return <RecentCards key={recent.sys.id} data={recent} />;
		});

		return list;
	};

	news = links => {
		const recentNews = {
			experienciacliente: 'CustomerExTag',
			nuestragente: 'PeopleTag',
			delta: 'DeltaTag',
			comercial: 'CommercialTag',
			operaciones: 'OperationsTag',
			mensajesceo: 'CeoTag',
			skyteam: 'SkyTeamTag',
			seguridad: 'SecurityTag',
			sostenibilidad: 'SusteTag'
		};
		return recentNews[links];
	};

	headerTitle = links => {
		const recentNews = {
			experienciacliente: 'Customer Header test',
			nuestragente: 'People Header test',
			delta: 'Delta Header test',
			comercial: 'Commercial Header test',
			operaciones: 'Operations Header test',
			mensajesceo: 'CEO Header test',
			skyteam: 'SkyTeam Header test',
			seguridad: 'Seguridad Header test'
		};
		return recentNews[links];
	};

	searchByName = e => {
		let searchList = [...this.state.entries];
		var query = e.target.value;
 
		if (query !== '') {
			var coincidences = searchList.filter(
				searchItem =>
					searchItem.fields.articleTittle
						.toLowerCase()
						.indexOf(query.toLowerCase()) !== -1
			);
			this.setState({
				entries: coincidences
			});
		} else {
			this.setState({
				entries: this.state.entriesMirror
			});
		}
	};
	  render() {
		  const {
			loading, 
		} = this.state;

		return (
			<LoadingScreen
				loading={loading}
				bgColor="#f5f5f5"
				spinnerColor="#007dc3"
				textColor="#0b2343"
				//logoSrc={avion}
				text={i18next.t('News.cargando')}
			>
        {/* <Arriba/> */}
				<div id="AllNewsAM">
					<NewsCategoryHeader />
					<AllNews />

					<div className="categoryNews">
						<p className="titleCategory">{i18next.t('News.porCategorias')}</p>
						<div className="midCategoryNews">
							<Link to="/noticias/seguridad" className="categoryBox">
								<div className="icon"></div>
								<p className="title">
									{i18next.t('News.categorias.seguridad')}
								</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							<Link to="/noticias/nuestragente" className="categoryBox">
								<div className="icon"></div>
								<p className="title">{i18next.t('News.categorias.gente')}</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							<Link to="/noticias/experienciacliente" className="categoryBox">
								<div className="icon"></div>
								<p className="title">
									{i18next.t('News.categorias.expCliente')}
								</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							<Link to="/noticias/delta" className="categoryBox">
								<div className="icon"></div>
								<p className="title">{i18next.t('News.categorias.jca')}</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							<Link to="/noticias/comercial" className="categoryBox">
								<div className="icon"></div>
								<p className="title">
									{i18next.t('News.categorias.comercial')}
								</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							<Link to="/noticias/operaciones" className="categoryBox">
								<div className="icon"></div>
								<p className="title">{i18next.t('News.categorias.opera')}</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							<Link to="/noticias/skyteam" className="categoryBox">
								<div className="icon"></div>
								<p className="title">{i18next.t('News.categorias.sky')}</p>
								<p className="link">{i18next.t('News.verNoticias')}</p>
							</Link>

							{/* TODO: Uncomment              
              <Link to="/experiencia-empleado/logros" className="categoryBox">
                <div className="icon"></div>
                <p className="title">{i18next.t('News.categorias.expEmpleado')}</p>
                <p className="link">{i18next.t('News.verNoticias')}</p>
              </Link>
               TODO: Uncomment */}

							{/* <Link to="/noticias/sostenibilidad" className="categoryBox">
                <div className="icon"></div>
                <p className="title">Sostenibilidad</p>
                <p className="link">Ver noticias</p>
              </Link> */}
						</div>
					</div>

					{/* <div claseName="recentCards">
            <div className="recentCardsContainer">
              
              {!loading && entries && this.renderList(entries)}
            </div>
          </div> */}

					{/* <TitleCategory data={this.props.match.params.newsId} /> */}

					{/*<Month />*/}
					{/*<MonthCards />*/}
					{/*<Loading />*/}
				</div>
        {/* <Index/> */}
			</LoadingScreen>

		);
	}
}

export default NewsCategory;
