import React, { useEffect } from "react";
import i18next from "i18next";
import FakeTableFC from "./FakeTableFC";
import FakeResultTableFC from "./FakeResultTableFC";
import { isValidEmail } from "../../../utils/isValidEmail";
import DOMPurify from "dompurify";
//icons
import AguinaldoIMG from "../../../assets/images/profile/comps/icon-comps-01.svg";
import PrimaVacacionalIMG from "../../../assets/images/profile/comps/icon-comps-02.svg";
import FondoExcedenteIMG from "../../../assets/images/profile/comps/icon-comps-03.svg";
import ValesDespensaIMG from "../../../assets/images/profile/comps/icon-comps-04.svg";
import UtilesIMG from "../../../assets/images/profile/comps/icon-comps-05.svg";
import AutomovilIMG from "../../../assets/images/profile/comps/icon-comps-06.svg";
import DesempenoIMG from "../../../assets/images/profile/comps/icon-comps-07.svg";
import SegudoVidaIMG from "../../../assets/images/profile/comps/seguro-vida.svg";
import SGMMIMG from "../../../assets/images/profile/comps/sgmm.svg";
import CheckupIMG from "../../../assets/images/profile/comps/checkup.svg";
import TravelIMG from "../../../assets/images/profile/comps/travel.svg";
import TempIMG from "../../../assets/images/profile/categoria-07.svg";
import FakeTableSmallerFC from "./FakeTableSmallerFC";
// data
// import isMockUser from '../../../utils/isMockUser';
import { aguinaldoTier, compensacionVariableTier, fondoAhorroTier, primaVacacionalTier } from "./utilsNumbers";

const GuaranteedFC = (props) => {
	const estado = {
		data: props.privateInfo,
		hrbp: !!localStorage.getItem("nombreHRBP") ? localStorage.getItem("nombreHRBP") : null,
		hrbp_email: !!localStorage.getItem("emailHRBP") ? localStorage.getItem("emailHRBP") : null,
		hasSecondHrbp: 0,
		secondHrbp: "Daniela Leal",
		secondHrbp_email: "dlealr@aeromexico.com",
		salarioNum: props.privateInfo.salario,
		salarioAnualNum: props.privateInfo.salarioAnual,
		aguinaldoNum: props.privateInfo.aguinaldo,
		primaVacacionalNum: props.privateInfo.primaVacacional,
		fondoAhorroNum: props.privateInfo.fondoAhorro,
		compensacionNum: props.privateInfo.compensacion,
		valesDespensaNum: props.privateInfo.valesDespensa,
		bonoUtilesNum: props.privateInfo.bonoUtiles,
		bonoAutomovilNum: props.privateInfo.bonoAutomovil,
		bonoDesempenoNum: props.privateInfo.bonoDesempeno,
		salario: props.privateInfo.salario,
		salarioAnual: props.privateInfo.salarioAnual,
		aguinaldo: props.privateInfo.aguinaldo,
		primaVacacional: props.privateInfo.primaVacacional,
		fondoAhorro: props.privateInfo.fondoAhorro,
		compensacion: props.privateInfo.compensacion,
		valesDespensa: props.privateInfo.valesDespensa,
		bonoUtiles: props.privateInfo.bonoUtiles,
		bonoAutomovil: props.privateInfo.bonoAutomovil,
		bonoDesempeno: props.privateInfo.bonoDesempeno,
		lti: props.privateInfo.lti,
		has_desempeno: false,
		performance: 0,
		percentage: 0,
		user_email: !!localStorage.getItem("email") ? localStorage.getItem("email") : null,
		user_level: props.publicInfo.nivel
	};
	const carroMensual = parseInt(localStorage.getItem("nivel")) < 10 ? props.privateInfo.bonoAutomovilMensual : 0;
	const compGarantizadaMensual =
		estado.salarioNum +
		props.privateInfo.valesMensual +
		props.privateInfo.bonoUtilesMensual +
		props.privateInfo.bonoUtilesMensual +
		props.privateInfo.compensacionMensual +
		carroMensual;
	const compGarantizadaAnual =
		estado.salarioAnualNum +
		estado.aguinaldoNum +
		estado.primaVacacionalNum +
		estado.fondoAhorroNum +
		props.privateInfo.compensacion +
		props.privateInfo.bonoUtiles +
		props.privateInfo.valesDespensa +
		props.privateInfo.bonoAutomovil;
	const comVariableAnual = estado.lti + estado.bonoDesempeno;

	useEffect(() => {
		localStorage.setItem("sumaMensual", props.privateInfo.sumaMensual);

		localStorage.setItem("sumaAnual", props.privateInfo.sumaAnual);

		localStorage.setItem("sumaAnualTotal", props.privateInfo.sumaAnualTotal);
		localStorage.setItem("valesMenusal", props.privateInfo.valesMensual);
		localStorage.setItem("bonoAutomovilMensual", props.privateInfo.bonoAutomovilMensual);
		localStorage.setItem("compensacionMensual", props.privateInfo.compensacionMensual);
	}, []);

	// const bonoDesempenoInfo = () => {
	// 	if (isMockUser()) return 'Target 0%';

	// 	const nivel = props.publicInfo.nivel;
	// 	const posicionItem = props.publicInfo.posicion;
	// 	let posicion = '';

	// 	if (posicionItem !== null && posicionItem !== '') {
	// 		posicion = posicionItem.toLowerCase();
	// 	}

	// 	/* Asistentes */
	// 	if (nivel === '6' || nivel === '7') {
	// 		if (
	// 			posicion === 'asistente ejecutiva' ||
	// 			posicion === 'asistente de direccion general'
	// 		) {
	// 			return i18next.language === 'es'
	// 				? 'Target 1.5 meses de sueldo'
	// 				: 'Target 1.5 months of salary';
	// 		}
	// 	}

	// 	/* Niveles 6,7 y 8 */
	// 	if (nivel === '6' || nivel === '7' || nivel === '8') {
	// 		return i18next.language === 'es'
	// 			? 'Target 2 meses de sueldo'
	// 			: 'Target 2 months of salary';
	// 	}
	// 	if (nivel === '9') {
	// 		return i18next.language === 'es'
	// 			? 'Target 20% de sueldo anual'
	// 			: 'Target 20% of annual salary';
	// 	}
	// 	if (nivel === '10') {
	// 		return i18next.language === 'es'
	// 			? 'Target 35% de sueldo anual'
	// 			: 'Target 35% of annual salary';
	// 	}
	// 	if (nivel === '11') {
	// 		return i18next.language === 'es'
	// 			? 'Target 35% de sueldo anual'
	// 			: 'Target 35% of annual salary';
	// 	}
	// 	if (nivel === '12') {
	// 		return i18next.language === 'es'
	// 			? 'Target 40% de sueldo anual'
	// 			: 'Target 40% of annual salary';
	// 	} else {
	// 		return '--';
	// 	}
	// };

	const tablaGarantizada = {
		titulo: i18next.t("MiExperAM.compensacion.garantizada.title"),
		elementos: [
			{
				id: "sueldoTip",
				titulo: i18next.t("MiExperAM.compensacion.garantizada.sueldo"),
				mensual: {
					cifra: estado.salarioNum,
					sub: "Mensual"
				},
				anual: {
					cifra: estado.salarioAnualNum,
					sub: "Anual"
				},
				bigBottom: true,
				bottom: true
			},
			{
				anual: estado.aguinaldoNum,
				icon: AguinaldoIMG,
				id: "aguinaldoTip",
				mensual: null,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.aguinaldo"),
				tooltip: aguinaldoTier(i18next.t("MiExperAM.compensacion.garantizada.aguinaldoTip")),
				bigBottom: false,
				bottom: true
			},
			{
				anual: estado.primaVacacionalNum,
				icon: PrimaVacacionalIMG,
				id: "primaTip",
				mensual: null,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.prima.title"),
				tooltip: i18next.t(primaVacacionalTier()),
				bigBottom: false,
				bottom: true
			},
			{
				anual: estado.fondoAhorroNum,
				icon: FondoExcedenteIMG,
				id: "fondoTip",
				mensual: null,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.ahorro"),
				tooltip: i18next.t(fondoAhorroTier()),
				bigBottom: false,
				bottom: true
			},
			{
				anual: props.privateInfo.compensacion,
				icon: FondoExcedenteIMG,
				id: "excedenteTip",
				mensual: props.privateInfo.compensacionMensual,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.fa"),
				tooltip:
					localStorage.getItem("compania") === "Aeroméxico"
						? i18next.t("MiExperAM.compensacion.garantizada.faTip1")
						: i18next.t("MiExperAM.compensacion.garantizada.faTip2"),
				bigBottom: false,
				bottom: true
			},
			{
				anual: props.privateInfo.bonoUtiles,
				icon: UtilesIMG,
				id: "utilesTip",
				mensual: props.privateInfo.bonoUtilesMensual,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.escolares"),
				tooltip: i18next.t("MiExperAM.compensacion.garantizada.escolaresTip"),
				bigBottom: false,
				bottom: true
			},
			{
				anual: props.privateInfo.valesDespensa,
				icon: ValesDespensaIMG,
				id: "valesTip",
				mensual: props.privateInfo.valesMensual,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.despensa"),
				tooltip: i18next.t("MiExperAM.compensacion.garantizada.despensaTip"),
				bigBottom: false,
				bottom: true
			},
			{
				anual: props.privateInfo.bonoAutomovil,
				icon: AutomovilIMG,
				id: "autoTip",
				mensual: parseInt(localStorage.getItem("nivel")) < 10 ? props.privateInfo.bonoAutomovilMensual : null,
				titulo: i18next.t("MiExperAM.compensacion.garantizada.auto"),
				tooltip:
					parseInt(localStorage.getItem("nivel")) > 9
						? ""
						: i18next.t("MiExperAM.compensacion.garantizada.autoTip"),
				bigBottom: false,
				bottom: false
			}
		]
	};

	const tablaVariable = {
		titulo: i18next.t("MiExperAM.compensacion.variable.title"),
		elementos: [
			{
				anual: estado.bonoDesempeno,
				bigBottom: false,
				bottom: true,
				icon: DesempenoIMG,
				id: "desempenoTip",
				mensual: null,
				titulo: i18next.t("MiExperAM.compensacion.variable.bono.title"),
				tooltip: compensacionVariableTier()
			},
			{
				anual: estado.lti,
				bigBottom: false,
				bottom: false,
				icon: DesempenoIMG,
				id: "ltiTip",
				mensual: null,
				titulo: "Bono LTI",
				tooltip: ""
			}
		]
	};

	const tablaBeneficios = {
		titulo: null,
		elementos: [
			{
				cifra: props.privateInfo.seguroVida,
				icon: SegudoVidaIMG,
				titulo: "Seguro de Vida"
			},
			{
				cifra: props.privateInfo.sgmm,
				icon: SGMMIMG,
				titulo: "Seguro Gastos Médicos Mayores",
				sub: `Empleado ${
					props.privateInfo.sgmmBenef > 1 ? `+ ${props.privateInfo.sgmmBenef - 1} beneficiarios` : ""
				}`
			},
			{
				cifra: props.privateInfo.checkup,
				icon: CheckupIMG,
				titulo: "Check Up médico"
			},
			{
				cifra: props.privateInfo.travelBenefits,
				icon: TravelIMG,
				titulo: "Travel Benefits"
			}
		]
	};

	const tablaTotal = {
		titulo: null,
		elementos: [
			{
				cifra: compGarantizadaAnual + comVariableAnual,
				color: "#0B2342",
				sub: "Garantizada + Variable",
				titulo: "Total Mi Compensación"
			},
			{
				cifra: props.privateInfo.totalBeneficios,
				color: "#48A83E",
				sub: "No integran ni son complementos de tu salario",
				titulo: "Total Mis Beneficios"
			}
		]
	};

	const sanitizeEmail = (email) => {
		if (!email) return null;
		const safeEmail = DOMPurify.sanitize(email);
		return isValidEmail(safeEmail) ? encodeURIComponent(safeEmail) : null;
	};
	

	return (
		<div className="container-guaranteed">
			<FakeTableFC data={tablaGarantizada} even />
			<div className="mt-4" />
			<FakeResultTableFC
				anual={compGarantizadaAnual}
				monthly={compGarantizadaMensual}
				sub={i18next.t("MiExperAM.compensacion.garantizada.totalGarantizadaSub")}
				title={i18next.t("MiExperAM.compensacion.garantizada.total")}
			/>
			<div className="mt-10" />
			<FakeTableFC data={tablaVariable} />
			<div className="mt-4" />
			<FakeResultTableFC
				anual={comVariableAnual}
				sub={i18next.t("MiExperAM.compensacion.garantizada.totalVariableSub")}
				title={i18next.t("MiExperAM.compensacion.garantizada.total")}
			/>
			<div className="mt-2" />
			<FakeResultTableFC
				anual={compGarantizadaAnual + comVariableAnual}
				monthly={compGarantizadaMensual}
				sub={i18next.t("MiExperAM.compensacion.garantizada.totalGaVaSum")}
				title={i18next.t("MiExperAM.compensacion.garantizada.total")}
			/>
			{/* MIS BENEFICIOS */}
			<div className="mis-beneficios-container-compensacion mt-10 rounded-xl p-4">
				<h3 className="text-2xl font-semibold !text-[#020C41]">
					{i18next.t("MiExperAM.compensacion.beneficios.title")}
				</h3>
				<div className="benefits-description mt-3 text-sm leading-tight">
					<p className="!text-[#020C41]">
						Además de tu compensación, tu Recompensa Total incluye una oferta de beneficios competitivos. A
						continuación, encontrarás el <i>valor de mercado*</i> correspondiente a tus beneficios de salud
						y viaje más relevantes.
					</p>
					<p className="mt-3 text-amBlue">
						<strong>
							*Por favor, ten en cuenta que las siguientes cantidades no forman parte del pago en efectivo
							de tu compensación regular, únicamente representan un supuesto, es decir la cantidad
							estimada que tendrías que pagar en el mercado si no fueran parte de tu Recompensa Total.
						</strong>
					</p>
				</div>
				<div className="mt-4" />
				<FakeTableSmallerFC data={tablaBeneficios} />
				<div className="mt-4" />
				<FakeResultTableFC
					anual={props.privateInfo.totalBeneficios}
					borderColor="#033C35"
					color="#033C35"
					sub="No integran ni son complemento de tu salario"
					title="Total Mis Beneficios"
					withThree={false}
				/>
				<div className="mt-10" />
				<h3 className="text-2xl font-semibold !text-[#FA0073]">Mi Recompensa Total</h3>
				<div className="mt-4" />
				<FakeTableSmallerFC data={tablaTotal} />
				<div className="mt-4" />
				<FakeResultTableFC
					anual={props.privateInfo.totalBeneficios + compGarantizadaAnual + comVariableAnual}
					borderColor="#DF144C"
					color="#FA0073"
					title="Total Mis Compensación + Mis Beneficios"
					withThree={false}
				/>
			</div>
			<div className="mt-10" />
			<p className="contactHRBP rounded-md">
				{i18next.t("MiExperAM.compensacion.hrbp")}
				{!estado.hasSecondHrbp ? (
					<span>
						<br />
						<span className="name" style={{ marginRight: "3px" }}>
							{estado.hrbp !== null ? estado.hrbp : "--------"}
						</span>
						{sanitizeEmail(estado.hrbp_email) ? (
							<a href={`mailto:${sanitizeEmail(estado.hrbp_email)}`}>
								{estado.hrbp_email}
							</a>
						) : null}
					</span>
				) : (
					<span>
						<br />
						<span className="name" style={{ marginRight: "3px" }}>
							{estado.hrbp}
						</span>
						{estado.hrbp_email ? (
							<a href={`mailto:${sanitizeEmail(estado.hrbp_email)}`}>
								{estado.hrbp_email}
							</a>
						) : (
							<p>Email no válido</p>
						)}
						<span className="name" style={{ marginRight: "3px" }}>
							{estado.secondHrbp}
						</span>
						{estado.secondHrbp_email ? (
							<a href={`mailto:${sanitizeEmail(estado.secondHrbp_email)}`}>
								{estado.secondHrbp_email}
							</a>
						) : null}
					</span>
				)}
			</p>
		</div>
	);
};

export default GuaranteedFC;
