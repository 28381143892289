//Index.js pagina de Login

import React, { Component } from "react";
import "./css/styles.css";
import logoSVG from "../../assets/images/logo/logo-aeromexico-white.svg";
import logoPNGMobile from "../../assets/icon-mobile.jpg";
import avionbg from "../../assets/images/login/avion-bg.png";
import ModalLangComponent from "./ModalLangComponent";
import AuthService from "../../services/auth.service";
import GraphService from "../../services/graph.service";
import i18next from "i18next";
import { ReactComponent as GateOne } from "./assets/gateone.svg";
import { withOktaAuth } from "@okta/okta-react";
import oktaAuth from "../../components/Main/oktaConfig";

class Index extends Component {
	constructor() {
		super();
		this.authService = new AuthService();
		this.graphService = new GraphService();
		this.state = {
			user: null,
			userInfo: null,
			apiCallFailed: false,
			loginFailed: false,
			redireccion: false,
			displayableId: "",
			explorer: false,
			edge: false,
			lang: "",
			langVisible: false,
			langClass: "non",
			langMovilVisible: false,
			isLocalHost: window.location.hostname === "localhost",
			existSesionGateone: false,
		};
	}

	handleLogin = async () => {
		try {
			const { oktaAuth } = this.props;
			await oktaAuth.signInWithRedirect();
		} catch (err) {
			console.error("Error al iniciar sesión con redirección:", err);
			this.setState({ loginFailed: true });
		}
	};	
	
	async componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const iss = urlParams.get("iss");
	
		if (iss && (iss == "https://gateone.amx-digital.com" || iss == "https://lobbygateone.amx-digital.com"|| iss == "APexperience2025")) await oktaAuth.signInWithRedirect();
	}


	openModalLang() {
		this.setState({
			langVisible: true,
			langMovilVisible: false,
			langClass: "non",
		});
	}

	openModalLangMovil() {
		this.setState((prevState) => ({
			langVisible: false,
			langMovilVisible: !prevState.langMovilVisible,
			langClass: !prevState.langMovilVisible ? "activeLang" : "non",
		}));
	}

	closeModalLang() {
		this.setState({
			langVisible: false,
			langMovilVisible: false,
			langClass: "non",
		});
	}

	onLangSelectedEvent = (l) => {
		if (this.state.lang === l) {
			this.closeModalLang();
			return;
		}
		i18next.changeLanguage(l);
		this.setState({ lang: l });
		window.location.reload();
	};

	render() {
		return (
			<div className="LoginPage">
				<div className="TopLoginPage">
					<div className="MidLoginContent">
						<div className="MidLoginContentCell">
							<div className="languageFlightPlan login desktop" onClick={() => this.openModalLang()}>
								<p>{i18next.t("Lang.lang")}</p>
								<div className={`languageFlag ${this.state.lang}`}></div>
							</div>
							<div className="languageFlightPlan login movil" onClick={() => this.openModalLangMovil()}>
								<p>{i18next.t("Lang.lang")}</p>
								<div className={`languageFlag ${this.state.lang}`}></div>
								<div className={`container-language ${this.state.langClass}`}>
									<div className="notification-column">
										<a className="notification-item" onClick={() => this.onLangSelectedEvent("es")}>
											<p className="notification-text"> ES </p>
										</a>
										<a className="notification-item" onClick={() => this.onLangSelectedEvent("en")}>
											<p className="notification-text"> EN </p>
										</a>
									</div>
								</div>
							</div>

							<img className="LogoMiAM1" src={logoSVG} alt="Logo Mi Aeromexico" />

							<img className="LogoMiAM1Mobile" src={logoPNGMobile} alt="Logo Mi Aeromexico" />

							<div className="ContentWrap">
								<h1 className="WelcomeSite">
									{" "}
									<div>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t1")}</span>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t2")}</span>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t3")}</span>
										<span className="text-amLightBlue">{i18next.t("Login.slider.t4")}</span>
									</div>
									<br />
									<span>{i18next.t("Login.slider.t5")}</span>
								</h1>

								<h1 className="WelcomeSiteMobile">
									{i18next.t("Login.welcome.p1")} <br />
									{i18next.t("Login.welcome.p2")}
								</h1>

								<div className="wrapper-disclaimer hidden">
									{this.state.explorer && (
										<p className="disclaimer">{i18next.t("Login.noExplorer")}</p>
									)}
									{this.state.edge && <p className="disclaimer">{/* No uses Edge */}</p>}
								</div>
								<p
									className="WelcomeIntro my-5 lg:my-10"
									dangerouslySetInnerHTML={{
										__html: i18next.t("Login.login")
									}}></p>
								<div className="buttons-holder" style={{ display: "flex" }}>
									<button
										type="button"
										className="fake-btn gate-btn border-1 flex items-center justify-center space-x-3 outline-none ring-0 focus:outline-none focus:ring-0"
										onClick={this.handleLogin}>
										<GateOne className="h-7 w-auto fill-current text-white" />
										<p className="my-0">{i18next.language === "es" ? "Iniciar sesión" : "Login"}</p>
									</button>
								</div>
								{this.state.loginFailed && <p className="failed-user">{i18next.t("Login.failed")}</p>}
							</div>
						</div>
					</div>
				</div>
				<div className="FilterLoginPage bg-amBlack" />
				<div className="BgLoginPage">
					<video className="VideoLogin" autoPlay muted loop playsInline>
						<source src={`${process.env.REACT_APP_SRC_URL}miam-videos/avion.mp4`} type="video/mp4" />
					</video>
					<img className="ImgLoginPageLogin" src={avionbg} alt="Bg Login page" />
				</div>
				<ModalLangComponent
					langVisible={this.state.langVisible}
					onCloseModal={() => this.closeModalLang()}
					onLangSelectedEvent={this.onLangSelectedEvent}
				/>
			</div>
		);
	}
}

export default withOktaAuth(Index);