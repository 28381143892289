import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./css/golfo.css";
import * as contentful from "../../lib/contentful";
import Modal from "react-awesome-modal";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemButton
} from "react-accessible-accordion";

import "react-accessible-accordion/dist/fancy-example.css";
import ReactMarkdown from "react-markdown";
import close from "../../assets/logo/close.svg";
import ReactPlayer from "react-player";
import Index from "../Footer";
import Arriba from "../Header/Arriba";
import { animateScroll as scroll} from "react-scroll";
var noScroll = require("no-scroll");

export default class golfoDelta extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			modalIsShow: false,
			modalIsShow2: false,
			error: null,
			entries: null,
			entries2: null,
			entries3: null,
			hasScrolledAlert: false,
			visible: false,
			visible2: false
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	openModal2() {
		this.setState({
			visible2: true
		});
		noScroll.on();
	}

	closeModal2() {
		this.setState({
			visible2: false
		});
		noScroll.off();
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
		noScroll.toggle();
	};

	showAlertModal2 = () => {
		this.setState({
			modalIsShow2: !this.state.modalIsShow2
		});
		noScroll.toggle();
	};

	componentDidMount() {
		this.fetchEntries();
		this.fetchEntries02();
		this.fetchEntries03();
		document.addEventListener("keydown", this.escFunction, false);
		scroll.scrollToTop();
	}

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}

	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false,
				visible2: false,
				modalIsShow: false,
				modalIsShow2: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = (e) => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false,
				visible2: false,
				modalIsShow: false,
				modalIsShow2: false
			});
			noScroll.off();
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
		// noScroll.toggle();
	}

	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "aerolineasdelGolfo",
				include: 2,
				locale: "es-MX"
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries02 = () => {
		contentful.client
			.getEntries({
				content_type: "aerolineasDelGolfoPreguntasYRespesutas",
				include: 2,
				locale: "es-MX",
				order: "fields.preguntaGolfo"
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries2: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	fetchEntries03 = () => {
		contentful.client
			.getEntries({
				content_type: "defendamosNuestroCieloNoticias",
				include: 2,
				locale: "es-MX"
			})
			.then((response) => response.items)
			.then((data) => {
				this.setState({
					loading: false,
					entries3: data
				});
			})
			.catch((error) => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch((err) => console.error(err));
	};

	render() {
		const { loading, entries, entries2, entries3 } = this.state;
		return (
			<div>
				{/* <Arriba /> */}
				{/* <div className="languageCambio">
          <Link className="list" to="/lets-defend-our-sky">
              <div className="image en">EN</div>
              <p className="idiom">English</p>
            </Link>

            <Link className="list" to="/defendamos-nuestro-cielo">
              <div className="image es">ES</div>
              <p className="idiom">Español</p>
            </Link>
            
          </div> */}

				<Modal
					visible={this.state.visible}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal()}>
					<div className="whiteWindowAsk modalheightInherithAsk">
						<div class="closeModal" href="javascript:void(0);" onClick={() => this.closeModal()}>
							<img src={close}></img>
						</div>
						<div>
							<h4 className="titlePreguntas">Preguntas frecuentes</h4>
							<Accordion allowZeroExpanded="true" id="wrapperAccordionThis" preExpanded={["foo"]}>
								{!loading &&
									entries &&
									entries.map((preguntasRespuestas) => (
										<AccordionItem
											key={preguntasRespuestas.sys.id}
											uuid={preguntasRespuestas.fields.expandedText}>
											<AccordionItemHeading>
												<AccordionItemButton>
													<div className="tableBoletosHeader">
														<p className="title">
															{preguntasRespuestas.fields.preguntaGolfo}
														</p>
													</div>
												</AccordionItemButton>
											</AccordionItemHeading>
											<AccordionItemPanel>
												<ReactMarkdown className="description">
													{preguntasRespuestas.fields.respuestaGolfo}
												</ReactMarkdown>
											</AccordionItemPanel>
										</AccordionItem>
									))}
							</Accordion>
						</div>
					</div>
				</Modal>

				<Modal
					visible={this.state.visible2}
					width="100%"
					height="auto"
					effect="fadeInDown"
					onClickAway={() => this.closeModal2()}>
					<div className="whiteWindowAsk">
						<div class="closeModal" href="javascript:void(0);" onClick={() => this.closeModal2()}>
							<img src={close}></img>
						</div>
						<div>
							<h4 className="titlePreguntas">Últimas noticias</h4>

							{/* {!loading &&
                  entries2 &&
                  entries2.map(preguntasRespuestas => ( 
                    
                    key={preguntasRespuestas.sys.id}
                    */}

							<div className="noticiasligas">
								<div className="midWarpperContent midwarpperTop">
									{/* <ul key={newsWebsite.sys.id}>
                    {!loading &&
                      entries &&
                      entries.map(newsWebsite => (
                          <li><a href="https://www.eluniversal.com.mx/columna/francisco-madrid-flores/cartera/patente-de-corso-en-los-cielos-mexicanos" target="_blank">¿Patente de corso en los cielos mexicanos? <span>- El Universal -Francisco Madrid</span></a></li>
                      ))}
                      </ul> */}

									<ul>
										{!loading &&
											entries3 &&
											entries3.map((noticiasEmirates) => (
												<li>
													<a href={noticiasEmirates.fields.ligaDeLaNoticia}>
														{noticiasEmirates.fields.tituloDeLaNoticia}
													</a>
												</li>
											))}

										{/*                         
                        <li><a href="https://a21.com.mx/aerolineas/2019/07/17/decepcionante-entrada-de-emirates-mexico-aeromexico" target="_blank">Decepcionante, entrada de Emirates a México: Aeroméxico <span>- A21</span></a></li>
                        <li><a href="https://mundoejecutivo.com.mx/economia-negocios/2019/07/18/entrada-emirates-decepcionante-aeromexico/" target="_blank">La entrada de Emirates es decepcionante: Aeroméxico <span>- Mundo Ejecutivo</span></a></li>
                        <li><a href="http://m.mundoejecutivo.com.mx/economia-negocios/2019/07/22/turbulencias-aeroespaciales-competencia-desleal-aeromexico-emirates" target="_blank">Turbulencias aeroespaciales y competencia desleal economía <span>- Mundo Ejecutivo</span></a></li>
                        <li><a href="https://simpleflying.com/aeromexico-unhappy-with-emirates-fifth-freedom-flight-to-mexico-city/" target="_blank">AeroMexico Unhappy With Emirates Fifth Freedom Flight To Mexico City <span>- Simple Flying</span></a></li>
                        <li><a href="https://www.efe.com/efe/america/mexico/trabajadores-mexicanos-de-aviacion-piden-revocar-permiso-a-emirates/50000545-4025889?utm_source=wwwefecom&utm_medium=rss&utm_campaign=rss" target="_blank">Trabajadores mexicanos de aviación piden revocar permiso a Emirates <span>- EFE</span></a></li>
                        <li><a href="http://www.zocalo.com.mx/reforma/detail/ven-complicado-frenar-arribo-de-emirates" target="_blank">Ven complicado frenar arribo de Emirates <span>- Reforma</span></a></li>
                        <li><a href="https://www.sentidocomun.com.mx/articulo.phtml?id=71746&utm;_source=dlvr.it&utm;_medium=twitter" target="_blank">AeroMéxico peleará revocación permiso México a Emirates <span>- Sentido Común</span></a></li>

                        <li><a href="https://www.eluniversal.com.mx/cartera/aeromexico-cuestiona-emirates-por-vuelo-mexico-dubai" target="_blank">Aeroméxico cuestiona a Emirates por vuelo México-Dubai <span>- El universal</span></a></li>
                        <li><a href="https://www.milenio.com/negocios/sct-emirates-airlines-autorizacion-volar-mexico" target="_blank">Emirates Airlines sin autorización para aterrizar en México, dice SCT <span>- Milenio</span></a></li>
                        <li><a href="http://www.efinf.com/clipviewer/files/df5995b60d9d5835e2cfd08985adf00a.pdf" target="_blank">In Versiones // Alertan pilotos sobre competencia desleal <span>- Milenio Diario</span></a></li>

                        <li><a href="https://expansion.mx/empresas/2019/08/05/la-sct-aun-analiza-los-permisos-de-emirates-para-volar-en-mexico" target="_blank">La SCT aún analiza los permisos de Emirates para volar en México <span>- EXPANSIÓN</span></a></li>
                        <li><a href="https://www.milenio.com/negocios/aeromexico-advierte-ilegalidad-emirates-airlines-vuelo-barcelona-cdmx" target="_blank">Aeroméxico advierte ilegalidad de Emirates Airlines en vuelo Barcelona-CDMX <span>- MILENIO</span></a></li>
                        <li><a href="https://www.razon.com.mx/negocios/emirates-no-puede-aterrizar-en-mexico-sct/" target="_blank">Emirates no puede aterrizar en México: SCT <span>- La Razón</span></a></li>
                        
                        <li><a href="https://lasillarota.com/dinero/cual-es-el-pleito-de-aeromexico-con-emirates-airlines-aeromexico/305718" target="_blank">¿Cuál es el pleito de Aeroméxico con Emirates Airlines? <span>- La Silla Rota</span></a></li>
                        <li><a href="https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&id=1739021&opinion=0&urlredirect=https://www.reforma.com/emirates-no-tiene-autorizada-ruta-sct/ar1739021?v=4&flow_type=paywall" target="_blank">Emirates no tiene autorizada ruta. <span>- SCT Reforma </span></a></li>

                        <li><a href="https://www.reportur.com/mexico/2019/08/13/emirates-reconoce-no-permiso-la-ruta-mexico-dubai/" target="_blank">Emirates reconoce no tener permiso para la ruta México-Dubái</a></li>
                        <li><a href="https://www.elfinanciero.com.mx/empresas/aeromexico-pide-a-sus-colaboradores-defender-el-cielo-ante-aterrizaje-de-emirates" target="_blank">Aeroméxico pide a sus colaboradores defender ‘el cielo’ ante aterrizaje de Emirates </a></li>
                        <li><a href="https://www.reforma.com/aplicacioneslibre/preacceso/articulo/default.aspx?__rval=1&id=1739021&opinion=0&urlredirect=https://www.reforma.com/emirates-no-tiene-autorizada-ruta-sct/ar1739021?v=4&flow_type=paywall" target="_blank">Emirates no tiene autorizada ruta.- SCT</a></li>
                        <li><a href="https://www.publimetro.com.mx/mx/opinion/2019/08/06/expande-genpact-presencia-en-latinoamerica.html" target="_blank">Expande Genpact su presencia en Latinoamérica</a></li>
                        <li><a href="https://www.sdpnoticias.com/negocios/competencia-desleal-inicio-evitar-buscan.html" target="_blank">Por competencia desleal, buscan evitar inicio de operaciones de Emirates Airlines en México</a></li>
                        <li><a href="https://eldeforma.com/2019/08/07/fly-emirates-esta-aplicando-una-trampota-para-competir-en-mexico-de-manera-desleal/" target="_blank">Te explicamos con peras y manzanas la mega tranza que se aventó Fly Emirates en México</a></li>
                        <li><a href="https://expansion.mx/empresas/2019/08/05/la-sct-aun-analiza-los-permisos-de-emirates-para-volar-en-mexico" target="_blank">La SCT aún analiza los permisos de Emirates para volar en México</a></li>
                        <li><a href="https://www.sdpnoticias.com/nacional/2019/08/09/claudia-sheinbaum-bien-por-la-f1-ahora-atencion-al-cielo-aeromexico-vs-emirates-y-las-calles-de-cdmx" target="_blank">Claudia Sheinbaum: ¡bien por la F1!; ahora atención al cielo (Aeroméxico vs Emirates) y las calles de CDMX</a></li>
                        <li><a href="https://expansion.mx/empresas/2019/08/09/emirates-airlines-y-su-estrategia-expansionista-en-mexico" target="_blank">Emirates Airlines y su estrategia expansionista en México</a></li>
                        <li><a href="https://a21.com.mx/aerolineas/2019/08/06/en-caso-emirates-seremos-equitativos-dgac" target="_blank">En caso Emirates, seremos equitativos: DGAC</a></li>


                        <li><a href="https://m.imagenradio.com.mx/david-paramo/emirates-airlines-no-cuenta-con-permisos-para-ofrecer-vuelos-mexico-aeromexico" target="_blank">Emirates Airlines no cuenta con permisos para ofrecer vuelos a México: Aeroméxico</a></li>
                        <li><a href="https://www.sdpnoticias.com/columnas/aeromexico-desmiente-airlines-emirates-permiso.html" target="_blank">Tengo otros datos. Aeroméxico desmiente a Emirates Airlines: la línea aérea árabe no tiene permiso para operar en México</a></li>
                        <li><a href="https://m.excelsior.com.mx/nacional/emirates-airlines-sin-permisos-para-ofrecer-vuelos-a-mexico-allard/1332957" target="_blank">Emirates Airlines sin permisos para ofrecer vuelos a México: Allard</a></li>
                        <li><a href="http://www.efinf.com/clipviewer/files/9c8842dcf34cfcc531e6d2a712865fbf.mp4" target="_blank">En entrevista, Sergio Allard, vicepresidente ejecutivo de Asuntos Legales y Relaciones Institucionales de Aeroméxico</a></li>

                        <li><a href="https://www.proceso.com.mx/597506/deshonesto-negar-opciones-competitivas-emirates-airlines-no-respeta-el-estado-de-derecho-responde-aeromexico" target="_blank">Deshonesto negar opciones competitivas: Emirates Airlines; no respeta el Estado de derecho, responde Aeroméxico</a></li>
                        <li><a href="https://www.eleconomista.com.mx/empresas/Se-intensifica-disputa-entre-Emirates-y-Aeromexico-20190828-0003.html" target="_blank">Se intensifica disputa entre Emirates y Aeroméxico</a></li> */}
									</ul>
								</div>
							</div>

							{/* ))} */}
						</div>
					</div>
				</Modal>

				{this.state.modalIsShow === true ? (
					<div className="flightVideoModal">
						<div className="videoModalWindow" ref={this.setWrapperRef}>
							<div className="closeModal" onClick={this.showAlertModal}>
								<img src={close}></img>
							</div>

							<ReactPlayer
								url="https://aeromexico-2.wistia.com/medias/6u22cvle4w"
								playing="false"
								width="100%"
								height="100%"
								controls="true"
								volume=".5"
							/>
						</div>
					</div>
				) : (
					""
				)}

				{this.state.modalIsShow2 === true ? (
					<div className="flightVideoModal">
						<div className="videoModalWindow" ref={this.setWrapperRef}>
							<div className="closeModal" onClick={this.showAlertModal2}>
								<img src={close}></img>
							</div>

							<ReactPlayer
								url="https://alexmejia01.wistia.com/medias/n3hq3li68p"
								playing="false"
								width="100%"
								height="100%"
								controls="true"
								volume=".5"
							/>
						</div>
					</div>
				) : (
					""
				)}

				{!loading &&
					entries &&
					entries.map((aerolineaContent) => (
						<div key={aerolineaContent.sys.id}>
							<div id="golfoAirlines">
								<div className="contentMnager">
									{/* blueBackEmirates */}
									<div className="paddingTopBlueHeader blueBackEmirates">
										<div className="midWarpperContent">
											<div className="logoGpoAM"></div>
											<p className="smallred">{aerolineaContent.fields.contentSmall}</p>
											<h2 className="title">{aerolineaContent.fields.contentTitulo}</h2>
											<p className="biggerText">
												<ReactMarkdown>{aerolineaContent.fields.contentIntro}</ReactMarkdown>
											</p>
										</div>
									</div>

									<div className="background-blue">
										<div className="meaning2"></div>
										<div
											className="map"
											// style={{backgroundImage: `url(${aerolineaContent.fields.contentImageMapa.fields.file.url})`}}
										></div>
									</div>

									<div className="midWarpperContent midwarpperTop">
										<div className="wrapperQuintas">
											<div className="leftQuintaLibertad">
												<p className="subtitle">
													{aerolineaContent.fields.contentQueEsLibertad}
												</p>
												<div className="imgQuinta"></div>
											</div>
											<div className="rightQuintaLibertad">
												<p className="biggerText">
													<ReactMarkdown>
														{aerolineaContent.fields.contentLibertadExplicacion}
													</ReactMarkdown>
												</p>
											</div>
										</div>
									</div>

									<div className="midWarpperContent midwarpperTop midWrapperLine">
										<div className="leftLogoEmirates">
											<div className="logoEmirates"></div>
											<h2 className="subtitle">
												<ReactMarkdown>
													{aerolineaContent.fields.contentEmiratesIntro}
												</ReactMarkdown>
											</h2>
										</div>

										<div className="rightLogoEmirates">
											<div className="wrapperGridPermite">
												<div className="optionGrid">
													<div className="img"></div>
													<p>{aerolineaContent.fields.contentIconoExplicacion01}</p>
												</div>

												<div className="optionGrid">
													<div className="img"></div>
													<p>{aerolineaContent.fields.contentIconoExplicacion02}</p>
												</div>

												<div className="optionGrid">
													<div className="img"></div>
													<p>{aerolineaContent.fields.contentIconoExplicacion03}</p>
												</div>
											</div>
										</div>
									</div>

									<div className="midWarpperContent midwarpperTop">
										<div className="youKnowWhat">
											<p>
												<ReactMarkdown>
													{aerolineaContent.fields.contentDatoCuriso}
												</ReactMarkdown>
											</p>
										</div>
										<div className="yellowAlert">
											<div className="wrapperAlert">
												<div className="imgAlert"></div>
												<p>
													<ReactMarkdown>
														{aerolineaContent.fields.contentAlertaEmirates}
													</ReactMarkdown>
												</p>
											</div>
										</div>
									</div>

									<div className="bluMapCompetencia">
										<div className="midWrapperTop">
											<div className="fixedAbsolute"></div>
											<h2 className="subtitle">
												<ReactMarkdown>
													{aerolineaContent.fields.contentRiesgoConectividad}
												</ReactMarkdown>
											</h2>
											<p>{aerolineaContent.fields.contentMexico}</p>
										</div>
									</div>

									<div className="midWarpperContent midwarpperTop">
										<p className="subtitle" style={{ textAlign: "center" }}>
											{aerolineaContent.fields.contentOtrosPaisesTitulo}
										</p>

										<div className="gridFlags">
											<div className="gridOption">
												<div className="flag"></div>
												<p className="title">Estados Unidos</p>
												<p className="subti">{aerolineaContent.fields.contentEstadosUnidos}</p>
											</div>

											<div className="gridOption">
												<div className="flag"></div>
												<p className="title">Canadá</p>
												<p className="subti">{aerolineaContent.fields.contentCanada}</p>
											</div>

											<div className="gridOption">
												<div className="flag"></div>
												<p className="title">Unión Europea</p>
												<p className="subti">{aerolineaContent.fields.contentEuropa}</p>
											</div>
										</div>
									</div>

									<div className="blueTextbg">
										<div className="imgLogo"></div>
										<div className="textWrapper">
											<h2 className="subtitle">
												{aerolineaContent.fields.contentQueHacemosEnMexico}
											</h2>

											<div className="leftWrapperTexto">
												<p className="biggerPText">
													<b>{aerolineaContent.fields.contentGrupoAeromexico}</b>
												</p>
											</div>

											<div className="leftWrapperTexto">
												<p className="">{aerolineaContent.fields.contentSindicatosAm}</p>
												<a
													href={`${process.env.REACT_APP_SRC_URL}miam_docs/defendamos/SALIDA_DESPLEGADO%20NACIONAL_REFORMA.pdf`}
													target="_blank"
													rel="noopener noreferrer">
													{aerolineaContent.fields.contentDesplegadoBoton}
												</a>
											</div>

											{/* <p className="clearText">Los sindicatos respaldan esta petición <a href="https://grupoaeromexico-my.sharepoint.com/:b:/g/personal/eabautista_aeromexico_com/EVTmETF7pFVHsD-qWyxWLpYBtDJ1kwcJtKK5P0eQewTCDQ?e=CqsFaE" target="_blank">Conoce más</a></p> */}
										</div>

										<div className="impaktante">
											<p>{aerolineaContent.fields.contentFraseFinal}</p>
										</div>

										<div
											className="temsConditionsText"
											style={{ marginLeft: "12%", marginRight: "12%" }}>
											{/* {aerolineaContent.fields.contentPieDePagina} */}
											{/* <ReactMarkdown> */}
											<p className="descripcion">
												* Restoring Open Skies: the need to address subsidized competition from
												state-owned airlines in Qatar and the UAE - White paper (
												<strong>
													<a href="https://www.openandfairskies.com/">http://www.openandfairskies.com/wp-content/themes/custom/media/White.Paper.pdf</a>
												</strong>
												)
												<br />
												** Facts & figures de flota:
												<strong><a href="https://www.airbus.com/content/dam/corporate-topics/publications/backgrounders/Backgrounder-Airbus-Commercial-Aircraft-A380-Facts-and-Figures-EN.pdf">https://www.airbus.com/content/dam/corporate-topics/publications/backgrounders/Backgrounder-Airbus-Commercial-Aircraft-A380-Facts-and-Figures-EN.pdf</a></strong>
												<br/>*** La Política de Cielos Abiertos es un acuerdo que permite la
												liberalización del mercado de transporte aéreo de los países firmantes y
												se minimiza la intervención gubernamental en los servicios de pasajeros
												y carga. 
												<br/>**** Gobierno de Canadá y el Gobierno de los Emiratos Árabes
												Unidos sobre transporte aéreo.
												(<strong><a href="https://otc-cta.gc.ca/eng/transport-agreement/united-arab-emirates">https://otc-cta.gc.ca/eng/transport-agreement/united-arab-emirates</a></strong>).
												<br/>***** Competencia justa: Carta de los CEOs de Air France-KLM and
												Lufthansa Group CEOs a la Comisionada de Transporte de la Unión Europea:
												<strong><a href="https://twitter.com/airfranceklm/status/836866061211435008"> https://twitter.com/airfranceklm/status/836866061211435008</a></strong>
											</p>
											{/* </ReactMarkdown> */}
										</div>
									</div>
								</div>
								{/* Header Right */}
								<div className={aerolineaContent.fields.mostrarBotonClase + " " + "headerAirleGolfo"}>
									<div className="wrapMidTable">
										<div className="wrapMid">
											<div className="wrapMidElement">
												<p className="title">{aerolineaContent.fields.titulo}</p>
												<p className="text">
													<ReactMarkdown className="description">
														{aerolineaContent.fields.subtitle}
													</ReactMarkdown>
												</p>
												<p className="intro">{aerolineaContent.fields.textoChico}</p>

												<a
													className="firmarPeticion"
													href={aerolineaContent.fields.ligaDelBoton}
													target="_blank"
													rel="noopener noreferrer">
													{aerolineaContent.fields.textoDelBoton}
												</a>

												<p className="intro contamos">
													{aerolineaContent.fields.fraseDeCierre}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="fixedTop">
								<div className="rightBanner">
									<div className="bannerTopWrapper">
										<div className="wrapperButtons">
											<a className="sqDownload" onClick={() => this.openModal()}>
												<div className="iconText"></div>
												<p>{aerolineaContent.fields.contentPreguntas}</p>
											</a>

											<a
												className="sqDownload"
												href={`${process.env.REACT_APP_SRC_URL}miam_docs/defendamos/INFOGRAFI%CC%81A%20%28ESP&ING%29.pdf`}
												target="_blank"
												rel="noopener noreferrer">
												<div className="iconText"></div>
												<p>{aerolineaContent.fields.contentInfografiatext}</p>
											</a>
											<a className="sqDownload smallOption" onClick={() => this.openModal2()}>
												<div className="iconText"></div>
												<p>{aerolineaContent.fields.contentNoticias}</p>
											</a>
											<a className="sqDownload videoDeltaThis" onClick={this.showAlertModal2}>
												<div className="iconText"></div>
												<div className="filterimage"></div>
												<p>Andrés Conesa en México INSIGHTS 2019</p>
											</a>

											<a className="sqDownload videoDeltaThis" onClick={this.showAlertModal}>
												<div className="iconText"></div>
												<div className="filterimage"></div>
												<p>{aerolineaContent.fields.contentDelta}</p>
											</a>

											<a className="sqDownload defaultOption" onClick={() => this.openModal2()}>
												<div className="iconText"></div>
												<p>{aerolineaContent.fields.contentNoticias}</p>
											</a>
										</div>
									</div>
								</div>

								<div className="leftBanner"></div>
							</div>
						</div>
					))}
				
			</div>
		);
	}
}
