export default function defaultLang() {
	const lang = localStorage.getItem('i18nextLng');
	
	if (lang === null) {
		
		localStorage.setItem('i18nextLng', 'es');
		// i18next.changeLanguage('es');
		return true;
	}

	if (lang !== 'es' && lang !== 'en') {
		localStorage.setItem('i18nextLng', 'es');
		return true;
	}

	if (lang === 'es' || lang === 'en') {
		return true;
	}

	return false;
}
