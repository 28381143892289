import React, { Component } from 'react'
import Swiper from 'react-id-swiper'
import * as contentful from '../../lib/contentful'
import '../Slider/css/swiper.css'
import "../Slider/css/style.css"

export default class SliderWork extends Component {
    constructor() {
        super();
        this.state = {
          loading: true,
          error: null,
          entries: null
        };
      }
      componentDidMount() {
        this.fetchEntries();
      }
      
      fetchEntries = () => {
        contentful.client
          .getEntries({
            content_type: "articleSlider",
            include: 2,
            order: '-sys.updatedAt',
          })
          .then(response => response.items)
          .then(data => {
            this.setState({
              loading: false,
              entries: data
            });
          })
          .catch(error => {
            this.setState({
              loading: false,
              error: error
            });
          })
          .catch(err => console.error(err));
      };

      checkVideo = video => {
        if (typeof video === 'undefined') {
          return false
        } else {
          return true
        }
      }

  render () {
    const { loading, entries } = this.state

    const params = {
      slidesPerView: 'auto',
      spaceBetween: 0,
      centeredSlides: true,
      autoplay: {
        delay: 1000,
        disableOnInteraction: true
      },

      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
        // el: '.swiper-pagination',
        // type: 'fraction',
      },
      mousewheel: false,
      freeMode: false,
      loop: true,
      
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      
    }

    return (
      <React.Fragment>
        {!loading && entries && (
          <Swiper {...params} className="swiper-home-slider">
    
                    <div className="cxGalleryPicture" ></div> 
                    <div className="cxGalleryPicture cx02"></div>
                    <div className="cxGalleryPicture cx03"></div>
                    <div className="cxGalleryPicture cx04"></div>
                    <div className="cxGalleryPicture cx05"></div>
                    <div className="cxGalleryPicture cx06"></div>
                    <div className="cxGalleryPicture cx07"></div>
                    <div className="cxGalleryPicture cx08"></div>
                    <div className="cxGalleryPicture cx09"></div>
                    <div className="cxGalleryPicture cx10"></div>
                    <div className="cxGalleryPicture cx11"></div>
                    <div className="cxGalleryPicture cx12"></div>
                    <div className="cxGalleryPicture cx13"></div>
                    <div className="cxGalleryPicture cx14"></div>
                    <div className="cxGalleryPicture cx15"></div>
                    <div className="cxGalleryPicture cx16"></div>
                    <div className="cxGalleryPicture cx17"></div>
                    <div className="cxGalleryPicture cx18"></div>
                    <div className="cxGalleryPicture cx19"></div>
                    <div className="cxGalleryPicture cx20"></div>
          </Swiper>
        )}
      </React.Fragment>
    )
  }
}
