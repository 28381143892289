// ACTION CREATOR

// import libraries
import InfoEnvironment from '../../utils/info.environment';
const hex = require('string-hex');
const sha1 = require('sha1');

// ACTION TYPE CONSTANT
// TO PREVENT TYPO ERRORS
// export const LOADED_PUBLIC_USER = 'LOADED_PUBLIC_USER';
export const GET_PUBLIC_USER = 'GET_PUBLIC_USER';

// BETTER WAY TO DISPATCH DATA
// export function loadedPublicUser() {
// 	return {
// 		type: 'LOADED_PUBLIC_USER'
// 	};
// }

export function getPublicUser() {
	return async function(dispatch) {
		const infoEnvironment = new InfoEnvironment();
		const email = localStorage.getItem('email');
		const HOST = 'NOMBREHOST';
		const token = sha1(`${HOST}|${email}`).toUpperCase();
		const theEmail = hex(email).toUpperCase();
		const URL = infoEnvironment.api_url({host: window.location.hostname});
		const formData = new FormData();
		formData.append('token', token);
		formData.append('email', theEmail);
		formData.append('tipo', '1');
		formData.append('tipo_salida', 'JSON');
		const res = await fetch(URL, {
			body: formData,
			method: 'POST'
		}).catch();
		if (res) {
			const userData = await res.json();
			// return dispatch({
			// 	type: 'GET_PUBLIC_USER',
			// 	data: userData
			// });
		}
		return null;
	};
}
